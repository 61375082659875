import React, { useContext } from "react";
import Context from "../../store/Context";
import { ACTIONS } from "../../store/useGlobalState";
import style from "./style.module.css";

function Modal2({ content }) {
  const { setState } = useContext(Context);
  return (
    <div
      className="flex j-c-c"
      style={{
        position: "fixed",
        top: "0",
        right: "0",
        left: "0",
        bottom: "0",
        background: "rgb(0,0,0,.5)",
        zIndex: "200",
      }}
    >
      <section
        className={`${style.modal}`}
        style={{
          padding: "3.4rem 2rem",
          background: "white",
          borderRadius: "10px",
          minHeight: "30vh",
          maxHeight: "100vh",
          minWidth: "fit-content",
          maxWidth: "100%",
          overflow: "hidden",
          overflowY: "auto",
        }}
      >
        <div
          className="flex j-c-end w-100 cursor-pointer"
          style={{ position: "sticky", paddingRight: "2rem" }}
          onClick={() => setState({ type: ACTIONS.modal_2_Off })}
        >
          <i
            className="fa fa-times text-black"
            style={{ fontSize: "1.6rem" }}
          ></i>
        </div>
        {content}
      </section>
    </div>
  );
}

export default Modal2;
