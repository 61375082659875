import React, { Fragment, useContext, useEffect, useState } from "react";
import AuthService from "../../../misc/AuthService";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import axios from "axios";
import Constants from "../../../misc/Constants";
import OrderForm from "./OrderForm";
import Context from "../../../store/Context";
import { ACTIONS } from "../../../store/useGlobalState";

function Header(props) {
  const dispatch = useContext(Context)?.setState;
  const [state, setState] = useState({
    userInfo: AuthService.getAuth().user,
    notification: [],
    showVerify: false,
  });

  useEffect(() => {
    if (props.git) {
      document.getElementById("newOrderBtn").click();
      props.resetBtn();
    }
  }, [props]);

  const location = window.location.pathname;
  const specialPages = ["/faq", "account-settings"];

  function AlertKyc({ message, btn, link }) {
    return (
      <div className="flex v-flex w-100 j-c-b">
        <p>{message}</p>
        <a href={link}>
          <button
            className="btn btn-primary flex j-c-c"
            style={{
              height: "30px",
              width: "fit-content",
              margin: "0.5rem 0 0 0",
            }}
          >
            {btn}
          </button>
        </a>
      </div>
    );
  }

  function trigger(message, btn, link) {
    toast(<AlertKyc message={message} btn={btn} link={link} />);
    sessionStorage.setItem("alertTriggered", true);
  }

  useEffect(() => {
    let path = window.location.pathname;
    let alertTriggered = Boolean(sessionStorage.getItem("alertTriggered"));

    if (path === "/dashboard" || path === "/account-settings")
      // Getting KYC Data ........
      axios
        .get(
          `${Constants.API_BASE_URL}/users/kyc/documents`,
          AuthService.getAxiosHeaders(),
        )
        .then((res) => {
          let data = res.data;
          if (!data) {
            if (path !== "/account-settings" && !alertTriggered) {
              setState({ ...state, showVerify: true });
              trigger(
                "Verify your account to expand your transaction limit",
                "Verify Now",
                "/account-settings#kyc",
              );
            }
          } else if (data.documentCheckStatus === "failed") {
            if (path !== "/account-settings" && !alertTriggered) {
              setState({ ...state, showVerify: true });
              trigger(
                "Continue to complete your account verification",
                "Complete Verification",
                "/account-settings#kyc",
              );
            }
          }
        })
        .catch(() => false);
    //eslint-disable-next-line
  }, []);

  return (
    <Fragment>
      <ToastContainer autoClose={120000} />
      <div
        id="kt_header"
        className="header py-6 py-lg-0"
        data-kt-sticky="true"
        data-kt-sticky-name="header"
        data-kt-sticky-offset="{lg: '300px'}"
      >
        <div className="header-container container-xxl">
          <div className="page-title d-flex flex-column align-items-start justify-content-center flex-wrap me-lg-20 py-3 py-lg-0 me-3">
            <h1 className="d-flex flex-column text-dark fw-bolder my-1">
              <span className="text-white fs-1">{props.title}</span>
            </h1>
          </div>

          <div className="d-flex align-items-center flex-wrap">
            <div className="d-flex align-items-center py-3 py-lg-0">
              {state.userInfo && (
                <div className="me-3">
                  {state.showVerify && (
                    <a href="/account-settings#kyc">
                      <small
                        className="flex j-c-c btn btn-icon btn-custom btn-active-color-primary position-relative border border-primary px-5 bg-white"
                        style={{ width: "fit-content" }}
                      >
                        Verify Account{" "}
                        <i className="fas fa-circle text-primary m-2"></i>
                      </small>
                    </a>
                  )}
                </div>
              )}

              <div className="me-3">
                <small
                  className="btn btn-icon btn-custom btn-active-color-primary"
                  data-kt-menu-trigger="click"
                  data-kt-menu-attach="parent"
                  data-kt-menu-placement="bottom-end"
                >
                  {" "}
                  <span className="svg-icon svg-icon-1 svg-icon-white">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                    >
                      <path
                        d="M6.28548 15.0861C7.34369 13.1814 9.35142 12 11.5304 12H12.4696C14.6486 12 16.6563 13.1814 17.7145 15.0861L19.3493 18.0287C20.0899 19.3618 19.1259 21 17.601 21H6.39903C4.87406 21 3.91012 19.3618 4.65071 18.0287L6.28548 15.0861Z"
                        fill="black"
                      ></path>
                      <rect
                        opacity="0.3"
                        x="8"
                        y="3"
                        width="8"
                        height="8"
                        rx="4"
                        fill="black"
                      ></rect>
                    </svg>
                  </span>
                </small>

                <div
                  className="menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-800 menu-state-bg menu-state-primary fw-bold py-4 fs-6 w-275px"
                  data-kt-menu="true"
                >
                  <div className="menu-item px-3">
                    <div className="menu-content d-flex align-items-center px-3">
                      <div className="symbol symbol-50px me-5">
                        <span className="svg-icon svg-icon-primary svg-icon-3x ms-n1">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            fill="none"
                          >
                            <g
                              stroke="none"
                              strokeWidth="1"
                              fill="none"
                              fillRule="evenodd"
                            >
                              <polygon points="0 0 24 0 24 24 0 24" />
                              <path
                                d="M12,11 C9.790861,11 8,9.209139 8,7 C8,4.790861 9.790861,3 12,3 C14.209139,3 16,4.790861 16,7 C16,9.209139 14.209139,11 12,11 Z"
                                fill="#000000"
                                fillRule="nonzero"
                                opacity="0.3"
                              />
                              <path
                                d="M3.00065168,20.1992055 C3.38825852,15.4265159 7.26191235,13 11.9833413,13 C16.7712164,13 20.7048837,15.2931929 20.9979143,20.2 C21.0095879,20.3954741 20.9979143,21 20.2466999,21 C16.541124,21 11.0347247,21 3.72750223,21 C3.47671215,21 2.97953825,20.45918 3.00065168,20.1992055 Z"
                                fill="#000000"
                                fillRule="nonzero"
                              />
                            </g>
                          </svg>
                        </span>
                      </div>

                      <div className="d-flex flex-column">
                        <div className="fw-bolder d-flex align-items-center fs-5">
                          {state.userInfo?.user?.firstName}{" "}
                          {state.userInfo?.user?.lastName}
                        </div>
                        <small className="fw-bold text-muted text-hover-primary fs-7">
                          {state.userInfo?.emailIdentity?.email}
                        </small>
                      </div>
                    </div>
                  </div>

                  <div className="separator my-2"></div>

                  <div className="menu-item px-5 my-1">
                    <a href="/account-settings" className="menu-link px-5">
                      Account Settings
                    </a>
                  </div>

                  <div className="menu-item px-5">
                    <a href="/logout" className="menu-link px-5">
                      Sign Out
                    </a>
                  </div>
                </div>
              </div>

              {
                !specialPages.includes(location) && (
                  <small
                    className="btn btn-white me-2"
                    onClick={() => {
                      dispatch({
                        type: ACTIONS.modal_2_On,
                        content: <OrderForm />,
                        head: <></>,
                      });
                    }}
                    id="newOrderBtn"
                  >
                    New Order
                  </small>
                )
                // <small className="btn btn-white me-2" data-bs-toggle="modal" data-bs-target="#new_order_modal" id="newOrderBtn">New Order</small>
              }
            </div>
          </div>
        </div>

        <div className="header-offset"></div>
      </div>
    </Fragment>
  );
}

export default Header;
