import React, { useEffect, useState } from "react";
import axios from "axios";
import CONSTANTS from "../../../misc/Constants";
// import 'antd/dist/antd.css';
import AuthService from "../../../misc/AuthService";
import classNames from "classnames";
import Constants from "../../../misc/Constants";
import { ToastContainer } from "react-toastify";
import startTokenRefresh from "../../../misc/sessionExpiry";
import ltc from "../../../iamges/ltc.png";
import eth from "../../../iamges/eth.png";
function OrderInfo(props) {
  const [state, setState] = useState({
    hasPaymentMethod: false,
    isLoading: true,
    isRequesting: false,
    orderInfo: {},
    transactionNumber: "",
    errorMessage: "",
    successMessage: "",
    copied: false,
    orderUpdated: false,
    paymentMethod: {},
    showNewPayout: false,
    buyOrderConfirm: {
      accountName: "",
      accountNumber: "",
      orderId: "",
      networkTransactionId: "",
    },
    HodlpayAccount: {
      AccountNumber: "0245542760",
      AccountName: "Hodlpay Limited",
    },
  });
  // console.log("props", props);
  // eslint-disable-next-line
  function copiedText() {
    setState({
      ...state,
      copied: true,
    });

    setTimeout(() => {
      setState({
        ...state,
        copied: false,
      });
    }, 2000);
  }

  // function updateOrder(event, name, networkTransactionId, accountName, accountNumber) {
  //   let value = event
  //   console.log("event", networkTransactionId)
  //   console.log("accountName", accountName)
  //   console.log(
  //     "accountNumber", accountNumber
  //   )

  //   setState({
  //     ...state,
  //     buyOrderConfirm: { ...state.buyOrderConfirm, [name]: value },
  //   });
  // }

  function updateOrder(event, name, props) {
    let value = event.target.value;

    if (name === "accountName") {
      setState({
        ...state,
        buyOrderConfirm: { ...state.buyOrderConfirm, accountName: value },
      });
    } else if (name === "accountNumber") {
      setState({
        ...state,
        buyOrderConfirm: { ...state.buyOrderConfirm, accountNumber: value },
      });
    } else if (name === "networkTransactionId") {
      setState({
        ...state,
        transactionNumber: props?.passedOrder?.transaction?.accountNumber,
        buyOrderConfirm: {
          ...state.buyOrderConfirm,
          networkTransactionId: props?.orderID,
        },
      });
    }

    // console.log("state", state)
  }

  // eslint-disable-next-line
  function submitBuyOrderForm() {
    setState({
      ...state,
      isRequesting: true,
      errorMessage: "",
      successMessage: "",
    });
    const data = {
      ...state.buyOrderConfirm,
      accountName: state.HodlpayAccount.AccountName,
      accountNumber: state.HodlpayAccount.AccountNumber,
    };

    axios
      .post(
        CONSTANTS.API_BASE_URL + "/users/orders/payment/confirm",
        data,
        AuthService.getAxiosHeaders(),
      )
      .then(() => {
        let orderInfo = state.orderInfo;

        setState({
          ...state,
          isRequesting: false,
          orderUpdated: true,
          orderInfo: orderInfo,
        });
        closeModal();
        window.modalScrollUp();
      })
      .catch(() => {
        setState({
          ...state,
          isRequesting: false,
          errorMessage: "",
        });
      });
  }

  async function getOrderInfo() {
    try {
      let data = { ...props.passedOrder };

      setState((prevState) => ({
        ...prevState,
        orderInfo: { ...prevState.orderInfo, ...data },
        buyOrderConfirm: { ...prevState.buyOrderConfirm, orderId: data?.id },
        isLoading: true,
      }));
    } catch (error) {
      console.error("Failed to get order info:", error);
      setState((prevState) => ({
        ...prevState,
        isLoading: false,
      }));
    }
  }

  function getUserPaymentMethod() {
    setState({
      ...state,
      isLoading: true,
      isRequesting: true,
    });
    axios
      .get(
        `${Constants.API_BASE_URL}/users/payment/method`,
        AuthService.getAxiosHeaders(),
      )
      .then((res) => {
        if (res) {
          setState((prev) => {
            return {
              ...prev,
              isLoading: false,
              isRequesting: false,
              hasPaymentMethod: true,
              paymentMethod: {
                ...res.data,
                accountName: res?.data?.accountName,
                network: res.data?.network,
                accountNumber: res.data?.phoneNumber,
              },
              buyOrderConfirm: {
                ...state.buyOrderConfirm,
                accountNumber: res.data?.phoneNumber,
                accountName: res.data?.accountName,
              },
            };
          });
        } else {
          setState({
            ...state,
            isLoading: false,
            hasPaymentMethod: false,
          });
        }
      })
      .catch((err) => {
        setState({
          ...state,
          isLoading: false,
        });
        if (err.response.status === 401) {
          startTokenRefresh(true);
        }
      });
  }

  function closeModal() {
    props.orderUpdate(false);

    if (window.location.href.includes("settings")) {
      window.location = document.referrer;
    }
  }

  function approveOrder() {
    if (window.confirm("Are you sure you want to approve this order?")) {
      let data = {
        orderId: props?.passedOrder?.id,
        status: "approved",
      };

      setState({ ...state, isRequesting: true });

      axios
        .post(
          `${Constants.API_BASE_URL}/admin/orders/buy/confirm`,
          data,
          AuthService.getAxiosHeaders(),
        )
        .then((res) => {
          setState({ ...state, isRequesting: false });
          window.alert("Order has been approved!");

          // console.log("state:", state)
        })
        .catch((err) => {
          setState({ ...state, isRequesting: false });
          // Handle errors as needed
          // console.error("Error approving order:", err);
          // console.log("data:", data);
        });
    }
  }
  function completeBuyOrder() {
    if (window.confirm("Are you sure you want to complete this order?")) {
      let data = {
        orderId: props?.passedOrder?.id,
        status: "completed",
      };
      // console.log("datta:", data);

      setState({ ...state, isRequesting: true });

      axios
        .post(
          `${Constants.API_BASE_URL}/admin/orders/buy/confirm`,
          data,
          AuthService.getAxiosHeaders(),
        )
        .then((res) => {
          setState({ ...state, isRequesting: false });
          window.alert("Order has been completed!");

          // console.log("state:", state)
        })
        .catch((err) => {
          setState({ ...state, isRequesting: false });
          // Handle errors as needed
          // console.error("Error approving order:", err);
          // console.log("data:", data);
        });
    }
  }

  function completeOrder() {
    if (window.confirm("Are you sure you want to complete this order?")) {
      let data = {
        orderId: props?.passedOrder?.id,
        status: "completed",
      };
      // console.log("datta:", data);

      setState({ ...state, isRequesting: true });

      axios
        .post(
          `${Constants.API_BASE_URL}/admin/orders/sell/confirm`,
          data,
          AuthService.getAxiosHeaders(),
        )
        .then((res) => {
          setState({ ...state, isRequesting: false });
          window.alert("Order has been completed!");

          // console.log("state:", state)
        })
        .catch((err) => {
          setState({ ...state, isRequesting: false });
          // Handle errors as needed
          // console.error("Error approving order:", err);
          // console.log("data:", data);
        });
    }
  }

  function cancelOrder(id) {
    if (window.confirm("Are you sure you want to cancel this order")) {
      let data = {
        orderId: props?.passedOrder?.id,
        status: "canceled",
      };
      // console.log("headers:", AuthService.getAxiosHeaders());
      setState({ ...state, isRequesting: true });
      axios
        .post(
          `${Constants.API_BASE_URL}/admin/orders/buy/confirm`,
          data,
          AuthService.getAxiosHeaders(),
        )
        .then((res) => {
          setState({ ...state, isRequesting: false });
          window.location.reload();
        })
        .catch((err) => {
          setState({ ...state, isRequesting: false });
        });
    }
  }

  function cancelSellOrder(id) {
    if (window.confirm("Are you sure you want to cancel this order")) {
      let data = {
        orderId: props?.passedOrder?.id,
        status: "canceled",
      };
      setState({ ...state, isRequesting: true });
      axios
        .post(
          `${Constants.API_BASE_URL}/admin/orders/sell/confirm`,
          data,
          AuthService.getAxiosHeaders(),
        )
        .then((res) => {
          setState({ ...state, isRequesting: false });
          window.location.reload();
        })
        .catch((err) => {
          setState({ ...state, isRequesting: false });
          console.log(err.response);
        });
    }
  }

  useEffect(() => {
    getUserPaymentMethod();

    getOrderInfo();
    // eslint-disable-next-line
  }, []);

  return (
    <div className="order-info">
      <ToastContainer autoClose={120000} />
      <div className="modal-body scroll-y mx-5 mx-xl-18 pt-0 pb-15">
        <div className="text-center mb-13">
          <h1 className="font-bold text-[25px]">
            {`${props?.passedOrder?.type
              ?.slice(0, 1)
              ?.toUpperCase()}${props?.passedOrder?.type?.slice(1)}`}{" "}
            Order Details
          </h1>
        </div>

        {state.isLoading || state.isRequesting ? (
          <div className="text-center py-20">
            <div className="spinner-border" role="status">
              <span className="sr-only">Loading...</span>
            </div>
          </div>
        ) : (
          <div className="py-5">
            <div className="flex flex-col justify-center align-center mb-9">
              {props?.passedOrder?.asset === "BTC" ? (
                <img
                  src="/assets/media/svg/brand-logos/btc.svg"
                  className="h-80px w-60px  align-center justify-center  mb-8"
                  alt=""
                />
              ) : props?.passedOrder?.asset === "LTC" ? (
                <img
                  src={ltc}
                  className="h-60px w-60px align-self-center mb-8"
                  alt=""
                />
              ) : props?.passedOrder?.asset === "ETH" ? (
                <img
                  src={eth}
                  className="h-60px w-60px align-center justify-center mb-8"
                  alt=""
                />
              ) : (
                <img
                  src="/assets/media/svg/brand-logos/usdt.svg"
                  className="h-80px w-60px align-self-center mb-8"
                  alt=""
                />
              )}
              <div className="text-center">
                <div className="fs-3x text-dark fw-bolder lh-1 mb-4">
                  ${props?.passedOrder?.amount}
                </div>
                <div className="fs-6 text-gray-600 fw-bold">
                  Wallet Address:{" "}
                  <b>{props?.passedOrder?.transaction?.walletAddress ?? ""}</b>
                </div>
              </div>
            </div>

            <div
              className={classNames(
                "alert d-flex align-items-center p-5 mb-10 w-100",
                {
                  "bg-light-danger": ["cancelled", "refunded"].includes(
                    props?.passedOrder?.status,
                  ),
                },
                {
                  "bg-light-warning": [
                    "pending_payment",
                    "pending_verification",
                    "pending",
                  ].includes(props?.passedOrder?.status),
                },
                {
                  "bg-light-primary": props?.passedOrder?.status === "approved",
                },
              )}
            >
              <span
                className={classNames(
                  "svg-icon svg-icon-2hx me-4",
                  {
                    "svg-icon-danger": ["cancelled", "refunded"].includes(
                      props?.passedOrder?.status,
                    ),
                  },
                  {
                    "svg-icon-warning": [
                      "pending_payment",
                      "pending_verification",
                      "pending",
                    ].includes(props?.passedOrder?.status),
                  },
                  {
                    "svg-icon-primary":
                      props?.passedOrder?.status === "approved",
                  },
                )}
              >
                {props?.passedOrder?.status === "approved" ? (
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                  >
                    <g
                      stroke="none"
                      strokeWidth="1"
                      fill="none"
                      fillRule="evenodd"
                    >
                      <polygon points="0 0 24 0 24 24 0 24" />
                      <path
                        d="M9.26193932,16.6476484 C8.90425297,17.0684559 8.27315905,17.1196257 7.85235158,16.7619393 C7.43154411,16.404253 7.38037434,15.773159 7.73806068,15.3523516 L16.2380607,5.35235158 C16.6013618,4.92493855 17.2451015,4.87991302 17.6643638,5.25259068 L22.1643638,9.25259068 C22.5771466,9.6195087 22.6143273,10.2515811 22.2474093,10.6643638 C21.8804913,11.0771466 21.2484189,11.1143273 20.8356362,10.7474093 L17.0997854,7.42665306 L9.26193932,16.6476484 Z"
                        fill="#000000"
                        fillRule="nonzero"
                        opacity="0.3"
                        transform="translate(14.999995, 11.000002) rotate(-180.000000) translate(-14.999995, -11.000002) "
                      />
                      <path
                        d="M4.26193932,17.6476484 C3.90425297,18.0684559 3.27315905,18.1196257 2.85235158,17.7619393 C2.43154411,17.404253 2.38037434,16.773159 2.73806068,16.3523516 L11.2380607,6.35235158 C11.6013618,5.92493855 12.2451015,5.87991302 12.6643638,6.25259068 L17.1643638,10.2525907 C17.5771466,10.6195087 17.6143273,11.2515811 17.2474093,11.6643638 C16.8804913,12.0771466 16.2484189,12.1143273 15.8356362,11.7474093 L12.0997854,8.42665306 L4.26193932,17.6476484 Z"
                        fill="#000000"
                        fillRule="nonzero"
                        transform="translate(9.999995, 12.000002) rotate(-180.000000) translate(-9.999995, -12.000002) "
                      />
                    </g>
                  </svg>
                ) : (
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                  >
                    <g
                      stroke="none"
                      strokeWidth="1"
                      fill="none"
                      fillRule="evenodd"
                    >
                      <rect x="0" y="0" width="24" height="24" />
                      <circle
                        fill="#000000"
                        opacity="0.3"
                        cx="12"
                        cy="12"
                        r="10"
                      />
                      <rect
                        fill="#000000"
                        x="11"
                        y="10"
                        width="2"
                        height="7"
                        rx="1"
                      />
                      <rect
                        fill="#000000"
                        x="11"
                        y="7"
                        width="2"
                        height="2"
                        rx="1"
                      />
                    </g>
                  </svg>
                )}
              </span>
              <div className="d-flex flex-column">
                <h4
                  className={classNames(
                    "mb-1 ",
                    {
                      "text-danger": ["cancelled", "refunded"].includes(
                        props?.passedOrder?.status,
                      ),
                    },
                    {
                      "text-warning": [
                        "pending_payment",
                        "pending_verification",
                      ].includes(props?.passedOrder?.status),
                    },
                    {
                      "text-primary": props?.passedOrder?.status === "approved",
                    },
                  )}
                >
                  Order Status:{" "}
                </h4>
                <span>{`${props.passedOrder?.status}`}</span>
                <div className="fs-7 text-muted">
                  Order ID: <b>{props?.passedOrder?.id}</b>
                </div>
              </div>
            </div>

            {state.errorMessage.length > 0 && (
              <div className="alert alert-danger d-flex align-items-center p-5 mb-10 w-100">
                <span className="svg-icon svg-icon-2hx svg-icon-danger me-4">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                  >
                    <g
                      stroke="none"
                      strokeWidth="1"
                      fill="none"
                      fillRule="evenodd"
                    >
                      <rect x="0" y="0" width="24" height="24" />
                      <circle
                        fill="#000000"
                        opacity="0.3"
                        cx="12"
                        cy="12"
                        r="10"
                      />
                      <rect
                        fill="#000000"
                        x="11"
                        y="10"
                        width="2"
                        height="7"
                        rx="1"
                      />
                      <rect
                        fill="#000000"
                        x="11"
                        y="7"
                        width="2"
                        height="2"
                        rx="1"
                      />
                    </g>
                  </svg>
                </span>
                <div className="d-flex flex-column">
                  <h4 className="mb-1 text-danger">Error</h4>{" "}
                  <span>{state.errorMessage}</span>{" "}
                </div>
              </div>
            )}

            <div className="fv-row mt-8">
              <div className="summary mt-10 p-7">
                <div className="table-responsive">
                  <table className="table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4 my-0">
                    <tbody>
                      <tr>
                        <td className="ps-0">
                          {" "}
                          <span className="text-gray-800 fw-bolder text-hover-primary mb-1 fs-6 text-start pe-0">
                            Buy Amount
                          </span>{" "}
                        </td>
                        <td>
                          {" "}
                          <span className="text-gray-800 fw-bolder d-block fs-6 ps-0 text-end">
                            ${props?.passedOrder?.amount}
                          </span>{" "}
                        </td>
                      </tr>
                      <tr>
                        <td className="ps-0">
                          {" "}
                          <span className="text-gray-800 fw-bolder text-hover-primary mb-1 fs-6 text-start pe-0">
                            Fees{" "}
                            <small className="text-muted">
                              ({props?.passedOrder?.feeType} priority)
                            </small>
                          </span>{" "}
                        </td>
                        <td>
                          {" "}
                          <span className="text-gray-800 fw-bolder d-block fs-6 ps-0 text-end">
                            ${props?.passedOrder?.fee}
                          </span>{" "}
                        </td>
                      </tr>
                      <tr>
                        <td className="ps-0">
                          {" "}
                          <span className="text-gray-800 fw-bolder text-hover-primary mb-1 fs-6 text-start pe-0">
                            Rate
                          </span>{" "}
                        </td>
                        <td>
                          <span className="text-gray-800 fw-bolder d-block fs-6 ps-0 text-end">
                            GHS {props?.passedOrder?.rate}
                          </span>
                        </td>
                      </tr>
                      <tr>
                        <td className="ps-0">
                          {" "}
                          <span className="text-gray-800 fw-bolder text-hover-primary mb-1 fs-6 text-start pe-0">
                            Crypto Amount
                          </span>{" "}
                        </td>
                        <td>
                          <span className="text-gray-800 fw-bolder d-block fs-6 ps-0 text-end">
                            {props?.passedOrder?.cryptoAmount}{" "}
                            {props?.passedOrder?.asset}
                          </span>
                        </td>
                      </tr>
                      <tr>
                        <td className="ps-0">
                          {" "}
                          <span className="text-gray-800 fw-bolder text-hover-primary mb-1 fs-6 text-start pe-0">
                            User Wallet Address
                          </span>{" "}
                        </td>
                        <td>
                          <span className="text-gray-800 fw-bolder d-block fs-6 ps-0 text-end">
                            {props?.passedOrder?.transaction?.walletAddress}
                          </span>
                        </td>
                      </tr>
                      <tr>
                        <td className="ps-0">
                          {" "}
                          <span className="text-gray-800 fw-bolder text-hover-primary mb-1 fs-6 text-start pe-0">
                            Order ID
                          </span>{" "}
                        </td>
                        <td>
                          <span className="text-gray-800 fw-bolder d-block fs-6 ps-0 text-end">
                            {props?.passedOrder?.id}
                          </span>
                        </td>
                      </tr>
                      {props?.passedOrder?.status !== "pending" &&
                        props?.passedOrder?.status !== "canceled" &&
                        props?.passedOrder?.status !== "failed" && (
                          <tr>
                            <td className="ps-0">
                              {" "}
                              <span className="text-gray-800 fw-bolder text-hover-primary mb-1 fs-6 text-start pe-0">
                                Network Transaction ID
                              </span>{" "}
                            </td>
                            <td>
                              <span className="text-gray-800 fw-bolder d-block fs-6 ps-0 text-end">
                                {
                                  props?.passedOrder?.transaction
                                    ?.networkTransactionId
                                }
                              </span>
                            </td>
                          </tr>
                        )}
                      <tr>
                        <td>
                          {props?.passedOrder?.type === "sell" && (
                            <a
                              href={
                                props?.passedOrder?.transaction.paymentType ===
                                "usdt_trc20"
                                  ? `https://tronscan.org/#/address/${props?.passedOrder?.transaction?.walletAddress}`
                                  : `https://blockchair.com/bitcoin/address/${props?.passedOrder?.transaction?.walletAddress}`
                              }
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              <span
                                style={{ textDecoration: "underline" }}
                                className="text-green-800 fw-bolder text-hover-primary mb-1 fs-6 text-start pe-0"
                              >
                                View Block Explorer
                              </span>
                            </a>
                          )}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <div className="mb-10 mt-5 px-9 text-center">
                  <span className="fs-6 fw-bold text-gray-400">Total:</span>
                  <div className="d-flex align-items-center mb-2 justify-content-center">
                    <span className="fs-4 fw-bold text-gray-400 align-self-start me-2 pr-5>">
                      GHS
                    </span>
                    <span className="fs-2hx fw-bolder text-gray-800 me-2 lh-1">
                      {(
                        parseFloat(
                          props?.passedOrder?.fee * props?.passedOrder?.rate,
                        ) +
                        parseInt(
                          props?.passedOrder?.amount * props?.passedOrder?.rate,
                        )
                      ).toFixed(2)}
                    </span>
                  </div>
                  <br />
                </div>
              </div>
            </div>

            {props?.passedOrder?.type === "buy" ? (
              <div className="order">
                {(props?.passedOrder?.status === "pending_payment" ||
                  props?.passedOrder?.status === "pending") && (
                  <>
                    <div
                      className="pm_box mb-10 bg-light-primary p-10"
                      style={{ borderRadius: "15px" }}
                    >
                      <div className="form-label-group">
                        <label className="form-label">
                          Payment will be made to :
                        </label>
                      </div>
                      <label className="d-flex flex-stack mb-5 cursor-pointer">
                        <span className="d-flex align-items-center me-2">
                          <span className="symbol symbol-20px me-6">
                            <span className="symbol-label bg-light-primary">
                              <span className="svg-icon svg-icon-1 svg-icon-primary">
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="24"
                                  height="24"
                                  viewBox="0 0 24 24"
                                  fill="none"
                                >
                                  <g
                                    stroke="none"
                                    strokeWidth="1"
                                    fill="none"
                                    fillRule="evenodd"
                                  >
                                    <rect x="0" y="0" width="24" height="24" />
                                    <path
                                      d="M7.5,4 L7.5,19 L16.5,19 L16.5,4 L7.5,4 Z M7.71428571,2 L16.2857143,2 C17.2324881,2 18,2.8954305 18,4 L18,20 C18,21.1045695 17.2324881,22 16.2857143,22 L7.71428571,22 C6.76751186,22 6,21.1045695 6,20 L6,4 C6,2.8954305 6.76751186,2 7.71428571,2 Z"
                                      fill="#000000"
                                      fillRule="nonzero"
                                    />
                                    <polygon
                                      fill="#000000"
                                      opacity="0.3"
                                      points="7.5 4 7.5 19 16.5 19 16.5 4"
                                    />
                                  </g>
                                </svg>
                              </span>
                            </span>
                          </span>
                          <span className="d-flex flex-column">
                            <span className="fw-bolder fs-6">
                              {state.paymentMethod.network}
                            </span>
                            <span className="fs-7 text-muted">
                              Account Number:{" "}
                              <b className="text-dark">
                                {state.HodlpayAccount.AccountNumber}
                              </b>
                            </span>
                            <span className="fs-7 text-muted">
                              Account Name:{" "}
                              <b className="text-dark">
                                {state.HodlpayAccount.AccountName}
                              </b>
                            </span>
                          </span>
                        </span>
                      </label>
                    </div>
                  </>
                )}

                {props?.passedOrder?.status?.includes("waiting") && (
                  <>
                    <div
                      className="pm_box mb-10 bg-light-primary p-10"
                      style={{ borderRadius: "15px" }}
                    >
                      <div className="form-label-group">
                        <label className="form-label">Payment made to :</label>
                      </div>
                      <label className="d-flex flex-stack mb-5 cursor-pointer">
                        <span className="d-flex align-items-center me-2">
                          <span className="symbol symbol-20px me-6">
                            <span className="symbol-label bg-light-primary">
                              <span className="svg-icon svg-icon-1 svg-icon-primary">
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="24"
                                  height="24"
                                  viewBox="0 0 24 24"
                                  fill="none"
                                >
                                  <g
                                    stroke="none"
                                    strokeWidth="1"
                                    fill="none"
                                    fillRule="evenodd"
                                  >
                                    <rect x="0" y="0" width="24" height="24" />
                                    <path
                                      d="M7.5,4 L7.5,19 L16.5,19 L16.5,4 L7.5,4 Z M7.71428571,2 L16.2857143,2 C17.2324881,2 18,2.8954305 18,4 L18,20 C18,21.1045695 17.2324881,22 16.2857143,22 L7.71428571,22 C6.76751186,22 6,21.1045695 6,20 L6,4 C6,2.8954305 6.76751186,2 7.71428571,2 Z"
                                      fill="#000000"
                                      fillRule="nonzero"
                                    />
                                    <polygon
                                      fill="#000000"
                                      opacity="0.3"
                                      points="7.5 4 7.5 19 16.5 19 16.5 4"
                                    />
                                  </g>
                                </svg>
                              </span>
                            </span>
                          </span>
                          <span className="d-flex flex-column">
                            <span className="fw-bolder fs-6">
                              {state.paymentMethod.network}
                            </span>
                            <span className="fs-7 text-muted">
                              Account Number:{" "}
                              <b className="text-dark">
                                {state.HodlpayAccount.AccountNumber}
                              </b>
                            </span>
                            <span className="fs-7 text-muted">
                              Account Name:{" "}
                              <b className="text-dark">
                                {state.HodlpayAccount.AccountName}
                              </b>
                            </span>
                          </span>
                        </span>
                      </label>
                    </div>
                    <div className="d-flex flex-center">
                      {state.isRequesting ? (
                        <button
                          type="button"
                          className="btn btn-lg btn-primary"
                          disabled
                        >
                          <span className="">
                            Please wait...
                            <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                          </span>
                        </button>
                      ) : (
                        <>
                          <button
                            onClick={() =>
                              approveOrder(
                                "buy",
                                "approved",
                                props?.passedOrder?.id,
                              )
                            }
                            className="btn btn-primary me-2"
                            style={{ margin: "0 0.5rem 0 0.5rem" }}
                          >
                            Approve
                          </button>
                          <button
                            onClick={() =>
                              cancelOrder(
                                "buy",
                                "canceled",
                                props?.passedOrder?.id,
                              )
                            }
                            className="btn btn-danger me-2"
                            style={{ margin: "0 0.5rem 0 0.5rem" }}
                          >
                            Cancel
                          </button>
                        </>
                      )}
                    </div>
                  </>
                )}

                {(props?.passedOrder?.status === "approved" ||
                  props?.passedOrder?.status === "cancelled" ||
                  props?.passedOrder?.status === "refunded") && (
                  <>
                    <div
                      className="pm_box mb-10 bg-light-primary p-10"
                      style={{ borderRadius: "15px" }}
                    >
                      <div className="form-label-group">
                        <label className="form-label">Payment made to:</label>
                      </div>
                      <label className="d-flex flex-stack mb-5 cursor-pointer">
                        <span className="d-flex align-items-center me-2">
                          <span className="symbol symbol-20px me-6">
                            <span className="symbol-label bg-light-primary">
                              <span className="svg-icon svg-icon-1 svg-icon-primary">
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="24"
                                  height="24"
                                  viewBox="0 0 24 24"
                                  fill="none"
                                >
                                  <g
                                    stroke="none"
                                    strokeWidth="1"
                                    fill="none"
                                    fillRule="evenodd"
                                  >
                                    <rect x="0" y="0" width="24" height="24" />
                                    <path
                                      d="M7.5,4 L7.5,19 L16.5,19 L16.5,4 L7.5,4 Z M7.71428571,2 L16.2857143,2 C17.2324881,2 18,2.8954305 18,4 L18,20 C18,21.1045695 17.2324881,22 16.2857143,22 L7.71428571,22 C6.76751186,22 6,21.1045695 6,20 L6,4 C6,2.8954305 6.76751186,2 7.71428571,2 Z"
                                      fill="#000000"
                                      fillRule="nonzero"
                                    />
                                    <polygon
                                      fill="#000000"
                                      opacity="0.3"
                                      points="7.5 4 7.5 19 16.5 19 16.5 4"
                                    />
                                  </g>
                                </svg>
                              </span>
                            </span>
                          </span>
                          <span className="d-flex flex-column">
                            <span className="fw-bolder fs-6">
                              {state.orderInfo.payment_type}
                            </span>
                            <span className="fs-7 text-muted">
                              Account Number:{" "}
                              <b>{state.HodlpayAccount.AccountNumber}</b>
                            </span>
                            <span className="fs-7 text-muted">
                              Account Name:{" "}
                              <b>{state.HodlpayAccount.AccountName}</b>
                            </span>
                          </span>
                        </span>
                      </label>
                      <div className="form-group mt-8">
                        <label className="form-label">
                          Payment Transaction Number:
                        </label>
                        <div className="fs-7 text-muted">
                          <b>
                            {" "}
                            {props?.passedOrder?.payment_transaction_number}
                          </b>
                        </div>
                      </div>
                      <div className="separator separator-dashed my-4"></div>
                      <div className="form-group mt-8">
                        <label className="form-label">Transaction Hash:</label>
                        <div className="fs-7 text-muted">
                          <b> {props?.passedOrder?.transactionId}</b>
                        </div>
                      </div>
                    </div>
                    <div className="d-flex flex-center">
                      {state.isRequesting ? (
                        <button
                          type="button"
                          className="btn btn-lg btn-primary"
                          disabled
                        >
                          <span className="">
                            Please wait...
                            <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                          </span>
                        </button>
                      ) : (
                        <>
                          <button
                            onClick={() =>
                              completeBuyOrder(
                                "buy",
                                "completed",
                                props?.passedOrder?.id,
                              )
                            }
                            className="btn btn-primary me-2"
                            style={{ margin: "0 0.5rem 0 0.5rem" }}
                          >
                            Complete
                          </button>
                          <button
                            onClick={() =>
                              cancelOrder(
                                "buy",
                                "canceled",
                                props?.passedOrder?.id,
                              )
                            }
                            className="btn btn-danger me-2"
                            style={{ margin: "0 0.5rem 0 0.5rem" }}
                          >
                            Cancel
                          </button>
                        </>
                      )}
                    </div>
                  </>
                )}
              </div>
            ) : (
              <div className="sell_order_info">
                {(props?.passedOrder?.status).includes("waiting") && (
                  <>
                    <div
                      className="pm_box mb-10 bg-light-primary p-10"
                      style={{ borderRadius: "15px" }}
                    >
                      <div>
                        <div className="form-label-group">
                          <label className="form-label">Payout Account:</label>
                        </div>
                        <span className="d-flex flex-column">
                          <span className="fs-7 text-muted">
                            Account Type:{" "}
                            {props?.passedOrder?.paymentMethod?.branch
                              ? state.paymentMethod?.type
                              : "Mobile Money"}{" "}
                            -{" "}
                            <b>
                              {props?.passedOrder?.paymentMethod?.branch
                                ? state.paymentMethod?.payout_type_name
                                : state.paymentMethod?.network}
                            </b>
                          </span>
                          <span className="fs-7 text-muted">
                            Account Number:{" "}
                            {/* <b>{state.orderInfo.transaction.accountNumber}</b> */}
                          </span>
                          <span className="fs-7 text-muted">
                            Account Name:{" "}
                            {/* <b>{state.orderInfo.transaction.accountName}</b> */}
                          </span>
                          {state.paymentMethod?.branch && (
                            <span className="fs-7 text-muted">
                              Account Branch:{" "}
                              <b>{props?.passedOrder?.paymentMethod?.branch}</b>
                            </span>
                          )}
                        </span>
                      </div>
                      <div className="separator separator-dashed my-4"></div>
                    </div>
                    <div className="d-flex flex-center">
                      {state.isRequesting ? (
                        <button
                          type="button"
                          className="btn btn-lg btn-primary"
                          disabled
                        >
                          <span className="">
                            Please wait...
                            <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                          </span>
                        </button>
                      ) : (
                        <>
                          {props?.passedOrder?.status?.includes("approval") &&
                            props?.passedOrder?.transaction
                              .cryptoTransactionStatus === "complete" &&
                            props?.passedOrder?.transaction.paymentMethod ===
                              "mtn" && (
                              <button
                                onClick={() =>
                                  updateOrder(
                                    "sell",
                                    "ManualPayment",
                                    props?.passedOrder?.id,
                                  )
                                }
                                className="btn btn-primary me-2"
                                style={{ margin: "0 0.5rem 0 0.5rem" }}
                              >
                                Make Manual Payment
                              </button>
                            )}
                          <button
                            onClick={() =>
                              cancelSellOrder(
                                "sell",
                                "canceled",
                                props?.passedOrder?.id,
                              )
                            }
                            className="btn btn-danger me-2"
                            style={{ margin: "0 0.5rem 0 0.5rem" }}
                          >
                            Cancel{" "}
                          </button>
                        </>
                      )}
                    </div>
                  </>
                )}

                {(props?.passedOrder?.status === "approved" ||
                  props?.passedOrder?.status === "cancelled" ||
                  props?.passedOrder?.status === "refunded") && (
                  <div
                    className="pm_box mb-10 bg-light-primary p-10"
                    style={{ borderRadius: "15px" }}
                  >
                    <div className="form-group mt-8">
                      <label className="form-label">Transaction Hash:</label>
                      <div className="fs-7 text-muted">
                        <b> {props?.passedOrder?.transactionId}</b>
                      </div>
                    </div>
                    <div className="separator separator-dashed my-4"></div>
                    <div>
                      <div className="form-label-group">
                        <label className="form-label">Payout Account:</label>
                      </div>
                      <span className="d-flex flex-column">
                        <span className="fs-7 text-muted">
                          Account Type:{" "}
                          {props?.passedOrder?.paymentMethod?.branch
                            ? state.paymentMethod?.type
                            : "Mobile Money"}{" "}
                          -{" "}
                          <b>
                            {props?.passedOrder?.paymentMethod?.branch
                              ? state.paymentMethod?.payout_type_name
                              : state.paymentMethod?.network}
                          </b>
                        </span>
                        <span className="fs-7 text-muted">
                          Account Number:{" "}
                          <b>{props?.passedOrder?.transaction.accountNumber}</b>
                        </span>
                        <span className="fs-7 text-muted">
                          Account Name:{" "}
                          <b>{props?.passedOrder?.transaction.accountName}</b>
                        </span>
                        {state.paymentMethod?.branch && (
                          <span className="fs-7 text-muted">
                            Account Branch:{" "}
                            <b>{props?.passedOrder?.paymentMethod?.branch}</b>
                          </span>
                        )}
                      </span>
                    </div>

                    <div className="d-flex flex-center">
                      {state.isRequesting ? (
                        <button
                          type="button"
                          className="btn btn-lg btn-primary"
                          disabled
                        >
                          <span className="">
                            Please wait...
                            <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                          </span>
                        </button>
                      ) : (
                        <>
                          <button
                            onClick={() =>
                              completeOrder(
                                "sell",
                                "completed",
                                props?.passedOrder?.id,
                              )
                            }
                            className="btn btn-primary me-2"
                            style={{ margin: "0 0.5rem 0 0.5rem" }}
                          >
                            Complete
                          </button>
                          <button
                            onClick={() =>
                              cancelSellOrder(
                                "sell",
                                "canceled",
                                props?.passedOrder?.id,
                              )
                            }
                            className="btn btn-danger me-2"
                            style={{ margin: "0 0.5rem 0 0.5rem" }}
                          >
                            Cancel
                          </button>
                        </>
                      )}
                    </div>
                  </div>
                )}
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  );
}

export default OrderInfo;
