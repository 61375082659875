import React from "react";
import Constants from "../../../misc/Constants";
class Footer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      currentYear: new Date().getFullYear(),
    };
  }

  componentDidMount() {
    this.setState({ ...this.state, currentYear: new Date().getFullYear() });
  }

  render() {
    return (
      <div className="footer py-4 d-flex flex-lg-column" id="kt_footer">
        <div className="container-xxl d-flex flex-column flex-md-row flex-stack">
          <div className="text-dark order-2 order-md-1">
            <span className="text-gray-400 fw-bold me-1">
              © {this.state.currentYear} HodlPay. All Rights Reserved
            </span>
          </div>

          <ul className="menu menu-gray-600 menu-hover-primary fw-bold order-1">
            <li className="menu-item">
              <a
                href={`${Constants.WEB_URL}/#about`}
                target="_blank"
                className="menu-link px-2"
                rel="noreferrer"
              >
                About
              </a>
            </li>
            <li className="menu-item">
              <a
                href={`${Constants.WEB_URL}/terms-and-conditions`}
                target="_blank"
                className="menu-link px-2"
                rel="noreferrer"
              >
                Terms and Conditions
              </a>
            </li>
            <li className="menu-item">
              <a
                href={`${Constants.WEB_URL}/privacy-policy`}
                target="_blank"
                className="menu-link px-2"
                rel="noreferrer"
              >
                Privacy Policy
              </a>
            </li>
          </ul>
        </div>
      </div>
    );
  }
}

export default Footer;
