import React, { Fragment, useEffect, useState } from "react";
import MetaTags from "react-meta-tags";
import AuthService from "../../misc/AuthService";
import cookie from "react-cookies";
import axios from "axios";
import Constants from "../../misc/Constants";
import ico from "./include/ico.png";
import { useParams } from "react-router-dom";
import { reactLocalStorage } from "reactjs-localstorage";
import ReactGA from "react-ga";
import startTokenRefresh from "../../misc/sessionExpiry";

function EmailVerification(props) {
  const params = useParams();
  const [state, setState] = useState({
    newPassword: "",
    // emailVerified: AuthService.getAuth().user?.emailIdentity?.emailVerified,
    isLoading: true,
    isRequesting: false,
    verificationLink: false,
    emailVerificationSent: false,
    authHeaders: AuthService.getAxiosAdminHeaders(),
    token: "",
    email: "",
    response: {
      error: false,
      success: false,
      message: "",
    },
    errorMessage: "",
    showToast: true,
  });

  function resendEmailVerification() {
    setState({
      ...state,
      isRequesting: true,
      emailVerificationSent: false,
      errorMessage: "",
    });

    fetch(`${Constants.API_BASE_URL}/auth/user/email/token`, {
      method: "POST",
      headers: AuthService.getAxiosHeaders().headers,
    })
      .then((res) => res.json())
      .then(() => {
        setState({ ...state, isRequesting: false });
      })
      .catch((err) => {
        if (err.response.status === 401) {
          startTokenRefresh(true);
        }
      });
  }

  useEffect(() => {
    ReactGA.pageview(window.location.pathname + window.location.search);
    setState({
      ...state,
      email: params.email,
      token: params.token,
      isLoading: false,
    });
    if (params.token) {
      setState({
        isLoading: true,
      });

      axios
        .post(`${Constants.API_BASE_URL}/auth/verify/email/token`, {
          verificationToken: params.token,
        })
        .then((res) => {
          let userObj = cookie.load("userObj");
          let locUserObj = reactLocalStorage.getObject("userObj");

          locUserObj.emailIdentity.emailVerified = true;
          userObj.emailIdentity.emailVerified = true;

          cookie.save("userObj", userObj);
          reactLocalStorage.setObject("userObj", locUserObj);
          alert("Your Email is verified 🙂");
          window.location = `${window.location.origin}/login`;
          setState({
            ...state,
            isLoading: false,
            verificationLink: true,
            token: params.token,
            response: {
              error: false,
              success: true,
              message: "",
            },
          });
        })
        .catch((err) => {
          window.location = "/login";
          setState({ ...state, isLoading: false });
        });
    }

    if (params.email) {
      setState({
        ...state,
        email: params.email,
        token: params.token,
        isLoading: true,
      });
      fetch(`${Constants.API_BASE_URL}/auth/user/email/token`, {
        method: "POST",
        headers: AuthService.getAxiosHeaders().headers,
      })
        .then((res) => res.json())
        .then(() => {
          setState({ ...state, isLoading: false });
        })
        .catch((err) => {
          setState({ ...state, isLoading: false });
        });
    }
    // eslint-disable-next-line
  }, []);

  return (
    <Fragment>
      <MetaTags>
        <title>Email Activation - HodlPay</title>
      </MetaTags>

      {state.showToast && (
        <div
          className="p-3 animate-top m-2"
          style={{
            width: "260px",
            border: "1px solid rgba(0,0,0,.0.7)",
            borderRadius: "5px",
            boxShadow: "0 0 5px -1px rgba(0,0,0,.3)",
          }}
        >
          <div>
            <div
              className="flex mb-1 p-1 text-primary"
              style={{ borderBottom: "1px dashed rgba(0,0,0,.2)" }}
            >
              <aside
                style={{
                  width: "30px",
                  height: "30px",
                  overflow: "hidden",
                  borderRadius: "50%",
                }}
                className="m-1"
              >
                <img src={ico} alt="Hodlpay" className="img-fit" />
              </aside>
              <strong className="me-auto">Email Verification</strong>
              <button
                onClick={() => setState({ ...state, showToast: false })}
                className="btn-close"
              ></button>
            </div>
            <div className="toast-info">
              Please check your spam messages if you don't see mail.
            </div>
          </div>
        </div>
      )}

      <div className="d-flex flex-column flex-root">
        <div className="d-flex flex-column flex-column-fluid bgi-position-y-bottom position-x-center bgi-no-repeat bgi-size-contain bgi-attachment-fixed">
          <div className="d-flex flex-column flex-column-fluid text-center p-10 py-lg-15">
            <a href="/login" className="mb-10 pt-lg-10">
              <img
                alt="Logo"
                src="/assets/media/logos/hodlpay-black.svg"
                className="h-70px"
              />
            </a>
            <div className="separator separator-dashed my-4"></div>
            <div className="fv-row fv-plugins-icon-container text-start">
              <div className="row">
                <div className="col-lg-3 offset-lg-3">
                  <span className="d-flex align-items-center my-4">
                    <span className="symbol symbol-50px me-6">
                      <span className="symbol-label bg-primary">
                        <span className="svg-icon svg-icon-1 svg-icon-white">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            fill="none"
                          >
                            <path
                              opacity="0.3"
                              d="M21 19H3C2.4 19 2 18.6 2 18V6C2 5.4 2.4 5 3 5H21C21.6 5 22 5.4 22 6V18C22 18.6 21.6 19 21 19Z"
                              fill="black"
                            />
                            <path
                              d="M21 5H2.99999C2.69999 5 2.49999 5.10005 2.29999 5.30005L11.2 13.3C11.7 13.7 12.4 13.7 12.8 13.3L21.7 5.30005C21.5 5.10005 21.3 5 21 5Z"
                              fill="black"
                            />
                          </svg>
                        </span>
                      </span>
                    </span>
                    <span className="d-flex flex-column">
                      <span className="fw-bolder fs-5">
                        1. Email Verification
                      </span>
                      <span className="fs-7 text-muted">
                        Check your email and verify your email address
                      </span>
                    </span>
                  </span>
                </div>
                <div className="col-lg-3">
                  <span className="d-flex align-items-center my-4">
                    <span className="symbol symbol-50px me-6">
                      <span className="symbol-label bg-light">
                        <span className="svg-icon svg-icon-1 svg-icon-primary">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            fill="none"
                          >
                            <path
                              d="M6 21C6 21.6 6.4 22 7 22H17C17.6 22 18 21.6 18 21V20H6V21Z"
                              fill="black"
                            />
                            <path
                              opacity="0.3"
                              d="M17 2H7C6.4 2 6 2.4 6 3V20H18V3C18 2.4 17.6 2 17 2Z"
                              fill="black"
                            />
                            <path
                              d="M12 4C11.4 4 11 3.6 11 3V2H13V3C13 3.6 12.6 4 12 4Z"
                              fill="black"
                            />
                          </svg>
                        </span>
                      </span>
                    </span>
                    <span className="d-flex flex-column">
                      <span className="fw-bolder fs-5">
                        2. Phone Number Verification
                      </span>
                      <span className="fs-7 text-muted">
                        Enter your phone number and verify via SMS
                      </span>
                    </span>
                  </span>
                </div>
              </div>
            </div>
            <div className="separator separator-dashed my-4"></div>
            {state.isLoading ? (
              <div className="pre-loader">
                <div className="text-center">
                  <div className="spinner-border" role="status">
                    <span className="sr-only">Loading...</span>
                  </div>
                </div>
              </div>
            ) : (
              <>
                {state.verificationLink ? (
                  <div className="pt-lg-10 mb-10">
                    {state.response.success ? (
                      <>
                        <h1 className="fw-bolder fs-2qx text-gray-800 mb-7">
                          Your Email has been verified successfully.
                        </h1>
                        <div className="fs-3 fw-bold text-muted mb-10">
                          You have successfully verified your email
                        </div>
                        <div className="text-center mb-10">
                          <a
                            href="/login"
                            className="btn btn-lg btn-primary fw-bolder"
                          >
                            Login
                          </a>
                        </div>
                      </>
                    ) : (
                      <>
                        <h1 className="fw-bolder fs-2qx text-gray-800 mb-7">
                          Invalid Activation Link
                        </h1>
                        <div className="fs-3 fw-bold text-muted mb-10">
                          Your activation link is invalid or has already been
                          used.
                        </div>
                        {state.errorMessage?.length > 0 && (
                          <div className="justify-content-center d-flex align-items-center p-5 mb-10 w-100">
                            <span className="svg-icon svg-icon-2hx svg-icon-danger me-4">
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="24"
                                height="24"
                                viewBox="0 0 24 24"
                                fill="none"
                              >
                                <g
                                  stroke="none"
                                  strokeWidth="1"
                                  fill="none"
                                  fillRule="evenodd"
                                >
                                  <rect x="0" y="0" width="24" height="24" />
                                  <circle
                                    fill="#000000"
                                    opacity="0.3"
                                    cx="12"
                                    cy="12"
                                    r="10"
                                  />
                                  <rect
                                    fill="#000000"
                                    x="11"
                                    y="10"
                                    width="2"
                                    height="7"
                                    rx="1"
                                  />
                                  <rect
                                    fill="#000000"
                                    x="11"
                                    y="7"
                                    width="2"
                                    height="2"
                                    rx="1"
                                  />
                                </g>
                              </svg>
                            </span>
                            <div className="d-flex flex-column">
                              <h4 className="mb-1 text-danger">
                                {state.errorMessage}
                              </h4>
                            </div>
                          </div>
                        )}
                        {state.emailVerificationSent && (
                          <div className="justify-content-center d-flex align-items-center p-5 mb-10 w-100">
                            <span className="svg-icon svg-icon-2hx svg-icon-primary me-4">
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="24"
                                height="24"
                                viewBox="0 0 24 24"
                                fill="none"
                              >
                                <path
                                  opacity="0.3"
                                  d="M20.5543 4.37824L12.1798 2.02473C12.0626 1.99176 11.9376 1.99176 11.8203 2.02473L3.44572 4.37824C3.18118 4.45258 3 4.6807 3 4.93945V13.569C3 14.6914 3.48509 15.8404 4.4417 16.984C5.17231 17.8575 6.18314 18.7345 7.446 19.5909C9.56752 21.0295 11.6566 21.912 11.7445 21.9488C11.8258 21.9829 11.9129 22 12.0001 22C12.0872 22 12.1744 21.983 12.2557 21.9488C12.3435 21.912 14.4326 21.0295 16.5541 19.5909C17.8169 18.7345 18.8277 17.8575 19.5584 16.984C20.515 15.8404 21 14.6914 21 13.569V4.93945C21 4.6807 20.8189 4.45258 20.5543 4.37824Z"
                                  fill="black"
                                ></path>
                                <path
                                  d="M10.5606 11.3042L9.57283 10.3018C9.28174 10.0065 8.80522 10.0065 8.51412 10.3018C8.22897 10.5912 8.22897 11.0559 8.51412 11.3452L10.4182 13.2773C10.8099 13.6747 11.451 13.6747 11.8427 13.2773L15.4859 9.58051C15.771 9.29117 15.771 8.82648 15.4859 8.53714C15.1948 8.24176 14.7183 8.24176 14.4272 8.53714L11.7002 11.3042C11.3869 11.6221 10.874 11.6221 10.5606 11.3042Z"
                                  fill="black"
                                ></path>
                              </svg>
                            </span>
                            <div className="d-flex flex-column">
                              <h4 className="mb-1 text-primary">
                                Verification email has been sent to your email
                              </h4>
                            </div>
                          </div>
                        )}
                        <div className="text-center mb-10">
                          {state.isRequesting ? (
                            <button
                              className="btn btn-lg btn-primary fw-bolder"
                              disabled
                            >
                              <span className="">
                                Please wait...
                                <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                              </span>
                            </button>
                          ) : (
                            <button
                              className="btn btn-lg btn-primary fw-bolder"
                              onClick={() => {
                                resendEmailVerification();
                              }}
                            >
                              Resend Email
                            </button>
                          )}
                        </div>
                      </>
                    )}
                  </div>
                ) : (
                  <div className="pt-lg-10 mb-10">
                    <h1 className="fw-bolder fs-2qx text-gray-800 mb-7">
                      Verify Your Email
                    </h1>
                    <div className="fs-3 fw-bold text-muted mb-10">
                      We have sent an email to
                      <small className="link-primary fw-bolder">
                        {" "}
                        {state?.email}
                      </small>
                      <br />
                      please follow a link to verify your email.
                    </div>
                    <br />
                    <br />
                    <small className="text-dark">
                      Please check your spam if you don't see it in your inbox
                    </small>
                    {/* <div className="text-center mb-10">
                        <a href="/login" className="btn btn-lg btn-primary fw-bolder">Skip for now</a>
                      </div> */}
                    {state.errorMessage?.length > 0 && (
                      <div className="justify-content-center d-flex align-items-center p-5 mb-10 w-100">
                        <span className="svg-icon svg-icon-2hx svg-icon-danger me-4">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            fill="none"
                          >
                            <g
                              stroke="none"
                              strokeWidth="1"
                              fill="none"
                              fillRule="evenodd"
                            >
                              <rect x="0" y="0" width="24" height="24" />
                              <circle
                                fill="#000000"
                                opacity="0.3"
                                cx="12"
                                cy="12"
                                r="10"
                              />
                              <rect
                                fill="#000000"
                                x="11"
                                y="10"
                                width="2"
                                height="7"
                                rx="1"
                              />
                              <rect
                                fill="#000000"
                                x="11"
                                y="7"
                                width="2"
                                height="2"
                                rx="1"
                              />
                            </g>
                          </svg>
                        </span>
                        <div
                          style={{ width: "100%", wordWrap: "break-word" }}
                          className="d-flex flex-column"
                        >
                          <h4
                            style={{ width: "100%", margin: "auto" }}
                            className="mb-1 text-danger"
                          >
                            {state.errorMessage}
                          </h4>
                        </div>
                      </div>
                    )}
                    {state.emailVerificationSent && (
                      <div className="justify-content-center d-flex align-items-center p-5 mb-10 w-100">
                        <span className="svg-icon svg-icon-2hx svg-icon-primary me-4">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            fill="none"
                          >
                            <path
                              opacity="0.3"
                              d="M20.5543 4.37824L12.1798 2.02473C12.0626 1.99176 11.9376 1.99176 11.8203 2.02473L3.44572 4.37824C3.18118 4.45258 3 4.6807 3 4.93945V13.569C3 14.6914 3.48509 15.8404 4.4417 16.984C5.17231 17.8575 6.18314 18.7345 7.446 19.5909C9.56752 21.0295 11.6566 21.912 11.7445 21.9488C11.8258 21.9829 11.9129 22 12.0001 22C12.0872 22 12.1744 21.983 12.2557 21.9488C12.3435 21.912 14.4326 21.0295 16.5541 19.5909C17.8169 18.7345 18.8277 17.8575 19.5584 16.984C20.515 15.8404 21 14.6914 21 13.569V4.93945C21 4.6807 20.8189 4.45258 20.5543 4.37824Z"
                              fill="black"
                            ></path>
                            <path
                              d="M10.5606 11.3042L9.57283 10.3018C9.28174 10.0065 8.80522 10.0065 8.51412 10.3018C8.22897 10.5912 8.22897 11.0559 8.51412 11.3452L10.4182 13.2773C10.8099 13.6747 11.451 13.6747 11.8427 13.2773L15.4859 9.58051C15.771 9.29117 15.771 8.82648 15.4859 8.53714C15.1948 8.24176 14.7183 8.24176 14.4272 8.53714L11.7002 11.3042C11.3869 11.6221 10.874 11.6221 10.5606 11.3042Z"
                              fill="black"
                            ></path>
                          </svg>
                        </span>
                        <div className="d-flex flex-column">
                          <h4 className="mb-1 text-primary">
                            Verification email has been sent to your email
                          </h4>
                        </div>
                      </div>
                    )}
                    <div className="fs-5">
                      <span className="fw-bold text-gray-700">
                        Didn’t receive an email?
                      </span>
                      {state.isRequesting ? (
                        <span className="text-secondary pointer-cursor fw-bolder">
                          {" "}
                          Please wait...
                        </span>
                      ) : (
                        <span
                          onClick={() => {
                            resendEmailVerification();
                          }}
                          className="text-primary pointer-cursor fw-bolder"
                        >
                          {" "}
                          Resend
                        </span>
                      )}
                    </div>
                    <br />
                    <div className="text-center mb-10">
                      <a
                        href="/login"
                        className="btn btn-lg btn-primary fw-bolder"
                      >
                        Login
                      </a>
                    </div>
                  </div>
                )}
              </>
            )}
          </div>
        </div>
      </div>
    </Fragment>
  );
}

export default EmailVerification;
