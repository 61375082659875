import React, { useContext, useEffect, useState } from "react";
import axios from "axios";
import CONSTANTS from "../../../misc/Constants";

// import 'antd/dist/antd.css';
import AuthService from "../../../misc/AuthService";
import classNames from "classnames";
import Numeral from "react-numeral";
import Constants from "../../../misc/Constants";
import refreshToken from "../../../misc/RefreshToken";
import { reactLocalStorage } from "reactjs-localstorage";
import getStore from "../../../misc/GetStore";
import cookie from "react-cookies";
import { ToastContainer, toast } from "react-toastify";
import Context from "../../../store/Context";
import { ACTIONS } from "../../../store/useGlobalState";
import OrderInfo from "./OrderInfo";
import startTokenRefresh from "../../../misc/sessionExpiry";
import style from "../styles/order.module.css";

function OrderForm(props) {
  const dispatch = useContext(Context)?.setState;
  const [state, setState] = useState(() => {
    return {
      userInfo: AuthService.getAuth().user,
      isLoading: true,
      isRequesting: false,
      hasPendingOrders: false,
      currenciesInfo: [],
      selectedCurrency: {
        sell_rate: 0,
      },
      // orderType: to.pathname.includes('addedPayment')? 'sell' : props.type,
      orderType: "sell",
      showOrderInfo: false,
      orderID: "",
      payoutMethod: getStore.getPaymentMethod() || {},
      buyOrderForm: {
        currency: "Bitcoin",
        amountInUSD: 0,
        amountInGHS: 0,
        buyWallet: "",
        orderPriority: "normal",
        fees: 0,
        total: "0",
        buy_rate: 0,
      },
      buyOrderFormErrors: {
        currency: "",
        amountInUSD: "",
        buyWallet: "",
        orderPriority: "",
      },
      sellOrderForm: {
        currency: "Bitcoin",
        amountInUSD: 0,
        amountInGHS: 0,
        total: "0",
      },
      sellOrderFormErrors: {
        currency: "",
        amountInUSD: "",
        sellWallet: "",
        payoutOption: "",
      },
      errorMessage: "",
      successMessage: "",
      cryptoAmount: {
        usdt: 0,
        btc: 0,
      },
      buyAmountError: "",
      sellAmountError: "",
    };
  });
  const userLevel = cookie.load("level");
  const level = {
    Limited: 1000,
    Intermediate: 5000,
    Full: 10000,
  };

  function updateSellOrderForm(event, name) {
    let value = event.target.value?.toString();
    let { sellOrderForm, selectedCurrency } = state;
    let sellAmountError = state.sellAmountError;

    if (name === "currency") {
      let currenciesInfo = state.currenciesInfo;
      let target = currenciesInfo[0].code?.toUpperCase();
      let curVal = value === "Bitcoin" ? "BTC" : value?.toUpperCase();

      if (target.includes(curVal)) {
        selectedCurrency = currenciesInfo[0];
      } else {
        selectedCurrency = currenciesInfo[1];
      }
    } else {
      if (parseFloat(value?.length > 0 ? value : 0) <= level[`${userLevel}`]) {
        sellOrderForm[`${name}`] =
          value?.length > 0 ? parseFloat(event.target.value) : "";

        axios
          .get(
            `${Constants.API_BASE_URL}/orders/crypto/amount?amount=${parseFloat(
              event.target.value,
            )}`,
            AuthService.getAxiosHeaders(),
          )
          .then((res) => {
            setState({
              ...state,
              cryptoAmount: { ...state.cryptoAmount, ...res.data },
            });
          })
          .catch((e) => {
            return;
          });
      } else if (parseFloat(value?.length > 0 ? value : 0) > 10000) {
        sellAmountError = `Contact us for bigger transactions`;
      } else {
        if (sellAmountError.length === 0)
          sellAmountError = `Can't sell more than ${level[`${userLevel}`]}`;
      }
    }

    setState({
      ...state,
      sellOrderForm,
      selectedCurrency,
      sellAmountError,
    });

    setTimeout(() => {
      updateSellTotalCost(
        parseFloat(sellOrderForm.amountInUSD),
        parseFloat(selectedCurrency?.data?.sellRate),
      );
    }, 500);
  }

  function formatTo2DP(amount) {
    return parseFloat(
      parseFloat(Math.round((amount ?? 0) * 100) / 100).toFixed(2),
    );
  }

  function updateTotalCost(fee, usd, rate) {
    let { buyOrderForm } = state;
    let totalCostInUSD = fee + usd;
    let totalCostInGHS = formatTo2DP(totalCostInUSD * rate);
    buyOrderForm.amountInGHS = totalCostInGHS;
    buyOrderForm.buy_rate = rate;
    setState((prev) => {
      try {
        return { ...prev, buyOrderForm };
      } catch (e) {
        return { ...state, buyOrderForm };
      }
    });
  }

  function updateBuyOrderForm(event, name) {
    let value;
    let buyOrderForm = state.buyOrderForm;
    let selectedCurrency = state.selectedCurrency;
    let buyAmountError = state.buyAmountError;
    value = event.target.value;

    if (name === "currency") {
      let currenciesInfo = state.currenciesInfo;
      let target = currenciesInfo[0].code?.toUpperCase();
      let curVal = value === "Bitcoin" ? "BTC" : value?.toUpperCase();

      if (target.includes(curVal)) {
        selectedCurrency = currenciesInfo[0];
      } else {
        selectedCurrency = currenciesInfo[1];
      }

      setState({ ...state, selectedCurrency });
      return updateTotalCost(
        buyOrderForm.fees,
        buyOrderForm.amountInUSD,
        parseFloat(selectedCurrency.data.buyRate),
      );
    } else if (name === "orderPriority" && value === "normal") {
      buyOrderForm[name] = value;
      buyOrderForm.fees = parseFloat(
        selectedCurrency?.data?.normalProcessingFee,
      );
    } else if (name === "orderPriority" && value === "high") {
      buyOrderForm[name] = value;
      buyOrderForm.fees = parseFloat(
        selectedCurrency?.data?.priorityProcessingFee,
      );
    } else if (name === "buyWallet") {
      buyOrderForm.buyWallet = value;
    } else {
      if (parseFloat(value?.length > 0 ? value : 0) <= level[`${userLevel}`]) {
        buyOrderForm[`${name}`] =
          value?.length > 0 ? parseFloat(event.target.value) : 0;
        if (value) {
          axios
            .get(
              `${
                Constants.API_BASE_URL
              }/assets/crypto/amount?amount=${parseFloat(event.target.value)}`,
              AuthService.getAxiosHeaders(),
            )
            .then((res) => {
              setState({
                ...state,
                cryptoAmount: { ...state.cryptoAmount, ...res.data },
              });
            })
            .catch((e) => {
              if (e.response.status === 401) startTokenRefresh(true);
            });
        }
      } else if (parseFloat(value?.length > 0 ? value : 0) > 10000) {
        buyAmountError = `Contact us for bigger transactions`;
      } else {
        if (buyAmountError.length === 0)
          buyAmountError = `Can't buy more than ${level[`${userLevel}`]}`;
      }
    }
    setState((prev) => {
      return {
        ...prev,
        buyOrderForm: buyOrderForm,
        selectedCurrency,
        buyAmountError,
      };
    });
    setTimeout(() => {
      setState((prev) => {
        return { ...prev, buyAmountError: "" };
      });
    }, 10000);

    setTimeout(() => {
      updateTotalCost(
        buyOrderForm.fees,
        buyOrderForm.amountInUSD,
        parseFloat(selectedCurrency.data.buyRate),
      );
    }, 500);
  }

  function updateSellTotalCost(usd, rate) {
    let sellOrderForm = state.sellOrderForm;
    let totalCostInGHS = formatTo2DP(usd * rate);
    sellOrderForm.amountInGHS = usd;
    sellOrderForm.total = totalCostInGHS.toString();
    setState((prev) => {
      try {
        return { ...prev, sellOrderForm };
      } catch (e) {
        return { ...state, sellOrderForm };
      }
    });
  }

  function submitBuyOrderForm() {
    trigger("Sorry 😕, we are currently out of stock, try selling");
    return;
    // eslint-disable-next-line
    if (state.buyOrderForm.amountInUSD < 1) {
      setState({ ...state, errorMessage: "Amount can't be zero" });
      return;
    }
    setState({
      ...state,
      isRequesting: true,
      buyOrderFormErrors: {
        currency: "",
        amountInUSD: "",
        buyWallet: "",
        orderPriority: "",
      },
      errorMessage: "",
      successMessage: "",
    });
    let data = {
      amount: parseFloat(state.buyOrderForm.amountInUSD),
      rate: parseFloat(state.buyOrderForm.buy_rate),
      walletId: state.selectedCurrency?.code,
      fee: state.buyOrderForm.fees,
      feeType:
        state.buyOrderForm.orderPriority === "normal"
          ? "normalProcessingFee"
          : "priorityProcessingFee",
      asset: state.selectedCurrency?.code === "BTC" ? "BTC" : "USDT",
      paymentType: "mtn",
      walletAddress: state.buyOrderForm.buyWallet,
    };

    axios
      .post(
        CONSTANTS.API_BASE_URL + "/orders/buy",
        data,
        AuthService.getAxiosHeaders(),
      )
      .then((response) => {
        let data = { ...response.data.data };
        setState({
          ...state,
          isRequesting: false,
          showOrderInfo: true,
          buyOrderForm: {
            currency: state.buyOrderForm.currency,
            amountInUSD: 0,
            amountInGHS: 0,
            buyWallet: "",
            orderPriority: "normal",
            fees: 0,
            total: 0,
          },
        });
        let { buyOrderForm, selectedCurrency } = state;
        // window.modalHide();

        document.getElementById("dismissModal").click();
        props.setPassOrder(data);
        setTimeout(() => {
          reactLocalStorage.set("orderData", JSON.stringify(data));
          props.showNewOrder(data?.id, data);
          updateTotalCost(
            buyOrderForm.fees,
            buyOrderForm.amountInUSD,
            parseFloat(selectedCurrency.data.buyRate),
          );
        }, 500);

        window.scrollTo({ top: 0, behavior: "smooth" });
      })
      .catch((error) => {
        window.scrollTo(0, 0);
        setState({
          ...state,
          isRequesting: false,
        });
        try {
          let errorResponse = error.response.data;
          let buyOrderFormErrors = state.buyOrderFormErrors;

          let errorMessage = "Error: Could not connect to server";
          if (errorResponse.hasOwnProperty("message")) {
            let errors = "";
            if (typeof errorResponse.message === "object") {
              errorResponse.message?.forEach((err) => {
                errors = errors + `\n${err}`;
              });
            } else {
              errors = errorResponse.message;
            }
            errorMessage = errors;
          }

          if (error.response.status === 401) {
            refreshToken();
          }

          setState({
            ...state,
            isRequesting: false,
            errorMessage: errorMessage,
            buyOrderFormErrors: buyOrderFormErrors,
          });
        } catch (e) {
          // window.location = '/server-offline'
        }
      });
  }

  function submitSellOrderForm(e) {
    if (parseFloat(state.sellOrderForm.amountInUSD) < 1) {
      setState({ ...state, errorMessage: "Amount can't be zero" });
      return;
    }
    setState({
      ...state,
      isRequesting: true,
      sellOrderFormErrors: {
        currency: "",
        amountInUSD: "",
      },
      errorMessage: "",
      successMessage: "",
    });
    // e.preventDefault();

    let data = {
      amount: parseFloat(state.sellOrderForm.amountInUSD),
      rate: parseFloat(state.selectedCurrency?.data?.sellRate),
      walletId: state.selectedCurrency?.code === "BTC" ? "BTC" : "USDT_TRC20",
      asset: state.selectedCurrency?.code === "BTC" ? "BTC" : "USDT",
      paymentType:
        state.sellOrderForm.currency === "Bitcoin" ? "mtn" : "usdt_trc20",
    };

    axios
      .post(
        CONSTANTS.API_BASE_URL + "/orders/sell",
        data,
        AuthService.getAxiosHeaders(),
      )
      .then((response) => {
        let data = response.data;

        setState({
          ...state,
          isRequesting: false,
          showOrderInfo: true,
          orderID: data.data.id,
          sellOrderForm: {
            currency: state.sellOrderForm.currency,
            amountInUSD: 0,
            amountInGHS: 0,
            total: 0,
          },
        });

        setTimeout(() => {
          // document.getElementById('dismissModal').click()
          // props.setPassOrder(data.data)
          // props.setOrderId(response.data.data.id)
          // props.showNewOrder('');
          updateTotalCost();
          dispatch({
            type: ACTIONS.modal_2_On,
            content: (
              <OrderInfo orderID={data.data.id} passedOrder={data.data} />
            ),
          });
          // props.showNewOrder(data.data.order_id);
        }, 500);
        window.scrollTo({ top: 0, behavior: "smooth" });
      })
      .catch((error) => {
        setState({ ...state, isLoading: false, isRequesting: false });
      });
  }

  function loadCurrencies() {
    axios
      .get(`${Constants.API_BASE_URL}/assets`, AuthService.getAxiosHeaders())
      .then((res) => {
        let assets = res.data;
        setState((prev) => {
          return {
            ...prev,
            isLoading: false,
            currenciesInfo: res.data,
            selectedCurrency: assets[0].code === "BTC" ? assets[0] : assets[1],
            buyOrderForm: {
              ...state.buyOrderForm,
              fees: parseFloat(
                assets[0].code === "BTC"
                  ? assets[0]?.data?.normalProcessingFee
                  : assets[1]?.data?.normalProcessingFee,
              ),
              buy_rate:
                assets[0]?.code === "BTC"
                  ? assets[0]?.data?.buyRate
                  : assets[1]?.data?.buyRate,
              sell_rate:
                assets[0]?.code === "BTC"
                  ? assets[0]?.data?.sellRate
                  : assets[1]?.data?.sellRate,
            },
            sellOrderForm: {
              ...state.sellOrderForm,
              currency:
                assets[0].code === "BTC"
                  ? assets[0].code === "BTC"
                    ? "Bitcoin"
                    : "USDT"
                  : assets[1].code === "BTC"
                    ? "Bitcoin"
                    : "USDT",
            },
          };
        });
      })
      .catch(() => {
        setState((prev) => {
          return { ...prev, isLoading: false };
        });
      });

    let { buyOrderForm, selectedCurrency } = state;
    setTimeout(() => {
      return updateTotalCost(
        buyOrderForm.fees,
        buyOrderForm.amountInUSD,
        parseFloat(selectedCurrency?.data?.buyRate),
      );
    }, 500);

    if (reactLocalStorage.getObject("orderSell").isOrder) {
      document.getElementById("altSellOrder")?.click();
    }
  }

  useEffect(() => {
    getPaymentMethod();
    if (props.getPayment) {
      props.resetPayment();
    }
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    window.preventNumberScroll();
    setState({ ...state, isLoading: true });

    // Checking for pending orders ......
    axios
      .get(
        `${Constants.API_BASE_URL}/orders/has/pending`,
        AuthService.getAxiosHeaders(),
      )
      .then((res) => {
        if (!res.data) {
          loadCurrencies();
        } else {
          setState({ ...state, hasPendingOrders: true });
        }
      })
      .catch(() => {
        setTimeout(() => {
          window.location.reload();
        }, 1000);
      });

    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (state.errorMessage?.length > 0) {
      setTimeout(() => {
        setState((prev) => {
          return { ...prev, errorMessage: "" };
        });
      }, 20000);
    }
    // eslint-disable-next-line
  }, [state.errorMessage]);

  function getPaymentMethod() {
    let paymentMethod = getStore.getPaymentMethod();
    setState({ ...state, payoutMethod: paymentMethod });
    // console.log(paymentMethod);
    if (!getStore.getPaymentMethod()) {
      setState({
        ...state,
        isLoading: true,
        selectedCurrency:
          state.currenciesInfo[0]?.code === "BTC"
            ? state.currenciesInfo[0]
            : state.currenciesInfo[1],
      });
      axios
        .get(
          CONSTANTS.API_BASE_URL + "/users/payment/method",
          AuthService.getAxiosHeaders(),
        )
        .then((response) => {
          let responseInfo = response.data;
          setState((prev) => {
            return {
              ...state,
              isLoading: false,
              payoutMethod: responseInfo,
            };
          });
        });
    }
  }

  const trigger = (message) => {
    toast(`${message}`, {
      position: "top-center",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });
  };

  return (
    <div className={`${style.order}`} style={{ width: "600px" }}>
      <ToastContainer autoClose={5000} />
      {state.hasPendingOrders ? (
        <section
          className={`${style.hasPending} w-100 flex v-flex a-i-c`}
          style={{ height: "200px", minWidth: "300px" }}
        >
          <br />
          <h1>Opps ...</h1>
          <br />
          <p className="border" style={{ fontSize: "1.4rem" }}>
            You have a pending order to complete.
          </p>
          <p className="border" style={{ fontSize: "1.2rem" }}>
            Finish with your pending orders to make new ones
          </p>
        </section>
      ) : (
        <>
          {state.isLoading ? (
            <div className="text-center py-20">
              <div className="spinner-border" role="status">
                <span className="sr-only">Loading...</span>
              </div>
            </div>
          ) : (
            <div className="modal-body scroll-y mx-5 mx-xl-18 pt-0 pb-15">
              <div className="text-center mb-13">
                <h1 className="mb-3">New Order</h1>
                <div className="text-muted fw-bold fs-5">
                  Create a new order
                </div>
              </div>

              <div
                id="kt_modal_users_search_handler"
                data-kt-search-keypress="true"
                data-kt-search-min-length="2"
                data-kt-search-enter="enter"
                data-kt-search-layout="inline"
                data-kt-search="true"
              >
                <div className="py-5">
                  <div className="row">
                    <div className="col-md-12 text-center mb-10 flex j-c-c">
                      <div
                        className="btn-group w-100 w-lg-50 flex j-c-b"
                        data-kt-buttons="true"
                        data-kt-buttons-target="[data-kt-button]"
                      >
                        {/* <label style={{margin: '0 0.5rem'}} className={classNames("btn btn-outline-secondary text-muted text-hover-white text-active-white btn-outline btn-active-primary", {"active": state.orderType === 'buy'})} data-kt-button="true">
                                                    <input className="btn-check" type="radio" name="order_type" checked={state.orderType === 'buy'} value="buy" onChange={() => {
                                                        trigger('Sorry 😕, we are currently out of stock, try selling')
                                                        setState({...state, orderType: 'buy'})
                                                    }} />
                                                    Buy Order
                                                </label> */}

                        <label
                          style={{ margin: "0 0.5rem" }}
                          className={classNames(
                            "btn btn-outline-secondary text-muted text-hover-white text-active-white btn-outline btn-active-primary",
                            { active: state.orderType === "sell" },
                          )}
                          data-kt-button="true"
                        >
                          <input
                            id="altSellOrder"
                            className="btn-check"
                            type="radio"
                            name="order_type"
                            checked={state.orderType === "sell"}
                            value="sell"
                            onChange={() => {
                              setState({ ...state, orderType: "sell" });
                            }}
                          />
                          Sell Order
                        </label>
                      </div>
                    </div>
                  </div>
                  {state.orderType === "buy" ? (
                    <div className="buy_order_form">
                      {state.errorMessage.length > 0 && (
                        <div className="alert alert-danger d-flex align-items-center p-5 mb-10 w-100">
                          <span className="svg-icon svg-icon-2hx svg-icon-danger me-4">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="24"
                              height="24"
                              viewBox="0 0 24 24"
                              fill="none"
                            >
                              <g
                                stroke="none"
                                strokeWidth="1"
                                fill="none"
                                fillRule="evenodd"
                              >
                                <rect x="0" y="0" width="24" height="24" />
                                <circle
                                  fill="#000000"
                                  opacity="0.3"
                                  cx="12"
                                  cy="12"
                                  r="10"
                                />
                                <rect
                                  fill="#000000"
                                  x="11"
                                  y="10"
                                  width="2"
                                  height="7"
                                  rx="1"
                                />
                                <rect
                                  fill="#000000"
                                  x="11"
                                  y="7"
                                  width="2"
                                  height="2"
                                  rx="1"
                                />
                              </g>
                            </svg>
                          </span>
                          <div className="d-flex flex-column">
                            <h4 className="mb-1 text-danger">Error</h4>{" "}
                            <span>{state.errorMessage}</span>{" "}
                          </div>
                        </div>
                      )}
                      <div className="form-group text-center">
                        <div className="form-label-group">
                          <label className="form-label">
                            Amount to Buy (USD)
                          </label>
                          <input
                            type="number"
                            max={1000}
                            className="form-control form-control-transparent text-center fs-4x"
                            placeholder="$100"
                            aria-label="amount"
                            value={
                              state.buyOrderForm.amountInUSD > 0
                                ? state.buyOrderForm.amountInUSD
                                : ""
                            }
                            onChange={(e) => {
                              updateBuyOrderForm(e, "amountInUSD");
                            }}
                            aria-describedby="amount"
                          />
                          {state.buyOrderForm.amountInUSD > 0 && (
                            <p className="">
                              Crypto Amount:
                              <b className="text-primary-800">
                                {" "}
                                {state.selectedCurrency?.code === "BTC"
                                  ? `${state.cryptoAmount.btc} BTC`
                                  : `${state.cryptoAmount.usdt} USDT`}
                              </b>
                            </p>
                          )}
                          {state.buyAmountError.length > 0 && (
                            <div style={{ margin: "1.5rem 0" }}>
                              <h4
                                style={{ fontWeight: "500" }}
                                className="text-danger"
                              >
                                {state.buyAmountError}
                              </h4>
                              <br />
                              {state.buyAmountError.includes("bigger") ? (
                                <>
                                  <h4
                                    className=""
                                    style={{ fontWeight: "400" }}
                                  >
                                    Click{" "}
                                    <b
                                      style={{
                                        margin: "0 5px",
                                        cursor: "pointer",
                                      }}
                                      className="text-primary"
                                      onClick={() =>
                                        (window.location.href = "/support")
                                      }
                                    >
                                      here to contact support
                                    </b>
                                    for bigger orders. <br />
                                  </h4>
                                </>
                              ) : state.buyAmountError.includes("more") ? (
                                <>
                                  <h4
                                    className=""
                                    style={{ fontWeight: "400" }}
                                  >
                                    Click{" "}
                                    <b
                                      style={{
                                        margin: "0 5px",
                                        cursor: "pointer",
                                      }}
                                      className="text-primary"
                                      onClick={() =>
                                        (window.location.href =
                                          "/account-settings#kyc")
                                      }
                                    >
                                      here to increase
                                    </b>
                                    your limit.
                                  </h4>
                                </>
                              ) : (
                                ""
                              )}
                            </div>
                          )}
                        </div>
                      </div>
                      <div className="row mt-10">
                        <div className="form-label-group">
                          <label className="form-label">Choose Currency</label>
                        </div>
                        <div className="col-md-6">
                          <input
                            type="radio"
                            className="btn-check"
                            name="order_currency"
                            value="Bitcoin"
                            checked={state.selectedCurrency?.code === "BTC"}
                            onChange={(e) => {
                              updateBuyOrderForm(e, "currency");
                            }}
                            id="btc"
                          />
                          <label
                            className="btn btn-outline btn-outline-dashed btn-outline-default p-7 d-flex align-items-center mb-5"
                            htmlFor="btc"
                          >
                            <span className="svg-icon svg-icon-2x me-4">
                              <img
                                src="/assets/media/svg/brand-logos/btc.svg"
                                className="h-30px w-30px align-self-center"
                                alt=""
                              />
                            </span>

                            <span className="d-block fw-bold text-start">
                              <span className="text-dark fw-bolder d-block fs-5">
                                Bitcoin
                              </span>
                              {/* <small className="text-muted fw-bold fs-6">Buy cryptocurrency</small> */}
                            </span>
                          </label>
                        </div>
                        <div className="col-md-6">
                          <input
                            type="radio"
                            className="btn-check"
                            name="order_currency"
                            value="USDT"
                            checked={
                              state.selectedCurrency?.code === "USDT_TRC20"
                            }
                            onChange={(e) => {
                              updateBuyOrderForm(e, "currency");
                            }}
                            id="usdt"
                          />
                          <label
                            className="btn btn-outline btn-outline-dashed btn-outline-default p-7 d-flex align-items-center"
                            htmlFor="usdt"
                          >
                            <span className="svg-icon svg-icon-2x me-4">
                              <img
                                src="/assets/media/svg/brand-logos/usdt.svg"
                                className="h-30px w-30px align-self-center"
                                alt=""
                              />
                            </span>
                            <span className="d-block fw-bold text-start">
                              <span className="text-dark fw-bolder d-block fs-5">
                                USDT{" "}
                                <small
                                  style={{
                                    color: "rgba(0,60,30,.6)",
                                    fontWeight: "normal",
                                    fontSize: "1rem",
                                  }}
                                >
                                  (TRC20)
                                </small>
                              </span>
                              {/* <small className="text-muted fw-bold fs-6">Sell your cryptocurrency</small> */}
                            </span>
                          </label>
                        </div>
                        {state.buyOrderFormErrors.currency.length > 0 && (
                          <p className="text-danger fs-12px">
                            {state.buyOrderFormErrors.currency}
                          </p>
                        )}
                      </div>

                      <div className="form-group mt-8">
                        <label className="form-label">
                          {state.selectedCurrency?.name} Wallet Address{" "}
                          {state.selectedCurrency?.name?.includes("sdt") && (
                            <small
                              style={{
                                color: "rgba(0,60,30,.6)",
                                fontWeight: "normal",
                                fontSize: "1rem",
                              }}
                            >
                              (TRC20)
                            </small>
                          )}
                        </label>
                        <br />
                        {/* <span>{`${JSON.stringify(state.currenciesInfo)}`}</span> */}
                        <input
                          type="text"
                          className="form-control form-control-solid"
                          placeholder=""
                          onChange={(e) => {
                            updateBuyOrderForm(e, "buyWallet");
                          }}
                          value={state.buyOrderForm.buyWallet}
                        />
                        {state.errorMessage.includes("wallet") && (
                          <p className="text-danger fs-12px">
                            {state.errorMessage}
                          </p>
                        )}
                      </div>
                      <div className="fv-row mt-8">
                        {state.selectedCurrency?.name === "Bitcoin" && (
                          <>
                            <div className="form-label-group">
                              <label className="form-label">
                                Order Priority
                              </label>
                            </div>
                            <div className="pm_box">
                              <label className="d-flex flex-stack mb-5 cursor-pointer">
                                <span className="d-flex align-items-center me-2">
                                  <span className="symbol symbol-50px me-6">
                                    <span className="symbol-label bg-light-primary">
                                      <span className="svg-icon svg-icon-1 svg-icon-primary">
                                        <svg
                                          xmlns="http://www.w3.org/2000/svg"
                                          width="24"
                                          height="24"
                                          viewBox="0 0 24 24"
                                          fill="none"
                                        >
                                          <g
                                            stroke="none"
                                            strokeWidth="1"
                                            fill="none"
                                            fillRule="evenodd"
                                          >
                                            <rect
                                              x="0"
                                              y="0"
                                              width="24"
                                              height="24"
                                            />
                                            <path
                                              d="M12,21 C7.581722,21 4,17.418278 4,13 C4,8.581722 7.581722,5 12,5 C16.418278,5 20,8.581722 20,13 C20,17.418278 16.418278,21 12,21 Z"
                                              fill="#000000"
                                              opacity="0.3"
                                            />
                                            <path
                                              d="M13,5.06189375 C12.6724058,5.02104333 12.3386603,5 12,5 C11.6613397,5 11.3275942,5.02104333 11,5.06189375 L11,4 L10,4 C9.44771525,4 9,3.55228475 9,3 C9,2.44771525 9.44771525,2 10,2 L14,2 C14.5522847,2 15,2.44771525 15,3 C15,3.55228475 14.5522847,4 14,4 L13,4 L13,5.06189375 Z"
                                              fill="#000000"
                                            />
                                            <path
                                              d="M16.7099142,6.53272645 L17.5355339,5.70710678 C17.9260582,5.31658249 18.5592232,5.31658249 18.9497475,5.70710678 C19.3402718,6.09763107 19.3402718,6.73079605 18.9497475,7.12132034 L18.1671361,7.90393167 C17.7407802,7.38854954 17.251061,6.92750259 16.7099142,6.53272645 Z"
                                              fill="#000000"
                                            />
                                            <path
                                              d="M11.9630156,7.5 L12.0369844,7.5 C12.2982526,7.5 12.5154733,7.70115317 12.5355117,7.96165175 L12.9585886,13.4616518 C12.9797677,13.7369807 12.7737386,13.9773481 12.4984096,13.9985272 C12.4856504,13.9995087 12.4728582,14 12.4600614,14 L11.5399386,14 C11.2637963,14 11.0399386,13.7761424 11.0399386,13.5 C11.0399386,13.4872031 11.0404299,13.4744109 11.0414114,13.4616518 L11.4644883,7.96165175 C11.4845267,7.70115317 11.7017474,7.5 11.9630156,7.5 Z"
                                              fill="#000000"
                                            />
                                          </g>
                                        </svg>
                                      </span>
                                    </span>
                                  </span>
                                  <span className="d-flex flex-column">
                                    <span className="fw-bolder fs-6">
                                      Normal
                                    </span>
                                    <span className="fs-7 text-muted">
                                      Process this order within the normally
                                      time frame.
                                    </span>
                                    <span className="fs-7 text-muted">
                                      Fee:{" "}
                                      <b>
                                        $
                                        {
                                          state.selectedCurrency?.data
                                            ?.normalProcessingFee
                                        }
                                      </b>
                                    </span>
                                  </span>
                                </span>
                                <span className="form-check form-check-custom form-check-solid">
                                  <input
                                    className="form-check-input"
                                    type="radio"
                                    checked={
                                      state.buyOrderForm.orderPriority ===
                                      "normal"
                                    }
                                    name="orderPriority"
                                    value="normal"
                                    onChange={(e) => {
                                      updateBuyOrderForm(e, "orderPriority");
                                    }}
                                  />
                                </span>
                              </label>
                              <label className="d-flex flex-stack mb-5 cursor-pointer">
                                <span className="d-flex align-items-center me-2">
                                  <span className="symbol symbol-50px me-6">
                                    <span className="symbol-label bg-light-primary">
                                      <span className="svg-icon svg-icon-1 svg-icon-primary">
                                        <svg
                                          xmlns="http://www.w3.org/2000/svg"
                                          width="24"
                                          height="24"
                                          viewBox="0 0 24 24"
                                          fill="none"
                                        >
                                          <g
                                            stroke="none"
                                            strokeWidth="1"
                                            fill="none"
                                            fillRule="evenodd"
                                          >
                                            <rect
                                              x="0"
                                              y="0"
                                              width="24"
                                              height="24"
                                            />
                                            <path
                                              d="M16.3740377,19.9389434 L22.2226499,11.1660251 C22.4524142,10.8213786 22.3592838,10.3557266 22.0146373,10.1259623 C21.8914367,10.0438285 21.7466809,10 21.5986122,10 L17,10 L17,4.47708173 C17,4.06286817 16.6642136,3.72708173 16.25,3.72708173 C15.9992351,3.72708173 15.7650616,3.85240758 15.6259623,4.06105658 L9.7773501,12.8339749 C9.54758575,13.1786214 9.64071616,13.6442734 9.98536267,13.8740377 C10.1085633,13.9561715 10.2533191,14 10.4013878,14 L15,14 L15,19.5229183 C15,19.9371318 15.3357864,20.2729183 15.75,20.2729183 C16.0007649,20.2729183 16.2349384,20.1475924 16.3740377,19.9389434 Z"
                                              fill="#000000"
                                            />
                                            <path
                                              d="M4.5,5 L9.5,5 C10.3284271,5 11,5.67157288 11,6.5 C11,7.32842712 10.3284271,8 9.5,8 L4.5,8 C3.67157288,8 3,7.32842712 3,6.5 C3,5.67157288 3.67157288,5 4.5,5 Z M4.5,17 L9.5,17 C10.3284271,17 11,17.6715729 11,18.5 C11,19.3284271 10.3284271,20 9.5,20 L4.5,20 C3.67157288,20 3,19.3284271 3,18.5 C3,17.6715729 3.67157288,17 4.5,17 Z M2.5,11 L6.5,11 C7.32842712,11 8,11.6715729 8,12.5 C8,13.3284271 7.32842712,14 6.5,14 L2.5,14 C1.67157288,14 1,13.3284271 1,12.5 C1,11.6715729 1.67157288,11 2.5,11 Z"
                                              fill="#000000"
                                              opacity="0.3"
                                            />
                                          </g>
                                        </svg>
                                      </span>
                                    </span>
                                  </span>
                                  <span className="d-flex flex-column">
                                    <span className="fw-bolder fs-6">
                                      Priority
                                    </span>
                                    <span className="fs-7 text-muted">
                                      Process this order as fast as possible.
                                    </span>
                                    <span className="fs-7 text-muted">
                                      Fee:{" "}
                                      <b>
                                        $
                                        {
                                          state.selectedCurrency?.data
                                            ?.priorityProcessingFee
                                        }
                                      </b>
                                    </span>
                                  </span>
                                </span>
                                <span className="form-check form-check-custom form-check-solid">
                                  <input
                                    className="form-check-input"
                                    type="radio"
                                    name="orderPriority"
                                    checked={
                                      state.buyOrderForm.orderPriority ===
                                      "high"
                                    }
                                    value="high"
                                    onChange={(e) => {
                                      updateBuyOrderForm(e, "orderPriority");
                                    }}
                                  />
                                </span>
                              </label>
                              {state.buyOrderFormErrors.orderPriority.length >
                                0 && (
                                <p className="text-danger fs-12px">
                                  {state.buyOrderFormErrors.orderPriority}
                                </p>
                              )}
                            </div>
                          </>
                        )}
                        <div className="summary mt-10 p-7">
                          <div className="table-responsive">
                            <table className="table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4 my-0">
                              <tbody>
                                <tr>
                                  <td className="ps-0">
                                    {" "}
                                    <span className="text-gray-800 fw-bolder text-hover-primary mb-1 fs-6 text-start pe-0">
                                      Buy Amount
                                    </span>{" "}
                                  </td>
                                  <td>
                                    {" "}
                                    <span className="text-gray-800 fw-bolder d-block fs-6 ps-0 text-end">
                                      ${state.buyOrderForm.amountInUSD || 0}
                                    </span>{" "}
                                  </td>
                                </tr>
                                <tr>
                                  <td className="ps-0">
                                    {" "}
                                    <span className="text-gray-800 fw-bolder text-hover-primary mb-1 fs-6 text-start pe-0">
                                      Fees
                                    </span>{" "}
                                  </td>
                                  <td>
                                    {" "}
                                    <span className="text-gray-800 fw-bolder d-block fs-6 ps-0 text-end">
                                      ${state.buyOrderForm.fees}
                                    </span>{" "}
                                  </td>
                                </tr>
                                <tr>
                                  <td className="ps-0">
                                    {" "}
                                    <span className="text-gray-800 fw-bolder text-hover-primary mb-1 fs-6 text-start pe-0">
                                      Rate
                                    </span>{" "}
                                  </td>
                                  <td>
                                    {" "}
                                    <span className="text-gray-800 fw-bolder d-block fs-6 ps-0 text-end">
                                      GHS{" "}
                                      {state.selectedCurrency?.data?.buyRate}
                                    </span>{" "}
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                          <div className="mb-10 mt-5 px-9 text-center">
                            <span className="fs-6 fw-bold text-gray-400">
                              Total:
                            </span>
                            <div className="d-flex align-items-center mb-2 justify-content-center mt-5">
                              <span className="fs-4 fw-bold text-gray-400 align-self-start me-2 pr-5>">
                                GHS
                              </span>
                              <span className="fs-2hx fw-bolder text-gray-800 me-2 lh-1">
                                <Numeral
                                  value={
                                    parseFloat(state.buyOrderForm.amountInGHS) >
                                      0 && state.buyOrderForm.amountInUSD > 0
                                      ? state.buyOrderForm.amountInGHS
                                      : "0.00"
                                  }
                                  format={"0,0.00"}
                                />
                                {/* <h1>{`${JSON.stringify(state.buyOrderForm.amountInGHS)}`}</h1> */}
                              </span>
                            </div>

                            {state.buyOrderForm.amountInUSD > 0 && (
                              <p className="">
                                Crypto Amount:
                                <b className="text-primary-800">
                                  {" "}
                                  {state.selectedCurrency?.code === "BTC"
                                    ? `${state.cryptoAmount.btc} BTC`
                                    : `${state.cryptoAmount.usdt} USDT`}
                                </b>
                              </p>
                            )}
                          </div>
                          <div className="d-flex flex-center">
                            {state.isRequesting ? (
                              <button
                                type="button"
                                className="btn btn-lg btn-primary"
                                disabled
                              >
                                <span className="">
                                  Please wait...
                                  <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                                </span>
                              </button>
                            ) : (
                              <button
                                onClick={() => submitBuyOrderForm()}
                                className="btn btn-lg btn-primary"
                              >
                                <span className="indicator-label">
                                  Submit Order
                                </span>
                              </button>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  ) : (
                    <div className="sell_order_form">
                      {Boolean(state.payoutMethod) ? (
                        <div className="sell_order_form">
                          {state.errorMessage.length > 0 && (
                            <div className="alert alert-danger d-flex align-items-center p-5 mb-10 w-100">
                              <span className="svg-icon svg-icon-2hx svg-icon-danger me-4">
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="24"
                                  height="24"
                                  viewBox="0 0 24 24"
                                  fill="none"
                                >
                                  <g
                                    stroke="none"
                                    strokeWidth="1"
                                    fill="none"
                                    fillRule="evenodd"
                                  >
                                    <rect x="0" y="0" width="24" height="24" />
                                    <circle
                                      fill="#000000"
                                      opacity="0.3"
                                      cx="12"
                                      cy="12"
                                      r="10"
                                    />
                                    <rect
                                      fill="#000000"
                                      x="11"
                                      y="10"
                                      width="2"
                                      height="7"
                                      rx="1"
                                    />
                                    <rect
                                      fill="#000000"
                                      x="11"
                                      y="7"
                                      width="2"
                                      height="2"
                                      rx="1"
                                    />
                                  </g>
                                </svg>
                              </span>
                              <div className="d-flex flex-column">
                                <h4 className="mb-1 text-danger">Error</h4>{" "}
                                <span>{state.errorMessage}</span>
                              </div>
                            </div>
                          )}
                          <div className="form-group text-center">
                            <div className="form-label-group">
                              <label className="form-label">
                                Amount to Sell (USD)
                              </label>
                              <input
                                type="number"
                                className="form-control form-control-transparent text-center fs-4x"
                                placeholder="$100"
                                aria-label="amount"
                                value={
                                  state.sellOrderForm.amountInUSD > 0
                                    ? state.sellOrderForm.amountInUSD
                                    : ""
                                }
                                onChange={(e) => {
                                  updateSellOrderForm(e, "amountInUSD");
                                }}
                                aria-describedby="amount"
                              />

                              {state.sellOrderForm.amountInUSD > 0 && (
                                <p className="">
                                  Crypto Amount:
                                  <b className="text-primary-800">
                                    {" "}
                                    {state.selectedCurrency?.code === "BTC"
                                      ? `${state.cryptoAmount.btc} BTC`
                                      : `${state.cryptoAmount.usdt} USDT`}
                                  </b>
                                </p>
                              )}

                              {state.sellAmountError.length > 0 && (
                                <div style={{ margin: "2rem 0" }}>
                                  <p className="text-danger fs-12px">
                                    {state.sellAmountError}
                                  </p>
                                  {state.sellAmountError.includes("bigger") ? (
                                    <>
                                      <h4
                                        className=""
                                        style={{ fontWeight: "400" }}
                                      >
                                        Click{" "}
                                        <b
                                          className="text-primary"
                                          onClick={() =>
                                            (window.location.href = "/support")
                                          }
                                        >
                                          here to contact
                                        </b>
                                        complete your order. <br />
                                      </h4>
                                    </>
                                  ) : state.sellAmountError.includes("more") ? (
                                    <>
                                      <h4
                                        className=""
                                        style={{ fontWeight: "400" }}
                                      >
                                        Click{" "}
                                        <b
                                          style={{ margin: "0 5px" }}
                                          className="text-primary"
                                          onClick={() =>
                                            (window.location.href =
                                              "/account-settings#kyc")
                                          }
                                        >
                                          here to expand
                                        </b>
                                        your account.
                                      </h4>
                                    </>
                                  ) : (
                                    ""
                                  )}
                                </div>
                              )}
                            </div>
                          </div>
                          <div className="row mt-10">
                            <div className="form-label-group">
                              <label className="form-label">
                                Choose Currency
                              </label>
                            </div>
                            <div className="col-md-6">
                              <input
                                type="radio"
                                className="btn-check"
                                name="order_currency"
                                value="Bitcoin"
                                checked={state.selectedCurrency?.code === "BTC"}
                                onChange={(e) => {
                                  updateSellOrderForm(e, "currency");
                                }}
                                id="btc"
                              />
                              <label
                                className="btn btn-outline btn-outline-dashed btn-outline-default p-7 d-flex align-items-center mb-5"
                                htmlFor="btc"
                              >
                                <span className="svg-icon svg-icon-2x me-4">
                                  <img
                                    src="/assets/media/svg/brand-logos/btc.svg"
                                    className="h-30px w-30px align-self-center"
                                    alt=""
                                  />
                                </span>

                                <span className="d-block fw-bold text-start">
                                  <span className="text-dark fw-bolder d-block fs-5">
                                    Bitcoin
                                  </span>
                                  {/* <small className="text-muted fw-bold fs-6">Buy cryptocurrency</small> */}
                                </span>
                              </label>
                            </div>
                            <div className="col-md-6">
                              <input
                                type="radio"
                                className="btn-check"
                                name="order_currency"
                                value="USDT"
                                checked={
                                  state.selectedCurrency?.code === "USDT_TRC20"
                                }
                                onChange={(e) => {
                                  updateSellOrderForm(e, "currency");
                                }}
                                id="usdt"
                              />
                              <label
                                className="btn btn-outline btn-outline-dashed btn-outline-default p-7 d-flex align-items-center"
                                htmlFor="usdt"
                              >
                                <span className="svg-icon svg-icon-2x me-4">
                                  <img
                                    src="/assets/media/svg/brand-logos/usdt.svg"
                                    className="h-30px w-30px align-self-center"
                                    alt=""
                                  />
                                </span>
                                <span className="d-block fw-bold text-start">
                                  <span className="text-dark fw-bolder d-block fs-5">
                                    USDT{" "}
                                    <small
                                      style={{
                                        color: "rgba(0,60,30,.6)",
                                        fontWeight: "normal",
                                        fontSize: "1rem",
                                      }}
                                    >
                                      (TRC20)
                                    </small>
                                  </span>
                                  {/* <small className="text-muted fw-bold fs-6">Sell your cryptocurrency</small> */}
                                </span>
                              </label>
                            </div>
                            {state.sellOrderFormErrors.currency.length > 0 && (
                              <p className="text-danger fs-12px">
                                {state.sellOrderFormErrors.currency}
                              </p>
                            )}
                          </div>
                          <div className="fv-row mt-8">
                            <div className="summary mt-10 p-7">
                              <div className="table-responsive">
                                <table className="table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4 my-0">
                                  <tbody>
                                    <tr>
                                      <td className="ps-0">
                                        {" "}
                                        <span className="text-gray-800 fw-bolder text-hover-primary mb-1 fs-6 text-start pe-0">
                                          Sell Amount
                                        </span>{" "}
                                      </td>
                                      <td>
                                        {" "}
                                        <span className="text-gray-800 fw-bolder d-block fs-6 ps-0 text-end">
                                          ${state.sellOrderForm.amountInUSD}
                                        </span>{" "}
                                      </td>
                                    </tr>
                                    {/* <tr>
                                                        <td className="ps-0"> <span className="text-gray-800 fw-bolder text-hover-primary mb-1 fs-6 text-start pe-0">Fees</span> </td>
                                                        <td> <span className="text-gray-800 fw-bolder d-block fs-6 ps-0 text-end">${state.sellOrderForm.fees}</span> </td>
                                                        </tr> */}
                                    <tr>
                                      <td className="ps-0">
                                        {" "}
                                        <span className="text-gray-800 fw-bolder text-hover-primary mb-1 fs-6 text-start pe-0">
                                          Rate
                                        </span>{" "}
                                      </td>
                                      <td>
                                        {" "}
                                        <span className="text-gray-800 fw-bolder d-block fs-6 ps-0 text-end">
                                          GHS{" "}
                                          {`${state.selectedCurrency?.data?.sellRate}`}
                                        </span>{" "}
                                      </td>
                                    </tr>
                                  </tbody>
                                </table>
                              </div>
                              <div className="mb-10 mt-5 px-9 text-center">
                                <span className="fs-6 fw-bold text-gray-400">
                                  Total Payout Amount:
                                </span>
                                <div className="d-flex align-items-center mb-2 justify-content-center mt-5">
                                  <span className="fs-4 fw-bold text-gray-400 align-self-start me-2 pr-5>">
                                    GHS
                                  </span>
                                  <span className="fs-2hx fw-bolder text-gray-800 me-2 lh-1">
                                    <Numeral
                                      value={state.sellOrderForm.total}
                                      format={"0,0.00"}
                                    />
                                  </span>
                                </div>

                                {state.sellOrderForm.amountInUSD > 0 && (
                                  <p className="">
                                    Crypto Amount:
                                    <b className="text-primary-800">
                                      {" "}
                                      {state.selectedCurrency?.code === "BTC"
                                        ? `${state.cryptoAmount.btc} BTC`
                                        : `${state.cryptoAmount.usdt} USDT`}
                                    </b>
                                  </p>
                                )}
                              </div>
                              <div className="d-flex flex-center">
                                {state.isRequesting ? (
                                  <button
                                    type="button"
                                    className="btn btn-lg btn-primary"
                                    disabled
                                  >
                                    <span className="">
                                      Please wait...
                                      <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                                    </span>
                                  </button>
                                ) : (
                                  <button
                                    onClick={() => submitSellOrderForm()}
                                    className="btn btn-lg btn-primary"
                                  >
                                    <span className="indicator-label">
                                      Submit Order
                                    </span>
                                  </button>
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                      ) : (
                        <div className="payout_account_form">
                          {" "}
                          {/** Has Payout Method */}
                          <div className="text-center py-20">
                            <span className="symbol">
                              <span className="svg-icon svg-icon-5x svg-icon-primary">
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="24"
                                  height="24"
                                  viewBox="0 0 24 24"
                                  fill="none"
                                >
                                  <path
                                    d="M6 20C6 20.6 5.6 21 5 21C4.4 21 4 20.6 4 20H6ZM18 20C18 20.6 18.4 21 19 21C19.6 21 20 20.6 20 20H18Z"
                                    fill="black"
                                  />
                                  <path
                                    opacity="0.3"
                                    d="M21 20H3C2.4 20 2 19.6 2 19V3C2 2.4 2.4 2 3 2H21C21.6 2 22 2.4 22 3V19C22 19.6 21.6 20 21 20ZM12 10H10.7C10.5 9.7 10.3 9.50005 10 9.30005V8C10 7.4 9.6 7 9 7C8.4 7 8 7.4 8 8V9.30005C7.7 9.50005 7.5 9.7 7.3 10H6C5.4 10 5 10.4 5 11C5 11.6 5.4 12 6 12H7.3C7.5 12.3 7.7 12.5 8 12.7V14C8 14.6 8.4 15 9 15C9.6 15 10 14.6 10 14V12.7C10.3 12.5 10.5 12.3 10.7 12H12C12.6 12 13 11.6 13 11C13 10.4 12.6 10 12 10Z"
                                    fill="black"
                                  />
                                  <path
                                    d="M18.5 11C18.5 10.2 17.8 9.5 17 9.5C16.2 9.5 15.5 10.2 15.5 11C15.5 11.4 15.7 11.8 16 12.1V13C16 13.6 16.4 14 17 14C17.6 14 18 13.6 18 13V12.1C18.3 11.8 18.5 11.4 18.5 11Z"
                                    fill="black"
                                  />
                                </svg>
                              </span>
                            </span>
                            <p className="text-gray-400 fs-4 fw-bold py-3">
                              Add a payout account to receive payment for sale.
                            </p>
                            <b
                              onClick={() => {
                                reactLocalStorage.setObject("orderSell", {
                                  isOrder: true,
                                  location: window.location.pathname,
                                });
                                setTimeout(() => {
                                  window.location =
                                    "/account-settings/addedPayment";
                                }, 500);
                              }}
                              className="btn btn-light-primary"
                            >
                              Add Payout Account
                            </b>
                          </div>
                        </div>
                      )}
                    </div>
                  )}
                </div>
              </div>
            </div>
          )}
        </>
      )}
    </div>
  );
}

export default OrderForm;
