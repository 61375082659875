let months = [
  "Jan",
  "Feb",
  "Mar",
  "Apr",
  "May",
  "Jun",
  "Jul",
  "Aug",
  "Sept",
  "Oct",
  "Nov",
  "Dec",
];

export default function format(fullData) {
  let date = fullData.slice(0, 10);
  let time = fullData.slice(11, 16);
  let current = parseInt(date.slice(5, 7)) - 1;
  let precident =
    `${date.slice(8, 10)}` === "1"
      ? "st"
      : `${date.slice(8, 10)}`[0] === "1"
        ? "th"
        : `${date.slice(8, 10)}`[1] === 2
          ? "nd"
          : `${date.slice(8, 10)}`[1] === 3
            ? "rd"
            : "th";

  date = `${date.slice(8, 10)}${precident} ${months[current]}, ${date.slice(0, 4)}`;

  let period = `${time.slice(0, 2)}` > 12 ? "pm" : "am";
  let min =
    `${time.slice(0, 2)}` > 12
      ? `${parseInt(time.slice(0, 2)) - 12}`
      : `${time.slice(0, 2)}`;
  time = `${min}:${time.slice(3)}${period}`;

  return [date, time];
}
