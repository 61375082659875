import React from "react";
import UserInfoTabs from "./UserInfoTabs";
import dayjs from "dayjs";
import axios from "axios";
import Constants from "../../misc/Constants";
import { useQuery } from "react-query";
import AuthService from "../../misc/AuthService";


function UserInfo(props) {
 

  const handleBackClick = () => {
    window.location.reload();
  };
  


  const { data } = useQuery(
    ["adminUsers"],
    () =>
      axios
        .get(
          `${Constants.API_BASE_URL}/admin/orders/user/stats?userId=${props.user.id}`,
          AuthService.getAxiosAdminHeaders()
        )
        .then((res) => res.data)
  );

  


  return (

    <div className="flex flex-col items-center justify-center w-full">
      <div className="text-start w-full md:w-[900px] px-2 ">
        <button className="text-blue-500 mb-6 text-xl font-semibold mt-4 " onClick={handleBackClick}>
          &larr; Back
        </button>

        <h2 className="text-4xl font-bold mt-4 mb-6">User Info</h2>
        <div className="border border-gray-300 p-6 rounded-lg flex justify-between">
          <div>
          <div className="mb-2">
            <span className=" text-[16px]">
              {props.user.firstName} {props.user.lastName}
            </span>
          </div>
          <div>
            <span className=" text-[18px] text-gray-500">
              {props.user.email}
            </span>
          </div>
          </div>

          <div>
            <span className=" text-[18px] text-gray-500">
              {props.user.id}
            </span>
          </div>

        </div>
        <div className="border border-gray-300 p-6 rounded-lg mt-8">
          <div className="mb-4">
            <span className="text-[22px] font-semibold">
              Personal Information
            </span>
          </div>
          <div className="grid grid-cols-2 md:grid-cols-3   gap-6 ">
            <div className="flex flex-col items-start">
              <span className="text-[14px] text-gray-500">FIRST NAME</span>
              <span className="text-[16px]">{props.user.firstName}</span>
            </div>
            <div className="flex flex-col items-start">
              <span className="text-[14px] text-gray-500">LAST NAME</span>
              <span className="text-[16px]">{props.user.lastName}</span>
            </div>
            <div className="flex flex-col items-start">
              <span className="text-[14px] text-gray-500">DATE OF BIRTH</span>
              <span className="text-[16px]">{props.user.dateOfBirth}</span>
            </div>
            <div className="flex flex-col items-start">
              <span className="text-[14px] text-gray-500">EMAIL ADDRESS</span>
              <span className="text-[16px]">{props.user.email}</span>
            </div>
            <div className="flex flex-col items-start">
              <span className="text-[14px] text-gray-500">DATE JOINED</span>
              <span className="text-[16px]">
                {dayjs(new Date(props.user.createdAt)).format(
                  "MMM D, YYYY hh:mm a"
                )}
              </span>
            </div>
            <div className="flex flex-col items-start">
              <span className="text-[14px] text-gray-500">KYC STATUS</span>
              <span
                className={`text-[14px] py-2 px-6 rounded-[60px] mt-2 inline-block ${
                  props.user.accountLevel === "Intermediate"
                    ? "bg-green-100 text-green-700"
                    : "bg-yellow-100 text-yellow-700"
                }`}
              >
                {props.user.accountLevel === "Intermediate"
                  ? "Submitted"
                  : "Pending"}
              </span>
            </div>
            <div className="flex flex-col items-start">
              <span className="text-[14px] text-gray-500">BUY ORDERS</span>
              <span className="text-[16px]">{data?.totalBuyOrdersCount}(${data?.totalBuyOrders})</span>
            </div>
            <div className="flex flex-col items-start">
              <span className="text-[14px] text-gray-500">SELL ORDERS</span>
              <span className="text-[16px]">{data?.totalSellOrdersCount}(${data?.totalSellOrders})</span>
            </div>
            <div className="flex flex-col items-start">
              <span className="text-[14px] text-gray-500">TOTAL ORDERS</span>
              <span className="text-[16px]">{data?.totalOrdersCount}(${data?.totalOrders})</span>
            </div>
          </div>
        </div>
        <div className="w-full mt-8">
          <UserInfoTabs  userId={props.user.id}/>
        </div>
      </div>
    </div>
  );
}

export default UserInfo;
