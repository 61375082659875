/* eslint-disable */

import React, { Fragment, useContext, useEffect, useState } from "react";
import MetaTags from "react-meta-tags";
import AuthService from "../../misc/AuthService";
import OrderForm from "./include/OrderForm";
import OrderInfo from "./include/OrderInfo";
import { TableLoader } from "./include/Loaders";
import axios from "axios";
// import 'antd/dist/antd.css';
import SideNav from "./include/SideNav";
import Header from "./include/Header";
import Footer from "./include/Footer";
import DatePicker from "react-datepicker";
import moment from "moment";

import "react-datepicker/dist/react-datepicker.css";
import Constants from "../../misc/Constants";
import format from "../../misc/formatData";
import refreshToken from "../../misc/RefreshToken";
import Paginate from "./include/Paginate";
import { reactLocalStorage } from "reactjs-localstorage";
import SideNavMobile from "./include/sideNavMobile";
import ReactGA from "react-ga";
import Context from "../../store/Context";
import { ACTIONS } from "../../store/useGlobalState";
import TokenExpiryAlert from "../Modal/TokenExpiryAlert";
import startTokenRefresh from "../../misc/sessionExpiry";
import {
  useHistory,
  useLocation,
  useParams,
} from "react-router-dom/cjs/react-router-dom.min";

function Orders(props) {
  const history = useHistory();
  const location = useLocation();
  const params = useParams();
  const dispatch = useContext(Context)?.setState;
  AuthService.checkProtected();
  useEffect(() => {
    ReactGA.pageview(window.location.pathname + window.location.search);
  }, []);

  const [state, setState] = useState({
    git: false,
    sidebar: false,
    userInfo: AuthService.getAuth().user,
    isLoading: false,
    isRequesting: false,
    showFilter: false,
    orderType: "buy",
    orderID: "",
    showOrderInfo: false,
    page: params.page ? params.page : 1,
    range: null,
    rangeStart: null,
    rangeEnd: null,
    search: "",
    status: "all",
    type: "all",
    currency: "all",
    currentPage: 1,
    totalList: 1,
    orders: [],
    total: 0,
    passedOrder: {},
  });

  function fetchByPagination(count) {
    setState({
      ...state,
      isRequesting: true,
    });
    // console.log(count)
    // Getting user orders ...
    axios
      .get(
        `${Constants.API_BASE_URL}/users/orders?limit=10&skip=${count === 1 ? 1 : count * 10 - 10}`,
        AuthService.getAxiosHeaders(),
      )
      .then((res) => {
        let data = res.data.data;
        setState({
          ...state,
          isLoading: false,
          isRequesting: false,
          currentPage: state.page,
          totalList: Math.ceil(data?.meta?.result_total / 10),
          orders: [...data],
          total: res.data?.total,
        });
        setState({ ...state, page: count });
      })
      .catch((err) => {
        setState({
          ...state,
          isRequesting: false,
        });
        if (err.response.status === 401) {
          startTokenRefresh(true);
        }
      });
    return;
  }

  function getOrders(isUpdating) {
    if (!isUpdating)
      setState({
        ...state,
        isRequesting: true,
      });

    // Getting user orders ...
    axios
      .get(
        `${Constants.API_BASE_URL}/users/orders`,
        AuthService.getAxiosHeaders(),
      )
      .then((res) => {
        let data = res.data.data;
        setState({
          ...state,
          isLoading: false,
          isRequesting: false,
          currentPage: state.page,
          totalList: Math.ceil(data?.meta?.result_total / 10),
          orders: [...data],
          total: res.data?.total,
        });
      })
      .catch((err) => {
        // refreshToken();
        setState({
          ...state,
          isRequesting: false,
        });
        if (err.response.status === 401) {
          startTokenRefresh(true);
        }
      });

    let isOrder = reactLocalStorage.getObject("orderSell").isOrder;
    if (isOrder) {
      setState({ ...state, git: true });
    }
  }

  useEffect(() => {
    try {
      if (params.page) {
        fetchByPagination(params.page);
      } else {
        fetchByPagination(1);
      }
    } catch (e) {
      return;
    }
  }, [location]);

  return (
    <Fragment>
      <MetaTags>
        <title>Orders - HodlPay</title>
      </MetaTags>
      <div className="header-fixed header-tablet-and-mobile-fixed aside-fixed aside-secondary-disabled body">
        <div className="d-flex flex-column flex-root">
          <div className="page d-flex flex-row flex-column-fluid">
            {state.sidebar && (
              <SideNavMobile
                close={() => setState({ ...state, sidebar: false })}
              />
            )}
            <SideNav className={"bigScreen"} active="orders" />

            <div
              className="p-0 wrapper d-flex flex-column flex-row-fluid"
              id="kt_wrapper"
            >
              <div className="header-mobile py-3">
                <div className="container d-flex flex-stack">
                  <div className="d-flex align-items-center flex-grow-1 flex-lg-grow-0">
                    <a href="/dashboard">
                      <img
                        alt="Logo"
                        src="/assets/media/logos/hodlpay-black.svg"
                        className="h-35px"
                      />
                    </a>
                  </div>

                  <button
                    className="btn btn-icon btn-active-color-primary"
                    onClick={() =>
                      setState({ ...state, sidebar: !state.sidebar })
                    }
                  >
                    <i
                      className="fa fa-bars"
                      style={{ fontSize: "2rem", color: "rgb(0,0,0,.8)" }}
                    ></i>
                  </button>
                </div>
              </div>

              <Header
                title={`Orders`}
                resetBtn={() => setState({ ...state, git: false })}
                git={state.git}
              />

              {state.isLoading ? (
                <div className="pre-loader">
                  <div className="text-center">
                    <div className="spinner-border" role="status">
                      <span className="sr-only">Loading...</span>
                    </div>
                  </div>
                </div>
              ) : (
                <>
                  <div
                    className="content d-flex flex-column flex-column-fluid"
                    id="kt_content"
                  >
                    <div className="container-xxl" id="kt_content_container">
                      <div className="card mb-7">
                        <div className="card-body"></div>
                      </div>
                      {state.isRequesting ? (
                        <TableLoader></TableLoader>
                      ) : (
                        <>
                          <div className="d-flex flex-wrap flex-stack pb-7">
                            <div className="d-flex flex-wrap align-items-center my-1">
                              <h3 className="fw-bolder me-5 my-1">
                                {state.total} Orders Found
                              </h3>
                            </div>
                          </div>
                          <div className="card card-flush">
                            <div className="card-body pt-0">
                              {state.orders?.length === 0 ? (
                                <div className="text-center py-20">
                                  <span className="symbol">
                                    <span className="svg-icon svg-icon-3x svg-icon-primary">
                                      <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="24"
                                        height="24"
                                        viewBox="0 0 24 24"
                                        fill="none"
                                      >
                                        <g
                                          stroke="none"
                                          strokeWidth="1"
                                          fill="none"
                                          fillRule="evenodd"
                                        >
                                          <rect
                                            x="0"
                                            y="0"
                                            width="24"
                                            height="24"
                                          />
                                          <rect
                                            fill="#000000"
                                            opacity="0.3"
                                            x="2"
                                            y="2"
                                            width="10"
                                            height="12"
                                            rx="2"
                                          />
                                          <path
                                            d="M4,6 L20,6 C21.1045695,6 22,6.8954305 22,8 L22,20 C22,21.1045695 21.1045695,22 20,22 L4,22 C2.8954305,22 2,21.1045695 2,20 L2,8 C2,6.8954305 2.8954305,6 4,6 Z M18,16 C19.1045695,16 20,15.1045695 20,14 C20,12.8954305 19.1045695,12 18,12 C16.8954305,12 16,12.8954305 16,14 C16,15.1045695 16.8954305,16 18,16 Z"
                                            fill="#000000"
                                          />
                                        </g>
                                      </svg>
                                    </span>
                                  </span>
                                  <p className="text-gray-400 fs-4 fw-bold py-3">
                                    No orders found.
                                  </p>
                                </div>
                              ) : (
                                <div className="table-responsive">
                                  <table className="table table-row-dashed table-row-gray-200 align-middle gs-0 gy-4">
                                    <thead className="fs-7 text-gray-400 text-uppercase">
                                      <tr>
                                        <th className="min-w-20px text-center">
                                          Currency
                                        </th>
                                        <th className="min-w-90px"></th>
                                        <th className="min-w-50px">
                                          USD Amount
                                        </th>
                                        <th className="min-w-60px">Rate</th>
                                        <th className="min-w-120px">Total</th>
                                        <th className="min-w-90px">Date</th>
                                        <th className="min-w-50px">Status</th>
                                        <th className="min-w-50px text-end"></th>
                                      </tr>
                                    </thead>

                                    <tbody>
                                      {state.orders?.map((data, index) => {
                                        let ghs =
                                          parseFloat(data.fee * data.rate) +
                                          parseInt(data.amount * data.rate);
                                        let date = format(`${data.updatedAt}`);
                                        return (
                                          <tr key={index}>
                                            <td className="text-center">
                                              <div className="symbol symbol-45px me-2">
                                                {data?.asset === "BTC" ? (
                                                  <span className="symbol-label bg-light-warning">
                                                    <img
                                                      src="/assets/media/svg/brand-logos/btc.svg"
                                                      className="h-50 align-self-center"
                                                      alt=""
                                                    />
                                                  </span>
                                                ) : (
                                                  <span className="symbol-label bg-light-success">
                                                    <img
                                                      src="/assets/media/svg/brand-logos/usdt.svg"
                                                      className="h-50 align-self-center"
                                                      alt=""
                                                    />
                                                  </span>
                                                )}
                                              </div>
                                            </td>
                                            <td>
                                              <small className="text-dark fw-bolder text-hover-primary mb-1 fs-6">
                                                {data?.asset}
                                                {data.type === "buy" ? (
                                                  <span className="badge badge-light-primary ms-2">
                                                    Buy Order
                                                  </span>
                                                ) : (
                                                  <span className="badge badge-light-dark ms-2">
                                                    Sell Order
                                                  </span>
                                                )}
                                              </small>
                                            </td>
                                            <td>
                                              <span className="text-dark fw-bold d-block">
                                                ${data.amount}{" "}
                                                {data.type === "buy" && (
                                                  <>
                                                    •{" "}
                                                    <span className="text-muted">
                                                      Fees:
                                                    </span>{" "}
                                                    ${data.fee}
                                                  </>
                                                )}
                                              </span>
                                            </td>
                                            <td>
                                              <small className="text-dark fw-bolder text-hover-primary mb-1">
                                                GHS {data.rate}
                                              </small>
                                            </td>
                                            <td>
                                              <small className="text-dark fw-bolder text-hover-primary mb-1">
                                                GHS {ghs}
                                              </small>
                                            </td>
                                            <td className="text-start text-muted fw-bold">
                                              {date}
                                            </td>
                                            <td className="text-start">
                                              {[
                                                "pending_payment",
                                                "pending_verification",
                                                "pending_transfer",
                                                "pending_payout",
                                                "pending",
                                              ].includes(data.status) && (
                                                <span className="badge badge-light-warning">
                                                  Pending
                                                </span>
                                              )}

                                              {data.status?.includes(
                                                "awaiting",
                                              ) && (
                                                <span className="badge badge-light-success">
                                                  Waiting approval
                                                </span>
                                              )}

                                              {data.status === "approved" && (
                                                <span className="badge badge-light-primary">
                                                  Approved
                                                </span>
                                              )}

                                              {data.status === "completed" && (
                                                <span className="badge badge-light-primary">
                                                  Completed
                                                </span>
                                              )}

                                              {data.status === "refunded" && (
                                                <span className="badge badge-light-danger">
                                                  Refunded
                                                </span>
                                              )}

                                              {data.status === "canceled" && (
                                                <span className="badge badge-light-danger">
                                                  Canceled
                                                </span>
                                              )}
                                            </td>
                                            <td className="text-end">
                                              <button
                                                className="btn btn-sm btn-icon btn-bg-light btn-active-color-primary"
                                                onClick={() => {
                                                  dispatch({
                                                    type: ACTIONS.modal_2_On,
                                                    content: (
                                                      <OrderInfo
                                                        orderID={data.id}
                                                        passedOrder={data}
                                                      />
                                                    ),
                                                  });
                                                }}
                                              >
                                                <span className="svg-icon svg-icon-2">
                                                  <svg
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    width="24"
                                                    height="24"
                                                    viewBox="0 0 24 24"
                                                    fill="none"
                                                  >
                                                    <rect
                                                      opacity="0.5"
                                                      x="18"
                                                      y="13"
                                                      width="13"
                                                      height="2"
                                                      rx="1"
                                                      transform="rotate(-180 18 13)"
                                                      fill="black"
                                                    />
                                                    <path
                                                      d="M15.4343 12.5657L11.25 16.75C10.8358 17.1642 10.8358 17.8358 11.25 18.25C11.6642 18.6642 12.3358 18.6642 12.75 18.25L18.2929 12.7071C18.6834 12.3166 18.6834 11.6834 18.2929 11.2929L12.75 5.75C12.3358 5.33579 11.6642 5.33579 11.25 5.75C10.8358 6.16421 10.8358 6.83579 11.25 7.25L15.4343 11.4343C15.7467 11.7467 15.7467 12.2533 15.4343 12.5657Z"
                                                      fill="black"
                                                    />
                                                  </svg>
                                                </span>
                                              </button>
                                            </td>
                                          </tr>
                                        );
                                      })}
                                    </tbody>
                                  </table>
                                </div>
                              )}
                            </div>
                          </div>
                        </>
                      )}
                      <div
                        className="pt-10"
                        style={{
                          opacity: `${state.isRequesting ? "0.5" : "1"}`,
                          pointerEvents: `${state.isRequesting ? "none" : ""}`,
                        }}
                      >
                        {state.total > 10 && (
                          <Paginate
                            page={state.page}
                            total={state.total}
                            setPage={(count) => {
                              history.push(`/orders/${count}`);
                            }}
                          />
                        )}
                      </div>
                    </div>
                  </div>
                  <Footer />
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
}

export default Orders;
