/* eslint-disable */
import React, { useEffect, useRef, useState } from "react";
import Compressor from "compressorjs";

function DragDrop({ content, style, setCardImg }) {
  const [image, setImage] = useState({ name: "", url: "" });
  const [isDragging, setIsDragging] = useState(false);
  const fileInputRef = useRef(null);

  const handleFile = (e) => {
    const file = e.target?.files?.[0];
    const fileData = {
      name: file?.name,
      url: URL.createObjectURL(file),
      file,
    };

    new Compressor(file, {
      quality: 0.3,
      success(result) {
        fileData.file = result;
      },
      error() {
        fileData.file = file;
      },
    });

    setImage(fileData);
  };
  useEffect(() => {
    setCardImg(image);
  }, [image]);

  const deleteImage = () => setImage("");

  const onDrageOver = (e) => {
    e.preventDefault();
    setIsDragging(true);
    e.dataTransfer.dropEffect = "copy";
  };

  const onDragLeave = (e) => {
    e.preventDefault();
    setIsDragging(false);
  };

  const onDrop = (e) => {
    e.preventDefault();
    setIsDragging(false);
    const file = e.dataTransfer.files;

    for (let x = 0; x < file.length; x++) {
      if (file[x].type.split("/")[0] !== "image") continue;

      if (!image.some((e) => e.name === file[x].name)) {
        setImage((prev) => [
          ...prev,
          {
            name: file[x].name,
            url: URL.createObjectURL(file[x]),
          },
        ]);
      }
    }
  };

  return (
    <div className="images w-100 relative flex" style={{ ...style }}>
      {!image.url && (
        <div
          onDragOver={(e) => onDrageOver(e)}
          onDragLeave={(e) => onDragLeave(e)}
          onDrop={(e) => onDrop(e)}
          className="flex j-c-c v-flex a-i-c"
          style={{ width: "100%", padding: "0.5rem" }}
        >
          {!isDragging ? (
            <>
              {content}
              <input
                required
                ref={fileInputRef}
                onChange={(e) => handleFile(e)}
                type="file"
                accept=".png, .jpg, .jpeg, .webp"
                name=""
                id=""
                className="absolute top-0 bottom-0 right-0 left-0"
                style={{ opacity: "0" }}
              />
            </>
          ) : (
            <>
              <h3>Drop Here</h3>
            </>
          )}
        </div>
      )}

      {image.url && (
        <div className="flex flex-wrap mt-2" style={{ width: "100%" }}>
          <div
            className="relative m-auto"
            style={{
              width: "100%",
              height: "200px",
              background: "transparent",
            }}
          >
            <b
              onClick={() => deleteImage()}
              role="button"
              className="shadow absolute round flex j-c-c bg-danger text-white"
              style={{
                width: "25px",
                height: "25px",
                background: "white",
                margin: "5px",
                zIndex: "5",
              }}
            >
              &times;
            </b>
            <img
              src={image.url}
              alt=""
              style={{ objectFit: "contain", width: "100%", height: "100%" }}
            />
          </div>
        </div>
      )}
    </div>
  );
}

export default DragDrop;
