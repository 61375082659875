import { reactLocalStorage } from "reactjs-localstorage";

const AuthService = {
  checkProtected: () => {
    var access_token = reactLocalStorage.get("access_token");

    var user = reactLocalStorage.get("userRoles");
    if (!user?.includes("Admin") || !user?.includes("SuperAdmin")) {
      reactLocalStorage.set("admin_access_token", "");
    }
    var isLogin = Boolean(reactLocalStorage.get("isLogin"));
    let userObj = JSON.parse(reactLocalStorage.get("userObj") || "{}");
    // let emailVerified =
    //   cookie.load("userObj")?.emailIdentity?.emailVerified ?? false;
    // let phoneVerified =
    //   Boolean(cookie.load("userObj")?.phoneIdentity?.phoneVerified) ?? false;

    let emailVerified = userObj?.emailIdentity?.emailVerified ?? false;
    let phoneVerified = Boolean(userObj?.phoneIdentity?.phoneVerified) ?? false;

    if (isLogin !== true) {
      window.location = "/logout";
      return;
    }

    if (
      access_token === undefined ||
      access_token === "" ||
      access_token === null
    ) {
      window.location = "/logout";
      return;
    }
    if (!emailVerified) {
      try {
        window.location = `/email-verification/check/${userObj?.emailIdentity?.email}`;
      } catch (e) {
        return;
      }
    }

    if (!phoneVerified) window.location = "/phone-verification";
  },

  checkAdminProtected: () => {
    var access_token = reactLocalStorage.get("admin_access_token");
    let isLogin = Boolean(reactLocalStorage.get("isLogin"));
    // console.log("isLogin", isLogin);

    if (
      access_token === undefined ||
      access_token === "" ||
      access_token === null
    ) {
      window.location = "/logout";
      // console.log("isLogin", isLogin);
      return;
    }
    if (isLogin !== true) {
      window.location = "/logout";
      return;
    }
  },

  getAuth: () => {
    var userObj = reactLocalStorage.getObject("userObj");
    var access_token = reactLocalStorage.get("access_token");
    // if(JSON.stringify(userObj) === '{}' || access_token === ''){
    //     window.location = "/logout";
    // 		return;
    // }

    return {
      user: userObj,
      access_token: access_token,
    };
  },

  getAdminAuth: () => {
    var adminObj = JSON.parse(reactLocalStorage.get("adminObj") || "{}");
    var admin_access_token = reactLocalStorage.get("admin_access_token");

    return {
      admin: adminObj,
      admin_access_token: admin_access_token,
    };
  },

  checkAdminGreeter: () => {
    var access_token = reactLocalStorage.get("admin_access_token");
    var adminObj = reactLocalStorage.getObject("adminObj");

    if (access_token !== undefined && adminObj.account_status) {
      window.location = "/admin/dashboard";
      return;
    }
  },

  getAxiosHeaders: () => {
    let token = reactLocalStorage.get("admin_access_token");

    return { headers: { Authorization: `Bearer ${token}` } };
  },

  getAxiosAdminHeaders: () => {
    let token = reactLocalStorage.get("admin_access_token");
    return { headers: { Authorization: `Bearer ${token}` } };
  },

  getAxiosAdminUploadHeaders: () => {
    var access_token = reactLocalStorage.get("admin_access_token");
    return {
      headers: {
        Authorization: `Bearer ${access_token}`,
        "content-type": "multipart/form-data",
      },
    };
  },

  getAxiosUploadHeaders: () => {
    var access_token = reactLocalStorage.get("access_token");
    return {
      headers: {
        Authorization: `Bearer ${access_token}`,
        "content-type": "multipart/form-data",
      },
    };
  },
};

export default AuthService;
