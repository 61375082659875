import React, { Fragment, useContext, useEffect, useState } from "react";
import MetaTags from "react-meta-tags";
import AuthService from "../../misc/AuthService";
import CONSTANTS from "../../misc/Constants";
import OrderForm from "./include/OrderForm";
import OrderInfo from "./include/OrderInfo";
import axios from "axios";
import cookie from "react-cookies";
import { reactLocalStorage } from "reactjs-localstorage";
// import 'antd/dist/antd.css';
import SideNav from "./include/SideNav";
import Header from "./include/Header";
import Footer from "./include/Footer";
import Constants from "../../misc/Constants";
import format from "../../misc/formatData";
import SideNavMobile from "./include/sideNavMobile";
import ReactGA from "react-ga";
import Context from "../../store/Context";
import { ACTIONS } from "../../store/useGlobalState";
import startTokenRefresh from "../../misc/sessionExpiry";

function Dashboard(props) {
  AuthService.checkProtected();
  const dispatch = useContext(Context)?.setState;
  const store = useContext(Context)?.state;
  const [state, setState] = useState({
    git: false,
    sidebar: false,
    userInfo: AuthService.getAuth().user,
    isLoading: false,
    isRequesting: false,
    orderType: "buy",
    welcomeImage: "lady",
    orderID: "",
    passedOrder: null,
    showOrderInfo: false,
    dashboardStats: {
      total_sell_order_amount: 0,
      total_sell_order_count: 0,
      total_buy_order_amount: 0,
      total_buy_order_count: 0,
      order_list: [],
      rates_info: [],
      payment_method: [],
    },
    orderStatus: {
      totalBuyOrders: 0,
      totalSellOrders: 0,
      totalBuyOrdersAmount: 0,
      totalSellOrdersAmount: 0,
    },
  });

  function getOrders() {
    // Getting user orders ...
    axios
      .get(
        `${Constants.API_BASE_URL}/users/orders`,
        AuthService.getAxiosHeaders(),
      )
      .then((res) => {
        let data = res.data.data;
        setState((prev) => {
          return {
            ...prev,
            dashboardStats: {
              ...prev.dashboardStats,
              order_list: data,
            },
          };
        });
      });
  }

  function getOrdersInfo() {
    // Getting user order status
    axios
      .get(
        `${Constants.API_BASE_URL}/users/orders/stats`,
        AuthService.getAxiosHeaders(),
      )
      .then((res) => {
        let data = { ...res?.data };
        setState((prev) => {
          return {
            ...prev,
            orderStatus: {
              ...prev.orderStatus,
              ...data,
            },
          };
        });
      });
  }

  function getDashboardStats() {
    // Getting user payment methods ...
    axios
      .get(
        CONSTANTS.API_BASE_URL + "/users/payment/method",
        AuthService.getAxiosHeaders(),
      )
      .then((response) => {
        let responseInfo = response.data;
        // Loading the object from local storage
        let userObjString = localStorage.getItem("userObj");
        let userObj = userObjString ? JSON.parse(userObjString) : {};
        userObj.user.paymentMethod = responseInfo;
        // Saving the updated user object back to local storage
        localStorage.setItem("userObj", JSON.stringify(userObj));

        setState((prev) => {
          return {
            ...prev,
            isLoading: false,
            dashboardStats: {
              ...prev.dashboardStats,
              payment_method: responseInfo?.data,
            },
          };
        });
      });

    getOrders(); // Getting user's orders
    getOrdersInfo(); // Getting user's orders statistics

    // Getting user order status
    axios
      .get(
        `${Constants.API_BASE_URL}/users/orders/stats`,
        AuthService.getAxiosHeaders(),
      )
      .then((res) => {
        let data = { ...res?.data };
        setState((prev) => {
          return {
            ...prev,
            orderStatus: {
              ...prev.orderStatus,
              ...data,
            },
          };
        });
      });
  }

  useEffect(() => {
    ReactGA.pageview(window.location.pathname + window.location.search);

    getDashboardStats();

    const welcomeImage = ["lady", "guy"];
    const index = Math.floor(Math.random() * welcomeImage.length);

    setState({ ...state, isLoading: true, welcomeImage: welcomeImage[index] });

    axios
      .get(`${Constants.API_BASE_URL}/users/me`, AuthService.getAxiosHeaders())
      .then((res) => {
        let data = res.data;
        reactLocalStorage.setObject("userObj", {
          phoneIdentity: data.phoneIdentity,
          emailIdentity: data.emailIdentity,
          user: data.user,
          accountStatus: true,
        });
        cookie.save("userObj", {
          phoneIdentity: data.phoneIdentity,
          emailIdentity: data.emailIdentity,
          user: data.user,
          accountStatus: true,
        });
        cookie.save("level", data?.accountLevel);

        setState((prev) => {
          return { ...prev, isLoading: false };
        });
      })
      .catch((err) => {
        try {
          let statusCode = err?.response?.status;
          if (statusCode === 401) startTokenRefresh(true);
          setState((prev) => {
            return { ...prev, isLoading: false, isRequesting: false };
          });
        } catch (e) {
          // window.location = "/server-offline"
          return;
        }
      });

    let isOrder = reactLocalStorage.getObject("orderSell").isOrder;
    if (isOrder) {
      setState({ ...state, git: true });
    }
    // eslint-disable-next-line
  }, [store]);

  return (
    <Fragment>
      <MetaTags>
        <title>Dashboard - HodlPay</title>
      </MetaTags>
      <div className="header-fixed header-tablet-and-mobile-fixed aside-fixed aside-secondary-disabled body">
        <div className="d-flex flex-column flex-root">
          <div className="page d-flex flex-row flex-column-fluid">
            {state.sidebar && (
              <SideNavMobile
                close={() => setState({ ...state, sidebar: false })}
              />
            )}
            <SideNav className={"bigScreen"} active="dashboard" />

            <div
              className="wrapper d-flex flex-column flex-row-fluid"
              id="kt_wrapper"
              style={{ padding: "0" }}
            >
              <div className="header-mobile py-3">
                <div className="container d-flex flex-stack">
                  <div className="d-flex align-items-center flex-grow-1 flex-lg-grow-0">
                    <a href="/dashboard">
                      <img
                        alt="Logo"
                        src="/assets/media/logos/hodlpay-black.svg"
                        className="h-35px"
                      />
                    </a>
                  </div>

                  <button
                    className="btn btn-icon btn-active-color-primary"
                    onClick={() =>
                      setState({ ...state, sidebar: !state.sidebar })
                    }
                  >
                    <i
                      className="fa fa-bars"
                      style={{ fontSize: "2rem", color: "rgb(0,0,0,.8)" }}
                    ></i>
                  </button>
                </div>
              </div>
              <Header
                title={`Dashboard`}
                resetBtn={() => setState({ ...state, git: false })}
                git={state.git}
              />

              {state.isLoading || state.isRequesting ? (
                <div className="pre-loader">
                  <div className="text-center">
                    <div className="spinner-border" role="status">
                      <span className="sr-only">Loading...</span>
                    </div>
                  </div>
                </div>
              ) : (
                <>
                  <div
                    className="content d-flex flex-column flex-column-fluid"
                    id="kt_content"
                  >
                    <div className="container-xxl" id="kt_content_container">
                      <div className="row g-5 g-xl-8">
                        <div className="col-xl-5">
                          <div className="row g-5">
                            <div className="col-lg-12">
                              <div
                                className="card bgi-position-y-bottom bgi-position-x-end bgi-no-repeat bgi-size-cover min-h-250px bg-primary mb-5 mb-xl-8"
                                style={{
                                  backgroundColor: "",
                                  backgroundPosition: "100% 30px",
                                  backgroundSize: "500px auto",
                                  backgroundImage: `url('/assets/media/svg/shapes/welcome_${state.welcomeImage}.svg')`,
                                }}
                              >
                                <div className="card-body d-flex flex-column justify-content-center ps-lg-12">
                                  <h3 className="text-white fs-2qx fw-bolder mb-0">
                                    Hi {state.userInfo?.user.firstName}{" "}
                                  </h3>
                                  <h4 className="text-white fs-2 mb-7">
                                    <small>
                                      What would you like to do today?
                                    </small>
                                  </h4>

                                  <div className="mt-5">
                                    <button
                                      className="btn btn-outline btn-outline-dashed btn-white fw-bold px-6 py-3"
                                      data-bs-toggle="modal"
                                      data-bs-target="#new_order_modal"
                                      onClick={() => {
                                        dispatch({
                                          type: ACTIONS.modal_2_On,
                                          content: <OrderForm />,
                                        });
                                      }}
                                    >
                                      New Buy or Sell Order
                                    </button>
                                  </div>
                                </div>
                              </div>
                            </div>

                            <div className="col-xl-6">
                              <aside
                                href="#"
                                className="card bg-body hoverable"
                              >
                                <div className="card-body">
                                  <span className="svg-icon svg-icon-primary svg-icon-3x ms-n1">
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      width="24"
                                      height="24"
                                      viewBox="0 0 24 24"
                                      fill="none"
                                    >
                                      <g
                                        stroke="none"
                                        strokeWidth="1"
                                        fill="none"
                                        fillRule="evenodd"
                                      >
                                        <rect
                                          x="0"
                                          y="0"
                                          width="24"
                                          height="24"
                                        />
                                        <circle
                                          fill="#000000"
                                          opacity="0.3"
                                          cx="20.5"
                                          cy="12.5"
                                          r="1.5"
                                        />
                                        <rect
                                          fill="#000000"
                                          opacity="0.3"
                                          transform="translate(12.000000, 6.500000) rotate(-15.000000) translate(-12.000000, -6.500000) "
                                          x="3"
                                          y="3"
                                          width="18"
                                          height="7"
                                          rx="1"
                                        />
                                        <path
                                          d="M22,9.33681558 C21.5453723,9.12084552 21.0367986,9 20.5,9 C18.5670034,9 17,10.5670034 17,12.5 C17,14.4329966 18.5670034,16 20.5,16 C21.0367986,16 21.5453723,15.8791545 22,15.6631844 L22,18 C22,19.1045695 21.1045695,20 20,20 L4,20 C2.8954305,20 2,19.1045695 2,18 L2,6 C2,4.8954305 2.8954305,4 4,4 L20,4 C21.1045695,4 22,4.8954305 22,6 L22,9.33681558 Z"
                                          fill="#000000"
                                        />
                                      </g>
                                    </svg>
                                  </span>
                                  <small className="card-title fw-bolder text-gray-900 fs-5 mb-3 d-block">
                                    Buy Orders
                                  </small>
                                  <div className="text-gray-900 fw-bolder fs-2 mb-2 mt-5">
                                    ${state.orderStatus.totalBuyOrdersAmount}
                                  </div>
                                  <div className="fw-bold text-gray-400">
                                    {state.orderStatus.totalBuyOrders} Total Buy
                                    Orders
                                  </div>
                                </div>
                              </aside>
                            </div>

                            <div className="col-xl-6">
                              <aside
                                href="#"
                                className="card bg-body hoverable"
                              >
                                <div className="card-body">
                                  <span className="svg-icon svg-icon-dark svg-icon-3x ms-n1">
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      width="24"
                                      height="24"
                                      viewBox="0 0 24 24"
                                      fill="none"
                                    >
                                      <g
                                        stroke="none"
                                        strokeWidth="1"
                                        fill="none"
                                        fillRule="evenodd"
                                      >
                                        <rect
                                          x="0"
                                          y="0"
                                          width="24"
                                          height="24"
                                        />
                                        <path
                                          d="M2,6 L21,6 C21.5522847,6 22,6.44771525 22,7 L22,17 C22,17.5522847 21.5522847,18 21,18 L2,18 C1.44771525,18 1,17.5522847 1,17 L1,7 C1,6.44771525 1.44771525,6 2,6 Z M11.5,16 C13.709139,16 15.5,14.209139 15.5,12 C15.5,9.790861 13.709139,8 11.5,8 C9.290861,8 7.5,9.790861 7.5,12 C7.5,14.209139 9.290861,16 11.5,16 Z"
                                          fill="#000000"
                                          opacity="0.3"
                                          transform="translate(11.500000, 12.000000) rotate(-345.000000) translate(-11.500000, -12.000000) "
                                        />
                                        <path
                                          d="M2,6 L21,6 C21.5522847,6 22,6.44771525 22,7 L22,17 C22,17.5522847 21.5522847,18 21,18 L2,18 C1.44771525,18 1,17.5522847 1,17 L1,7 C1,6.44771525 1.44771525,6 2,6 Z M11.5,16 C13.709139,16 15.5,14.209139 15.5,12 C15.5,9.790861 13.709139,8 11.5,8 C9.290861,8 7.5,9.790861 7.5,12 C7.5,14.209139 9.290861,16 11.5,16 Z M11.5,14 C12.6045695,14 13.5,13.1045695 13.5,12 C13.5,10.8954305 12.6045695,10 11.5,10 C10.3954305,10 9.5,10.8954305 9.5,12 C9.5,13.1045695 10.3954305,14 11.5,14 Z"
                                          fill="#000000"
                                        />
                                      </g>
                                    </svg>
                                  </span>
                                  <small className="card-title fw-bolder text-gray-900 fs-5 mb-3 d-block">
                                    Sell Orders
                                  </small>
                                  <div className="text-gray-900 fw-bolder fs-2 mb-2 mt-5">
                                    ${state.orderStatus.totalSellOrdersAmount}
                                  </div>
                                  <div className="fw-bold text-gray-400">
                                    {state.orderStatus.totalSellOrders} Total
                                    Sell Orders
                                  </div>
                                </div>
                              </aside>
                            </div>
                          </div>
                        </div>

                        <div className="col-xl-7 ps-xl-12">
                          <div className="card mb-xl-8">
                            <div className="card-header border-0 pt-5">
                              <h3 className="card-title align-items-start flex-column">
                                <span className="card-label fw-bolder fs-3 mb-1">
                                  Recent Orders
                                </span>
                                <span className="text-muted mt-1 fw-bold fs-7">
                                  List of your recent orders
                                </span>
                              </h3>
                            </div>

                            <div className="card-body py-3">
                              {state.dashboardStats?.order_list?.length ===
                              0 ? (
                                <div className="text-center py-20">
                                  <span className="symbol">
                                    <span className="svg-icon svg-icon-3x svg-icon-primary">
                                      <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="24"
                                        height="24"
                                        viewBox="0 0 24 24"
                                        fill="none"
                                      >
                                        <g
                                          stroke="none"
                                          strokeWidth="1"
                                          fill="none"
                                          fillRule="evenodd"
                                        >
                                          <rect
                                            x="0"
                                            y="0"
                                            width="24"
                                            height="24"
                                          />
                                          <rect
                                            fill="#000000"
                                            opacity="0.3"
                                            x="2"
                                            y="2"
                                            width="10"
                                            height="12"
                                            rx="2"
                                          />
                                          <path
                                            d="M4,6 L20,6 C21.1045695,6 22,6.8954305 22,8 L22,20 C22,21.1045695 21.1045695,22 20,22 L4,22 C2.8954305,22 2,21.1045695 2,20 L2,8 C2,6.8954305 2.8954305,6 4,6 Z M18,16 C19.1045695,16 20,15.1045695 20,14 C20,12.8954305 19.1045695,12 18,12 C16.8954305,12 16,12.8954305 16,14 C16,15.1045695 16.8954305,16 18,16 Z"
                                            fill="#000000"
                                          />
                                        </g>
                                      </svg>
                                    </span>
                                  </span>
                                  <p className="text-gray-400 fs-4 fw-bold py-3">
                                    No orders found.
                                  </p>
                                </div>
                              ) : (
                                <div className="table-responsive">
                                  <table className="table table-row-dashed table-row-gray-200 align-middle gs-0 gy-4">
                                    <thead>
                                      <tr className="border-0">
                                        <th className="p-0 w-50px"></th>
                                        <th className="p-0 min-w-150px"></th>
                                        <th className="p-0 min-w-140px"></th>
                                        <th className="p-0 min-w-110px"></th>
                                        <th className="p-0 min-w-50px"></th>
                                      </tr>
                                    </thead>

                                    <tbody>
                                      {state.dashboardStats?.order_list?.map(
                                        (data, index) => {
                                          let ghs =
                                            parseFloat(data.fee * data.rate) +
                                            parseInt(data.amount * data.rate);
                                          let date = format(
                                            `${data.updatedAt}`,
                                          );
                                          return (
                                            <tr key={index}>
                                              <td>
                                                <div className="symbol symbol-45px me-2">
                                                  {data.asset === "BTC" ? (
                                                    <span className="symbol-label bg-light-warning">
                                                      <img
                                                        src="/assets/media/svg/brand-logos/btc.svg"
                                                        className="h-50 align-self-center"
                                                        alt=""
                                                      />
                                                    </span>
                                                  ) : (
                                                    <span className="symbol-label bg-light-success">
                                                      <img
                                                        src="/assets/media/svg/brand-logos/usdt.svg"
                                                        className="h-50 align-self-center"
                                                        alt=""
                                                      />
                                                    </span>
                                                  )}
                                                </div>
                                              </td>
                                              <td>
                                                <aside className="text-dark fw-bolder text-hover-primary mb-1 fs-6">
                                                  {data.asset === "BTC"
                                                    ? "Bitcoin"
                                                    : "USDT"}
                                                  {data.type === "buy" ? (
                                                    <span className="badge badge-light-primary ms-2">
                                                      Buy
                                                    </span>
                                                  ) : (
                                                    <span className="badge badge-light-dark ms-2">
                                                      Sell
                                                    </span>
                                                  )}
                                                </aside>
                                                <span className="text-muted fw-bold d-block">
                                                  ${data.amount}{" "}
                                                  {data.type === "buy" && (
                                                    <>• Fees: ${data.fee}</>
                                                  )}
                                                </span>
                                              </td>
                                              <td>
                                                <small className="text-dark fw-bolder text-hover-primary mb-1 fs-6">
                                                  GHS {ghs.toFixed(2)}
                                                </small>
                                                <span className="text-muted fw-bold d-block">
                                                  Total
                                                </span>
                                              </td>
                                              <td className="text-end text-muted fw-bold">{`${date[0]} ${date[1]}`}</td>
                                              <td className="text-end">
                                                {[
                                                  "pending_payment",
                                                  "pending_verification",
                                                  "pending_transfer",
                                                  "pending_payout",
                                                  "pending",
                                                ].includes(data.status) && (
                                                  <span className="badge badge-light-warning">
                                                    Pending
                                                  </span>
                                                )}

                                                {data.status?.includes(
                                                  "awaiting",
                                                ) && (
                                                  <span className="badge badge-light-success">
                                                    Waiting approval
                                                  </span>
                                                )}

                                                {data.status === "approved" && (
                                                  <span className="badge badge-light-primary">
                                                    Approved
                                                  </span>
                                                )}

                                                {data.status ===
                                                  "completed" && (
                                                  <span className="badge badge-light-primary">
                                                    Completed
                                                  </span>
                                                )}

                                                {data.status === "refunded" && (
                                                  <span className="badge badge-light-danger">
                                                    Refunded
                                                  </span>
                                                )}

                                                {data.status === "canceled" && (
                                                  <span className="badge badge-light-danger">
                                                    Canceled
                                                  </span>
                                                )}
                                              </td>
                                              <td className="text-end">
                                                <button
                                                  className="btn btn-sm btn-icon btn-bg-light btn-active-color-primary"
                                                  onClick={() => {
                                                    dispatch({
                                                      type: ACTIONS.modal_2_On,
                                                      content: (
                                                        <OrderInfo
                                                          orderID={data.id}
                                                          passedOrder={data}
                                                        />
                                                      ),
                                                    });
                                                  }}
                                                >
                                                  <span className="svg-icon svg-icon-2">
                                                    <svg
                                                      xmlns="http://www.w3.org/2000/svg"
                                                      width="24"
                                                      height="24"
                                                      viewBox="0 0 24 24"
                                                      fill="none"
                                                    >
                                                      <rect
                                                        opacity="0.5"
                                                        x="18"
                                                        y="13"
                                                        width="13"
                                                        height="2"
                                                        rx="1"
                                                        transform="rotate(-180 18 13)"
                                                        fill="black"
                                                      />
                                                      <path
                                                        d="M15.4343 12.5657L11.25 16.75C10.8358 17.1642 10.8358 17.8358 11.25 18.25C11.6642 18.6642 12.3358 18.6642 12.75 18.25L18.2929 12.7071C18.6834 12.3166 18.6834 11.6834 18.2929 11.2929L12.75 5.75C12.3358 5.33579 11.6642 5.33579 11.25 5.75C10.8358 6.16421 10.8358 6.83579 11.25 7.25L15.4343 11.4343C15.7467 11.7467 15.7467 12.2533 15.4343 12.5657Z"
                                                        fill="black"
                                                      />
                                                    </svg>
                                                  </span>
                                                </button>
                                              </td>
                                            </tr>
                                          );
                                        },
                                      )}
                                    </tbody>
                                  </table>
                                </div>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <Footer />
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
}

export default Dashboard;
