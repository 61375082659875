import React from "react";
import { useQuery } from "react-query";
import Constants from "../../misc/Constants";
import AuthService from "../../misc/AuthService";
import axios from "axios";

function OrderStats() {
  const fetchOrderStats = async () => {
    try {
      const response = await axios.get(
        `${Constants.API_BASE_URL}/admin/users/stats`,
        AuthService.getAxiosAdminHeaders()
      );
      return response.data;
    } catch (error) {
      console.error("Failed to fetch order stats:", error);
      throw error; // Re-throw the error to be handled by useQuery's error state
    }
  };

  const fetchDailyAverageOrders = async () => {
    try {
      const response = await axios.get(
        `${Constants.API_BASE_URL}/admin/orders/avg`,
        AuthService.getAxiosAdminHeaders()
      );
      return response.data;
    } catch (error) {
      console.error("Failed to fetch daily average orders:", error);
      throw error; // Re-throw the error to be handled by useQuery's error state
    }
  };

  const fetchMonthltOrdersPrediction = async () => {
    try {
      const response = await axios.get(
        `${Constants.API_BASE_URL}/admin/orders/prediction`,
        AuthService.getAxiosAdminHeaders()
      );
      return response.data;
    } catch (error) {
      console.error("Failed to fetch monthly orders prediction:", error);
      throw error; // Re-throw the error to be handled by useQuery's error state
    }
  }

  const { data: monthlyOrdersPrediction,  } = useQuery("monthlyOrdersPrediction", fetchMonthltOrdersPrediction);
  const { data: dailyAverageOrders, } =
    useQuery("dailyAverageOrders", fetchDailyAverageOrders);
  const { data, isLoading, error } = useQuery("orderStats", fetchOrderStats);

  if (isLoading) return <p>Loading...</p>;
  if (error) return <p>Error: {error.message}</p>;

  // console.log("data", dailyAverageOrders);

  return (
    <div>
      <div className="usersStats w-50" style={{ height: "350px" }}>
        <div className={`card card-flush h-100`}>
          <div className="card-header pt-5">
            <div className="card-title d-flex flex-column">
              <span className="fs-2hx fw-bolder text-dark me-2 lh-1">
                {data.totalUsers}
              </span>
              <span className="text-gray-400 pt-1 fw-bold fs-6">
                Total Users
              </span>
              <span className="text-gray-400 pt-1 fw-bold fs-6">
                <a href="/admin/users">
                  <button className="btn btn-primary">View All</button>
                </a>
              </span>
            </div>
          </div>

          <div className="flex flex-col md:flex-row mt-32">
            <div className="card-body d-flex flex-column justify-content-end pe-0">
              <span className="fs-6 fw-boldest text-gray-800 d-block mb-2 whitespace-nowrap">
              Total Active Users This Month
              </span>
              <span className="fs-2 fw-bolder text-dark me-2 lh-1">
                {data.activeUsersThisMonth}
              </span>
            </div>
            <div className="card-body d-flex flex-column ">
              <span className="fs-6 fw-boldest text-gray-800 d-block mb-2 whitespace-nowrap">
                Daily Average Orders
              </span>
              <span className="fs-2 fw-bolder text-dark me-2 lh-1">
              <span className="text-gray-400  mr-1">$</span> {dailyAverageOrders}
              </span>
            </div>

            <div className="card-body d-flex flex-column ">
              <span className="fs-6 fw-boldest text-gray-800 d-block mb-2 whitespace-nowrap">
               Monthly Orders Prediction
              </span>
              <span className="fs-2 fw-bolder text-dark me-2 lh-1">
              <span className="text-gray-400  mr-1">$</span> {monthlyOrdersPrediction}
              </span>
            </div>
          </div>
        </div>
      </div>

     
        <div
          className="adminDash-sec2 flex a-i-s w-100"
          style={{ height: "350px" }}
        >
          <div className={`usersStats2 card card-flush h-100 w-100`}>
            <div className="card-header pt-5">
              <div className="card-title d-flex flex-column">
                <span className="fs-2hx fw-bolder text-dark me-2 lh-1">
                  {data.totalUsers}
                </span>
                <span className="text-gray-400 pt-1 fw-bold fs-6">
                  Total Users
                </span>
                <span className="text-gray-400 pt-1 fw-bold fs-6">
                  <a href="/admin/users">
                    <button className="btn btn-primary">View All</button>
                  </a>
                </span>
              </div>
            </div>

            <div className="flex flex-col items-start md:flex-row  ">

            <div className="card-body d-flex flex-column justify-content-end pe-0">
              <span className="fs-6 fw-boldest text-gray-800 d-block mb-2">
                Total Active Users This Month
              </span>
              <span className="fs-2 fw-bolder text-dark me-2 lh-1">
                {data.activeUsersThisMonth}
              </span>
            </div>

            <div className="card-body d-flex flex-column ">
              <span className="fs-6 fw-boldest text-gray-800 d-block mb-2">
                Daily Average Orders
              </span>
              <span className="fs-2 fw-bolder text-dark me-2 lh-1">
              <span className="text-gray-400  mr-1">$</span> {dailyAverageOrders}
              </span>
            </div>

            <div className="card-body d-flex flex-column ">
              <span className="fs-6 fw-boldest text-gray-800 d-block mb-2 whitespace-nowrap">
               Monthly Orders Prediction
              </span>
              <span className="fs-2 fw-bolder text-dark me-2 lh-1">
              <span className="text-gray-400  mr-1">$</span> {monthlyOrdersPrediction}
              </span>
            </div>
            
          </div>
          </div>
        </div>
      </div>

  );
}

export default OrderStats;
