import React, { useContext } from "react";
import { ACTIONS } from "../../store/useGlobalState";
import Context from "../../store/Context";

function ModalSuccess({ successMessage }) {
  const { setState } = useContext(Context);
  return (
    <div
      style={{
        width: "100%",
        background: "white",
        padding: "1rem 0",
        minHeight: "fit-content",
      }}
      className="flex v-flex a-i-c j-c-c text-center"
    >
      <div
        className="flex j-c-c round border border-success bg-success m-2"
        style={{ minWidth: "30px", minHeight: "30px" }}
      >
        <i className="fa fa-check m-3 text-white"></i>
      </div>
      <p className="m-4">{`${successMessage ? successMessage : "Successful"}`}</p>
      <div className="flex">
        <button
          className="btn btn-primary m-3"
          onClick={() => setState({ type: ACTIONS.modalOff })}
        >
          Done
        </button>
      </div>
    </div>
  );
}

export default ModalSuccess;
