import React, { useContext, useEffect } from "react";
import { ACTIONS } from "../../store/useGlobalState";
import Context from "../../store/Context";
import ModalSuccess from "./ModalSuccess";

function ModalError({ errorMessage, reset, proceed, Form }) {
  const { setState } = useContext(Context);
  useEffect(() => {
    if (!errorMessage) {
      setState({ type: ACTIONS.modalOn, content: <ModalSuccess /> });
    }
  }, [errorMessage, setState]);
  return (
    <div
      style={{ width: "100%", background: "white", minHeight: "150px" }}
      className="flex v-flex a-i-c j-c-c "
    >
      <div
        className="flex j-c-c round border border-danger m-2"
        style={{ width: "30px", height: "30px" }}
      >
        <i className="fa fa-info m-3 text-danger"></i>
      </div>
      <div className="m-3 flex v-flex a-i-c">
        {typeof errorMessage === "string" ? (
          <h4>{`${errorMessage}`}</h4>
        ) : (
          [...errorMessage].map((val) => (
            <small key={val} className="">
              {val}
            </small>
          ))
        )}
      </div>

      {Form ? (
        <div>{Form}</div>
      ) : (
        <div className="flex">
          {proceed ? (
            <button className="btn btn-primary m-3" onClick={() => proceed()}>
              Done
            </button>
          ) : reset ? (
            <button className="btn btn-primary m-3" onClick={() => reset()}>
              Try Again
            </button>
          ) : (
            <button
              className="btn btn-primary m-3"
              onClick={() => {
                setState({ type: ACTIONS.modalOn });
                proceed();
              }}
            >
              Try Again
            </button>
          )}

          <button
            className="btn btn-secondary m-3"
            onClick={() => {
              setState({ type: ACTIONS.modalOff });
              proceed();
            }}
          >
            Cancel
          </button>
        </div>
      )}
    </div>
  );
}

export default ModalError;
