import axios from "axios";
import { reactLocalStorage } from "reactjs-localstorage";
import AuthService from "./AuthService";
import Constants from "./Constants";

const getStore = {
  getPaymentMethod: () => {
    let payment = reactLocalStorage.getObject("userObj").user?.paymentMethod;
    return payment;
  },

  getPersonalInfo: () => {
    let { email, emailVerified } =
      reactLocalStorage.getObject("userObj").emailIdentity ?? "";
    let { phone, phoneVerified } =
      reactLocalStorage.getObject("userObj").phoneIdentity ?? "";
    let { firstName, lastName } =
      reactLocalStorage.getObject("userObj").user ?? "";
    return { email, phone, firstName, lastName, emailVerified, phoneVerified };
  },

  getKycInfo: async () => {
    try {
      const response = await axios.get(
        `${Constants.API_BASE_URL}/users/kyc/documents`,
        AuthService.getAxiosHeaders(),
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  },
};

export default getStore;
