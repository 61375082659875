/* eslint-disable */
import React, {
  useContext,
  useEffect,
  useReducer,
  useRef,
  useState,
} from "react";
import Context from "../../store/Context";
import { ACTIONS } from "../../store/useGlobalState";
import getStore from "../../misc/GetStore";
import ModalError from "../../ui-elements/modal/ModalError";
import ModalLoading from "../../ui-elements/modal/ModalLoading";
import axios from "axios";
import Constants from "../../misc/Constants";
import AuthService from "../../misc/AuthService";
import ModalSuccess from "../../ui-elements/modal/ModalSuccess";

const defaultDetail = {
  oldPassword: {
    show: false,
    value: "",
  },
  newPassword: {
    show: false,
    value: "",
  },
  confirmPassword: {
    show: false,
    value: "",
  },
  otpNumber: "",
};

const reducer = (state, action) => {
  switch (action.type) {
    case "otp":
      return { ...state, otpNumber: action.text };
    case "old":
      return {
        ...state,
        oldPassword: { ...state.oldPassword, value: action.text },
      };
    case "old-show":
      return {
        ...state,
        oldPassword: { ...state.oldPassword, show: !state.oldPassword.show },
      };
    case "new":
      return {
        ...state,
        newPassword: { ...state.newPassword, value: action.text },
      };
    case "new-show":
      return {
        ...state,
        newPassword: { ...state.newPassword, show: !state.newPassword.show },
      };
    case "confirm":
      return {
        ...state,
        confirmPassword: { ...state.confirmPassword, value: action.text },
      };
    case "confirm-show":
      return {
        ...state,
        confirmPassword: {
          ...state.confirmPassword,
          show: !state.confirmPassword.show,
        },
      };

    default:
      return state;
  }
};

function ChangePassword() {
  const { setState } = useContext(Context);
  const [detail, setDetail] = useReducer(reducer, defaultDetail);
  const [passState, setPassState] = useState({
    phone: getStore.getPersonalInfo().phone,
    stage: "otp",
    isLoading: false,
    isRequesting: false,
    otpReceived: false,
    errorMessage: "",
  });
  const inputOtp = useRef();

  const submitPasswordChange = (e) => {
    e.preventDefault();
    setPassState({ ...passState, isRequesting: true });
    let data = {
      otpNumber: `${detail.otpNumber}`,
      oldPassword: `${detail.oldPassword?.value}`,
      newPassword: `${detail.newPassword?.value}`,
    };
    axios
      .post(
        Constants.API_BASE_URL + "/auth/user/change/password",
        { ...data },
        AuthService.getAxiosHeaders(),
      )
      .then(() => {
        setPassState({ ...passState, isRequesting: false, stage: "success" });
      })
      .catch((e) => {
        let err = e.response;
        setPassState({
          ...passState,
          isRequesting: false,
          stage: "error",
          errorMessage: err.data?.message,
        });
      });
  };

  const getOtp = () => {
    setPassState({ ...passState, isLoading: true });
    axios
      .post(
        `${Constants.API_BASE_URL}/auth/user/phone/otp`,
        {},
        AuthService.getAxiosHeaders(),
      )
      .then((res) => {
        setDetail({ type: "otp", text: "" });
        setPassState({
          ...passState,
          stage: "otp",
          isLoading: false,
          isRequesting: false,
          otpReceived: true,
        });
      })
      .catch((e) => {
        setPassState({
          ...passState,
          stage: "error",
          isLoading: false,
          isRequesting: false,
        });
      });
  };

  useEffect(() => {
    inputOtp.current?.focus();
    let strPhone = "";
    if (passState.phone.length > 10 && passState.phone.includes(+233)) {
      strPhone = `${passState.phone}`.replace("+233", "0");
    }
    setPassState({
      ...passState,
      phone: `${strPhone.slice(0, 3)}***${strPhone.slice(6)}`,
    });
  }, []);

  useEffect(() => {
    if (!passState.otpReceived) {
      setPassState({ ...passState, isLoading: true });
      getOtp();
    }
  }, []);

  const submitOtp = (e) => {
    e.preventDefault();
    setPassState({
      ...passState,
      stage: "change",
    });
  };

  if (passState.isLoading) {
    return <ModalLoading />;
  } else {
    if (passState.stage === "otp") {
      return (
        <div
          style={{ width: "100%", margin: "2rem 0 0 0" }}
          className="flex v-flex a-i-c animatefromleft"
        >
          <p className="text-center w-100">
            To verify that is actually you.
            <br />
            We have sent you a code on{" "}
            <small className="text-primary">{`${passState.phone}`}</small>.
            <br />
            Check your phone and enter the code sent here.
          </p>
          <br />
          <form action="" onSubmit={submitOtp}>
            <div
              className="flex"
              style={{
                border: "1px solid lightgray",
                width: "180px",
                height: "40px",
                overflow: "hidden",
                borderRadius: "5px",
              }}
            >
              <div
                className="flex j-c-c"
                style={{ width: "20%", height: "100%" }}
              >
                <i
                  className="fa fa-mobile text-dark"
                  style={{ fontSize: "1.5rem" }}
                ></i>
              </div>
              <input
                required
                value={detail.otpNumber}
                onChange={(e) =>
                  setDetail({ type: "otp", text: e.target.value })
                }
                ref={inputOtp}
                type="text"
                className="p-2"
                style={{
                  width: "80%",
                  height: "100%",
                  border: "none",
                  outline: "none",
                }}
                placeholder="xxxx"
              />
            </div>
            <div className="flex">
              <div className="flex">
                Didn't receive?{" "}
                <p
                  className="text-info p-2"
                  onClick={getOtp}
                  style={{ cursor: "pointer" }}
                >
                  Resend
                </p>
              </div>
            </div>
            <button className="btn btn-primary w-100 mt-5">Next</button>
          </form>
        </div>
      );
    }
    if (passState.stage === "change") {
      return (
        <form style={{ width: "100%" }} className="flex v-flex a-i-c">
          <div className="flex mt-2 mb-4 j-c-c w-100">
            <i className="fa fa-lock"></i>
            <aside
              className="flex m-1 w-100"
              style={{ borderBottom: "1px solid lightgray" }}
            >
              <input
                value={detail.oldPassword.value}
                onChange={(e) =>
                  setDetail({ type: "old", text: e.target.value })
                }
                type={`${detail.oldPassword.show ? "text" : "password"}`}
                className="w-100 p-3"
                placeholder="Enter Old Password"
                style={{ border: "none" }}
              />
              <i
                className={`fa fa-${detail.oldPassword.show ? "eye-slash" : "eye"}`}
                onClick={() => setDetail({ type: "old-show" })}
              ></i>
            </aside>
          </div>
          <div className="flex mt-2 mb-4 j-c-c w-100">
            <i className="fa fa-lock"></i>
            <aside
              className="flex m-1 w-100"
              style={{ borderBottom: "1px solid lightgray" }}
            >
              <input
                value={detail.newPassword.value}
                onChange={(e) =>
                  setDetail({ type: "new", text: e.target.value })
                }
                type={`${detail.newPassword.show ? "text" : "password"}`}
                className="w-100 p-3"
                placeholder="Enter New Password"
                style={{ border: "none" }}
              />
              <i
                className={`fa fa-${detail.newPassword.show ? "eye-slash" : "eye"}`}
                onClick={() => setDetail({ type: "new-show" })}
              ></i>
            </aside>
          </div>
          <div className="flex mt-2 mb-4 j-c-c w-100">
            <i className="fa fa-lock"></i>
            <aside
              className="flex m-1 w-100"
              style={{
                borderBottom: `1px solid ${detail.confirmPassword?.lenght > 1 ? (detail.newPassword === detail.confirmPassword ? "lightgray" : "red") : "lightgray"}`,
              }}
            >
              <input
                value={detail.confirmPassword.value}
                onChange={(e) =>
                  setDetail({ type: "confirm", text: e.target.value })
                }
                type={`${detail.confirmPassword.show ? "text" : "password"}`}
                className="w-100 p-3"
                placeholder="Confirm Password"
                style={{ border: "none" }}
              />
              <i
                className={`fa fa-${detail.confirmPassword.show ? "eye-slash" : "eye"}`}
                onClick={() => setDetail({ type: "confirm-show" })}
              ></i>
            </aside>
          </div>

          <br />
          <div className="flex mt-2 mb-4 j-c-c w-100">
            <div
              className="btn btn-secondary m-3 mb-3"
              onClick={() => {
                setPassState({ ...passState, stage: "otp" });
              }}
            >
              Back
            </div>
            <button
              className="btn btn-primary m-3 mb-3"
              onClick={submitPasswordChange}
            >
              {passState.isRequesting ? (
                <div className="spinner-border" role="status"></div>
              ) : (
                "Done"
              )}
            </button>
          </div>
        </form>
      );
    }
    if (passState.stage === "error") {
      return (
        <ModalError
          errorMessage={passState.errorMessage ? passState.errorMessage : ""}
          reset={() => setPassState({ ...passState, stage: "otp" })}
        />
      );
    }
    if (passState.stage === "success") {
      return <ModalSuccess successMessage={"Password Reset Successful"} />;
    }
  }
}

export default ChangePassword;
