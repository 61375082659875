import React, { Fragment, useEffect, useState } from "react";
import MetaTags from "react-meta-tags";
import AuthService from "../../misc/AuthService";
import CONSTANTS from "../../misc/Constants";
import axios from "axios";
import cookie from "react-cookies";
import { reactLocalStorage } from "reactjs-localstorage";
import Constants from "../../misc/Constants";
import ReactGA from "react-ga";
import startTokenRefresh from "../../misc/sessionExpiry";
import { useHistory } from "react-router-dom";

function PhoneNumberVerification(props) {
  const history = useHistory();
  const [state, setState] = useState({
    phoneVerified: AuthService.getAuth().user.phoneIdentity,
    isLoading: false,
    isRequesting: false,
    showPhoneNumberForm: true,
    showOTPForm: false,
    phone_number: "",
    OTP: "",
    last_four: "",
    successMessage: "",
    errorMessage: "",
  });

  function phoneForm(event, name) {
    let value = event.target.value;
    setState({
      ...state,
      [name]: value,
    });
  }

  const submitPhoneForm = (e) => {
    setState({
      ...state,
      isRequesting: true,
      errorMessage: "",
    });

    e.preventDefault();

    axios
      .post(
        CONSTANTS.API_BASE_URL + "/auth/user/phone",
        { phone: state.phone_number },
        AuthService.getAxiosHeaders(),
      )
      .then((response) => {
        let responseInfo = response.data;
        let userObj = reactLocalStorage.getObject("userObj");
        userObj.phone_number = responseInfo.data?.phone_number;
        let last_four = state.phone_number.slice(state.phone_number.length - 4);
        // console.log(response)
        reactLocalStorage.setObject("userObj", userObj);
        setState({
          ...state,
          isRequesting: false,
          showPhoneNumberForm: false,
          showOTPForm: true,
          last_four: last_four,
        });
      })
      .catch((err) => {
        try {
          let errorMessage = err.response.data.message ?? "Not found";
          setState({
            ...state,
            isRequesting: false,
            errorMessage,
          });
          if (err.response.data.statusCode === 401) {
            startTokenRefresh(true);
          }
        } catch (e) {
          return;
        }
      });
  };

  const submitOTP = (e) => {
    setState({
      ...state,
      isRequesting: true,
      errorMessage: "",
      successMessage: "",
    });

    e.preventDefault();

    axios
      .post(
        `${Constants.API_BASE_URL}/auth/verify/phone/otp`,
        { otpNumber: state.OTP },
        AuthService.getAxiosHeaders(),
      )
      .then((res) => {
        try {
          let userObj = cookie.load("userObj");
          userObj = {
            ...userObj,
            phoneIdentity: {
              ...userObj?.phoneIdentity,
              phoneVerified: true,
            },
          };
          cookie.save("userObj", userObj);

          setState({
            ...state,
            isRequesting: false,
            isLoading: false,
            successMessage: "Phone Number Verified",
          });

          setTimeout(() => {
            setState({ ...state, isLoading: false });
          }, 7000);
          history.push("/dashboard");
        } catch (e) {}
      })
      .catch((error) => {
        if (error.response) {
          setState({
            ...state,
            isRequesting: false,
            isLoading: false,
            errorMessage:
              error.response?.data.message +
                ", Click on resend for a new code" ?? "",
          });
          if (error.response.status === 401) {
            startTokenRefresh(true);
          }
        }
      });
  };

  function resendOTP() {
    setState({
      ...state,
      isRequesting: true,
      errorMessage: "",
      successMessage: "",
    });

    let access_token = cookie.load("access_token");
    axios
      .post(
        CONSTANTS.API_BASE_URL + "/auth/user/phone/otp",
        {
          access_token: access_token,
        },
        AuthService.getAxiosHeaders(),
      )
      .then((response) => {
        let responseInfo = response.data;
        setState({
          ...state,
          isRequesting: false,
          successMessage: responseInfo.message,
        });
      })
      .catch((error) => {
        try {
          let errorResponse = error.response.data;

          let errorMessage = "Error: Could not connect to server";
          if (errorResponse.hasOwnProperty("message")) {
            errorMessage = errorResponse.message;
          }

          setState({
            ...state,
            isRequesting: false,
            errorMessage: errorMessage,
          });
        } catch (e) {
          return; //window.location = "/server-offline";
        }
      });
  }

  useEffect(() => {
    ReactGA.pageview(window.location.pathname + window.location.search);
    if (state.phoneVerified) window.history.back();
    setState({
      ...state,
      isLoading: false,
    });

    axios
      .get(`${CONSTANTS.API_BASE_URL}/users/me`, AuthService.getAxiosHeaders())
      .then((res) => {
        let data = res.data;
        if (!res.data?.phoneIdentity) {
          reactLocalStorage.setObject("userObj", {
            phoneIdentity: data.phoneIdentity,
            emailIdentity: data.emailIdentity,
            user: data.user,
            accountStatus: true,
          });
          cookie.save("userObj", {
            phoneIdentity: data.phoneIdentity,
            emailIdentity: data.emailIdentity,
            user: data.user,
            accountStatus: true,
          });

          let phoneIdentity = cookie.load("userObj").phoneIdentity;
          let phone = phoneIdentity?.phone;
          let lastFourDigits = phone?.slice(phone?.length - 4);
          if (phoneIdentity?.phoneVerified) {
            window.location = "/dashboard";
          }
          if (phone?.length > 2) {
            setState({
              ...state,
              last_four: lastFourDigits,
              phone_number: phone,
              showPhoneNumberForm: false,
              showOTPForm: true,
              isLoading: false,
            });
          }
        } else {
          let phoneIdentity = cookie.load("phoneIdentity");
          // phoneIdentity.phone = null;
          cookie.save("phoneIdentity", phoneIdentity);
          setState({
            ...state,
            showPhoneNumberForm: false,
            showOTPForm: true,
            isLoading: false,
          });
        }
      })
      .catch((err) => {
        try {
          setState({ isLoading: false });
          if (err.response.status === 401) {
            startTokenRefresh(true);
          }
        } catch (e) {
          return;
        }
      });
    // eslint-disable-next-line
  }, []);

  return (
    <Fragment>
      <MetaTags>
        <title>Phone Number Verification - HodlPay</title>
      </MetaTags>
      {state.isLoading ? (
        <div className="pre-loader">
          <div className="text-center">
            <div className="spinner-border" role="status">
              <span className="sr-only">Loading...</span>
            </div>
          </div>
        </div>
      ) : (
        <div className="d-flex flex-column flex-root">
          <div className="d-flex flex-column flex-column-fluid bgi-position-y-bottom position-x-center bgi-no-repeat bgi-size-contain bgi-attachment-fixed">
            <div className="d-flex flex-center flex-column flex-column-fluid p-10 pb-lg-20">
              <a href="/login" className="mb-10 pt-lg-10">
                <img
                  alt="Logo"
                  src="/assets/media/logos/hodlpay-black.svg"
                  className="h-70px"
                />
              </a>
              <div className="separator separator-dashed my-4"></div>
              <div className="fv-row fv-plugins-icon-container text-start">
                <div className="row">
                  <div className="col-lg-3 offset-lg-3">
                    <span className="d-flex align-items-center my-4">
                      <span className="symbol symbol-50px me-6">
                        <span className="symbol-label bg-light">
                          <span className="svg-icon svg-icon-1 svg-icon-primary">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="24"
                              height="24"
                              viewBox="0 0 24 24"
                              fill="none"
                            >
                              <path
                                opacity="0.3"
                                d="M21 19H3C2.4 19 2 18.6 2 18V6C2 5.4 2.4 5 3 5H21C21.6 5 22 5.4 22 6V18C22 18.6 21.6 19 21 19Z"
                                fill="black"
                              />
                              <path
                                d="M21 5H2.99999C2.69999 5 2.49999 5.10005 2.29999 5.30005L11.2 13.3C11.7 13.7 12.4 13.7 12.8 13.3L21.7 5.30005C21.5 5.10005 21.3 5 21 5Z"
                                fill="black"
                              />
                            </svg>
                          </span>
                        </span>
                      </span>
                      <span className="d-flex flex-column">
                        <span className="fw-bolder fs-5">
                          1. Email Verification
                        </span>
                        <span className="fs-7 text-muted">
                          Check your email and verify your email address
                        </span>
                      </span>
                    </span>
                  </div>
                  <div className="col-lg-3">
                    <span className="d-flex align-items-center my-4">
                      <span className="symbol symbol-50px me-6">
                        <span className="symbol-label bg-primary">
                          <span className="svg-icon svg-icon-1 svg-icon-white">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="24"
                              height="24"
                              viewBox="0 0 24 24"
                              fill="none"
                            >
                              <path
                                d="M6 21C6 21.6 6.4 22 7 22H17C17.6 22 18 21.6 18 21V20H6V21Z"
                                fill="black"
                              />
                              <path
                                opacity="0.3"
                                d="M17 2H7C6.4 2 6 2.4 6 3V20H18V3C18 2.4 17.6 2 17 2Z"
                                fill="black"
                              />
                              <path
                                d="M12 4C11.4 4 11 3.6 11 3V2H13V3C13 3.6 12.6 4 12 4Z"
                                fill="black"
                              />
                            </svg>
                          </span>
                        </span>
                      </span>
                      <span className="d-flex flex-column">
                        <span className="fw-bolder fs-5">
                          2. Phone Number Verification
                        </span>
                        <span className="fs-7 text-muted">
                          Enter your phone number and verify via SMS
                        </span>
                      </span>
                    </span>
                  </div>
                </div>
              </div>
              <div className="separator separator-dashed my-4"></div>
              <div className="w-lg-600px bg-body rounded shadow-sm p-10 p-lg-15 mx-auto">
                {state.showPhoneNumberForm && (
                  <form
                    className="form w-100 mb-10"
                    onSubmit={submitPhoneForm}
                    method="POST"
                  >
                    <div className="text-center mb-10">
                      <img
                        alt="Logo"
                        className="mh-125px"
                        src="/assets/media/svg/misc/smartphone.svg"
                      />
                    </div>
                    <div className="text-center mb-10">
                      <h1 className="text-dark mb-3">
                        Phone Number Verification
                      </h1>
                      <div className="text-muted fw-bold fs-5 mb-5">
                        Enter your phone number
                      </div>
                    </div>
                    {state.errorMessage?.length > 0 && (
                      <div className="alert alert-danger d-flex align-items-center p-5 mb-10 w-100">
                        <span className="svg-icon svg-icon-2hx svg-icon-danger me-4">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            fill="none"
                          >
                            <g
                              stroke="none"
                              strokeWidth="1"
                              fill="none"
                              fillRule="evenodd"
                            >
                              <rect x="0" y="0" width="24" height="24" />
                              <circle
                                fill="#000000"
                                opacity="0.3"
                                cx="12"
                                cy="12"
                                r="10"
                              />
                              <rect
                                fill="#000000"
                                x="11"
                                y="10"
                                width="2"
                                height="7"
                                rx="1"
                              />
                              <rect
                                fill="#000000"
                                x="11"
                                y="7"
                                width="2"
                                height="2"
                                rx="1"
                              />
                            </g>
                          </svg>
                        </span>
                        <div className="d-flex flex-column">
                          <h4 className="mb-1 text-danger">Error</h4>{" "}
                          <span>{state.errorMessage}</span>{" "}
                        </div>
                      </div>
                    )}
                    <div className="mb-10 px-md-10">
                      <div className="fw-bolder text-start text-dark fs-6 mb-1 ms-1">
                        Phone Number
                      </div>
                      <div className="input-group input-group-solid mb-5">
                        <span className="input-group-text" id="basic-addon1">
                          +233
                        </span>
                        <input
                          type="text"
                          className="form-control"
                          maxLength={10}
                          placeholder="020 123 4567"
                          aria-label="phone"
                          value={state.phone_number}
                          onChange={(e) => {
                            phoneForm(e, "phone_number");
                          }}
                          aria-describedby="basic-addon1"
                        />
                      </div>
                    </div>
                    <div className="d-flex flex-center">
                      {state.isRequesting ? (
                        <button
                          type="button"
                          className="btn btn-lg btn-primary"
                          disabled
                        >
                          <span className="">
                            Please wait...
                            <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                          </span>
                        </button>
                      ) : (
                        <button
                          type="submit"
                          className="btn btn-lg btn-primary"
                        >
                          <span className="indicator-label">Submit</span>
                        </button>
                      )}
                    </div>
                  </form>
                )}

                {state.showOTPForm && (
                  <>
                    <form
                      className="form w-100 mb-10"
                      onSubmit={submitOTP}
                      method="POST"
                    >
                      <div className="text-center mb-10">
                        <img
                          alt="Logo"
                          className="mh-125px"
                          src="/assets/media/svg/misc/smartphone.svg"
                        />
                      </div>
                      <div className="text-center mb-10">
                        <h1 className="text-dark mb-3">
                          Phone Number Verification
                        </h1>
                        <div className="text-muted fw-bold fs-5 mb-5">
                          Enter the verification code we sent to
                        </div>
                        <div className="fw-bolder text-dark fs-3">
                          *** *** {state.last_four ?? "xxx"}
                        </div>
                      </div>
                      {state.errorMessage?.length > 0 && (
                        <div className="alert alert-danger d-flex align-items-center p-5 mb-10 w-100">
                          <span className="svg-icon svg-icon-2hx svg-icon-danger me-4">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="24"
                              height="24"
                              viewBox="0 0 24 24"
                              fill="none"
                            >
                              <g
                                stroke="none"
                                strokeWidth="1"
                                fill="none"
                                fillRule="evenodd"
                              >
                                <rect x="0" y="0" width="24" height="24" />
                                <circle
                                  fill="#000000"
                                  opacity="0.3"
                                  cx="12"
                                  cy="12"
                                  r="10"
                                />
                                <rect
                                  fill="#000000"
                                  x="11"
                                  y="10"
                                  width="2"
                                  height="7"
                                  rx="1"
                                />
                                <rect
                                  fill="#000000"
                                  x="11"
                                  y="7"
                                  width="2"
                                  height="2"
                                  rx="1"
                                />
                              </g>
                            </svg>
                          </span>
                          <div className="d-flex flex-column">
                            <h4 className="mb-1 text-danger">Error</h4>{" "}
                            <span>{state.errorMessage}</span>{" "}
                          </div>
                        </div>
                      )}

                      {state.successMessage?.length > 0 && (
                        <div className="alert alert-primary d-flex align-items-center p-5 mb-10 w-100">
                          <span className="svg-icon svg-icon-2hx svg-icon-primary me-4">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="24"
                              height="24"
                              viewBox="0 0 24 24"
                              fill="none"
                            >
                              <path
                                opacity="0.3"
                                d="M20.5543 4.37824L12.1798 2.02473C12.0626 1.99176 11.9376 1.99176 11.8203 2.02473L3.44572 4.37824C3.18118 4.45258 3 4.6807 3 4.93945V13.569C3 14.6914 3.48509 15.8404 4.4417 16.984C5.17231 17.8575 6.18314 18.7345 7.446 19.5909C9.56752 21.0295 11.6566 21.912 11.7445 21.9488C11.8258 21.9829 11.9129 22 12.0001 22C12.0872 22 12.1744 21.983 12.2557 21.9488C12.3435 21.912 14.4326 21.0295 16.5541 19.5909C17.8169 18.7345 18.8277 17.8575 19.5584 16.984C20.515 15.8404 21 14.6914 21 13.569V4.93945C21 4.6807 20.8189 4.45258 20.5543 4.37824Z"
                                fill="black"
                              ></path>
                              <path
                                d="M10.5606 11.3042L9.57283 10.3018C9.28174 10.0065 8.80522 10.0065 8.51412 10.3018C8.22897 10.5912 8.22897 11.0559 8.51412 11.3452L10.4182 13.2773C10.8099 13.6747 11.451 13.6747 11.8427 13.2773L15.4859 9.58051C15.771 9.29117 15.771 8.82648 15.4859 8.53714C15.1948 8.24176 14.7183 8.24176 14.4272 8.53714L11.7002 11.3042C11.3869 11.6221 10.874 11.6221 10.5606 11.3042Z"
                                fill="black"
                              ></path>
                            </svg>
                          </span>
                          <div className="d-flex flex-column">
                            <h4 className="mb-1 text-primary">
                              {state.successMessage}
                            </h4>
                          </div>
                        </div>
                      )}
                      <div className="mb-10 px-md-10">
                        <div className="fw-bolder text-start text-dark fs-6 mb-1 ms-1">
                          Type your 6 digit security code
                        </div>
                        <input
                          className="form-control form-control-solid"
                          maxLength={6}
                          type="text"
                          placeholder=""
                          name="otp"
                          value={state.OTP}
                          onChange={(e) => {
                            phoneForm(e, "OTP");
                          }}
                          autoComplete="off"
                        />
                      </div>
                      <div className="d-flex flex-center">
                        {state.isRequesting ? (
                          <button
                            type="button"
                            className="btn btn-lg btn-primary"
                            disabled
                          >
                            <span className="">
                              Please wait...
                              <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                            </span>
                          </button>
                        ) : (
                          <button
                            type="submit"
                            className="btn btn-lg btn-primary"
                          >
                            <span className="indicator-label">Submit</span>
                          </button>
                        )}
                      </div>
                    </form>
                    {state.isRequesting ? (
                      <div className="text-center fw-bold fs-5">
                        <span className="text-muted me-1">
                          Didn’t get the code ?
                        </span>
                        <span className="text-muted fw-bolder fs-5 me-1">
                          Resend
                        </span>
                        <span className="text-muted me-1">or</span>
                        <span className="text-muted fw-bolder fs-5">
                          Change Phone Number
                        </span>
                      </div>
                    ) : (
                      <div className="text-center fw-bold fs-5">
                        <span className="text-muted me-1">
                          Didn’t get the code ?
                        </span>
                        <span
                          className="text-primary cursor-pointer fw-bolder fs-5 me-1"
                          onClick={() => {
                            resendOTP();
                          }}
                        >
                          Resend
                        </span>
                        <span className="text-muted me-1">or</span>
                        <span
                          className="text-primary cursor-pointer fw-bolder fs-5"
                          onClick={() => {
                            setState({
                              ...state,
                              showPhoneNumberForm: true,
                              showOTPForm: false,
                              phone_number: "",
                            });
                          }}
                        >
                          Change Phone Number
                        </span>
                      </div>
                    )}
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      )}
    </Fragment>
  );
}

export default PhoneNumberVerification;
