import React, { useContext } from "react";
import Context from "../../store/Context";
import { ACTIONS } from "../../store/useGlobalState";
import cookie from "react-cookies";
import style from "./style.module.css";

function AlertInfo({ message }) {
  const { setState } = useContext(Context);

  return (
    <div
      className={`flex j-c-c animatefromtop`}
      style={{
        position: "fixed",
        top: "1rem",
        left: "0",
        right: "0",
        width: "100%",
      }}
    >
      <section
        className={`relative ${style.alertInfo} flex v-flex j-c-c`}
        style={{
          minWidth: "fit-content",
          width: "fit-content",
          minHeight: "100px",
          background: "white",
          boxShadow: "0 3px 9px -3px gray",
          padding: "2rem 4rem",
          color: "black",
          borderRadius: "10px",
        }}
      >
        {Boolean(message) ? (
          <>{message}</>
        ) : (
          <div style={{}}>
            <h1 className="">Sorry 😕</h1>
            <b className=""></b>
            <p>
              Unfortunately we are currently out of stocks to process{" "}
              <b className="text-primary cursor-pointer">Buy Orders</b>
            </p>
            <p>
              Feel free to make{" "}
              <b className="text-primary cursor-pointer">Sell Orders</b>
            </p>
          </div>
        )}

        <div
          className="flex j-c-c absolute cursor-pointer"
          style={{ right: "2rem", top: "2rem" }}
          onClick={() => {
            setState({ type: ACTIONS.alertOff });
            cookie.save("stock-alert", "false");
          }}
        >
          <i
            className="fa fa-times text-dark"
            style={{ fontSize: "1.5rem" }}
          ></i>
        </div>
      </section>
    </div>
  );
}

export default AlertInfo;
