/* eslint-disable */

import React, { Fragment, useEffect, useState } from "react";
import MetaTags from "react-meta-tags";
import AuthService from "../../misc/AuthService";
import CONSTANTS from "../../misc/Constants";
import cookie from "react-cookies";
import axios from "axios";
import { reactLocalStorage } from "reactjs-localstorage";
import { passwordValidation } from "../../misc/methods";
import ReactGA from "react-ga";
import { Formik, Form, Field, ErrorMessage } from "formik";

function Register() {
  const [state, setState] = useState({
    isLoading: true,
    isRequesting: false,
    showPassword: false,
    registrationForm: {
      firstName: "",
      lastName: "",
      email: "",
      password: "",
    },
    registrationFormErrors: {
      firstName: "",
      lastName: "",
      email: "",
      password: "",
    },
    errorMessage: "",
    passwordErrors: [],
  });

  function registrationForm(event, name) {
    let value = event.target.value;
    var oldData = state.registrationForm;
    oldData[name] = value;
    setState({
      ...state,
      registrationForm: oldData,
    });
  }

  function submitRegistrationForm(values, actions) {
    setState({
      ...state,
      isRequesting: true,
      errorMessage: "",
      registrationFormErrors: {
        firstName: "",
        lastName: "",
        email: "",
        password: "",
      },
    });

    axios
      .post(CONSTANTS.API_BASE_URL + "/auth/user/register", { ...values })
      .then((response) => {
        localStorage.setItem("toptop", JSON.stringify(response));
        let data = response.data;

        cookie.save("access_token", response.data.token);
        cookie.save("refresh_token", response.data.refreshToken);
        reactLocalStorage.setObject("userObj", {
          phoneIdentity: data.phoneIdentity,
          emailIdentity: data.emailIdentity,
          user: data.user,
          accountStatus: true,
        });
        cookie.save("userObj", {
          phoneIdentity: data.phoneIdentity,
          emailIdentity: data.emailIdentity,
          user: data.user,
          accountStatus: true,
        });
        // console.log(data)
        window.location = `/email-verification/check/${data.emailIdentity.email}`;
      })
      .catch((error) => {
        try {
          let errorResponse = error.response.data;
          let registrationFormErrors = state.registrationFormErrors;
          let errorMessage = "";

          if (errorResponse.hasOwnProperty("message")) {
            actions.setStatus(errorResponse.message);
          }

          setState({
            ...state,
            isRequesting: false,
            registrationFormErrors: registrationFormErrors,
          });
        } catch (e) {
          setState({
            ...state,
            isRequesting: false,
          });
          // window.location = "/server-offline";
        }
      });
  }

  useEffect(() => {
    ReactGA.pageview(window.location.pathname + window.location.search);
    setTimeout(() => {
      setState({
        ...state,
        isLoading: false,
      });
    }, 1000);
  }, []);

  useEffect(() => {
    setState({
      ...state,
      passwordErrors: passwordValidation(state.registrationForm.password),
    });
  }, [state.registrationForm.password]);

  return (
    <Fragment>
      <MetaTags>
        <title>Sign Up - HodlPay</title>
      </MetaTags>
      <div className="d-flex flex-column flex-root">
        {state.isLoading ? (
          <div className="pre-loader">
            <div className="text-center">
              <div className="spinner-border" role="status">
                <span className="sr-only">Loading...</span>
              </div>
            </div>
          </div>
        ) : (
          <div className="d-flex flex-column flex-column-fluid bgi-position-y-bottom position-x-center bgi-no-repeat bgi-size-contain bgi-attachment-fixed">
            <div className="d-flex flex-center flex-column flex-column-fluid p-10 pb-lg-20">
              <a href="/login" className="mb-10 pt-lg-10">
                <img
                  alt="Logo"
                  src="/assets/media/logos/hodlpay-black.svg"
                  className="h-70px"
                />
              </a>
              <div className="w-lg-600px bg-body rounded shadow-sm p-10 p-lg-15 mx-auto">
                <div className="mb-10 text-center">
                  <h1 className="text-dark mb-3">Create an Account</h1>
                  <div className="text-gray-400 fw-bold fs-4">
                    Already have an account?
                    <a href="/login" className="link-primary fw-bolder">
                      {" "}
                      Sign in here
                    </a>
                  </div>
                </div>

                <Formik
                  initialValues={{
                    firstName: "",
                    lastName: "",
                    email: "",
                    password: "",
                  }}
                  onSubmit={submitRegistrationForm}
                  validate={(values) => {
                    const errors = {};

                    if (!values.firstName)
                      errors.firstName = "First name can't be empty";
                    if (!values.lastName)
                      errors.lastName = "Last name can't be empty";
                    if (!values.email) {
                      errors.email = "Your email can't be empty";
                    } else if (
                      !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(
                        values.email,
                      )
                    ) {
                      errors.email = "Invalid email address";
                    }

                    if (!values.password) {
                      errors.password = "Password can't be empty";
                    } else if (values.password.length < 8) {
                      errors.password = "Password must be 8 or more characters";
                    } else if (
                      !/(?=.*[a-zA-Z])(?=.*[0-9])(?=.*[!@#$%^&*])/.test(
                        values.password,
                      )
                    ) {
                      errors.password =
                        "Password must contain a mix of letters, numbers, and symbols";
                    }

                    return errors;
                  }}
                >
                  {({ values, handleChange, status }) => (
                    <Form className="form w-100">
                      {status && (
                        <div className="alert alert-danger scaleanimate d-flex align-items-center p-5 mb-10 w-100">
                          <span className="svg-icon svg-icon-2hx svg-icon-danger me-4">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="24"
                              height="24"
                              viewBox="0 0 24 24"
                              fill="none"
                            >
                              <g
                                stroke="none"
                                strokeWidth="1"
                                fill="none"
                                fillRule="evenodd"
                              >
                                <rect x="0" y="0" width="24" height="24" />
                                <circle
                                  fill="#000000"
                                  opacity="0.3"
                                  cx="12"
                                  cy="12"
                                  r="10"
                                />
                                <rect
                                  fill="#000000"
                                  x="11"
                                  y="10"
                                  width="2"
                                  height="7"
                                  rx="1"
                                />
                                <rect
                                  fill="#000000"
                                  x="11"
                                  y="7"
                                  width="2"
                                  height="2"
                                  rx="1"
                                />
                              </g>
                            </svg>
                          </span>
                          <div className="d-flex flex-column">
                            <h4 className="mb-1 text-danger">Error</h4>{" "}
                            <span>{status}</span>
                          </div>
                        </div>
                      )}
                      <div className="row fv-row">
                        <div className="col-xl-6 mb-7">
                          <label className="form-label fw-bolder text-dark fs-6">
                            First Name
                          </label>
                          <Field
                            className="form-control form-control-lg form-control-solid"
                            type="text"
                            name="firstName"
                            autoComplete="off"
                            value={values.firstName}
                            onChange={handleChange}
                          />
                          <div className="flex" style={{ overflow: "hidden" }}>
                            <ErrorMessage
                              className="text-danger animatefromleft"
                              name="firstName"
                              component="div"
                            />
                          </div>
                        </div>
                        <div className="col-xl-6 mb-7">
                          <label className="form-label fw-bolder text-dark fs-6">
                            Last Name
                          </label>
                          <Field
                            className="form-control form-control-lg form-control-solid"
                            type="text"
                            name="lastName"
                            autoComplete="off"
                            value={values.lastName}
                            onChange={handleChange}
                          />
                          <div className="flex" style={{ overflow: "hidden" }}>
                            <ErrorMessage
                              className="text-danger animatefromleft"
                              name="lastName"
                              component="div"
                            />
                          </div>
                        </div>
                      </div>
                      <div className="fv-row mb-7">
                        <label className="form-label fw-bolder text-dark fs-6">
                          Email
                        </label>
                        <Field
                          className="form-control form-control-lg form-control-solid"
                          type="email"
                          name="email"
                          autoComplete="off"
                          value={values.email}
                          onChange={handleChange}
                        />
                        <div className="flex" style={{ overflow: "hidden" }}>
                          <ErrorMessage
                            className="text-danger animatefromleft"
                            name="email"
                            component="div"
                          />
                        </div>
                      </div>
                      <div
                        className="mb-10 fv-row"
                        data-kt-password-meter="true"
                      >
                        <div className="mb-1">
                          <label className="form-label fw-bolder text-dark fs-6">
                            Password{" "}
                            <small className="text-muted">
                              (Use 8 or more characters with a mix of letters,
                              numbers &amp; symbols.)
                            </small>
                          </label>
                          <div className="position-relative mb-3">
                            <Field
                              className="form-control form-control-lg form-control-solid"
                              type="password"
                              name="password"
                              autoComplete="off"
                              value={values.password}
                              onChange={handleChange}
                            />
                            <div
                              className="flex"
                              style={{ overflow: "hidden" }}
                            >
                              <ErrorMessage
                                className="text-danger animatefromleft"
                                name="password"
                                component="div"
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="fv-row mb-10 text-center">
                        <label className="form-label">
                          <span className="form-check-label fw-bold text-gray-700 fs-6">
                            By creating an account I agree to HodlPay's
                            <a
                              href="https://hodlpay.io/terms-and-conditions"
                              target="_blank"
                              rel="noreferrer"
                            >
                              <small className="ms-1 link-primary">
                                Terms and conditions
                              </small>
                            </a>
                            .
                          </span>
                        </label>
                      </div>
                      <div className="text-center">
                        {state.isRequesting ? (
                          <button
                            type="button"
                            className="btn btn-lg btn-primary"
                            disabled
                          >
                            <span className="">
                              Please wait...
                              <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                            </span>
                          </button>
                        ) : (
                          <button
                            type="submit"
                            className="btn btn-lg btn-primary"
                          >
                            <span className="indicator-label">
                              Create Account
                            </span>
                          </button>
                        )}
                      </div>
                    </Form>
                  )}
                </Formik>
              </div>
            </div>
            <div className="d-flex flex-center flex-column-auto p-10">
              <div className="d-flex align-items-center fw-bold fs-6">
                <a
                  href={`${CONSTANTS.WEB_URL}/#about`}
                  target="_blank"
                  className="text-muted text-hover-primary px-2"
                  rel="noreferrer"
                >
                  About
                </a>
                <a
                  href={`${CONSTANTS.WEB_URL}/terms-and-conditions`}
                  target="_blank"
                  className="text-muted text-hover-primary px-2"
                  rel="noreferrer"
                >
                  Terms and Conditions
                </a>
                <a
                  href={`${CONSTANTS.WEB_URL}/privacy-policy`}
                  target="_blank"
                  className="text-muted text-hover-primary px-2"
                  rel="noreferrer"
                >
                  Privacy Policy
                </a>
              </div>
            </div>
          </div>
        )}
      </div>
    </Fragment>
  );
}

export default Register;
