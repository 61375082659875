const Production = {
  Constants: {
    WEB_URL: "https://hodlpay.io",
    APP_URL: "https://app.hodlpay.io",
    //prod
    API_BASE_URL: "https://backend.hodlpay.io/api/v1",
    //staging
    // API_BASE_URL: "https://dev.hodlpay.io/api/v1",
    LIVE_CHAT: "https://tawk.to/chat/5e76db0e8d24fc226589341c/default",
  },
};

const Development = {
  Constants: {
    WEB_URL: "https://hodlpay.io",
    APP_URL: "http://localhost:3000",
    //prod
    API_BASE_URL: "https://backend.hodlpay.io/api/v1",
    //staging
    // API_BASE_URL: "https://dev.hodlpay.io/api/v1",
    // "API_BASE_URL": "http://104.199.41.198/api/v1",
    LIVE_CHAT: "https://tawk.to/chat/5e76db0e8d24fc226589341c/default",
  },
};

export default process.env.NODE_ENV === "production"
  ? Production.Constants
  : Development.Constants;
