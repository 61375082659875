const pag = (total) => {
  let arr = [];
  for (let x = 1; x <= Math.round(total / 10); x++) {
    arr.push(x);
  }

  return arr;
};

export default pag;
