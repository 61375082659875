import React, { Fragment } from "react";
import MetaTags from "react-meta-tags";

class NotFound extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      returnUrl: "/dashboard",
    };
  }
  componentDidMount() {
    if (document.referrer.includes("admin")) {
      this.setState({
        ...this.state,
        returnUrl: "/admin/dashboard",
      });
    }
  }
  render() {
    return (
      <Fragment>
        <MetaTags>
          <title>404 - HodlPay</title>
        </MetaTags>
        <div className="d-flex flex-column flex-root">
          <div className="d-flex flex-column flex-center flex-column-fluid p-10">
            <img
              src="/assets/media/404.svg"
              alt=""
              className="mw-100 mb-10 h-lg-450px"
            />
            <h1 className="fw-bold mb-10">Seems there is nothing here</h1>
            <a href={`${this.state.returnUrl}`} className="btn btn-primary">
              Return Home
            </a>
          </div>
        </div>
      </Fragment>
    );
  }
}

export default NotFound;
