import React, { useEffect, useRef, useState } from "react";
import dataURLToFile from "../../../misc/dataURLToFile";
import Compressor from "compressorjs";

function ImageCapture({ setSelfie, retry }) {
  let videoRef = useRef();
  let photoRef = useRef();
  let [photoUrl, setPhotoUrl] = useState("");
  const [videoLoaded, setVideoLoaded] = useState(false);
  const [remountKey, setRemountKey] = useState(0);
  const [webcamStream, setWebcamStream] = useState(null);

  const handleRemount = () => {
    setPhotoUrl("");
    setRemountKey((prev) => prev++);
    setVideoLoaded(false);
    retry();
  };

  useEffect(() => {
    navigator.mediaDevices
      .getUserMedia({
        video: true,
      })
      .then((stream) => {
        let video = videoRef.current;
        try {
          video.srcObject = stream;
          video.oncanplay = () => {
            setVideoLoaded(true);
            video.play();
          };
          setWebcamStream(stream);
        } catch (e) {
          return;
        }
      });
  }, [remountKey, videoLoaded, videoRef, photoUrl]);

  const capture = (e) => {
    let video = videoRef.current;
    let photo = photoRef.current;
    let context = photo?.getContext("2d");

    new Compressor(video, {
      quality: 0.3,
      success(result) {
        video = result;
      },
      error() {
        return;
      },
    });

    const { videoWidth, videoHeight } = video;
    const aspectRatio = videoWidth / videoHeight;
    const canvasWidth = photo?.offsetWidth;
    const canvasHeight = canvasWidth / aspectRatio;

    photo.width = canvasWidth;
    photo.height = canvasHeight;

    context?.drawImage(video, 0, 0, canvasWidth, canvasHeight);

    setPhotoUrl(photo?.toDataURL());
    setVideoLoaded(false);
    if (webcamStream) {
      webcamStream.getTracks().forEach((track) => track.stop());
      setWebcamStream(null);
    }
    try {
      setSelfie(dataURLToFile(photo?.toDataURL()));
    } catch (e) {}
  };

  // const setfileImg = (e) => {
  //     const file = e.target?.files?.[0];
  //     setSelfie(file)
  // }

  return (
    <div
      key={remountKey}
      className="flex v-flex w-100 relative"
      style={{ minHeight: "300px" }}
    >
      {/* <input type="file" name="" id="" onChange={setfileImg}/> */}
      <div
        className={`video relative flex v-flex j-c-c ${photoUrl ? "hide" : "show"} shadow`}
        style={{
          width: "100%",
          height: "300px",
          alignItems: "end",
          margin: "auto",
          borderRadius: "10px",
          overflow: "hidden",
        }}
      >
        <video
          src=""
          ref={videoRef}
          className="shadow img-fit absolute left-0"
        ></video>
        <div
          onClick={(e) => capture(e)}
          className="round mb-1 flex j-c-c shadow absolute"
          style={{
            width: "50px",
            height: "50px",
            background: "white",
            border: "none",
            bottom: "5px",
            left: "40%",
            right: "40%",
          }}
        >
          <div
            style={{ width: "10px", height: "10px", background: "black" }}
            className="round"
          ></div>
        </div>
      </div>
      <small className="mt-5 text-center">
        For security reasons, we take a selfie picture of you for additional
        checks.
        <b className="text-primary">Read More</b>
      </small>

      <div
        style={{
          opacity: `${photoUrl.length > 0 ? "1" : "0"}`,
          zIndex: `${photoUrl.length > 0 ? "1" : "-123"}`,
        }}
        className="absolute flex j-c-c a-i-c v-flex"
      >
        <canvas
          style={{ pointerEvents: "none", borderRadius: "10px" }}
          className="img-fit"
          ref={photoRef}
        />
        <b
          className={`${!photoUrl ? "hide" : "show"} mt-5 btn btn-outline-info border border-info`}
          onClick={() => handleRemount()}
        >
          <b>Retry</b>
        </b>
      </div>
    </div>
  );
}

export default ImageCapture;
