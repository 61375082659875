import React, { useEffect } from "react";
import { useQuery } from "react-query";
import Constants from "../../misc/Constants";
import axios from "axios";
import AuthService from "../../misc/AuthService";

function OrderFilter({
  handleFetchedOrders,
  limit,
  year,
  month,
  status,
  type,
  page,
  pageNumber,
  setPageNumber,
  selectedDate,
  setSelectedDate,
  setPage,
  setYear,
  setMonth,
  setStatus,
  setType,
}) {
  const fetchOrders = async (
    limit,
    year,
    month,
    status,
    type,
    page,
    selectedDate
  ) => {
    let url = `${Constants.API_BASE_URL}/admin/orders`;

    const params = [];
    if (limit) params.push(`limit=${limit}`);
    if (year) params.push(`year=${year}`);
    if (month) params.push(`month=${month}`);
    if (status) params.push(`status=${status}`);
    if (type) params.push(`type=${type}`);
    if (page) params.push(`skip=${page + limit}`);
    if (selectedDate) params.push(`date=${selectedDate}`);

    if (params.length > 0) {
      url += `?${params.join("&")}`;
    }
    const response = await axios.get(url, AuthService.getAxiosAdminHeaders());
    return response.data;
  };

  const {
    //eslint-disable-next-line
    data,
  } = useQuery({
    queryKey: ["orders", limit, year, month, status, type, page, selectedDate],
    queryFn: async () =>
      await fetchOrders(limit, year, month, status, type, page, selectedDate),
    onSuccess: handleFetchedOrders,
    keepPreviousData: true,
  });

  useEffect(() => {
    setPage(0);
    setPageNumber(0);
    //eslint-disable-next-line
  }, [year, month, status, type, selectedDate]);

  const handleFilterChange = (e) => {
    const { name, value } = e.target;
    switch (name) {
      case "year":
        setYear(value);
        break;
      case "month":
        setMonth(value);
        break;
      case "status":
        setStatus(value);
        break;
      case "type":
        setType(value);
        break;
      case "date":
        setSelectedDate(value);
        break;
      default:
        break;
    }
  };

  return (

      <div className="card-body">
        <div className="flex gap-4 flex-col lg:flex-row lg:flex-wrap items-start">
          <div className="flex gap-4 mb-2">
            <label>Years</label>
            <select
              name="year"
              value={year}
              onChange={handleFilterChange}
              className="form-select"
            >
              <option value="">All Years</option>
              {[...Array(5)].map((_, i) => (
                <option key={i} value={new Date().getFullYear() - i}>
                  {new Date().getFullYear() - i}
                </option>
              ))}
            </select>
          </div>
          <div className="flex gap-4 mb-2">
            <label>Months</label>
            <select
              name="month"
              value={month}
              onChange={handleFilterChange}
              className="form-select"
            >
              <option value="">All Months</option>
              {[...Array(12)].map((_, i) => (
                <option key={i} value={i}>
                  {new Date(0, i).toLocaleString("default", { month: "long" })}
                </option>
              ))}
            </select>
          </div>
          <div className="flex gap-4 mb-2">
            <label>Status</label>
            <select
              name="status"
              value={status}
              onChange={handleFilterChange}
              className="form-select"
            >
              <option value="">All Statuses</option>
              <option value="completed">Completed</option>
              <option value="approved">Approved</option>
              <option value="awaiting.approval">Waiting Approval</option>
              <option value="pending">Pending</option>
              <option value="canceled">Canceled</option>
              <option value="expired">Expired</option>

            </select>
          </div>
          <div className="flex gap-4 mb-2">
            <label className="whitespace-nowrap">Order Types</label>
            <select
              name="type"
              value={type}
              onChange={handleFilterChange}
              className="form-select"
            >
              <option value="">All Order Types</option>
              <option value="buy">Buy</option>
              <option value="sell">Sell</option>
            </select>
          </div>
          <div className="flex gap-4 mb-2">
          <label className="whitespace-nowrap" >    {selectedDate ? "Date" : "Select date"}
          </label>
            <input
              aria-label="Date"
              type="date"
              name="date"
              value={selectedDate}
              onChange={handleFilterChange}
            />
          </div>
        </div>
      </div>

  );
}

export default OrderFilter;
