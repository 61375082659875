import React, { Fragment } from "react";
import AuthService from "../../../misc/AuthService";
class SideNav extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      adminInfo: AuthService.getAdminAuth().admin,
      notification: [],
    };
  }

  render() {
    return (
      <Fragment>
        <div
          id="kt_header"
          className="header py-6 py-lg-0"
          data-kt-sticky="true"
          data-kt-sticky-name="header"
          data-kt-sticky-offset="{lg: '300px'}"
        >
          <div className="header-container container-xxl">
            <div className="page-title d-flex flex-column align-items-start justify-content-center flex-wrap me-lg-20 py-3 py-lg-0 me-3">
              <h1 className="d-flex flex-column text-dark fw-bolder my-1">
                <span className="text-white fs-1">{this.props.title}</span>
              </h1>
            </div>

            <div className="d-flex align-items-center flex-wrap">
              <div className="d-flex align-items-center py-3 py-lg-0">
                <div className="me-3">
                  <small
                    className="btn btn-icon btn-custom btn-active-color-primary position-relative"
                    data-kt-menu-trigger="click"
                    data-kt-menu-attach="parent"
                    data-kt-menu-placement="bottom-end"
                  >
                    <span className="svg-icon svg-icon-1 svg-icon-white">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                      >
                        <path
                          opacity="0.3"
                          d="M12 22C13.6569 22 15 20.6569 15 19C15 17.3431 13.6569 16 12 16C10.3431 16 9 17.3431 9 19C9 20.6569 10.3431 22 12 22Z"
                          fill="black"
                        />
                        <path
                          d="M19 15V18C19 18.6 18.6 19 18 19H6C5.4 19 5 18.6 5 18V15C6.1 15 7 14.1 7 13V10C7 7.6 8.7 5.6 11 5.1V3C11 2.4 11.4 2 12 2C12.6 2 13 2.4 13 3V5.1C15.3 5.6 17 7.6 17 10V13C17 14.1 17.9 15 19 15ZM11 10C11 9.4 11.4 9 12 9C12.6 9 13 8.6 13 8C13 7.4 12.6 7 12 7C10.3 7 9 8.3 9 10C9 10.6 9.4 11 10 11C10.6 11 11 10.6 11 10Z"
                          fill="black"
                        />
                      </svg>
                    </span>

                    {/* <span className="bullet bullet-dot bg-success h-6px w-6px position-absolute translate-middle top-0 start-50 animation-blink"></span> */}
                  </small>

                  <div
                    className="menu menu-sub menu-sub-dropdown menu-column w-350px w-lg-375px"
                    data-kt-menu="true"
                  >
                    <div
                      className="d-flex flex-column bgi-no-repeat rounded-top bg-dark"
                      style={{
                        backgroundImage:
                          "url('/assets/media/misc/pattern-1.jpg')",
                      }}
                    >
                      <h3 className="text-white fw-bold px-9 mt-10 mb-6">
                        Notifications
                        {/* <span className="fs-8 opacity-75 ps-3">24 reports</span> */}
                      </h3>
                    </div>
                    <div className="">
                      <div className="scroll-y mh-325px my-5 px-8">
                        {this.state.notification.length === 0 ? (
                          <div className="text-center py-20">
                            <span className="symbol">
                              <span className="svg-icon svg-icon-3x svg-icon-primary">
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="24"
                                  height="24"
                                  viewBox="0 0 24 24"
                                  fill="none"
                                >
                                  <path
                                    opacity="0.3"
                                    d="M12 22C13.6569 22 15 20.6569 15 19C15 17.3431 13.6569 16 12 16C10.3431 16 9 17.3431 9 19C9 20.6569 10.3431 22 12 22Z"
                                    fill="black"
                                  />
                                  <path
                                    d="M19 15V18C19 18.6 18.6 19 18 19H6C5.4 19 5 18.6 5 18V15C6.1 15 7 14.1 7 13V10C7 7.6 8.7 5.6 11 5.1V3C11 2.4 11.4 2 12 2C12.6 2 13 2.4 13 3V5.1C15.3 5.6 17 7.6 17 10V13C17 14.1 17.9 15 19 15ZM11 10C11 9.4 11.4 9 12 9C12.6 9 13 8.6 13 8C13 7.4 12.6 7 12 7C10.3 7 9 8.3 9 10C9 10.6 9.4 11 10 11C10.6 11 11 10.6 11 10Z"
                                    fill="black"
                                  />
                                </svg>
                              </span>
                            </span>
                            <p className="text-gray-400 fs-4 fw-bold py-3">
                              No notification.
                            </p>
                          </div>
                        ) : (
                          <div className="d-flex flex-stack py-4">
                            <div className="d-flex align-items-center">
                              <div className="symbol symbol-35px me-4">
                                <span className="symbol-label bg-light-primary">
                                  <span className="svg-icon svg-icon-2 svg-icon-primary">
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      width="24"
                                      height="24"
                                      viewBox="0 0 24 24"
                                      fill="none"
                                    >
                                      <path
                                        opacity="0.3"
                                        d="M11 6.5C11 9 9 11 6.5 11C4 11 2 9 2 6.5C2 4 4 2 6.5 2C9 2 11 4 11 6.5ZM17.5 2C15 2 13 4 13 6.5C13 9 15 11 17.5 11C20 11 22 9 22 6.5C22 4 20 2 17.5 2ZM6.5 13C4 13 2 15 2 17.5C2 20 4 22 6.5 22C9 22 11 20 11 17.5C11 15 9 13 6.5 13ZM17.5 13C15 13 13 15 13 17.5C13 20 15 22 17.5 22C20 22 22 20 22 17.5C22 15 20 13 17.5 13Z"
                                        fill="black"
                                      />
                                      <path
                                        d="M17.5 16C17.5 16 17.4 16 17.5 16L16.7 15.3C16.1 14.7 15.7 13.9 15.6 13.1C15.5 12.4 15.5 11.6 15.6 10.8C15.7 9.99999 16.1 9.19998 16.7 8.59998L17.4 7.90002H17.5C18.3 7.90002 19 7.20002 19 6.40002C19 5.60002 18.3 4.90002 17.5 4.90002C16.7 4.90002 16 5.60002 16 6.40002V6.5L15.3 7.20001C14.7 7.80001 13.9 8.19999 13.1 8.29999C12.4 8.39999 11.6 8.39999 10.8 8.29999C9.99999 8.19999 9.20001 7.80001 8.60001 7.20001L7.89999 6.5V6.40002C7.89999 5.60002 7.19999 4.90002 6.39999 4.90002C5.59999 4.90002 4.89999 5.60002 4.89999 6.40002C4.89999 7.20002 5.59999 7.90002 6.39999 7.90002H6.5L7.20001 8.59998C7.80001 9.19998 8.19999 9.99999 8.29999 10.8C8.39999 11.5 8.39999 12.3 8.29999 13.1C8.19999 13.9 7.80001 14.7 7.20001 15.3L6.5 16H6.39999C5.59999 16 4.89999 16.7 4.89999 17.5C4.89999 18.3 5.59999 19 6.39999 19C7.19999 19 7.89999 18.3 7.89999 17.5V17.4L8.60001 16.7C9.20001 16.1 9.99999 15.7 10.8 15.6C11.5 15.5 12.3 15.5 13.1 15.6C13.9 15.7 14.7 16.1 15.3 16.7L16 17.4V17.5C16 18.3 16.7 19 17.5 19C18.3 19 19 18.3 19 17.5C19 16.7 18.3 16 17.5 16Z"
                                        fill="black"
                                      />
                                    </svg>
                                  </span>
                                </span>
                              </div>

                              <div className="mb-0 me-2">
                                <small className="fs-6 text-gray-800 text-hover-primary fw-bolder">
                                  Project Alice
                                </small>
                                <div className="text-gray-400 fs-7">
                                  Phase 1 development
                                </div>
                              </div>
                            </div>
                            <span className="badge badge-light fs-8">1 hr</span>
                          </div>
                        )}
                      </div>

                      {/* <div className="py-3 text-center border-top">
                      <a href="pages/user-profile/activity.html" className="btn btn-color-gray-600 btn-active-color-primary">View All 
                      
                      <span className="svg-icon svg-icon-5">
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                          <rect opacity="0.5" x="18" y="13" width="13" height="2" rx="1" transform="rotate(-180 18 13)" fill="black" />
                          <path d="M15.4343 12.5657L11.25 16.75C10.8358 17.1642 10.8358 17.8358 11.25 18.25C11.6642 18.6642 12.3358 18.6642 12.75 18.25L18.2929 12.7071C18.6834 12.3166 18.6834 11.6834 18.2929 11.2929L12.75 5.75C12.3358 5.33579 11.6642 5.33579 11.25 5.75C10.8358 6.16421 10.8358 6.83579 11.25 7.25L15.4343 11.4343C15.7467 11.7467 15.7467 12.2533 15.4343 12.5657Z" fill="black" />
                        </svg>
                      </span>
                      </a>
                    </div> */}
                    </div>
                  </div>
                </div>

                <div className="me-3">
                  <small
                    className="btn btn-icon btn-custom btn-active-color-primary"
                    data-kt-menu-trigger="click"
                    data-kt-menu-attach="parent"
                    data-kt-menu-placement="bottom-end"
                  >
                    {" "}
                    <span className="svg-icon svg-icon-1 svg-icon-white">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                      >
                        <path
                          d="M6.28548 15.0861C7.34369 13.1814 9.35142 12 11.5304 12H12.4696C14.6486 12 16.6563 13.1814 17.7145 15.0861L19.3493 18.0287C20.0899 19.3618 19.1259 21 17.601 21H6.39903C4.87406 21 3.91012 19.3618 4.65071 18.0287L6.28548 15.0861Z"
                          fill="black"
                        ></path>
                        <rect
                          opacity="0.3"
                          x="8"
                          y="3"
                          width="8"
                          height="8"
                          rx="4"
                          fill="black"
                        ></rect>
                      </svg>
                    </span>
                  </small>

                  <div
                    className="menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-800 menu-state-bg menu-state-primary fw-bold py-4 fs-6 w-275px"
                    data-kt-menu="true"
                  >
                    <div className="menu-item px-3">
                      <div className="menu-content d-flex align-items-center px-3">
                        <div className="symbol symbol-50px me-5">
                          <span className="svg-icon svg-icon-primary svg-icon-3x ms-n1">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="24"
                              height="24"
                              viewBox="0 0 24 24"
                              fill="none"
                            >
                              <g
                                stroke="none"
                                stroke-width="1"
                                fill="none"
                                fill-rule="evenodd"
                              >
                                <polygon points="0 0 24 0 24 24 0 24" />
                                <path
                                  d="M12,11 C9.790861,11 8,9.209139 8,7 C8,4.790861 9.790861,3 12,3 C14.209139,3 16,4.790861 16,7 C16,9.209139 14.209139,11 12,11 Z"
                                  fill="#000000"
                                  fill-rule="nonzero"
                                  opacity="0.3"
                                />
                                <path
                                  d="M3.00065168,20.1992055 C3.38825852,15.4265159 7.26191235,13 11.9833413,13 C16.7712164,13 20.7048837,15.2931929 20.9979143,20.2 C21.0095879,20.3954741 20.9979143,21 20.2466999,21 C16.541124,21 11.0347247,21 3.72750223,21 C3.47671215,21 2.97953825,20.45918 3.00065168,20.1992055 Z"
                                  fill="#000000"
                                  fill-rule="nonzero"
                                />
                              </g>
                            </svg>
                          </span>
                        </div>

                        <div className="d-flex flex-column">
                          <div className="fw-bolder d-flex align-items-center fs-5">
                            {`${this.state.adminInfo?.user?.firstName} ${this.state.adminInfo?.user?.lastName}`}
                            <span className="badge badge-light-success fw-bolder fs-8 px-2 py-1 ms-2">
                              Pro
                            </span>
                          </div>
                          <small className="fw-bold text-muted text-hover-primary fs-7">
                            {this.state.adminInfo?.emailIdentity?.email}
                          </small>
                        </div>
                      </div>
                    </div>

                    <div className="separator my-2"></div>

                    {/* <div className="menu-item px-5">
                    <a href="account/overview.html" className="menu-link px-5">My Profile</a>
                  </div> */}

                    {/* <div className="menu-item px-5 my-1">
                    <a href="/account-settings" className="menu-link px-5">Account Settings</a>
                  </div> */}

                    <div className="menu-item px-5">
                      <a href="/logout" className="menu-link px-5">
                        Sign Out
                      </a>
                    </div>

                    {/* <div className="separator my-2"></div>
                  
                  
                  <div className="menu-item px-5">
                    <div className="menu-content px-5">
                      <label className="form-check form-switch form-check-custom form-check-solid pulse pulse-success" for="kt_user_menu_dark_mode_toggle">
                        <input className="form-check-input w-30px h-20px" type="checkbox" value="1" name="mode" id="kt_user_menu_dark_mode_toggle" data-kt-url="dark/index.html" />
                        <span className="pulse-ring ms-n1"></span>
                        <span className="form-check-label text-gray-600 fs-7">Dark Mode</span>
                      </label>
                    </div>
                  </div> */}
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="header-offset"></div>
        </div>
      </Fragment>
    );
  }
}

export default SideNav;
