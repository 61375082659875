import React, { Fragment, useEffect, useState } from "react";
import { MetaTags } from "react-meta-tags";
import SideNav from "./include/SideNav";
import Header from "./include/Header";
import Footer from "./include/Footer";

import AuthService from "../../misc/AuthService";
import SideNavMobile from "./include/sideNavMobile";
import imageOne from "../../iamges/support1.jpeg";
import imageTwo from "../../iamges/support2.jpeg";
import imageThree from "../../iamges/support3.jpeg";
import style from "./styles/support.module.css";
import ReactGA from "react-ga";

function Support() {
  useEffect(() => {
    ReactGA.pageview(window.location.pathname + window.location.search);
  }, []);

  const [state, setState] = useState({
    sidebar: false,
    userInfo: AuthService.getAuth().user,
    isLoading: false,
    isRequesting: false,
    showFilter: false,
    orderType: "buy",
    orderID: "",
    showOrderInfo: false,
  });
  // eslint-disable-next-line
  const updateOrders = (isClose) => {
    if (isClose === false) {
      setState({ ...state, orderID: "", showOrderInfo: isClose });
    }
    window.location.reload();
  };

  const images = [imageOne, imageTwo, imageThree];
  return (
    <Fragment>
      <MetaTags>
        <title>Support - HodlPay</title>
      </MetaTags>
      <div className="header-fixed header-tablet-and-mobile-fixed aside-fixed aside-secondary-disabled body">
        <div className="d-flex flex-column flex-root">
          <div className="page d-flex flex-row flex-column-fluid">
            {state.sidebar && (
              <SideNavMobile
                close={() => setState({ ...state, sidebar: false })}
              />
            )}
            <SideNav className={"bigScreen"} active="wallet" />

            <div
              className="p-0 wrapper d-flex flex-column flex-row-fluid"
              id="kt_wrapper"
            >
              <div className="header-mobile py-3">
                <div className="container d-flex flex-stack">
                  <div className="d-flex align-items-center flex-grow-1 flex-lg-grow-0">
                    <a href="/dashboard">
                      <img
                        alt="Logo"
                        src="../assets/media/logos/hodlpay-black.svg"
                        className="h-35px"
                      />
                    </a>
                  </div>

                  <button
                    className="btn btn-icon btn-active-color-primary"
                    onClick={() =>
                      setState({ ...state, sidebar: !state.sidebar })
                    }
                  >
                    <i
                      className="fa fa-bars"
                      style={{ fontSize: "2rem", color: "rgb(0,0,0,.8)" }}
                    ></i>
                  </button>
                </div>
              </div>
              <Header title={`Support`} />

              {state.isLoading ? (
                <div className="pre-loader">
                  <div className="text-center">
                    <div className="spinner-border" role="status">
                      <span className="sr-only">Loading...</span>
                    </div>
                  </div>
                </div>
              ) : (
                <>
                  <div
                    className="content d-flex flex-column flex-column-fluid"
                    id="kt_content"
                  >
                    <div
                      className={`container-xxl card flex ${style.supportContainer}`}
                      id="kt_content_container"
                      style={{ padding: "5rem", background: "white" }}
                    >
                      <div className={`${style.supportMain} flex w-100 j-c-c`}>
                        <section className={`${style.textContent} flex v-flex`}>
                          <h1 style={{ fontSize: "5rem", fontWeight: "700" }}>
                            CONTACT US
                          </h1>
                          {/* <h3 style={{fontSize: '2rem'}}>YOUR TEXT HERE</h3> */}

                          <br />

                          <p>
                            We are available 24 hours to respond to all your
                            texts. <br />
                            Click on either of the media to contact our support
                            team.
                          </p>

                          <br />
                          <br />
                          <br />

                          <div className={`${style.mediaIcons} flex`}>
                            <a
                              href="https://wa.me/233246444248"
                              target="_blank"
                              rel="noreferrer"
                              title="whatsapp"
                              className={`flex btn mr-1 text-white whatsapp ${style.whatsapp}`}
                              style={{
                                background:
                                  "linear-gradient(45deg,rgb(16,136,122), rgb(16,136,122,.5))",
                              }}
                            >
                              <i
                                className="fab fa-whatsapp text-white"
                                style={{
                                  fontSize: "1.4rem",
                                  margin: "0 0.3rem 0 0",
                                }}
                              ></i>
                              <b>WhatsApp</b>
                            </a>
                            <a
                              href="https://www.facebook.com/hodlpay?mibextid=LQQJ4d"
                              target="_blank"
                              rel="noreferrer"
                              title="facebook"
                              className={`flex btn mr-1 text-white ${style.facebook}`}
                              style={{
                                background:
                                  "linear-gradient(45deg, rgb(23,116,235), rgb(23,116,235, .5)",
                              }}
                            >
                              <i
                                className="fab fa-facebook text-white"
                                style={{
                                  fontSize: "1.4rem",
                                  margin: "0 0.3rem 0 0",
                                }}
                              ></i>
                              <b>Facebook</b>
                            </a>
                            <a
                              href="https://instagram.com/hodlpay?igshid=OGQ5ZDc2ODk2ZA=="
                              target="_blank"
                              rel="noreferrer"
                              title="instagram"
                              className={`flex btn mr-1 text-white ${style.instagram}`}
                              style={{
                                background:
                                  "linear-gradient(45deg, rgb(30,156,234), rgb(142,45,181, .8), rgb(203,39,112, .8), rgb(237,119,29, .8), rgb(240,207,111, .8))",
                              }}
                            >
                              <i
                                className="fab fa-instagram text-white"
                                style={{
                                  fontSize: "1.4rem",
                                  margin: "0 0.3rem 0 0",
                                }}
                              ></i>
                              <b>Instagram</b>
                            </a>
                            <a
                              href="https://twitter.com/@hodlpay"
                              target="_blank"
                              rel="noreferrer"
                              title="twitter"
                              className={`flex btn mr-1 text-white ${style.twitter}`}
                              style={{
                                background:
                                  "linear-gradient(45deg,rgb(30,156,234), rgb(30,156,234, .5))",
                              }}
                            >
                              <i
                                className="fab fa-twitter text-white"
                                style={{
                                  fontSize: "1.4rem",
                                  margin: "0 0.3rem 0 0",
                                }}
                              ></i>
                              <b>Twitter</b>
                            </a>
                          </div>
                        </section>
                        <section className={`${style.supportImage}`}>
                          <img
                            src={images[Math.floor(Math.random() * 3)]}
                            alt=""
                          />
                        </section>
                      </div>
                    </div>
                  </div>
                  <Footer />
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
}

export default Support;
