import React, { useContext, useEffect, useReducer, useState } from "react";
import uploadIcon from "../../iamges/upload.png";
import selfieIcon from "../../iamges/selfie.png";
import licenseIcon from "../../iamges/license.png";
import passportIcon from "../../iamges/passport.png";
import cardIcon from "../../iamges/drivers-license.png";
import woman from "../../iamges/woman.png";
import man from "../../iamges/man.png";
import dnd from "../../iamges/dnd.png";
import ImageCapture from "./include/ImageCapture";
import { ACTIONS } from "../../store/useGlobalState";
import Context from "../../store/Context";

import axios from "axios";
import AuthService from "../../misc/AuthService";
import Constants from "../../misc/Constants";
import ModalLoading from "../../ui-elements/modal/ModalLoading";
import ModalError from "../../ui-elements/modal/ModalError";
import DragDrop from "./include/DragDrop";
import ModalSuccess from "../../ui-elements/modal/ModalSuccess";
import { regions } from "../../misc/methods";
import startTokenRefresh from "../../misc/sessionExpiry";

import DatePicker from "react-datepicker";

export const KYCACTIONS = {
  STAGE1: {
    age: "age",
    dateOfBirth: "dateOfBirth",
    address: "address",
    country: "country",
    city: "city",
    state: "state",
  },
  STAGE2: {
    front: "front",
    back: "back",
    idType: "idType",
    selfie: "selfie",
    id: "id",
  },
};

const initDetails = {
  stage1: {
    dateOfBirth: "",
    address: "",
    country: "Ghana",
    city: "",
    state: "",
  },
  stage2: {
    front: "",
    back: "",
    idType: "",
    selfie: "",
    id: "",
  },
};

const reducer = (state, action) => {
  switch (action.type) {
    // STAGE ONE ( 1 )
    case KYCACTIONS.STAGE1.age:
      return { ...state, stage1: { ...state.stage1, age: action.data } };
    case KYCACTIONS.STAGE1.dateOfBirth:
      return {
        ...state,
        stage1: { ...state.stage1, dateOfBirth: action.data },
      };
    case KYCACTIONS.STAGE1.address:
      return { ...state, stage1: { ...state.stage1, address: action.data } };
    case KYCACTIONS.STAGE1.city:
      return { ...state, stage1: { ...state.stage1, city: action.data } };
    case KYCACTIONS.STAGE1.state:
      return { ...state, stage1: { ...state.stage1, state: action.data } };
    case KYCACTIONS.STAGE1.country:
      return { ...state, stage1: { ...state.stage1, country: action.data } };

    // STAGE TWO ( 2 )
    case KYCACTIONS.STAGE2.back:
      return { ...state, stage2: { ...state.stage2, back: action.data } };
    case KYCACTIONS.STAGE2.front:
      return { ...state, stage2: { ...state.stage2, front: action.data } };
    case KYCACTIONS.STAGE2.idType:
      return { ...state, stage2: { ...state.stage2, idType: action.data } };
    case KYCACTIONS.STAGE2.selfie:
      return { ...state, stage2: { ...state.stage2, selfie: action.data } };
    case KYCACTIONS.STAGE2.id:
      return { ...state, stage2: { ...state.stage2, id: action.data } };

    // STAGE THREE ( 3 )
    default:
      return state;
  }
};
function Kyc({ skip }) {
  const { setState } = useContext(Context);
  const [status, setStatus] = useState("start");
  // const [cardType, setCardType] = useState(null)
  const [load, setLoad] = useState(false);
  const [error, setError] = useState({ message: "", status: false });
  const [success, setSuccess] = useState({ message: "", status: false });
  const [kyc, dispatchKyc] = useReducer(reducer, initDetails);
  const [activeGender, setActiveGender] = useState("");

  const onStart = () => setStatus("stage1");

  const onSubmitStage1 = (e) => {
    e.preventDefault();
    if (!activeGender) {
      alert("Select your gender");
      return;
    }
    if (!kyc?.stage1.address) {
      alert("Enter your address");
      return;
    }

    setLoad(true);
    let data = { ...kyc?.stage1, gender: activeGender };
    axios
      .patch(
        `${Constants.API_BASE_URL}/users`,
        data,
        AuthService.getAxiosHeaders(),
      )
      .then((res) => {
        setLoad(false);
        setStatus("stage2");
      })
      .catch((e) => {
        setError({ ...error, status: true, message: "Something went wrong" });
        setLoad(false);
        if (e.response.status === 401) {
          startTokenRefresh(true);
        }
      });
  };

  const onSubmitStage2 = (e) => {
    e.preventDefault();
    setStatus("stage3");
  };

  const onSubmitStage3 = (e) => {
    setLoad(true);
    e.preventDefault();

    const formData = new FormData();
    formData.append("front", kyc.stage2.front?.file);
    formData.append("selfie", kyc.stage2.selfie);
    formData.append("idType", kyc.stage2.idType);
    if (kyc.stage2.back?.file) {
      formData.append("back", kyc.stage2.back?.file);
    }
    formData.append("id", kyc.stage2.id);

    let Headers = {
      ...AuthService.getAxiosHeaders(),
      "Content-Type": "multipart/form-data",
    };

    axios
      .post(
        `${Constants.API_BASE_URL}/users/kyc/identity/verification`,
        formData,
        Headers,
      )
      .then((res) => {
        setLoad(false);
        setSuccess({ message: res.data.message, status: true });
      })
      .catch((e) => {
        dispatchKyc({ type: KYCACTIONS.STAGE2.selfie, data: "" });
        let res = e.response;
        setLoad(false);
        if (res?.status === 401) {
          startTokenRefresh(true);
        } else {
          setError({ status: true, message: res?.data?.message });
        }
      });
  };

  useEffect(() => {
    if (skip) {
      setStatus("stage2");
    }
  }, [skip]);

  if (error.status) {
    if (status === "stage3") {
      return (
        <ModalError
          errorMessage={error.message || ""}
          reset={() => setError({ ...error, status: false, message: "" })}
          proceed={() => {
            setLoad(true);
            setState({ type: ACTIONS.modalOff });
            window.location.reload();
          }}
        />
      );
    }
    return (
      <ModalError
        errorMessage={error.message || ""}
        reset={() => setError({ ...error, status: false, message: "" })}
      />
    );
  }

  if (success.status) {
    return (
      <ModalSuccess
        successMessage={
          "You have successfully completed KYC verification, your profile will be checked for feedback soon."
        }
      />
    );
  }

  if (load) {
    return <ModalLoading />;
  }

  if (status === "start") {
    return (
      <div style={{ width: "100%" }} className="flex v-flex a-i-c scaleanimate">
        <div className="twofactorheader text-center mb-5 mt-3">
          <h4>Let's verify your identity</h4>
          <small className="mb-5">To get verified, you will need to:</small>
        </div>
        <div
          className="flex v-flex mt-5 mb-5 j-c-c"
          style={{ width: "300px", marging: "0 auto 0 auto" }}
        >
          <aside className="flex mb-5 mt-5">
            <div style={{ width: "40px" }} className="mr-1">
              <img src={uploadIcon} alt="" className="img-fit" />
            </div>
            <p>Upload photos of documents providing your identity</p>
          </aside>
          <aside className="flex mb-2 mt-5">
            <div style={{ width: "40px" }} className="mr-1">
              <img src={selfieIcon} alt="" className="img-fit" />
            </div>
            <p>Record a short selfie</p>
          </aside>
        </div>

        <br />
        <div className="flex mt-2 mb-4 j-c-c w-100">
          <button
            onClick={() => onStart()}
            className="btn btn-primary p-4 w-100"
            style={{ borderRadius: "2px" }}
          >
            Start
          </button>
        </div>
        <small className="text-center">
          By clicking on "Start", you agree to our Terms and condition and our
          Privacy Policy
        </small>
      </div>
    );
  }

  if (status === "stage1") {
    return (
      <form
        onSubmit={onSubmitStage1}
        style={{ width: "100%" }}
        className="flex v-flex a-i-c scaleanimate"
      >
        <div className="twofactorheader text-center mb-5">
          <h4>Stage One</h4>
          {kyc.stage1.country}
          <small className="mb-5">Fill in the form below to continue</small>
        </div>
        <div
          className="flex mt-5 mb-5 j-c-c"
          style={{ width: "300px", marging: "0 auto 0 auto" }}
        >
          <aside className="flex v-flex mb-5 mt-5">
            <div className="w-100 flex j-c-c mb-2">
              <b style={{ fontSize: "1.4rem" }}>You are ?</b>
            </div>

            <div className="flex mb-4">
              <div
                onClick={() => setActiveGender("male")}
                className="relative flex v-flex a-i-c j-c-c"
                style={{
                  width: "150px",
                  height: "150px",
                  border: `${activeGender === "male" ? "2px solid rgb(50,80,140)" : "1px solid gray"}`,
                  background: activeGender === "male" ? "#eee" : "white",
                  borderRadius: "7px",
                }}
              >
                <img src={man} alt="" className="w-50" />
                <b className="mt-2">Male</b>
              </div>
              <b className="m-3">OR</b>
              <div
                onClick={() => setActiveGender("female")}
                className="relative flex v-flex a-i-c j-c-c"
                style={{
                  width: "150px",
                  height: "150px",
                  border: `${activeGender === "female" ? "2px solid rgb(50,80,140)" : "1px solid gray"}`,
                  background: activeGender === "female" ? "#eee" : "white",
                  borderRadius: "7px",
                }}
              >
                <img src={woman} alt="" className="w-50" />
                <b className="mt-2">Female</b>
              </div>
            </div>
            <div className="flex w-100 mt-3">
              <i
                className="fa fa-calendar mr-1"
                style={{ fontSize: "1.2rem" }}
              ></i>
              <div
                className="flex relative"
                style={{
                  height: "40px",
                  paddingLeft: "1px",
                  border: "0px solid gray",
                  width: "100%",
                }}
              >
                <b className="mr-1">Date of Birth</b>
                <DatePicker
                  placeholderText="Click here to select"
                  required
                  selected={kyc.stage1?.dateOfBirth}
                  onChange={(date) =>
                    dispatchKyc({
                      type: KYCACTIONS.STAGE1.dateOfBirth,
                      data: date,
                    })
                  }
                />
              </div>
            </div>
            <small className="text-dark">eg: 04/06/1999</small>
            <div className="flex w-100 mt-3 mb-3">
              <i
                className="fa fa-address-book mr-1"
                style={{ fontSize: "1.2rem" }}
              ></i>
              <input
                value={kyc.stage1?.address}
                onChange={(e) =>
                  dispatchKyc({
                    type: KYCACTIONS.STAGE1.address,
                    data: e.target.value,
                  })
                }
                type="text"
                placeholder="Address"
                className="p-2 w-100 text-dark"
                style={{
                  height: "40px",
                  border: "none",
                  borderBottom: "1px solid lightgray",
                }}
                required
              />
            </div>
            <div className="flex w-100 mt-3 mb-3">
              <i className="fa fa-flag mr-1" style={{ fontSize: "1.2rem" }}></i>
              <input
                value={kyc.stage1?.country}
                disabled
                onChange={(e) =>
                  dispatchKyc({
                    type: KYCACTIONS.STAGE1.country,
                    data: e.target.value,
                  })
                }
                type="text"
                placeholder="Country"
                className="p-2 w-100 text-dark"
                style={{
                  height: "40px",
                  border: "none",
                  borderBottom: "1px solid lightgray",
                }}
                required
              />
            </div>
            <div className="flex w-100 mt-3 mb-3">
              <i className="fa fa-city mr-1" style={{ fontSize: "1.2rem" }}></i>
              <select
                name=""
                id=""
                value={kyc.stage1?.state}
                onChange={(e) =>
                  dispatchKyc({
                    type: KYCACTIONS.STAGE1.state,
                    data: e.target.value,
                  })
                }
                className="p-2 w-100 text-dark"
                style={{
                  height: "40px",
                  border: "none",
                  borderBottom: "1px solid lightgray",
                }}
                required
              >
                <option value="">Select your region</option>
                {regions.map((region, idx) => {
                  return (
                    <option key={idx} value={region}>
                      {region}
                    </option>
                  );
                })}
              </select>
            </div>
            <div className="flex w-100 mt-3 mb-3">
              <i className="fa fa-home mr-1" style={{ fontSize: "1.2rem" }}></i>
              <input
                value={kyc.stage1?.city}
                onChange={(e) =>
                  dispatchKyc({
                    type: KYCACTIONS.STAGE1.city,
                    data: e.target.value,
                  })
                }
                type="text"
                placeholder="City"
                className="p-2 w-100 text-dark"
                style={{
                  height: "40px",
                  border: "none",
                  borderBottom: "1px solid lightgray",
                }}
                required
              />
            </div>
          </aside>
        </div>
        <div className="flex j-c-b mt-2 mb-4 j-c-c w-100">
          <button
            className="btn btn-primary mx-auto p-4"
            style={{ borderRadius: "2px", width: "48%" }}
          >
            Submit
          </button>
          {skip ? (
            <b
              className="btn btn-outline-dark border border-dark p-4"
              style={{ borderRadius: "2px", width: "48%" }}
              onClick={() => setStatus("stage2")}
            >
              Skip
            </b>
          ) : (
            <></>
          )}
        </div>
        <small className="text-center w-50">
          Please make sure the information you provide us are correct
        </small>
      </form>
    );
  }

  if (status === "stage2") {
    return (
      <div style={{ width: "100%" }} className="flex v-flex a-i-c scaleanimate">
        <div className="twofactorheader text-center mb-5">
          <h4>Stage Two {kyc.stage2.idType && "[2nd]"}</h4>
          <small className="mb-5">
            {!kyc.stage2.idType
              ? "Select any Identity card to proceed with."
              : "Provide the necessary images of the card you selected."}
          </small>
        </div>
        {!kyc.stage2.idType ? (
          <div
            className="flex v-flex mt-5 mb-5 a-i-c"
            style={{ width: "80%", marging: "0 auto 0 auto" }}
          >
            <aside
              id="passport"
              onClick={(e) =>
                dispatchKyc({
                  type: KYCACTIONS.STAGE2.idType,
                  data: e.target.id,
                })
              }
              className="flex j-c-b w-100 mt-5 p-3"
              style={{
                borderRadius: "5px",
                border: "1px solid rgb(50,80,140)",
              }}
            >
              <div className="mr-1" style={{ width: "50px" }}>
                <img src={passportIcon} alt="" className="img-fit" />
              </div>
              <h5>Passport</h5>
            </aside>
            <aside
              id="national_identity_card"
              onClick={(e) =>
                dispatchKyc({
                  type: KYCACTIONS.STAGE2.idType,
                  data: e.target.id,
                })
              }
              className="flex j-c-b w-100 mt-5 p-3"
              style={{
                borderRadius: "5px",
                border: "1px solid rgb(50,80,140)",
              }}
            >
              <div className="mr-1" style={{ width: "50px" }}>
                <img src={cardIcon} alt="" className="img-fit" />
              </div>
              <h5>Ghana Card</h5>
            </aside>
            <aside
              id="driving_license"
              onClick={(e) =>
                dispatchKyc({
                  type: KYCACTIONS.STAGE2.idType,
                  data: e.target.id,
                })
              }
              className="flex j-c-b w-100 mt-5 p-3"
              style={{
                borderRadius: "5px",
                border: "1px solid rgb(50,80,140)",
              }}
            >
              <div className="mr-1" style={{ width: "50px" }}>
                <img src={licenseIcon} alt="" className="img-fit" />
              </div>
              <h5>Driver's License</h5>
            </aside>
            <aside className="w-100 flex j-c-c mt-5">
              <button
                onClick={() => setStatus("stage1")}
                className="btn border border-secondary"
              >
                Back
              </button>
            </aside>
          </div>
        ) : (
          <form
            onSubmit={onSubmitStage2}
            className="flex v-flex mt-5 mb-5 a-i-c"
            style={{ width: "80%", marging: "0 auto 0 auto" }}
          >
            {kyc.stage2.idType === "passport" ? (
              <DragDrop
                setCardImg={(img) =>
                  dispatchKyc({ type: KYCACTIONS.STAGE2.front, data: img })
                }
                content={
                  <section
                    className="flex v-flex mr-1"
                    style={{ width: "100%" }}
                  >
                    <div
                      className="relative w-100 flex v-flex a-i-c j-c-c mt-2"
                      style={{
                        width: "100%",
                        height: "200px",
                        border: "1px dashed #2B294B",
                        padding: "0 1rem 0 1rem",
                      }}
                    >
                      <p className="mb-3">Drag and Drop</p>
                      <img src={dnd} alt="" width={50} />
                    </div>
                    <small
                      className="btn btn-secondary mt-3 w-50 m-auto"
                      style={{ padding: "1rem", fontSize: "0.8rem" }}
                    >
                      From Device
                    </small>
                  </section>
                }
              />
            ) : (
              <div className="flex j-c-b mb-2" style={{ width: "100%" }}>
                <DragDrop
                  setCardImg={(img) =>
                    dispatchKyc({ type: KYCACTIONS.STAGE2.front, data: img })
                  }
                  style={{ width: "100%" }}
                  content={
                    <section className="flex v-flex" style={{ width: "100%" }}>
                      <div className="w-100 flex j-c-c">
                        <h5>FrontView</h5>
                      </div>
                      <div
                        className="relative w-100 flex v-flex a-i-c j-c-c mt-2"
                        style={{
                          width: "100%",
                          height: "150px",
                          border: "1px dashed #2B294B",
                          padding: "0 1rem 0 1rem",
                        }}
                      >
                        <p className="mb-3">Drag and Drop</p>
                        <img src={dnd} alt="" width={50} />
                      </div>
                      <small
                        className="btn btn-secondary mt-3 w-100"
                        style={{ padding: "0.5rem 1rem", fontSize: "0.8rem" }}
                      >
                        From Device
                      </small>
                    </section>
                  }
                />
                <DragDrop
                  setCardImg={(img) =>
                    dispatchKyc({ type: KYCACTIONS.STAGE2.back, data: img })
                  }
                  style={{ width: "100%" }}
                  content={
                    <section className="flex v-flex" style={{ width: "100%" }}>
                      <div className="w-100 flex j-c-c">
                        <h5>BackView</h5>
                      </div>

                      <div
                        className="relative w-100 flex v-flex a-i-c j-c-c mt-2"
                        style={{
                          width: "100%",
                          height: "150px",
                          border: "1px dashed #2B294B",
                          padding: "0 1rem 0 1rem",
                        }}
                      >
                        <p className="mb-3">Drag and Drop</p>
                        <img src={dnd} alt="" width={50} />
                      </div>
                      <small
                        className="btn btn-secondary mt-3 w-100"
                        style={{ padding: "0.5rem 1rem", fontSize: "0.8rem" }}
                      >
                        From Device
                      </small>
                    </section>
                  }
                />
              </div>
            )}
            <div
              className="flex w-100 my-2"
              style={{ height: "45px", border: "1px solid lightgray" }}
            >
              <input
                autoFocus
                required
                defaultValue={kyc.stage2.id}
                onChange={(e) =>
                  dispatchKyc({
                    type: KYCACTIONS.STAGE2.id,
                    data: e.target.value,
                  })
                }
                type="text"
                className="w-100 h-100 p-2"
                placeholder="Enter Card Number"
                style={{ border: "none" }}
              />
            </div>
            <aside className="w-100 flex j-c-c mt-5">
              <b
                onClick={() =>
                  dispatchKyc({ type: KYCACTIONS.STAGE2.idType, data: null })
                }
                className="btn border border-secondary mr-1 text-dark"
              >
                Back
              </b>
              <button className="btn btn-primary border border-primary">
                Proceed
              </button>
            </aside>
          </form>
        )}

        <br />
        {/* {
                    cardType && (
                        <div className="flex mt-2 mb-4 j-c-b w-100">
                            <button onClick={()=>dispatchKyc({type: KYCACTIONS.STAGE2.idType, data: null})} className="btn btn-outline-secondary p-4 w-100 border border-secondary mr-1" style={{borderRadius: '2px'}}>Go Back</button>
                            <button onClick={()=>onSubmitStage2()} className="btn btn-primary p-4 w-100" style={{borderRadius: '2px'}}>Continue</button>
                        </div>
                    )
                } */}

        <small className="text-center w-50">
          Please make sure you provide the card selected here in the next stage
        </small>
      </div>
    );
  }

  if (status === "stage3") {
    return (
      <form
        onSubmit={onSubmitStage3}
        style={{ width: "100%" }}
        className="flex v-flex a-i-c scaleanimate"
      >
        <div className="twofactorheader text-center mb-5 mt-3">
          <h4>Let's verify your identity</h4>
          <small className="mb-5">To get verified, you will need to:</small>
        </div>

        <div
          className="flex v-flex mt-5 mb-5 j-c-c"
          style={{ width: "300px", marging: "0 auto 0 auto" }}
        >
          <ImageCapture
            retry={() =>
              dispatchKyc({ type: KYCACTIONS.STAGE2.selfie, data: "" })
            }
            setSelfie={(img) =>
              dispatchKyc({ type: KYCACTIONS.STAGE2.selfie, data: img })
            }
          />
        </div>

        <br />
        <div className="flex mt-2 mb-4 j-c-c w-100">
          <b
            onClick={() => setStatus("stage2")}
            className="btn btn-secondary border border-secondary p-4 w-50 mr-1"
            style={{ borderRadius: "2px" }}
          >
            Go Back
          </b>
          {/* <button className="btn btn-primary p-4 w-50" style={{borderRadius: '2px'}}>Proceed</button> */}
          {kyc.stage2.selfie?.toString().length > 0 && (
            <button
              className="btn btn-primary p-4 w-50"
              style={{ borderRadius: "2px" }}
            >
              Proceed
            </button>
          )}
        </div>

        <small className="text-center w-100">
          {kyc.stage2.selfie
            ? "Click on proceed to continue with the process"
            : "Click on the capture button ⚪️ to capture your selfie"}
        </small>
      </form>
    );
  }
}

export default Kyc;
