import axios from "axios";
import Constants from "./Constants";
import { reactLocalStorage } from "reactjs-localstorage";

const REFRESH_INTERVAL = 700000;
const INITIAL_DELAY = 2000;

function refreshToken(force) {
  let refresh_token = reactLocalStorage.get("refreshToken");
  axios
    .post(`${Constants.API_BASE_URL}/auth/refresh/token`, {
      refreshToken: refresh_token,
    })
    .then((res) => {
      let data = res.data;
      // console.log(data)
      reactLocalStorage.set("token", data.token);
      reactLocalStorage.set("refreshToken", data.refreshToken);
      // console.log(res)
      if (force) {
        // window.location.reload()
      }
    })
    .catch((err) => {
      // console.log(err.response)
      // if(window.location.pathname.includes('admin')){
      //     window.location.href = '/admin/login'
      // }else{
      //     window.location.href = '/login'
      // }
    })
    .finally(() => {
      setTimeout(refreshToken, REFRESH_INTERVAL); // Schedule the next refresh after the fixed interval
    });
}

function startTokenRefresh(force) {
  if (new Date().getMinutes() % 5 === 0) {
    setTimeout(refreshToken, INITIAL_DELAY); // Start the initial token refresh after the initial delay
  }
  if (force) {
    refreshToken(force);
  }
}

export default startTokenRefresh;
