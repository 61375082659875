import React, { useEffect, useState } from "react";
import style from "../styles/sidebarMobile.module.css";
import logo from "../../../iamges/logo1.png";
import { useLocation, useHistory } from "react-router-dom";
import { getDate } from "../../../misc/methods";
import AccountPopup from "../../client/include/AccountPopup";

function SideNavMobile({ close }) {
  const [state, setState] = useState({
    showAcc: false,
    userInfo: /*AuthService.getAuth().user*/ "",
  });
  const location = useLocation();
  const history = useHistory();
  const [active, setActive] = useState(location.pathname);

  const handleRoute = (path) => {
    history.push(path);
    return close();
  };

  useEffect(() => {
    setActive(location.pathname);
  }, [location]);

  window.addEventListener("resize", () => close());
  return (
    <div className={`${style.mobileSidebar} flex a-i-s relative`}>
      <section
        className={`${style.navContainer} flex v-flex j-c-b animatefromleft relative`}
      >
        <div
          onClick={close}
          className={`absolute ${style.closeBtn} flex j-c-c`}
          type="button"
        >
          <b>
            <i className="fa fa-times"></i>
          </b>
        </div>
        <aside className={`${style.navSection} flex v-flex w-100`}>
          <div className={`${style.hodlpayLogo} flex j-c-c mb-10`}>
            <aside className={`${style.logoContainer}`}>
              <img src={logo} alt="" className="img-fit" />
            </aside>
          </div>
          <div className="flex j-c-c w-100">
            <small className="text-center">
              Buy and sell cryptocurrency
              <br />
              Easy and Faster
            </small>
          </div>
          <div className={`navItems flex v-flex w-100 mt-16`}>
            <aside
              onClick={() => handleRoute("/admin/dashboard")}
              className={`${style.navItem} flex mt-8 m-auto ${active === "/admin/dashboard" && style.navItemActive}`}
              style={{ paddingLeft: "3.5rem" }}
            >
              <svg
                className="mr-1"
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
              >
                <rect
                  x="2"
                  y="2"
                  width="9"
                  height="9"
                  rx="2"
                  fill={`${active === "/dashboard" ? "white" : "black"}`}
                />
                <rect
                  opacity="0.3"
                  x="13"
                  y="2"
                  width="9"
                  height="9"
                  rx="2"
                  fill={`${active === "/dashboard" ? "white" : "black"}`}
                />
                <rect
                  opacity="0.3"
                  x="13"
                  y="13"
                  width="9"
                  height="9"
                  rx="2"
                  fill={`${active === "/dashboard" ? "white" : "black"}`}
                />
                <rect
                  opacity="0.3"
                  x="2"
                  y="13"
                  width="9"
                  height="9"
                  rx="2"
                  fill={`${active === "/dashboard" ? "white" : "black"}`}
                />
              </svg>
              <h5>Dashboard</h5>
            </aside>
            <aside
              onClick={() => handleRoute("/admin/orders")}
              className={`${style.navItem} flex mt-8 m-auto ${active === "/admin/orders" && style.navItemActive}`}
              style={{ paddingLeft: "3.5rem" }}
            >
              <svg
                className="mr-1"
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
              >
                <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                  <rect x="0" y="0" width="24" height="24" />
                  <rect
                    fill={`${active === "/orders" ? "white" : "black"}`}
                    opacity="0.3"
                    x="2"
                    y="2"
                    width="10"
                    height="12"
                    rx="2"
                  />
                  <path
                    fill={`${active === "/orders" ? "white" : "black"}`}
                    d="M4,6 L20,6 C21.1045695,6 22,6.8954305 22,8 L22,20 C22,21.1045695 21.1045695,22 20,22 L4,22 C2.8954305,22 2,21.1045695 2,20 L2,8 C2,6.8954305 2.8954305,6 4,6 Z M18,16 C19.1045695,16 20,15.1045695 20,14 C20,12.8954305 19.1045695,12 18,12 C16.8954305,12 16,12.8954305 16,14 C16,15.1045695 16.8954305,16 18,16 Z"
                  />
                </g>
              </svg>
              <h5>Orders</h5>
            </aside>
            <aside
              onClick={() => handleRoute("/admin/users")}
              className={`${style.navItem} flex mt-8 m-auto ${active === "/admin/users" && style.navItemActive}`}
              style={{ paddingLeft: "3.5rem" }}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
              >
                <path
                  d="M16.0173 9H15.3945C14.2833 9 13.263 9.61425 12.7431 10.5963L12.154 11.7091C12.0645 11.8781 12.1072 12.0868 12.2559 12.2071L12.6402 12.5183C13.2631 13.0225 13.7556 13.6691 14.0764 14.4035L14.2321 14.7601C14.2957 14.9058 14.4396 15 14.5987 15H18.6747C19.7297 15 20.4057 13.8774 19.912 12.945L18.6686 10.5963C18.1487 9.61425 17.1285 9 16.0173 9Z"
                  fill="black"
                />
                <rect
                  opacity="0.3"
                  x="14"
                  y="4"
                  width="4"
                  height="4"
                  rx="2"
                  fill="black"
                />
                <path
                  d="M4.65486 14.8559C5.40389 13.1224 7.11161 12 9 12C10.8884 12 12.5961 13.1224 13.3451 14.8559L14.793 18.2067C15.3636 19.5271 14.3955 21 12.9571 21H5.04292C3.60453 21 2.63644 19.5271 3.20698 18.2067L4.65486 14.8559Z"
                  fill="black"
                />
                <rect
                  opacity="0.3"
                  x="6"
                  y="5"
                  width="6"
                  height="6"
                  rx="3"
                  fill="black"
                />
              </svg>
              <h5>Users</h5>
            </aside>
            <aside
              onClick={() => handleRoute("/admin/settings")}
              className={`${style.navItem} flex mt-8 m-auto ${active === "/admin/settings" && style.navItemActive}`}
              style={{ paddingLeft: "3.5rem" }}
            >
              <i
                className="fa fa-gear mr-1 p-1"
                style={{
                  fontSize: "1.4rem",
                  color: `${active === "/settings" ? "white" : "rgb(36, 32, 59)"} `,
                }}
              ></i>
              <h5>Settings</h5>
            </aside>
            <aside
              onClick={() => handleRoute("/admin/swap")}
              className={`${style.navItem} flex mt-8 m-auto ${active === "/admin/swap" && style.navItemActive}`}
              style={{ paddingLeft: "3.5rem" }}
            >
              <i
                className="fa fa-gear mr-1 p-1"
                style={{
                  fontSize: "1.4rem",
                  color: `${active === "/swap" ? "white" : "rgb(36, 32, 59)"} `,
                }}
              ></i>
              <h5>Swap transactions</h5>
            </aside>

            <aside
              onClick={() => handleRoute("/admin/referral")}
              className={`${style.navItem} flex mt-8 m-auto ${active === "/admin/referral" && style.navItemActive}`}
              style={{ paddingLeft: "3.5rem" }}
            >
              <i
                className="fa fa-gear mr-1 p-1"
                style={{
                  fontSize: "1.4rem",
                  color: `${active === "/referral" ? "white" : "rgb(36, 32, 59)"} `,
                }}
              ></i>
              <h5>Referrals</h5>
            </aside>

            <aside
              onClick={() => handleRoute("/admin/leaderboard")}
              className={`${style.navItem} flex mt-8 m-auto ${active === "/admin/leaderboard" && style.navItemActive}`}
              style={{ paddingLeft: "3.5rem" }}
            >
              <i
                className="fa fa-gear mr-1 p-1"
                style={{
                  fontSize: "1.4rem",
                  color: `${active === "/leaderboard" ? "white" : "rgb(36, 32, 59)"} `,
                }}
              ></i>
              <h5>LeaderBoard</h5>
            </aside>
          </div>
          <div className={`${style.sideBottom} w-100 flex v-flex j-c-b a-i-c`}>
            <aside
              onClick={() => setState({ ...state, showAcc: !state.showAcc })}
              className={`${style.navItem} flex mb-5 mt-8 m-auto ${active === "/admin/settings" && style.navItemActive}`}
              style={{ paddingLeft: "3.5rem" }}
            >
              <svg
                className="mr-1"
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
              >
                <path
                  d="M6.28548 15.0861C7.34369 13.1814 9.35142 12 11.5304 12H12.4696C14.6486 12 16.6563 13.1814 17.7145 15.0861L19.3493 18.0287C20.0899 19.3618 19.1259 21 17.601 21H6.39903C4.87406 21 3.91012 19.3618 4.65071 18.0287L6.28548 15.0861Z"
                  fill={`${active === "/account-settings" ? "white" : "black"}`}
                />
                <rect
                  opacity="0.3"
                  x="8"
                  y="3"
                  width="8"
                  height="8"
                  rx="4"
                  fill={`${active === "/account-settings" ? "white" : "black"}`}
                />
              </svg>
              <h5>Account</h5>
            </aside>
            {state.showAcc && (
              <AccountPopup
                link={"/admin/settings"}
                close={() => setState({ ...state, showAcc: false })}
              />
            )}
            <aside className="flex j-c-c">
              <small className="text-center text-dark w-100">
                HodlPay {getDate("year")}
              </small>
            </aside>
          </div>
        </aside>
      </section>
      <section
        onClick={close}
        className={`${style.rightSide} modalBackDrop absolute`}
      ></section>
    </div>
  );
}

export default SideNavMobile;
