import React, { Fragment, useEffect, useState } from "react";
import MetaTags from "react-meta-tags";
import CONSTANTS from "../../misc/Constants";
import axios from "axios";
import cookie from "react-cookies";
import { reactLocalStorage } from "reactjs-localstorage";
import { Formik, Form, Field, ErrorMessage } from "formik";

function Login() {
  // AuthService.checkGreeter();
  const [state, setState] = useState({
    isLoading: false,
    isRequesting: false,
    showPassword: false,
    loginForm: {
      email: "",
      password: "",
    },
    errorMessage: "",
  });

  useEffect(() => {
    cookie.remove("isLogin");
    cookie.remove("admin_access_token");
    cookie.remove("access_token");
    cookie.remove("userObj");
    cookie.remove("phoneIdentity");
    cookie.remove("userOrders");
    cookie.save("isLogin", false);
    reactLocalStorage.clear();
    cookie.remove();
  }, []);

  const submitLoginForm = (values, actions) => {
    setState({
      ...state,
      isRequesting: true,
      errorMessage: "",
    });

    axios
      .post(
        CONSTANTS.API_BASE_URL + "/auth/user/login",
        { ...values },
        {
          timeout: 60000,
        },
      )
      .then((response) => {
        sessionStorage.setItem("currentCount", 1000);
        let data = response.data;
        cookie.save("access_token", data.token);
        cookie.save("refresh_token", data.refreshToken);
        reactLocalStorage.set("userRoles", response.data.roles);
        reactLocalStorage.set("token", data.token);
        reactLocalStorage.set("refreshToken", data.refreshToken);
        cookie.save("userObj", {
          phoneIdentity: data.phoneIdentity,
          emailIdentity: data.emailIdentity,
          user: data.user,
          accountStatus: true,
          paymentMethod: data.user.paymentMethod,
        });
        reactLocalStorage.setObject("userObj", {
          phoneIdentity: data.phoneIdentity,
          emailIdentity: data.emailIdentity,
          user: data.user,
          accountStatus: true,
        });

        cookie.save("level", data?.accountLevel);
        if (!data.emailIdentity.emailVerified) {
          window.location = `/email-verification/check/${data.emailIdentity.email}`;
        } else if (data.phoneIdentity === null) {
          window.location = "/phone-verification";
        } else if (!data.phoneIdentity?.phoneVerified) {
          window.location = "/phone-verification";
        } else {
          cookie.save("isLogin", true);

          setTimeout(() => {
            window.location.href = "/dashboard";
          }, 500);
        }
      })
      .catch((error) => {
        setState({
          ...state,
          isRequesting: false,
        });
        try {
          let errorResponse = error.response.data;
          let errorMessage = "";
          if (errorResponse.hasOwnProperty("message")) {
            errorMessage = errorResponse.message;
          } else {
            errorMessage = "Error: Could not connect to server";
          }

          setState({
            ...state,
            isRequesting: false,
            errorMessage: errorMessage,
          });
        } catch (e) {
          setState({
            ...state,
            isRequesting: false,
            errorMessage: "Error: Could not connect to server",
          });
          // return window.location = "/server-offline";
        }
      });
  };

  return (
    <Fragment>
      <MetaTags>
        <title>Login - HodlPay</title>
      </MetaTags>
      <div className="d-flex flex-column flex-root">
        <div className="d-flex flex-column flex-column-fluid bgi-position-y-bottom position-x-center bgi-no-repeat bgi-size-contain bgi-attachment-fixed">
          <div className="d-flex flex-center flex-column flex-column-fluid p-10 pb-lg-20">
            <a href="/login" className="mb-10 pt-lg-10">
              <img
                alt="Logo"
                src="/assets/media/logos/hodlpay-black.svg"
                className="h-70px"
              />
            </a>

            <div className="w-lg-500px bg-body rounded shadow-sm p-10 p-lg-15 mx-auto">
              <div className="text-center mb-10">
                <h1 className="text-dark mb-3">Sign In</h1>
                <div className="text-gray-400 fw-bold fs-4">
                  Don't have an account?
                  <a href="/register" className="link-primary fw-bolder">
                    {" "}
                    Create an Account
                  </a>
                </div>
              </div>

              <Formik
                initialValues={{ email: "", password: "" }}
                onSubmit={submitLoginForm}
                validate={(values, actions) => {
                  const errors = {};

                  if (!values.email) {
                    errors.email = "Your email can't be empty";
                  } else if (
                    !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(
                      values.email,
                    )
                  ) {
                    errors.email = "Invalid email address";
                  }

                  // if (!values.password) {
                  //   errors.password = "Password can't be empty";
                  // } else if (values.password.length < 8) {
                  //   errors.password = 'Password must be 8 or more characters';
                  // } else if (!/(?=.*[a-zA-Z])(?=.*[0-9])(?=.*[!@#$%^&*])/.test(values.password)) {
                  //   errors.password = 'Password must contain a mix of letters, numbers, and symbols';
                  // }

                  return errors;
                }}
              >
                {({ values, handleChange }) => (
                  <Form>
                    {state.errorMessage.length > 0 && (
                      <div className="alert alert-danger scaleanimate d-flex align-items-center p-5 mb-10 w-100">
                        <span className="svg-icon svg-icon-2hx svg-icon-danger me-4">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            fill="none"
                          >
                            <g
                              stroke="none"
                              strokeWidth="1"
                              fill="none"
                              fillRule="evenodd"
                            >
                              <rect x="0" y="0" width="24" height="24" />
                              <circle
                                fill="#000000"
                                opacity="0.3"
                                cx="12"
                                cy="12"
                                r="10"
                              />
                              <rect
                                fill="#000000"
                                x="11"
                                y="10"
                                width="2"
                                height="7"
                                rx="1"
                              />
                              <rect
                                fill="#000000"
                                x="11"
                                y="7"
                                width="2"
                                height="2"
                                rx="1"
                              />
                            </g>
                          </svg>
                        </span>
                        <div className="d-flex flex-column">
                          <h4 className="mb-1 text-danger">Error</h4>{" "}
                          <span>{state.errorMessage}</span>
                        </div>
                      </div>
                    )}
                    <div>
                      <div className="fv-row mb-10">
                        <label
                          htmlFor="name"
                          className="form-label fs-6 fw-bolder text-dark"
                        >
                          Email
                        </label>
                        <Field
                          className="form-control form-control-lg form-control-solid"
                          type="text"
                          name="email"
                          autoComplete="off"
                          value={values.email}
                          onChange={handleChange}
                        />
                        <div className="flex" style={{ overflow: "hidden" }}>
                          <ErrorMessage
                            className="text-danger animatefromleft"
                            name="email"
                            component="div"
                          />
                        </div>
                      </div>
                      <div className="fv-row mb-10">
                        <label
                          htmlFor="password"
                          className="form-label fs-6 fw-bolder text-dark flex j-c-b"
                        >
                          Password{" "}
                          <small className="text-primary cursor-pointer">
                            <a href="/forgot-password">Forgot Password?</a>
                          </small>
                        </label>
                        <Field
                          className="form-control form-control-lg form-control-solid"
                          type="password"
                          name="password"
                          autoComplete="off"
                          value={values.password}
                          onChange={handleChange}
                        />
                        <div className="flex" style={{ overflow: "hidden" }}>
                          <ErrorMessage
                            className="text-danger animatefromleft"
                            name="password"
                            component="div"
                          />
                        </div>
                      </div>
                      <div className="text-center">
                        {state.isRequesting ? (
                          <button
                            type="button"
                            className="btn btn-lg btn-primary"
                            disabled
                          >
                            <span className="">
                              Please wait...{" "}
                              <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                            </span>
                          </button>
                        ) : (
                          <button
                            type="submit"
                            className="btn btn-lg btn-primary"
                          >
                            <span className="indicator-label">Sign In</span>
                          </button>
                        )}
                      </div>
                    </div>
                  </Form>
                )}
              </Formik>

              {/* <form onSubmit={submitLoginForm} method="POST">
                    <div className="fv-row mb-10">
                      <label className="form-label fs-6 fw-bolder text-dark">Email</label>
                      <input required className="form-control form-control-lg form-control-solid" type="text" name="email" autoComplete="off" value={state.loginForm.email} onChange={(e) => {loginForm(e,"email");}}/>
                    </div>
                    <div className="mb-10 fv-row" data-kt-password-meter="true">
                      <div className="mb-1">
                        <div className="d-flex flex-stack mb-2">
                          <label className="form-label fw-bolder text-dark fs-6">Password</label>
                          <a href="/forgot-password" className="link-primary fs-6 fw-bolder">Forgot Password ?</a>
                        </div>
                        <div className="position-relative mb-3">
                          <input required className="form-control form-control-lg form-control-solid" type={state.showPassword ? "text" : "password"} placeholder="" name="password" autoComplete="off" value={state.loginForm.password} onChange={(e) => {loginForm(e,"password");}} />
                          <span className="btn btn-sm btn-icon position-absolute translate-middle top-50 end-0 me-n2" data-kt-password-meter-control="visibility" onClick={() => {
                            setState({
                              ...state,
                              showPassword: state.showPassword ? false : true
                            })
                          }}>
                            {
                              state.showPassword ?
                              <i className="bi bi-eye-slash fs-2"></i>
                              :
                              <i className="bi bi-eye fs-2"></i>
                            }
                          </span>
                        </div>
                      </div>
                    </div>
                    <div className="text-center">
                    {
                      state.isRequesting ?
                      <button type="button" className="btn btn-lg btn-primary" disabled>
                        <span className="">Please wait... 
                        <span className="spinner-border spinner-border-sm align-middle ms-2"></span></span>
                      </button>
                      :
                      <button type="submit" className="btn btn-lg btn-primary">
                        <span className="indicator-label">Sign In</span>
                      </button>
                    }
                    </div>
                  </form> */}
            </div>
          </div>
          <div className="d-flex flex-center flex-column-auto p-10">
            <div className="d-flex align-items-center fw-bold fs-6">
              <a
                href={`${CONSTANTS.WEB_URL}/#about`}
                target="_blank"
                rel="noreferrer"
                className="text-muted text-hover-primary px-2"
              >
                About
              </a>
              <a
                href={`${CONSTANTS.WEB_URL}/terms-and-conditions`}
                target="_blank"
                rel="noreferrer"
                className="text-muted text-hover-primary px-2"
              >
                Terms and Conditions
              </a>
              <a
                href={`${CONSTANTS.WEB_URL}/privacy-policy`}
                target="_blank"
                rel="noreferrer"
                className="text-muted text-hover-primary px-2"
              >
                Privacy Policy
              </a>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
}

export default Login;
