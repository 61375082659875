import React from "react";
import { createRoot } from "react-dom/client";
import "./index.css";
import Routes from "./routes";
import reportWebVitals from "./reportWebVitals";
import GlobalProvider from "./store/useContext";
import ReactGA from "react-ga";
import { QueryClient, QueryClientProvider } from "react-query";

const queryClient = new QueryClient();

function initializeReactGA() {
  ReactGA.initialize("G-VLCC1JW82Q");
}

initializeReactGA();

const container = document.getElementById("root");
const root = createRoot(container);

root.render(
  <React.StrictMode>
    <QueryClientProvider client={queryClient}>
      <GlobalProvider>
        <Routes />
      </GlobalProvider>
    </QueryClientProvider>
  </React.StrictMode>,
);

reportWebVitals();
