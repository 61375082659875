import React, { useContext, useEffect } from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import Login from "./components/client/Login";
import AdminLogin from "./components/admin/Login";
import AdminDashboard from "./components/admin/Dashboard";
import AdminOrders from "./components/admin/Orders";
import AdminSettings from "./components/admin/Settings";
import Logout from "./components/client/Logout";
import Registration from "./components/client/Registration";
import EmailVerification from "./components/client/EmailVerification";
import PhoneNumberVerification from "./components/client/PhoneNumberVerification";
import ForgotPassword from "./components/client/ForgotPassword";
import ResetPassword from "./components/client/ResetPassword";
import Dashboard from "./components/client/Dashboard";
import Swap from "./components/admin/Swap";
import Referral from "./components/admin/Referral";
import Orders from "./components/client/Orders";
import Faq from "./components/client/Faq";
import UserSettings from "./components/client/UserSettings";
import NotFound from "./components/client/NotFound";
import Expiry from "./misc/sessionExpiry";
import AdminUsers from "./components/admin/Users";
import Modal from "./ui-elements/modal/Modal";
import Context from "./store/Context";
import Support from "./components/client/Support";
import WaitList from "./components/admin/WaitList";
import ReactGA from "react-ga";
import Modal2 from "./components/Modal/Modal";
import AlertInfo from "./components/Modal/AlertInfo";
import { reactLocalStorage } from "reactjs-localstorage";
import UserInfo from "./components/admin/UserInfo";
import LeaderBoard from "./components/admin/Leaderboard";

function Routes() {
  const { state, setState } = useContext(Context);

  useEffect(() => {
    if (Boolean(reactLocalStorage.get("isLogin")) === true) {
      Expiry();
    } else {
      if (
        !window.location.href.includes("login") &&
        window.location.href.includes("admin")
      ) {
        if (!window.location.href.includes("register")) {
          window.location = "/admin/login";
        }
      } else if (
        !window.location.href.includes("login") &&
        !window.location.href.includes("admin") &&
        window.location.href.includes("register")
      ) {
        if (!window.location.href.includes("register")) {
          window.location = "/admin/login";
        }
      }
    }
    // eslint-disable-next-line
  }, []);

  window.addEventListener("click", () => {
    ReactGA.event({
      category: "User Interaction",
      action: "Button Click",
      label: "Clicked",
    });
  });

  // useEffect(() => {
  //   if (cookie.load("stock-alert") === undefined) {
  //     cookie.save("stock-alert", "false");
  //     setState({ type: ACTIONS.alertOn });
  //   }

  //   if (
  //     new Date().getMinutes() <= 50 &&
  //     JSON.parse(cookie.load("stock-alert") ?? false)
  //   ) {
  //     cookie.save("stock-alert", "false");
  //     setState({ type: ACTIONS.alertOn });
  //   } else if (new Date().getMinutes() > 50) {
  //     cookie.save("stock-alert", "true");
  //   }

  //   // eslint-disable-next-line
  // }, []);

  const showAlertPages = [
    "login",
    "register",
    "email",
    "phone",
    "forgot",
    "reset",
    "account",
    "not-found",
  ];

  return (
    <>
      <Router>
        {state?.modal?.on && (
          <Modal content={state.modal?.content} head={state.modal?.head} />
        )}
        {state?.modal2?.on && (
          <Modal2 content={state.modal2?.content} head={state.modal2?.head} />
        )}

        {state?.alert?.on &&
          showAlertPages.find((val) => window.location.pathname.includes(val))
            ?.length === 0 && (
            <AlertInfo
              message={state.alert?.content ?? ""}
              head={state.alert?.head}
            />
          )}
        <Switch>
          <Route
            exact
            path={`${process.env.PUBLIC_URL + "/"}`}
            component={Login}
          />
          <Route
            exact
            path={`${process.env.PUBLIC_URL + "/login"}`}
            component={Login}
          />
          <Route
            exact
            path={`${process.env.PUBLIC_URL + "/register"}`}
            component={Registration}
          />
          <Route
            exact
            path={`${
              process.env.PUBLIC_URL + "/email-verification/code/:token"
            }`}
            component={EmailVerification}
          />
          <Route
            exact
            path={`${
              process.env.PUBLIC_URL + "/email-verification/check/:email"
            }`}
            component={EmailVerification}
          />
          <Route
            exact
            path={`${process.env.PUBLIC_URL + "/phone-verification"}`}
            component={PhoneNumberVerification}
          />
          <Route
            exact
            path={`${process.env.PUBLIC_URL + "/forgot-password/"}`}
            component={ForgotPassword}
          />
          <Route
            exact
            path={`${process.env.PUBLIC_URL + "/forgot-password/code/:token"}`}
            component={ForgotPassword}
          />
          <Route
            exact
            path={`${process.env.PUBLIC_URL + "/reset-password/:token"}`}
            component={ResetPassword}
          />
          <Route
            exact
            path={`${process.env.PUBLIC_URL + "/dashboard"}`}
            component={Dashboard}
          />
          <Route
            exact
            path={`${process.env.PUBLIC_URL + "/orders"}`}
            component={Orders}
          />
          <Route
            exact
            path={`${process.env.PUBLIC_URL + "/orders/:page"}`}
            component={Orders}
          />
          <Route
            exact
            path={`${process.env.PUBLIC_URL + "/faq"}`}
            component={Faq}
          />
          <Route
            exact
            path={`${process.env.PUBLIC_URL + "/support"}`}
            component={Support}
          />
          <Route
            exact
            path={`${process.env.PUBLIC_URL + "/account-settings"}`}
            component={UserSettings}
          />
          <Route
            exact
            path={`${process.env.PUBLIC_URL + "/account-settings/:fromOrder"}`}
            component={UserSettings}
          />

          <Route
            exact
            path={`${process.env.PUBLIC_URL + "/admin/login"}`}
            component={AdminLogin}
          />
          <Route
            exact
            path={`${process.env.PUBLIC_URL + "/admin/dashboard"}`}
            component={AdminDashboard}
          />
          <Route
            exact
            path={`${process.env.PUBLIC_URL + "/admin/orders"}`}
            component={AdminOrders}
          />
          <Route
            exact
            path={`${process.env.PUBLIC_URL + "/admin/orders/:page"}`}
            component={AdminOrders}
          />
          <Route
            exact
            path={`${process.env.PUBLIC_URL + "/admin/users"}`}
            component={AdminUsers}
          />
          <Route
            exact
            path={`${process.env.PUBLIC_URL + "/admin/users/:page"}`}
            component={AdminUsers}
          />
          <Route
            exact
            path={`${process.env.PUBLIC_URL + "/admin/settings"}`}
            component={AdminSettings}
          />
          <Route
            exact
            path={`${process.env.PUBLIC_URL + "/admin/swap"}`}
            component={Swap}
          />
          <Route
            exact
            path={`${process.env.PUBLIC_URL + "/admin/referral"}`}
            component={Referral}
          />

          <Route
            exact
            path={`${process.env.PUBLIC_URL + "/admin/user-info"}`}
            component={UserInfo}
          />

          <Route
            exact
            path={`${process.env.PUBLIC_URL + "/admin/leaderboard"}`}
            component={LeaderBoard}
          />

          <Route
            exact
            path={`${process.env.PUBLIC_URL + "/admin/wait-list"}`}
            component={WaitList}
          />
          <Route
            exact
            path={`${process.env.PUBLIC_URL + "/admin/wait-list/:page"}`}
            component={WaitList}
          />

          <Route
            exact
            path={`${process.env.PUBLIC_URL + "/logout"}`}
            component={Logout}
          />
          <Route
            path={`${process.env.PUBLIC_URL + "/not-found"}`}
            component={NotFound}
          />
          <Route exact component={NotFound} />
        </Switch>
      </Router>
    </>
  );
}

export default Routes;
