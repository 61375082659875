import React from "react";

function ModalLoading() {
  return (
    <div
      style={{ width: "100%", background: "white", height: "100%" }}
      className="flex v-flex a-i-c j-c-c absolute left-0 right-0 bottom-0"
    >
      <div className="pre-loader">
        <div className="text-center v-flex a-i-c j-c-c">
          <div className="spinner-border" role="status">
            <span className="sr-only">Loading...</span>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ModalLoading;
