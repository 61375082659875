import React, {
  Fragment,
  useState,
  useEffect,
  useContext,
  useReducer,
} from "react";
import MetaTags from "react-meta-tags";
import AuthService from "../../misc/AuthService";
import axios from "axios";
import cookie from "react-cookies";
import { reactLocalStorage } from "reactjs-localstorage";
import SideNav from "./include/SideNav";
import Header from "./include/Header";
import Footer from "./include/Footer";
import classNames from "classnames";

import "react-datepicker/dist/react-datepicker.css";
import Constants from "../../misc/Constants";
import { useLocation, useParams } from "react-router-dom";
import getStore from "../../misc/GetStore";
import Context from "../../store/Context";
import { ACTIONS } from "../../store/useGlobalState";
import ChangePassword from "./ChangePassword";
import Kyc from "./Kyc";
import { Done, Status, Undone } from "../../ui-elements/status/Status";
import style from "./styles/userSettings.module.css";
import AddAddressForm from "./include/AddAddressForm";
import SideNavMobile from "./include/sideNavMobile";
import ReactGA from "react-ga";
import startTokenRefresh from "../../misc/sessionExpiry";

function UserSettings(props) {
  useEffect(() => {
    ReactGA.pageview(window.location.pathname + window.location.search);
  }, []);

  const globalSetState = useContext(Context)?.setState;
  const { fromOrder } = useParams();
  const url = useLocation();
  AuthService.checkProtected();
  const [state, setState] = useState({
    resetPaymentFun: false,
    git: false,
    sidebar: false,
    userInfo: AuthService.getAuth().user,
    isLoading: true,
    isRequesting: true,
    isRequestingPayment: false,
    isRequestingPersonal: false,
    showFilter: false,
    orderType: "buy",
    passedOrder: null,
    orderID: "",
    showOrderInfo: false,
    banks: [
      "Absa Bank Ghana Limited",
      "Access Bank Ghana Plc",
      "Agricultural Development Bank of Ghana",
      "Bank of Africa Ghana Limited",
      "CalBank Limited",
      "Consolidated Bank Ghana Limited",
      "Ecobank Ghana Limited",
      "FBN Bank Ghana Limited",
      "Fidelity Bank Ghana Limited",
      "First Atlantic Bank Limited",
      "First National Bank Ghana",
      "GCB Bank Limited",
      "Guaranty Trust Bank Ghana Limited",
      "National Investment Bank Limited",
      "OmniBSIC Bank Ghana Limited",
      "Prudential Bank Limited",
      "Republic Bank Ghana Limited",
      "Société Générale Ghana Limited",
      "Stanbic Bank Ghana Limited",
      "Standard Chartered Bank Ghana Limited",
      "United Bank for Africa Ghana Limited",
      "Universal Merchant Bank Limited",
      "Zenith Bank Ghana Limited",
    ],
    personalInfoForm: {
      first_name: "",
      last_name: "",
      phone_number: "",
      email: "",
      old_password: "",
      new_password: "",
      account_type: "",
      account_type_name: "",
      account_name: "",
      account_number: "",
      account_branch: "",
      accountType: "",
    },
    personalInfoFormError: {
      first_name: "",
      last_name: "",
      phone_number: "",
      email: "",
      old_password: "",
      new_password: "",
      account_type: "",
      account_type_name: "",
      account_name: "",
      account_number: "",
      account_branch: "",
    },
    kycInfo: null,
    errorMessage: "",
    successMessage: "",
  });

  function getAccountInfo() {
    if (getStore.getPaymentMethod()) {
      if (url.pathname !== "/account-settings") {
        window.location = "/account-settings";
      }
      let personalInfoForm = state.personalInfoForm;
      let paymentMethod = getStore.getPaymentMethod();

      personalInfoForm.account_type_name = paymentMethod.network;
      personalInfoForm.account_type = paymentMethod.network;
      personalInfoForm.account_name = paymentMethod.accountName;
      personalInfoForm.account_number = paymentMethod.phoneNumber;
      personalInfoForm.accountType = paymentMethod.accountType;

      setState({
        ...state,
        personalInfoForm: personalInfoForm,
      });
    } else {
      // Getting user payment method
      axios
        .get(
          `${Constants.API_BASE_URL}/users/payment/method`,
          AuthService.getAxiosHeaders(),
        )
        .then((res) => {
          let personalInfoForm = state.personalInfoForm;
          if (res.data) {
            if (url.pathname !== "/account-settings") {
              window.location = "/account-settings";
            }
            personalInfoForm.account_type_name = `${res.data.network}`;
            personalInfoForm.account_type = "momo";
            personalInfoForm.account_name = res.data?.accountName;
            personalInfoForm.account_number = res.data?.phoneNumber;
            personalInfoForm.accountType = res.data?.accountType;

            setState({
              ...state,
              personalInfoForm: personalInfoForm,
            });
          }
        });
    }

    function getUser() {
      setState({ ...state, isRequesting: true });
      axios
        .get(
          `${Constants.API_BASE_URL}/users/me`,
          AuthService.getAxiosHeaders(),
        )
        .then((response) => {
          let data = response.data;
          reactLocalStorage.setObject("userObj", {
            phoneIdentity: data.phoneIdentity,
            emailIdentity: data.emailIdentity,
            user: data.user,
            accountStatus: true,
          });
          cookie.save("userObj", {
            phoneIdentity: data.phoneIdentity,
            emailIdentity: data.emailIdentity,
            user: data.user,
            accountStatus: true,
          });
          let userObj = reactLocalStorage.getObject("userObj");

          let personalInfoForm = state.personalInfoForm;
          personalInfoForm.first_name = userObj.user.firstName;
          personalInfoForm.last_name = userObj.user.lastName;
          personalInfoForm.phone_number =
            "0" + userObj.phoneIdentity.phone?.slice(4);
          personalInfoForm.email = userObj.emailIdentity.email;
          setState({
            ...state,
            userInfo: userObj,
            personalInfoForm: personalInfoForm,
          });
        })
        .catch((err) => {
          try {
            setState({ ...state, isRequesting: false });
            if (err?.response?.status === 401) {
              startTokenRefresh(true);
            }
          } catch (e) {
            return; //window.location = 'server-offline'
          }
        });
    }

    if (getStore.getPersonalInfo()) {
      let personalInfoForm = state.personalInfoForm;
      let userInfo = getStore.getPersonalInfo();

      personalInfoForm.first_name = userInfo.firstName;
      personalInfoForm.last_name = userInfo.lastName;
      personalInfoForm.phone_number = "0" + userInfo.phone?.slice(4);
      personalInfoForm.email = userInfo.email;
      setState({
        ...state,
        personalInfoForm: personalInfoForm,
      });
      Object.keys(userInfo).forEach((key) => {
        if (!userInfo[key]) {
          getUser();
        }
      });
    }

    getStore
      .getKycInfo()
      .then((result) => {
        setState({ ...state, isLoading: false, isRequesting: false });
        if (result) {
          setState((prev) => {
            return {
              ...prev,
              kycInfo: {
                ...prev.kycInfo,
                documentOutcome: result.documentCheckOutcome,
                documentStatus: result.documentCheckStatus,
                identityOutcome: result.identityCheckOutcome,
                identityStatus: result.identityCheckStatus,
                poaCheckStatus: result.poaCheckStatus,
                poaCheckOutcome: result.poaCheckOutcome,
                user: result.user,
              },
            };
          });
        }
      })
      .catch((e) => {
        setState({
          ...state,
          isLoading: false,
          isRequesting: false,
        });
        if (e.response?.status === 401) {
          startTokenRefresh(true);
        }
      });
  }

  useEffect(() => {
    setState({ ...state, isLoading: true, isRequesting: true });
    getAccountInfo();
    // eslint-disable-next-line
  }, []);

  function updateForm(event, name) {
    let value = event.target.value;
    let personalInfoForm = state.personalInfoForm;

    personalInfoForm[name] = value;

    setState({
      ...state,
      personalInfoForm: { ...state.personalInfoForm, ...personalInfoForm },
    });
  }

  const submitPersonalDetails = (e) => {
    e.preventDefault();
    setState({
      ...state,
      isRequestingPersonal: true,
      personalInfoFormError: {
        ...state.personalInfoFormError,
        first_name: "",
        last_name: "",
      },
    });
    let data = {
      firstName: state.personalInfoForm.first_name,
      lastName: state.personalInfoForm.last_name,
    };
    axios
      .patch(
        `${Constants.API_BASE_URL}/users`,
        data,
        AuthService.getAxiosHeaders(),
      )
      .then(() => {
        setState({
          ...state,
          isRequestingPersonal: false,
          successMessage: "Changes Saved",
          personalInfoFormError: {
            ...state.personalInfoFormError,
            first_name: "",
            last_name: "",
          },
        });

        window.modalShow("success-modal");
      })
      .catch((error) => {
        try {
          let errorData = error.response.data;
          if (errorData.hasOwnProperty("message")) {
            errorData.message?.filter((val) => {
              if (val?.includes("first")) {
                setState({
                  ...state,
                  personalInfoFormError: {
                    ...state.personalInfoFormError,
                    first_name: val,
                  },
                });
              }
              if (val?.includes("last")) {
                setState({
                  ...state,
                  personalInfoFormError: {
                    ...state.personalInfoFormError,
                    last_name: val,
                  },
                });
              }
              return false;
            });
          }
          if (error.response.status === 401) {
            setState({ ...state, isRequesting: false });
            startTokenRefresh(true);
          }
        } catch (e) {
          setState({ ...state });
        }
      });
  };

  const submitPayoutDetails = (e) => {
    e.preventDefault();
    setState({
      ...state,
      isRequestingPayment: true,
      personalInfoFormError: {
        ...state.personalInfoFormError,
        account_name: "",
        account_number: "",
      },
    });
    if (state.personalInfoForm.account_number.length > 10) {
      alert("Phone Number can't be more than 10");
      return;
    }
    if (state.personalInfoForm.account_number.length < 10) {
      alert("Phone Number is not up to 10");
      return;
    }
    let data = {
      phoneNumber: state.personalInfoForm.account_number,
      accountName: state.personalInfoForm.account_name,
      accountType: state.personalInfoForm.accountType,
      network: state.personalInfoForm.account_type_name,
      // "paymentType": state.personalInfoForm.account_type_name
    };
    axios
      .post(
        `${Constants.API_BASE_URL}/users/payment/method`,
        data,
        AuthService.getAxiosHeaders(),
      )
      .then((response) => {
        let data = response.data;
        let userObj = cookie.load("userObj");
        userObj.user.paymentMethod = data;

        reactLocalStorage.setObject("userObj", userObj);
        cookie.save("userObj", userObj);

        setState({
          ...state,
          isRequestingPayment: false,
          successMessage: "Changes Saved",
        });

        window.modalShow("success-modal");
      })
      .catch((error) => {
        try {
          let errorData = error.response.data;
          if (errorData.hasOwnProperty("message")) {
            errorData.message?.filter((val) => {
              let err = val.toLowerCase();
              if (err?.includes("name")) {
                setState({
                  ...state,
                  personalInfoFormError: {
                    ...state.personalInfoFormError,
                    account_name: val,
                  },
                });
              }
              if (err?.includes("number")) {
                setState({
                  ...state,
                  personalInfoFormError: {
                    ...state.personalInfoFormError,
                    account_number: val,
                  },
                });
              }
              return false;
            });
          }
          if (error.response.status === 401) {
            setState({ ...state, isRequesting: false });
            startTokenRefresh(true);
          }
        } catch (e) {
          setState({ ...state, isRequestingPayment: false });
        }
      });
  };

  const closeHandler = () => {
    if (fromOrder) {
      let urls = ["/orders", "/dashboard"];
      let url = reactLocalStorage.getObject("orderSell").location;
      if (urls.includes(url)) {
        window.location = url;
      } else {
        window.location = "/orders";
      }
    }
  };

  const navReducer = (state, action) => {
    switch (action?.type) {
      case "profile":
        return { ...state, profile: true, security: false, kyc: false };
      case "security":
        return { ...state, profile: false, security: true, kyc: false };
      case "kyc":
        return { ...state, kyc: true, profile: false, security: false };
      default:
        return state;
    }
  };
  const defNav = {
    profile: true,
    security: false,
    kyc: false,
  };
  const [nav, setNav] = useReducer(navReducer, defNav);

  useEffect(() => {
    const url = new URL(window.location.href);
    const fragment = url.hash.slice(1);
    if (fragment.includes("security")) {
      setNav({ type: "security" });
    }
    if (fragment.includes("profile")) {
      setNav({ type: "profile" });
    }
    if (fragment.includes("kyc")) {
      setNav({ type: "kyc" });
    }
  }, []);

  return (
    <Fragment>
      <MetaTags>
        <title>Settings - HodlPay</title>
      </MetaTags>

      <div className="header-fixed header-tablet-and-mobile-fixed aside-fixed aside-secondary-disabled body">
        <div className="d-flex flex-column flex-root">
          <div className="page d-flex flex-row flex-column-fluid">
            {state.sidebar && (
              <SideNavMobile
                close={() => setState({ ...state, sidebar: false })}
              />
            )}
            <SideNav className={"bigScreen"} active="account" />

            <div
              className="p-0 wrapper d-flex flex-column flex-row-fluid"
              id="kt_wrapper"
            >
              <div className="header-mobile py-3">
                <div className="container d-flex flex-stack">
                  <div className="d-flex align-items-center flex-grow-1 flex-lg-grow-0">
                    <a href="/dashboard">
                      <img
                        alt="Logo"
                        src="/assets/media/logos/hodlpay-black.svg"
                        className="h-35px"
                      />
                    </a>
                  </div>

                  <button
                    className="btn btn-icon btn-active-color-primary"
                    onClick={() =>
                      setState({ ...state, sidebar: !state.sidebar })
                    }
                  >
                    <i
                      className="fa fa-bars"
                      style={{ fontSize: "2rem", color: "rgb(0,0,0,.8)" }}
                    ></i>
                  </button>
                </div>
              </div>
              <Header
                title={`Account Settings`}
                resetBtn={() => setState({ ...state, git: false })}
                git={state.git}
              />

              {state.isLoading ? (
                <div className="pre-loader">
                  <div className="text-center">
                    <div className="spinner-border" role="status">
                      <span className="sr-only">Loading...</span>
                    </div>
                  </div>
                </div>
              ) : (
                <>
                  <div
                    className="content d-flex flex-column flex-column-fluid"
                    id="kt_content"
                  >
                    <div className="flex container mb-4 mt-2 text-white securityNav">
                      <a
                        href="#profile"
                        className="text-white"
                        style={{ margin: "0 1.3rem 0 0" }}
                      >
                        <big
                          onClick={() => setNav({ type: "profile" })}
                          className="mr-1"
                          style={{
                            borderBottom: `${nav.profile ? "2px solid white" : ""}`,
                            paddingBottom: `${nav.profile ? "3px" : ""}`,
                            fontWeight: `${nav.profile ? "500" : ""}`,
                            cursor: "pointer",
                          }}
                        >
                          Profile
                        </big>
                      </a>
                      <a
                        href="#security"
                        className="text-white"
                        style={{ margin: "0 1.3rem 0 0" }}
                      >
                        <big
                          onClick={() => setNav({ type: "security" })}
                          className="mr-1"
                          style={{
                            borderBottom: `${nav.security ? "2px solid white" : ""}`,
                            paddingBottom: `${nav.security ? "3px" : ""}`,
                            fontWeight: `${nav.security ? "500" : ""}`,
                            cursor: "pointer",
                          }}
                        >
                          Security
                        </big>
                      </a>
                      <a
                        href="#kyc"
                        className="text-white"
                        style={{ margin: "0 1.3rem 0 0" }}
                      >
                        <big
                          onClick={() => setNav({ type: "kyc" })}
                          className="mr-1"
                          style={{
                            borderBottom: `${nav.kyc ? "2px solid white" : ""}`,
                            paddingBottom: `${nav.kyc ? "3px" : ""}`,
                            fontWeight: `${nav.kyc ? "500" : ""}`,
                            cursor: "pointer",
                          }}
                        >
                          Account Verification
                        </big>
                      </a>
                    </div>
                    {state.errorMessage?.length > 0 && (
                      <div className="container-xxl">
                        <div className="alert alert-danger d-flex align-items-center p-5 mb-10 w-100">
                          <span className="svg-icon svg-icon-2hx svg-icon-danger me-4">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="24"
                              height="24"
                              viewBox="0 0 24 24"
                              fill="none"
                            >
                              <g
                                stroke="none"
                                stroke-width="1"
                                fill="none"
                                fill-rule="evenodd"
                              >
                                <rect x="0" y="0" width="24" height="24" />
                                <circle
                                  fill="#000000"
                                  opacity="0.3"
                                  cx="12"
                                  cy="12"
                                  r="10"
                                />
                                <rect
                                  fill="#000000"
                                  x="11"
                                  y="10"
                                  width="2"
                                  height="7"
                                  rx="1"
                                />
                                <rect
                                  fill="#000000"
                                  x="11"
                                  y="7"
                                  width="2"
                                  height="2"
                                  rx="1"
                                />
                              </g>
                            </svg>
                          </span>
                          <div className="d-flex flex-column">
                            <h4 className="mb-1 text-danger">Error</h4>{" "}
                            <span>{state.errorMessage}</span>{" "}
                          </div>
                        </div>
                      </div>
                    )}

                    {nav.profile && (
                      <div
                        className={`container-xxl animatefrombottom flex a-i-s ${style.personalInfoContainer}`}
                        id="kt_content_container"
                      >
                        {/* Personal Info Settings */}
                        <div
                          className={`${style.personalInfo} card mb-5 mb-xl-10`}
                        >
                          <div
                            className="card-header border-0 cursor-pointer"
                            role="button"
                            data-bs-toggle="collapse"
                            data-bs-target="#kt_account_profile_details"
                            aria-expanded="true"
                            aria-controls="kt_account_profile_details"
                          >
                            <div className="card-title m-0">
                              <h3 className="fw-bolder m-0">Profile Details</h3>
                            </div>
                          </div>
                          <div
                            id="kt_account_settings_profile_details"
                            className="collapse show"
                          >
                            <form
                              onSubmit={submitPersonalDetails}
                              method="POST"
                              className="form"
                            >
                              <div className="card-body border-top p-9">
                                <div className="row mb-6">
                                  <label className="col-lg-4 col-form-label required fw-bold fs-6">
                                    First Name
                                  </label>
                                  <div className="col-lg-8">
                                    <div className="row">
                                      <div className="col-lg-12 fv-row">
                                        <input
                                          type="text"
                                          name="fname"
                                          value={
                                            state.personalInfoForm?.first_name
                                          }
                                          className="form-control form-control-lg form-control-solid mb-3 mb-lg-0"
                                          placeholder="First name"
                                          onChange={(e) => {
                                            updateForm(e, "first_name");
                                          }}
                                        />
                                      </div>
                                      {state.personalInfoFormError?.first_name
                                        .length > 0 && (
                                        <p className="text-danger fs-12px">
                                          {
                                            state.personalInfoFormError
                                              ?.first_name
                                          }
                                        </p>
                                      )}
                                    </div>
                                  </div>
                                </div>
                                <div className="row mb-6">
                                  <label className="col-lg-4 col-form-label required fw-bold fs-6">
                                    Last Name
                                  </label>
                                  <div className="col-lg-8">
                                    <div className="row">
                                      <div className="col-lg-12 fv-row">
                                        <input
                                          type="text"
                                          name="lname"
                                          className="form-control form-control-lg form-control-solid"
                                          placeholder="Last name"
                                          value={
                                            state.personalInfoForm.last_name
                                          }
                                          onChange={(e) => {
                                            updateForm(e, "last_name");
                                          }}
                                        />
                                      </div>
                                      {state.personalInfoFormError?.last_name
                                        .length > 0 && (
                                        <p className="text-danger fs-12px">
                                          {
                                            state.personalInfoFormError
                                              ?.last_name
                                          }
                                        </p>
                                      )}
                                    </div>
                                  </div>
                                </div>
                                <div className="row mb-6">
                                  <label className="col-lg-4 col-form-label fw-bold fs-6">
                                    Email
                                  </label>
                                  <div className="col-lg-8">
                                    <div className="row">
                                      <div className="col-lg-12 fv-row">
                                        <input
                                          disabled
                                          type="text"
                                          name="lname"
                                          className="form-control form-control-lg form-control-solid"
                                          placeholder="Email"
                                          defaultValue={
                                            state.personalInfoForm.email
                                          }
                                        />
                                      </div>
                                      {state.personalInfoFormError?.email
                                        .length > 0 && (
                                        <p className="text-danger fs-12px">
                                          {state.personalInfoFormError?.email}
                                        </p>
                                      )}
                                    </div>
                                  </div>
                                </div>
                                <div className="row mb-6">
                                  <label className="col-lg-4 col-form-label fw-bold fs-6">
                                    <span className="required">
                                      Phone Number
                                    </span>
                                    <i
                                      className="fas fa-exclamation-circle ms-1 fs-7"
                                      data-bs-toggle="tooltip"
                                      title="Phone number must be active"
                                    ></i>
                                  </label>
                                  <div className="col-lg-8 fv-row">
                                    <div className="input-group input-group-solid mb-2">
                                      <span
                                        className="input-group-text"
                                        id="basic-addon1"
                                      >
                                        +233
                                      </span>
                                      <input
                                        type="text"
                                        className="form-control"
                                        maxLength={10}
                                        placeholder="020 123 4567"
                                        aria-label="phone"
                                        value={
                                          state.personalInfoForm?.phone_number
                                        }
                                        onChange={(e) => {
                                          updateForm(e, "phone_number");
                                        }}
                                        aria-describedby="basic-addon1"
                                      />
                                    </div>
                                    {state.personalInfoFormError?.phone_number
                                      .length > 0 && (
                                      <p className="text-danger fs-12px">
                                        {
                                          state.personalInfoFormError
                                            ?.phone_number
                                        }
                                      </p>
                                    )}
                                  </div>
                                </div>
                              </div>
                              {state.isRequestingPersonal ? (
                                <div className="card-footer d-flex justify-content-end py-6 px-9">
                                  {/* <button type="reset" className="btn btn-light btn-active-light-primary me-2" disabled>Discard</button> */}
                                  <button
                                    type="submit"
                                    className="btn btn-primary"
                                    disabled
                                  >
                                    <span className="">
                                      Please wait...
                                      <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                                    </span>
                                  </button>
                                </div>
                              ) : (
                                <div className="card-footer d-flex justify-content-end py-6 px-9">
                                  {/* <button type="reset" className="btn btn-light btn-active-light-primary me-2">Discard</button> */}
                                  <button
                                    type="submit"
                                    className="btn btn-primary"
                                  >
                                    Save Changes
                                  </button>
                                </div>
                              )}
                            </form>
                          </div>
                        </div>

                        {/* Payment Method Settings */}
                        <div
                          className={`${style.paymentMethod} card mb-5 mb-xl-10`}
                        >
                          <div
                            className="card-header border-0 cursor-pointer"
                            role="button"
                            data-bs-toggle="collapse"
                            data-bs-target="#kt_account_profile_details"
                            aria-expanded="true"
                            aria-controls="kt_account_profile_details"
                          >
                            <div className="card-title m-0">
                              <h3 className="fw-bolder m-0">
                                Payout Information Details
                              </h3>
                            </div>
                          </div>
                          <div
                            id="kt_account_settings_payout_details"
                            className="collapse show"
                          >
                            <form
                              onSubmit={submitPayoutDetails}
                              method="POST"
                              className="form"
                              style={{
                                opacity: `${state.isRequestingPayment ? "0.4" : ""}`,
                                pointerEvents: `${state.isRequestingPersonal ? "none" : ""}`,
                              }}
                            >
                              <div className="card-body border-top p-9">
                                <div className="row mb-6">
                                  <label className="col-lg-4 col-form-label required fw-bold fs-6">
                                    Payout Type
                                  </label>
                                  <div className="col-lg-8">
                                    <div className="row">
                                      <div className="col-lg-12 fv-row">
                                        <div
                                          className="btn-group w-100 border"
                                          data-kt-buttons="true"
                                          data-kt-buttons-target="[data-kt-button]"
                                        >
                                          {/* <label className={classNames("btn btn-outline-secondary text-muted text-hover-white text-active-white btn-outline btn-active-primary", {"active": state.personalInfoForm?.account_type === 'bank'})} data-kt-button="true">
                                                  <input className="btn-check" type="radio" name="order_type" checked={state.personalInfoForm?.account_type === 'bank'} value="buy" onChange={() => {
                                                    setState({
                                                      ...state,
                                                      personalInfoForm: {...state.personalInfoForm, account_type: 'bank'}
                                                    })
                                                  }} /> Bank Account
                                                </label> */}
                                          <label
                                            className={classNames(
                                              "btn btn-outline-secondary text-muted text-hover-white text-active-white btn-outline btn-active-primary",
                                              {
                                                active:
                                                  state.personalInfoForm
                                                    ?.account_type === "momo",
                                              },
                                            )}
                                            data-kt-button="true"
                                          >
                                            <input
                                              className="btn-check"
                                              type="radio"
                                              name="order_type"
                                              checked={
                                                state.personalInfoForm
                                                  ?.account_type === "momo"
                                              }
                                              value="sell"
                                              onChange={() => {
                                                setState({
                                                  ...state,
                                                  personalInfoForm: {
                                                    ...state.personalInfoForm,
                                                    account_type: "momo",
                                                  },
                                                });
                                              }}
                                            />{" "}
                                            Mobile Money Account
                                          </label>
                                        </div>
                                        {state.personalInfoFormError
                                          ?.account_type.length > 0 && (
                                          <p className="text-danger fs-12px">
                                            {
                                              state.personalInfoFormError
                                                ?.account_type
                                            }
                                          </p>
                                        )}
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                {state.personalInfoForm?.account_type !==
                                  null && (
                                  <div className="row mb-6">
                                    <label className="col-lg-4 col-form-label required fw-bold fs-6">
                                      Account Type
                                    </label>
                                    <div className="col-lg-8 fv-row">
                                      {state.personalInfoForm?.account_type ===
                                      "bank" ? (
                                        <select
                                          className="form-select"
                                          defaultValue={
                                            state.personalInfoForm
                                              ?.account_type_name
                                          }
                                          onChange={(e) => {
                                            updateForm(e, "account_type_name");
                                          }}
                                          aria-label="Select example"
                                        >
                                          <option>
                                            select your bank of choice
                                          </option>
                                          {state.banks.map((data, index) => {
                                            return (
                                              <option key={index} value={data}>
                                                {data}
                                              </option>
                                            );
                                          })}
                                        </select>
                                      ) : (
                                        <select
                                          className="form-select"
                                          value={
                                            state.personalInfoForm
                                              .account_type_name
                                          }
                                          onChange={(e) => {
                                            updateForm(e, "account_type_name");
                                          }}
                                          aria-label="Select example"
                                        >
                                          <option>
                                            Choose a Delivery Method
                                          </option>
                                          <option value="MTN">MTN MoMo</option>
                                          <option value="VODAFONE">
                                            Voda Cash
                                          </option>
                                          <option value="AIRTEL_TIGO">
                                            AirtelTigo Cash
                                          </option>
                                        </select>
                                      )}

                                      {state.personalInfoFormError
                                        ?.account_type_name.length > 0 && (
                                        <p className="text-danger fs-12px">
                                          {
                                            state.personalInfoFormError
                                              ?.account_type_name
                                          }
                                        </p>
                                      )}
                                    </div>
                                  </div>
                                )}
                                {state.personalInfoForm.account_type_name ===
                                  "MTN" && (
                                  <div className="row mb-6">
                                    <label className="col-lg-4 col-form-label required fw-bold fs-6">
                                      Sim Type
                                    </label>
                                    <div className="col-lg-8 fv-row">
                                      <select
                                        className="form-select"
                                        value={
                                          state.personalInfoForm.accountType
                                        }
                                        onChange={(e) =>
                                          updateForm(e, "accountType")
                                        }
                                        aria-label="Select example"
                                      >
                                        <option value={""}>
                                          Select Sim Type
                                        </option>
                                        <option value="subscriber">
                                          Subscriber
                                        </option>
                                        <option value="agent">Agent</option>
                                        {/* <option value="merchant">Merchant</option> */}
                                      </select>
                                    </div>
                                  </div>
                                )}

                                <div className="row mb-6">
                                  <label className="col-lg-4 col-form-label required fw-bold fs-6">
                                    Account Name
                                  </label>
                                  <div className="col-lg-8 fv-row">
                                    <input
                                      required
                                      type="text"
                                      className="form-control form-control-lg form-control-solid"
                                      placeholder="eg. Kwame Asare"
                                      value={
                                        state.personalInfoForm?.account_name
                                      }
                                      onChange={(e) => {
                                        updateForm(e, "account_name");
                                      }}
                                    />
                                    {state.personalInfoFormError?.account_name
                                      .length > 0 && (
                                      <p className="text-danger fs-12px">
                                        {
                                          state.personalInfoFormError
                                            ?.account_name
                                        }
                                      </p>
                                    )}
                                  </div>
                                </div>
                                <div
                                  className="row mb-6"
                                  style={{
                                    border: `${state.personalInfoFormError.account_number.length > 0 ? "2px solid red" : ""}`,
                                  }}
                                >
                                  <label className="col-lg-4 col-form-label required fw-bold fs-6">
                                    Account Number
                                  </label>
                                  <div className="col-lg-8 fv-row">
                                    <input
                                      required
                                      type="text"
                                      className="form-control form-control-lg form-control-solid"
                                      placeholder="xxxxx xxxxx xxxxx xxxxx"
                                      value={
                                        state.personalInfoForm.account_number
                                      }
                                      onChange={(e) => {
                                        updateForm(e, "account_number");
                                      }}
                                    />
                                    {state.personalInfoFormError?.account_number
                                      .length > 0 && (
                                      <p className="text-danger fs-12px">
                                        {
                                          state.personalInfoFormError
                                            ?.account_number
                                        }
                                      </p>
                                    )}
                                  </div>
                                </div>
                                {state.personalInfoForm.account_type ===
                                  "bank" && (
                                  <div className="row mb-6">
                                    <label className="col-lg-4 col-form-label required fw-bold fs-6">
                                      Account Branch
                                    </label>
                                    <div className="col-lg-8 fv-row">
                                      <input
                                        type="text"
                                        name="website"
                                        className="form-control form-control-lg form-control-solid"
                                        placeholder="eg. Madina"
                                        value={
                                          state.personalInfoForm?.account_branch
                                        }
                                        onChange={(e) => {
                                          updateForm(e, "account_branch");
                                        }}
                                      />
                                      {state.personalInfoFormError
                                        ?.account_branch.length > 0 && (
                                        <p className="text-danger fs-12px">
                                          {
                                            state.personalInfoFormError
                                              ?.account_branch
                                          }
                                        </p>
                                      )}
                                    </div>
                                  </div>
                                )}
                              </div>
                              {state.isRequestingPayment ? (
                                <div className="card-footer d-flex justify-content-end py-6 px-9">
                                  {/* <button type="reset" className="btn btn-light btn-active-light-primary me-2" disabled>Discard</button> */}
                                  <button
                                    type="submit"
                                    className="btn btn-primary"
                                    disabled
                                  >
                                    <span className="">
                                      Please wait...
                                      <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                                    </span>
                                  </button>
                                </div>
                              ) : (
                                <div className="card-footer d-flex justify-content-end py-6 px-9">
                                  {/* <button type="reset" className="btn btn-light btn-active-light-primary me-2">Discard</button> */}
                                  <button
                                    type="submit"
                                    className="btn btn-primary"
                                  >
                                    Save Changes
                                  </button>
                                </div>
                              )}
                            </form>
                          </div>
                        </div>
                      </div>
                    )}

                    {nav.security && (
                      <div
                        className="container-xxl animatefrombottom"
                        id="kt_content_container"
                      >
                        <div className="card mb-5 mb-xl-10" style={{}}>
                          <div
                            className="card-header border-0 cursor-pointer"
                            role="button"
                            data-bs-toggle="collapse"
                            data-bs-target="#kt_account_profile_details"
                            aria-expanded="true"
                            aria-controls="kt_account_profile_details"
                          >
                            <div className="card-title m-0 flex j-c-b w-100">
                              <h3 className="fw-bolder m-0">Check Security</h3>
                            </div>
                          </div>
                          <div
                            id="kt_account_settings_payout_details"
                            className="collapse show"
                            style={{
                              borderTop: "1px solid rgba(100,100,100,.1)",
                            }}
                          >
                            <div className="flex v-flex">
                              <aside
                                onClick={() =>
                                  globalSetState({
                                    type: ACTIONS.modalOn,
                                    content: <ChangePassword />,
                                    head: (
                                      <div className="flex v-flex">
                                        <h2>Password</h2>
                                        <small>Change your password</small>
                                      </div>
                                    ),
                                  })
                                }
                                className="securityTaps flex j-c-b border border-bottom-primary"
                                style={{
                                  width: "100%",
                                  height: "80px",
                                  background: "white",
                                  margin: "0 0 0rem 0",
                                }}
                              >
                                <div className="flex v-flex ml-1">
                                  <h5>Change Password</h5>
                                  <small>Reset old password</small>
                                </div>
                                <i className="fa fa-chevron-right text-dark mr-1"></i>
                              </aside>
                            </div>
                          </div>
                        </div>
                      </div>
                    )}
                    {nav.kyc && (
                      <div
                        className="container-xxl scaleanimate"
                        id="kt_content_container"
                      >
                        <div
                          className={`${style.kycCard} card mb-5 mb-xl-10 p-5`}
                          style={{}}
                        >
                          {state.kycInfo ? (
                            <>
                              <section className="flex v-flex">
                                <div className="flex j-c-c m-3"></div>
                                <div className="flex p-0 v-flex w-100 m-auto row">
                                  <h3>Account Verification</h3>
                                  <p className="">--- --- ---</p>
                                  <small className="p-0">
                                    Access unlimited withdrawals, crypto swaps
                                    and deposits by verifying your identity
                                  </small>

                                  <br />
                                  <div
                                    className={`${style.kycLevel} flex p-0 j-c-b mb-5 col-lg-8 col-md-8 col-sm-12`}
                                  >
                                    <aside
                                      className={`flex v-flex mr-1`}
                                      style={{}}
                                    >
                                      <p>
                                        <b>Level 1 : User Identity Check</b>
                                      </p>
                                      <p>
                                        Set user identity; residential address
                                        and date of birth
                                      </p>
                                    </aside>
                                    <aside className="flex ml-1">
                                      {state.kycInfo?.identityOutcome ===
                                      "attention" ? (
                                        <Undone color={"warning"} />
                                      ) : state.kycInfo?.identityOutcome ===
                                        "not_processed" ? (
                                        <Undone color={"warning"} />
                                      ) : state.kycInfo?.identityOutcome ===
                                        "failed" ? (
                                        <Undone color={"danger"} />
                                      ) : state.kycInfo?.identityOutcome ===
                                        "complete" ? (
                                        <Done />
                                      ) : (
                                        ""
                                      )}
                                      <Status
                                        color={state.kycInfo?.identityOutcome}
                                        status={state.kycInfo?.identityOutcome}
                                      />
                                    </aside>
                                  </div>

                                  <div
                                    className={`${style.kycLevel} flex p-0 j-c-b mb-5 mt-5 col-lg-8 col-md-8 col-sm-12`}
                                  >
                                    <aside
                                      className="flex v-flex mr-1"
                                      style={{}}
                                    >
                                      <p>
                                        <b>Level 2 : User Document Check</b>
                                      </p>
                                      <p>
                                        Upload personal documents; Selfie and
                                        identity card
                                      </p>
                                    </aside>
                                    <aside className="flex ml-1">
                                      {state.kycInfo?.documentOutcome ===
                                      "attention" ? (
                                        <Undone color={"warning"} />
                                      ) : state.kycInfo?.documentOutcome ===
                                        "not_processed" ? (
                                        <Undone color={"warning"} />
                                      ) : state.kycInfo?.documentOutcome ===
                                        "failed" ? (
                                        <Undone color={"danger"} />
                                      ) : state.kycInfo?.documentOutcome ===
                                        "complete" ? (
                                        <Done />
                                      ) : (
                                        ""
                                      )}
                                      <Status
                                        color={state.kycInfo?.documentOutcome}
                                        status={state.kycInfo?.documentOutcome}
                                      />
                                    </aside>
                                  </div>
                                  {(state.kycInfo?.documentOutcome !==
                                    "complete") |
                                  (state.kycInfo?.identityCheckOutcome !==
                                    "complete") ? (
                                    <div className="p-0">
                                      <button
                                        className="btn text-white"
                                        style={{ background: "rgb(43,41,75)" }}
                                        onClick={() => {
                                          globalSetState({
                                            type: ACTIONS.modalOn,
                                            content: <Kyc skip />,
                                            head: (
                                              <div className="flex v-flex"></div>
                                            ),
                                          });
                                        }}
                                      >
                                        Complete KYC
                                      </button>
                                    </div>
                                  ) : (
                                    <></>
                                  )}

                                  {state.kycInfo?.documentOutcome ===
                                    "complete" &&
                                  state.kycInfo?.identityCheckOutcome ===
                                    "complete" ? (
                                    <>
                                      <br />
                                      <hr />
                                      <br />
                                      <h3>Address Verification</h3>
                                      <p className="">--- --- ---</p>

                                      <div
                                        className={`${style.kycLevel} flex p-0 j-c-b mb-5 mt-5 col-lg-8 col-md-8 col-sm-12`}
                                      >
                                        <aside
                                          className="flex v-flex mr-1"
                                          style={{}}
                                        >
                                          <p>
                                            <b>
                                              Level 3 : User Address Information
                                            </b>
                                          </p>
                                          <p>
                                            Provide your residential address and
                                            other utility bills
                                          </p>
                                        </aside>
                                        <aside className="flex ml-1">
                                          {state.kycInfo.poaCheckStatus ? (
                                            <Done />
                                          ) : (
                                            <Undone />
                                          )}
                                          <Status
                                            status={
                                              state.kycInfo.incomplete
                                                ? "Done"
                                                : "Incomplete"
                                            }
                                          />
                                        </aside>
                                      </div>

                                      {!state.kycInfo.poaCheckStatus ? (
                                        <div className="w-100 flex j-c-b">
                                          <button
                                            className="btn text-white"
                                            style={{
                                              background: "rgb(43,41,75)",
                                            }}
                                            onClick={() => {
                                              globalSetState({
                                                type: ACTIONS.modalOn,
                                                content: <AddAddressForm />,
                                                head: (
                                                  <div className="flex v-flex"></div>
                                                ),
                                              });
                                            }}
                                          >
                                            Add Address Information
                                          </button>
                                        </div>
                                      ) : (
                                        <></>
                                      )}

                                      <br />
                                    </>
                                  ) : (
                                    ""
                                  )}
                                </div>
                              </section>
                            </>
                          ) : (
                            <section className="flex v-flex">
                              <div className="flex j-c-c m-5"></div>
                              <div className="flex v-flex w-100 m-auto">
                                <h4>
                                  Please, read this information before starting
                                  the verification process
                                </h4>
                                <h5>You will need to: </h5>
                                <p className="mt-2">
                                  1. Provide a government issued ID
                                </p>
                                <p className="mb-3">
                                  2. Record a short selfie video
                                </p>

                                <aside
                                  className="flex v-flex a-i-c m-auto shadow p-3"
                                  style={{ borderRadius: "5px" }}
                                >
                                  <div
                                    className="flex j-c-c border text-white round m-2"
                                    style={{
                                      width: "30px",
                                      height: "30px",
                                      background: "rgb(43,41,75)",
                                      color: "white",
                                    }}
                                  >
                                    <i className="fa fa-info text-white"></i>
                                  </div>

                                  <ol type="a">
                                    <li className="mb-1">
                                      . All documents must be original with no
                                      alterations
                                    </li>
                                    <li className="mb-1">
                                      . Use a clear picture with no exposure
                                    </li>
                                    <li className="mb-1">
                                      . Respect the corners of the document
                                    </li>
                                    <li className="mb-1">
                                      . Please, use a background with adequate
                                      brightness for better visibility
                                    </li>
                                  </ol>
                                </aside>
                              </div>
                              <div className="flex j-c-end w-100 mt-3">
                                <button
                                  className={`m-2 btn btn-outline-secondary border border-secondary`}
                                >
                                  CANCEL
                                </button>
                                <button
                                  className={`m-2 btn`}
                                  style={{
                                    background: "rgb(43,41,75)",
                                    color: "white",
                                  }}
                                  onClick={() =>
                                    globalSetState({
                                      type: ACTIONS.modalOn,
                                      content: <Kyc />,
                                      head: <div className="flex v-flex"></div>,
                                    })
                                  }
                                >
                                  VERIFY MY IDENTITY
                                </button>
                              </div>
                            </section>
                          )}
                        </div>
                      </div>
                    )}
                  </div>

                  <Footer />
                </>
              )}
            </div>
          </div>
        </div>
      </div>

      {/* success modal */}
      <div
        className="modal fade"
        id="success-modal"
        tabIndex="-1"
        role="dialog"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered" role="document">
          <div className="modal-content">
            <div className="modal-body text-center">
              <div className="py-10">
                <span className="svg-icon svg-icon-5x svg-icon-primary mb-5">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                  >
                    <rect
                      opacity="0.3"
                      x="2"
                      y="2"
                      width="20"
                      height="20"
                      rx="10"
                      fill="black"
                    />
                    <path
                      d="M10.4343 12.4343L8.75 10.75C8.33579 10.3358 7.66421 10.3358 7.25 10.75C6.83579 11.1642 6.83579 11.8358 7.25 12.25L10.2929 15.2929C10.6834 15.6834 11.3166 15.6834 11.7071 15.2929L17.25 9.75C17.6642 9.33579 17.6642 8.66421 17.25 8.25C16.8358 7.83579 16.1642 7.83579 15.75 8.25L11.5657 12.4343C11.2533 12.7467 10.7467 12.7467 10.4343 12.4343Z"
                      fill="black"
                    />
                  </svg>
                </span>
              </div>
              <h4 className="nk-modal-title">Updated Successfully</h4>
              <div className="nk-modal-text">
                <p className="lead">{state.successMessage}</p>
              </div>
              <div className="nk-modal-action py-10">
                <button
                  className="btn btn-lg btn-mw btn-light m-1"
                  data-bs-dismiss="modal"
                  onClick={() => {
                    closeHandler();
                  }}
                >
                  Close
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* modal end */}
    </Fragment>
  );
}

export default UserSettings;
