import React, { Fragment, useEffect, useState } from "react";
import MetaTags from "react-meta-tags";
import CONSTANTS from "../../misc/Constants";
import axios from "axios";
import { reactLocalStorage } from "reactjs-localstorage";

function AdminLogin() {
  const [state, setState] = useState({
    redirectUser: false,
    isLoading: false,
    isRequesting: false,
    showPassword: false,
    loginForm: {
      email: "",
      password: "",
    },
    errorMessage: "",
  });

  function loginForm(event, name) {
    let value = event.target.value;
    var oldData = state.loginForm;
    oldData[name] = value;
    setState({
      ...state,
      loginForm: oldData,
    });
  }

  const submitLoginForm = (e) => {
    setState({
      ...state,
      isRequesting: true,
      errorMessage: "",
    });
    e.preventDefault();

    axios
      .post(CONSTANTS.API_BASE_URL + "/auth/admin/login", {
        ...state.loginForm,
      })
      .then((response) => {
        setState({
          ...state,
          isRequesting: false,
        });

        let data = response.data;

        sessionStorage.setItem("currentCount", 1000);
        // cookie.save("isLogin", true);
        // cookie.save("access_token", data.token);
        // cookie.save("admin_access_token", data.token);

        // cookie.save("refresh_token", data.refreshToken);
        // cookie.save("userRoles", response.data.roles);
        reactLocalStorage.set("isLogin", true);
        reactLocalStorage.set("access_token", data.token);
        reactLocalStorage.set("admin_access_token", data.token);
        reactLocalStorage.set("refresh_token", data.refreshToken);
        reactLocalStorage.setObject("userRoles", data.user.roles[0]);

        reactLocalStorage.setObject("adminObj", {
          phoneIdentity: data.phoneIdentity,
          emailIdentity: data.emailIdentity,
          user: data.user,
          accountStatus: true,
        });
        // cookie.save("adminObj", {
        //   phoneIdentity: data.phoneIdentity,
        //   emailIdentity: data.emailIdentity,
        //   user: data.user,
        //   accountStatus: true,
        // });
        window.location = "/admin/dashboard";
        // console.log("refresh", reactLocalStorage.get("access_token"));
      })
      .catch((error) => {
        console.log("error:", error);
        try {
          let errorMessage = "Error: Could not connect to server";
          setState({
            ...state,
            isRequesting: false,
            errorMessage: errorMessage,
          });
        } catch (e) {
          setState({
            ...state,
            isRequesting: false,
            errorMessage: "Error: Could not connect to server",
          });
          return;
        }
      });
  };

  // useEffect(() => {
  //   reactLocalStorage.clear();
  //   reactLocalStorage.set("isLogin", false);
  // }, []);

  return (
    <Fragment>
      <MetaTags>
        <title>Admin Login - HodlPay</title>
      </MetaTags>
      <div className="d-flex flex-column flex-root bg-dark h-100">
        <div className="d-flex flex-column flex-column-fluid bgi-position-y-bottom position-x-center bgi-no-repeat bgi-size-contain bgi-attachment-fixed">
          <div className="d-flex flex-center flex-column flex-column-fluid p-10 pb-lg-20">
            <a href="/admin/login" className="mb-10 pt-lg-10">
              <img
                alt="Logo"
                src="/assets/media/logos/hodlpay-white.svg"
                className="h-70px"
              />
            </a>

            <div className="w-lg-500px bg-body rounded shadow-sm p-10 p-lg-15 mx-auto">
              <div className="text-center mb-10">
                <h1 className="text-dark mb-3">Sign In to Admin Portal</h1>
              </div>
              {state.errorMessage.length > 0 && (
                <div className="alert alert-danger d-flex align-items-center p-5 mb-10 w-100">
                  <span className="svg-icon svg-icon-2hx svg-icon-danger me-4">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                    >
                      <g
                        stroke="none"
                        strokeWidth="1"
                        fill="none"
                        fillRule="evenodd"
                      >
                        <rect x="0" y="0" width="24" height="24" />
                        <circle
                          fill="#000000"
                          opacity="0.3"
                          cx="12"
                          cy="12"
                          r="10"
                        />
                        <rect
                          fill="#000000"
                          x="11"
                          y="10"
                          width="2"
                          height="7"
                          rx="1"
                        />
                        <rect
                          fill="#000000"
                          x="11"
                          y="7"
                          width="2"
                          height="2"
                          rx="1"
                        />
                      </g>
                    </svg>
                  </span>
                  <div className="d-flex flex-column">
                    <h4 className="mb-1 text-danger">Error</h4>{" "}
                    <span>{state.errorMessage}</span>{" "}
                  </div>
                </div>
              )}
              <form onSubmit={submitLoginForm} method="POST">
                <div className="fv-row mb-10">
                  <label className="form-label fs-6 fw-bolder text-dark">
                    Email
                  </label>
                  <input
                    className="form-control form-control-lg form-control-solid"
                    type="text"
                    name="email"
                    autoComplete="off"
                    value={state.loginForm.email}
                    onChange={(e) => {
                      loginForm(e, "email");
                    }}
                  />
                </div>
                <div className="fv-row mb-10">
                  <label className="form-label fs-6 fw-bolder text-dark">
                    Password
                  </label>

                  <input
                    className="form-control form-control-lg form-control-solid"
                    type="password"
                    name="password"
                    autoComplete="off"
                    value={state.loginForm.password}
                    onChange={(e) => {
                      loginForm(e, "password");
                    }}
                  />
                </div>

                <div className="text-center">
                  {state.isRequesting ? (
                    <button
                      type="button"
                      className="btn btn-lg btn-primary w-100"
                      disabled
                    >
                      <span className="">
                        Please wait...
                        <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                      </span>
                    </button>
                  ) : (
                    <button
                      type="submit"
                      className="btn btn-lg btn-primary w-100"
                    >
                      <span className="indicator-label">Sign In</span>
                    </button>
                  )}
                  {state.redirectUser && (
                    <p
                      className="text-primary"
                      style={{ cursor: "pointer", marginTop: "1rem" }}
                      onClick={() =>
                        (window.location = `${window.location.origin}/login`)
                      }
                    >
                      Login here to start buying and selling
                    </p>
                  )}
                </div>
              </form>
            </div>
          </div>
          <div className="d-flex flex-center flex-column-auto p-10">
            <div className="d-flex align-items-center fw-bold fs-6">
              <a
                href={`${CONSTANTS.WEB_URL}/#about`}
                target="_blank"
                className="text-muted text-hover-primary px-2"
                rel="noreferrer"
              >
                About
              </a>
              <a
                href={`${CONSTANTS.WEB_URL}/terms-and-conditions`}
                target="_blank"
                className="text-muted text-hover-primary px-2"
                rel="noreferrer"
              >
                Terms and Conditions
              </a>
              <a
                href={`${CONSTANTS.WEB_URL}/privacy-policy`}
                target="_blank"
                className="text-muted text-hover-primary px-2"
                rel="noreferrer"
              >
                Privacy Policy
              </a>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
}

export default AdminLogin;
