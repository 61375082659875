import React, { Fragment, useEffect, useState } from "react";
import MetaTags from "react-meta-tags";
import AuthService from "../../misc/AuthService";
import SideNav from "./include/SideNav";
import Header from "./include/Header";
import Footer from "./include/Footer";
import "react-datepicker/dist/react-datepicker.css";
import SideNavMobile from "./include/sideNavMobile";
import ReactGA from "react-ga";

function Faq() {
  useEffect(() => {
    ReactGA.pageview(window.location.pathname + window.location.search);
  }, []);

  const [state, setState] = useState({
    sidebar: false,
    userInfo: AuthService.getAuth().user,
    isLoading: false,
    isRequesting: false,
    showFilter: false,
    orderType: "buy",
    orderID: "",
    showOrderInfo: false,
  });

  return (
    <Fragment>
      <MetaTags>
        <title>FAQ - HodlPay</title>
      </MetaTags>
      <div className="header-fixed header-tablet-and-mobile-fixed aside-fixed aside-secondary-disabled body">
        <div className="d-flex flex-column flex-root">
          <div className="page d-flex flex-row flex-column-fluid">
            {state.sidebar && (
              <SideNavMobile
                close={() => setState({ ...state, sidebar: false })}
              />
            )}
            <SideNav className={"bigScreen"} active="faq" />

            <div
              className="p-0 wrapper d-flex flex-column flex-row-fluid"
              id="kt_wrapper"
            >
              <div className="header-mobile py-3">
                <div className="container d-flex flex-stack">
                  <div className="d-flex align-items-center flex-grow-1 flex-lg-grow-0">
                    <a href="index.html">
                      <img
                        alt="Logo"
                        src="/assets/media/logos/hodlpay-black.svg"
                        className="h-35px"
                      />
                    </a>
                  </div>

                  <button
                    className="btn btn-icon btn-active-color-primary"
                    onClick={() =>
                      setState({ ...state, sidebar: !state.sidebar })
                    }
                  >
                    <i
                      className="fa fa-bars"
                      style={{ fontSize: "2rem", color: "rgb(0,0,0,.8)" }}
                    ></i>
                  </button>
                </div>
              </div>
              <Header title={`Frequently Asked Questions`} />

              {state.isLoading ? (
                <div className="pre-loader">
                  <div className="text-center">
                    <div className="spinner-border" role="status">
                      <span className="sr-only">Loading...</span>
                    </div>
                  </div>
                </div>
              ) : (
                <>
                  <div
                    className="content d-flex flex-column flex-column-fluid"
                    id="kt_content"
                  >
                    <div className="container-xxl" id="kt_content_container">
                      <div className="card">
                        <div className="card-body p-10 p-lg-15">
                          <div className="row mb-12">
                            <div className="col-md-6 pe-md-10 mb-10 mb-md-0">
                              <h2 className="text-gray-800 fw-bolder mb-4">
                                How to buy Bitcoin or USDT on HoldPay?
                              </h2>
                              <div className="m-0">
                                <p className="fs-6">
                                  <b>1.</b> Click on New Order.
                                </p>
                                <p className="fs-6">
                                  <b>2.</b> Select Bitcoin or USDT.
                                </p>
                                <p className="fs-6">
                                  <b>3.</b> Enter the amount of Bitcoin or USDT
                                  to buy in USD.
                                </p>
                                <p className="fs-6">
                                  <b>4.</b> Enter your Bitcoin or USDT address
                                  and choose preferred fee.
                                </p>
                                <p className="fs-6">
                                  <b>5.</b> Choose your preferred payment method
                                  and follow the instructions to complete
                                  payment.
                                </p>
                                <p className="fs-6">
                                  <b>6.</b> Click on submit to create order.
                                </p>
                                <p className="fs-6">
                                  <b>7.</b> Enter payment transaction ID and
                                  submit.
                                </p>
                              </div>
                            </div>
                            <div className="col-md-6 ps-md-10">
                              <h2 className="text-gray-800 fw-bolder mb-4">
                                How to sell Bitcoin or USDT on HoldPay?
                              </h2>
                              <div className="m-0">
                                <p className="fs-6">
                                  <b>1.</b> Click on New Order.
                                </p>
                                <p className="fs-6">
                                  <b>2.</b> Select Bitcoin or USDT.
                                </p>
                                <p className="fs-6">
                                  <b>3.</b> Enter the amount of Bitcoin or USDT
                                  to sell in USD.
                                </p>
                                <p className="fs-6">
                                  <b>4.</b> Click on submit to create order.
                                </p>
                                <p className="fs-6">
                                  <b>5.</b> Transfer cryptocurrency to wallet
                                  provided.
                                </p>
                                <p className="fs-6">
                                  <b>6.</b> Enter payment transaction hash and
                                  submit.
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <Footer />
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
}

export default Faq;
