import React, { useContext, useEffect, useReducer, useState } from "react";
import Constants from "../../../misc/Constants";
import AuthService from "../../../misc/AuthService";
import axios from "axios";
import Compressor from "compressorjs";
import ModalLoading from "../../../ui-elements/modal/ModalLoading";
import ModalError from "../../../ui-elements/modal/ModalError";
import Context from "../../../store/Context";
import { ACTIONS } from "../../../store/useGlobalState";
import ModalSuccess from "../../../ui-elements/modal/ModalSuccess";

const initialAddressInfo = {
  address: "",
  city: "",
  country: "",
  state: "",
};

const ADDRESSACTIONS = {
  address: "address",
  city: "city",
  country: "country",
  state: "state",
};

const reducer = (state, action) => {
  switch (action.type) {
    case ADDRESSACTIONS.address:
      return { ...state, address: action.data };
    case ADDRESSACTIONS.city:
      return { ...state, city: action.data };
    case ADDRESSACTIONS.country:
      return { ...state, country: action.data };
    case ADDRESSACTIONS.state:
      return { ...state, state: action.data };
    default:
      return state;
  }
};

function AddAddressForm() {
  const { setState } = useContext(Context);
  const [load, setLoad] = useState(true);
  const [error, setError] = useState({ message: "", status: false });
  const [success, setSuccess] = useState({ message: "", status: false });
  const [doc, setDoc] = useState({ documentType: "", file: "" });
  const [addressInfo, setAddressInfo] = useReducer(reducer, initialAddressInfo);

  const handleFile = (e) => {
    const file = e.target.files[0];
    new Compressor(file, {
      quality: 0.6,
      success(result) {
        setDoc({ ...doc, file: result });
      },
      error() {
        setDoc({ ...doc, file });
      },
    });
  };

  useEffect(() => {
    let obj = AuthService.getAuth().user?.user;
    if (!obj.address)
      setError({ message: "Some Information are missen", status: true });
    if (!obj.city)
      setError({ message: "Some Information are missen", status: true });
    if (!obj.country)
      setError({ message: "Some Information are missen", status: true });
    if (!obj.state)
      setError({ message: "Some Information are missen", status: true });
    else setLoad(false);
  }, []);

  const submitAddress = (e) => {
    e.preventDefault();
    setLoad(true);
    const data = new FormData();

    data.append("documentType", doc.documentType);
    data.append("file", doc.file);

    axios
      .post(
        `${Constants.API_BASE_URL}/users/kyc/address/verification`,
        data,
        AuthService.getAxiosHeaders(),
      )
      .then(() => {
        setLoad(false);
        setSuccess({ ...success, status: true });
      })
      .catch((e) => {
        setLoad(false);
        if (typeof e.response.message === "object") {
          setError({
            ...error,
            message: e.response.data.messages[0],
            status: true,
            form: false,
          });
        } else
          setError({
            ...error,
            message: e.response.data.message,
            status: true,
            form: false,
          });
      });
  };

  const AddAddress = () => {
    const submitUserAddress = (e) => {
      e.preventDefault();
      setLoad(true);
      axios
        .patch(
          `${Constants.API_BASE_URL}/users`,
          { ...addressInfo },
          AuthService.getAxiosHeaders(),
        )
        .then((res) => {
          setLoad(false);
          setError({ status: false });
        })
        .catch((e) => {
          setState({ type: ACTIONS.modalOff });
        });
    };
    return (
      <form onSubmit={submitUserAddress} className="mt-3 mb-3">
        <div className="flex v-flex w-100 ">
          <div className="w-100 flex j-c-c mb-2">
            <p className="text-center">
              Check and complete this form and <br /> click on <b>Submit</b>
            </p>
          </div>
          <div className="flex w-100 mt-3 mb-3">
            <i
              className="fa fa-address-book mr-1"
              style={{ fontSize: "1.2rem" }}
            ></i>
            <input
              value={addressInfo?.address}
              onChange={(e) =>
                setAddressInfo({
                  type: ADDRESSACTIONS.address,
                  data: e.target.value,
                })
              }
              type="text"
              placeholder="Address"
              className="p-2 w-100 text-dark"
              style={{
                height: "40px",
                border: "none",
                borderBottom: "1px solid lightgray",
              }}
              required
            />
          </div>
          <div className="flex w-100 mt-3 mb-3">
            <i className="fa fa-city mr-1" style={{ fontSize: "1.2rem" }}></i>
            <input
              value={addressInfo?.city}
              onChange={(e) =>
                setAddressInfo({
                  type: ADDRESSACTIONS.city,
                  data: e.target.value,
                })
              }
              type="text"
              placeholder="City"
              className="p-2 w-100 text-dark"
              style={{
                height: "40px",
                border: "none",
                borderBottom: "1px solid lightgray",
              }}
              required
            />
          </div>
          <div className="flex w-100 mt-3 mb-3">
            <i className="fa fa-flag mr-1" style={{ fontSize: "1.2rem" }}></i>
            <input
              value={addressInfo?.country}
              onChange={(e) =>
                setAddressInfo({
                  type: ADDRESSACTIONS.country,
                  data: e.target.value,
                })
              }
              type="text"
              placeholder="Country"
              className="p-2 w-100 text-dark"
              style={{
                height: "40px",
                border: "none",
                borderBottom: "1px solid lightgray",
              }}
              required
            />
          </div>
          <div className="flex w-100 mt-3 mb-3">
            <i className="fa fa-home mr-1" style={{ fontSize: "1.2rem" }}></i>
            <input
              value={addressInfo?.state}
              onChange={(e) =>
                setAddressInfo({
                  type: ADDRESSACTIONS.state,
                  data: e.target.value,
                })
              }
              type="text"
              placeholder="State"
              className="p-2 w-100 text-dark"
              style={{
                height: "40px",
                border: "none",
                borderBottom: "1px solid lightgray",
              }}
              required
            />
          </div>
          <button className="btn btn-primary mt-3 m-auto">Submit</button>
        </div>
      </form>
    );
  };

  if (load) return <ModalLoading />;

  if (error.status)
    return (
      <ModalError
        errorMessage={error.message}
        Form={error.form && <AddAddress />}
      />
    );
  if (success.status) return <ModalSuccess successMessage={"Address added"} />;

  return (
    <form
      onSubmit={submitAddress}
      style={{ width: "100%", height: "300px" }}
      className="flex v-flex a-i-c scaleanimate"
    >
      <div className="twofactorheader text-center mb-5 mt-3">
        <h4>Add Address Information</h4>
        <small className="mb-5">
          You can either provide a utility bill or a bank statement
        </small>
      </div>

      <div
        className="flex v-flex mt-5 mb-5 j-c-c"
        style={{ width: "300px", marging: "0 auto 0 auto" }}
      >
        <p className="mt-5"></p>
        <div
          className="flex"
          style={{ width: "80%", margin: "0 auto 2.5rem auto" }}
        >
          <i
            className="fa fa-address-book mr-1"
            style={{ fontSize: "2rem" }}
          ></i>
          <div
            className="relative flex"
            style={{
              border: "2px solid black",
              borderWidth: "0 0 1px 0",
              width: "100%",
              height: "30px",
            }}
          >
            <select
              required
              value={doc.documentType}
              onChange={(e) => setDoc({ ...doc, documentType: e.target.value })}
              name="documentType"
              id="documentType"
              style={{ width: "100%", height: "100%", border: "none" }}
            >
              <option value="">Document Type</option>
              <option value="bank_statement">Bank Statement</option>
              <option value="utility_bill">Utility Bill</option>
            </select>
          </div>
        </div>
        <div className="flex m-auto" style={{ width: "80%" }}>
          <i
            onClick={() => document.getElementById("addAddress").click()}
            className="fa fa-file mr-1"
            style={{ fontSize: "2rem" }}
          ></i>
          <div
            className="relative flex"
            style={{
              border: "2px solid black",
              borderWidth: "0 0 1px 0",
              width: "100%",
              height: "30px",
            }}
          >
            <input
              required
              onChange={(e) => handleFile(e)}
              id="addAddress"
              type="file"
              className="absolute right-0 left-0 top-0 bottom-0"
              placeholder="Utility bill"
              style={{ borderWidth: "0 0 1px 0", opacity: "0" }}
              accept=".pdf, .docx, image/jpeg, image/png, image/gif"
            />
            <p className="" style={{}}>
              {doc.file
                ? doc.file?.name?.slice(0, 12) + "..."
                : "Utility Bill or Bank Statement File"}
            </p>
          </div>
        </div>
        <br />
        <br />
        <button className="m-auto btn btn-primary">Submit Address</button>
      </div>

      <br />
    </form>
  );
}

export default AddAddressForm;
