import React, { useEffect, useState } from "react";
import style from "../styles/sidebarMobile.module.css";
import logo from "../../../iamges/logo1.png";
import { useLocation, useHistory } from "react-router-dom";
import { getDate } from "../../../misc/methods";
import AccountPopup from "./AccountPopup";

function SideNavMobile({ close }) {
  const [state, setState] = useState({
    showAcc: false,
    userInfo: /*AuthService.getAuth().user*/ "",
  });
  const location = useLocation();
  const history = useHistory();
  const [active, setActive] = useState(location.pathname);

  const handleRoute = (path) => {
    history.push(path);
    return close();
  };

  useEffect(() => {
    setActive(location.pathname);
  }, [location]);

  window.addEventListener("resize", () => close());
  return (
    <div className={`${style.mobileSidebar} flex a-i-s relative`}>
      <section
        className={`${style.navContainer} flex v-flex j-c-b animatefromleft relative`}
      >
        <div
          onClick={close}
          className={`absolute ${style.closeBtn} flex j-c-c`}
          type="button"
        >
          <b>
            <i className="fa fa-times"></i>
          </b>
        </div>
        <aside className={`${style.navSection} flex v-flex w-100`}>
          <div className={`${style.hodlpayLogo} flex j-c-c mb-10`}>
            <aside className={`${style.logoContainer}`}>
              <img src={logo} alt="" className="img-fit" />
            </aside>
          </div>
          <div className="flex j-c-c w-100">
            <small className="text-center">
              Buy and sell cryptocurrency
              <br />
              Easy and Faster
            </small>
          </div>
          <div className={`navItems flex v-flex w-100 mt-16`}>
            <aside
              onClick={() => handleRoute("/dashboard")}
              className={`${style.navItem} flex mt-8 m-auto ${active === "/dashboard" && style.navItemActive}`}
              style={{ paddingLeft: "3.5rem" }}
            >
              <svg
                className="mr-1"
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
              >
                <rect
                  x="2"
                  y="2"
                  width="9"
                  height="9"
                  rx="2"
                  fill={`${active === "/dashboard" ? "white" : "black"}`}
                />
                <rect
                  opacity="0.3"
                  x="13"
                  y="2"
                  width="9"
                  height="9"
                  rx="2"
                  fill={`${active === "/dashboard" ? "white" : "black"}`}
                />
                <rect
                  opacity="0.3"
                  x="13"
                  y="13"
                  width="9"
                  height="9"
                  rx="2"
                  fill={`${active === "/dashboard" ? "white" : "black"}`}
                />
                <rect
                  opacity="0.3"
                  x="2"
                  y="13"
                  width="9"
                  height="9"
                  rx="2"
                  fill={`${active === "/dashboard" ? "white" : "black"}`}
                />
              </svg>
              <h5>Dashboard</h5>
            </aside>
            <aside
              onClick={() => handleRoute("/orders")}
              className={`${style.navItem} flex mt-8 m-auto ${active === "/orders" && style.navItemActive}`}
              style={{ paddingLeft: "3.5rem" }}
            >
              <svg
                className="mr-1"
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
              >
                <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                  <rect x="0" y="0" width="24" height="24" />
                  <rect
                    fill={`${active === "/orders" ? "white" : "black"}`}
                    opacity="0.3"
                    x="2"
                    y="2"
                    width="10"
                    height="12"
                    rx="2"
                  />
                  <path
                    fill={`${active === "/orders" ? "white" : "black"}`}
                    d="M4,6 L20,6 C21.1045695,6 22,6.8954305 22,8 L22,20 C22,21.1045695 21.1045695,22 20,22 L4,22 C2.8954305,22 2,21.1045695 2,20 L2,8 C2,6.8954305 2.8954305,6 4,6 Z M18,16 C19.1045695,16 20,15.1045695 20,14 C20,12.8954305 19.1045695,12 18,12 C16.8954305,12 16,12.8954305 16,14 C16,15.1045695 16.8954305,16 18,16 Z"
                  />
                </g>
              </svg>
              <h5>Orders</h5>
            </aside>
            <aside
              onClick={() => handleRoute("/faq")}
              className={`${style.navItem} flex mt-8 m-auto ${active === "/faq" && style.navItemActive}`}
              style={{ paddingLeft: "3.5rem" }}
            >
              <svg
                className="mr-1"
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
              >
                <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                  <rect x="0" y="0" width="24" height="24" />
                  <polygon
                    fill={`${active === "/faq" ? "white" : "black"}`}
                    opacity="0.3"
                    points="5 15 3 21.5 9.5 19.5"
                  />
                  <path
                    fill={`${active === "/faq" ? "white" : "black"}`}
                    d="M13.5,21 C8.25329488,21 4,16.7467051 4,11.5 C4,6.25329488 8.25329488,2 13.5,2 C18.7467051,2 23,6.25329488 23,11.5 C23,16.7467051 18.7467051,21 13.5,21 Z M9,8 C8.44771525,8 8,8.44771525 8,9 C8,9.55228475 8.44771525,10 9,10 L18,10 C18.5522847,10 19,9.55228475 19,9 C19,8.44771525 18.5522847,8 18,8 L9,8 Z M9,12 C8.44771525,12 8,12.4477153 8,13 C8,13.5522847 8.44771525,14 9,14 L14,14 C14.5522847,14 15,13.5522847 15,13 C15,12.4477153 14.5522847,12 14,12 L9,12 Z"
                  />
                </g>
              </svg>
              <h5>FAQ</h5>
            </aside>
            <aside
              onClick={() => handleRoute("/support")}
              className={`${style.navItem} flex mt-8 m-auto ${active === "/support" && style.navItemActive}`}
              style={{ paddingLeft: "3.5rem" }}
            >
              <i
                className="fa fa-wallet mr-1 p-1"
                style={{
                  fontSize: "1.4rem",
                  color: `${active === "/wallet" ? "white" : "rgb(36, 32, 59)"} `,
                }}
              ></i>
              <h5>Support</h5>
            </aside>
          </div>
          <div className={`${style.sideBottom} w-100 flex v-flex j-c-b a-i-c`}>
            <aside
              onClick={() => (window.location = "/account-settings")}
              className={`${style.navItem} flex mb-5 mt-8 m-auto ${active === "/account-settings" && style.navItemActive}`}
              style={{ paddingLeft: "3.5rem" }}
            >
              <svg
                className="mr-1"
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
              >
                <path
                  d="M6.28548 15.0861C7.34369 13.1814 9.35142 12 11.5304 12H12.4696C14.6486 12 16.6563 13.1814 17.7145 15.0861L19.3493 18.0287C20.0899 19.3618 19.1259 21 17.601 21H6.39903C4.87406 21 3.91012 19.3618 4.65071 18.0287L6.28548 15.0861Z"
                  fill={`${active === "/account-settings" ? "white" : "black"}`}
                />
                <rect
                  opacity="0.3"
                  x="8"
                  y="3"
                  width="8"
                  height="8"
                  rx="4"
                  fill={`${active === "/account-settings" ? "white" : "black"}`}
                />
              </svg>
              <h5>Account</h5>
            </aside>
            {state.showAcc && (
              <AccountPopup
                close={() => setState({ ...state, showAcc: false })}
              />
            )}
            <aside className="flex j-c-c">
              <small className="text-center text-dark w-100">
                HodlPay {getDate("year")}
              </small>
            </aside>
          </div>
        </aside>
      </section>
      <section
        onClick={close}
        className={`${style.rightSide} modalBackDrop absolute`}
      ></section>
    </div>
  );
}

export default SideNavMobile;
