export function getDate(type) {
  let year = new Date().getFullYear();
  let day = new Date().getDate();
  let month = new Date().getMonth();

  switch (type) {
    case "year":
      return year;
    case "day":
      return day;
    case "month":
      return month;
    default:
      return `${day}/${month}/${year}`;
  }
}

export function passwordValidation(password) {
  let result = [""];
  const hasNumber = /\d/.test(password);
  const hasUpperCase = /[A-Z]/.test(password);
  const regex = /[!@#$%^&*(),.?":{}|<>]/;
  const hasSymbol = regex.test(password);

  if (!hasNumber) {
    result.push("num");
  } else {
    result.filter((item) => item !== "num");
  }

  if (!hasUpperCase) {
    result.push("upper");
  } else {
    result.filter((item) => item !== "upper");
  }

  if (!hasSymbol) {
    result.push("symbol");
  } else {
    result.filter((item) => item !== "symbol");
  }

  if (password?.length < 8) {
    result.push("8char");
  } else {
    result.filter((item) => item !== "8char");
  }

  if (hasNumber && hasUpperCase && hasSymbol && !result.includes("8char")) {
    result = [""];
  }

  return result;
}

export const validatePassword = (value) => {
  let error;
  if (!value) {
    error = "Password can't be empty";
  } else if (value.length < 8) {
    error = "Password must be 8 or more characters";
  } else if (!/(?=.*[a-zA-Z])(?=.*[0-9])(?=.*[!@#$%^&*])/.test(value)) {
    error = "Password must contain a mix of letters, numbers, and symbols";
  }
  return error;
};

export const validateEmail = (value) => {
  let error;
  if (!value) {
    error = "Your email can't be empty";
  } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(value)) {
    error = "Invalid email address";
  }
  return error;
};

export const regions = [
  "Ashanti",
  "Greater Accra",
  "Bono",
  "Bono East",
  "Ahafo",
  "Central",
  "Eastern",
  "Northern",
  "Savanna",
  "North East",
  "Upper East",
  "Upper West",
  "Volta",
  "Oti",
  "Western",
  "Westen North",
];
