import React, { useEffect, useState } from "react";
import axios from "axios";
import CONSTANTS from "../../../misc/Constants";
// import 'antd/dist/antd.css';
import AuthService from "../../../misc/AuthService";
import classNames from "classnames";
import Constants from "../../../misc/Constants";
import startTokenRefresh from "../../../misc/sessionExpiry";
import style from "../styles/order.module.css";

function OrderInfo(props) {
  const [state, setState] = useState({
    hasPaymentMethod: false,
    isLoading: true,
    isRequesting: false,
    orderInfo: {},
    transactionNumber: "",
    errorMessage: "",
    successMessage: "",
    copied: false,
    orderUpdated: false,
    paymentMethod: {},
    showNewPayout: false,
    isModalClose: true,
    buyOrderConfirm: {
      accountName: "",
      accountNumber: "",
      orderId: "",
      networkTransactionId: "",
    },
    HodlpayAccount: {
      AccountNumber: "0245542760",
      AccountName: "Hodlpay Limited",
    },
  });

  function copiedText() {
    setState({
      ...state,
      copied: true,
    });

    setTimeout(() => {
      setState({
        ...state,
        copied: false,
      });
    }, 2000);
  }

  function completeOrder() {
    const isConfirmed = window.confirm(
      "Are you sure you want to mark this order as complete?",
    );

    if (isConfirmed) {
      if (!state.orderInfo || !state.orderInfo.id) {
        console.error("Invalid order information");
        return;
      }

      const orderId = state.orderInfo.id;

      const data = {
        orderId: orderId,
        status: "completed",
      };

      // console.log('Token:', AuthService.getAxiosHeaders().headers.Authorization);
      // console.log("data:", data);
      // console.log("status:", state.orderInfo.status)
      setState({ ...state, isRequesting: true });

      axios
        .post(
          `${Constants.API_BASE_URL}/admin/orders/sell/confirm`,
          data,
          AuthService.getAxiosHeaders(),
        )
        .then((res) => {
          setState({ ...state, isRequesting: false });

          window.alert("Order has been marked as complete!");

          // console.log("Order marked as complete successfully:", res.data);
        })
        .catch((err) => {
          setState({ ...state, isRequesting: false });

          // console.error("Error marking order as complete:", err);
          // console.log("error:", err.response.data)
        });
    }
  }

  function updateForm(event, name) {
    let value = event.target.value;
    if (name === "accountName") {
      setState({
        ...state,
        buyOrderConfirm: { ...state.buyOrderConfirm, accountName: value },
      });
    } else if (name === "accountNumber") {
      setState({
        ...state,
        buyOrderConfirm: { ...state.buyOrderConfirm, accountNumber: value },
      });
    } else if (name === "networkTransactionId") {
      setState({
        ...state,
        transactionNumber: value,
        buyOrderConfirm: {
          ...state.buyOrderConfirm,
          networkTransactionId: value,
        },
      });
    }
  }

  function submitBuyOrderForm() {
    setState({
      ...state,
      isRequesting: true,
      errorMessage: "",
      successMessage: "",
    });
    const data = {
      ...state.buyOrderConfirm,
      accountName: state.HodlpayAccount.AccountName,
      accountNumber: state.HodlpayAccount.AccountNumber,
    };

    axios
      .post(
        CONSTANTS.API_BASE_URL + "/users/orders/payment/confirm",
        data,
        AuthService.getAxiosHeaders(),
      )
      .then(() => {
        let orderInfo = state.orderInfo;

        setState({
          ...state,
          isRequesting: false,
          orderUpdated: true,
          orderInfo: orderInfo,
        });
        closeModal();
        window.modalScrollUp();
      })
      .catch(() => {
        setState({
          ...state,
          isRequesting: false,
          errorMessage: "",
        });
      });
  }

  function getOrderInfo() {
    let data = { ...props.passedOrder };
    setState({
      ...state,
      orderInfo: { ...state.orderInfo, ...data },
      buyOrderConfirm: { ...state.buyOrderConfirm, orderId: data?.id },
      isLoading: true,
    });
  }

  function getUserPaymentMethod() {
    setState({
      ...state,
      isLoading: true,
      isRequesting: true,
    });
    axios
      .get(
        `${Constants.API_BASE_URL}/users/payment/method`,
        AuthService.getAxiosHeaders(),
      )
      .then((res) => {
        if (res.data) {
          setState((prev) => {
            return {
              ...prev,
              isLoading: false,
              isRequesting: false,
              hasPaymentMethod: true,
              paymentMethod: {
                ...res.data,
                accountName: res.data?.accountName,
                network: res.data?.network,
                accountNumber: res.data?.phoneNumber,
              },
              buyOrderConfirm: {
                ...state.buyOrderConfirm,
                accountNumber: res.data?.phoneNumber,
                accountName: res.data?.accountName,
              },
            };
          });
        } else {
          setState({
            ...state,
            isLoading: false,
            hasPaymentMethod: false,
          });
        }
      })
      .catch((err) => {
        setState({
          ...state,
          isLoading: false,
        });
        if (err.response.status === 401) {
          startTokenRefresh(true);
        }
      });
  }

  function closeModal() {
    // console.log("close modal", props)
    props.orderUpdated(false);

    if (window.location.href.includes("settings")) {
      window.location = document.referrer;
    }
  }

  /**
   * Closes the modal and updates the order status.
   */
  function handlecloseModal() {
    if (window.location.href.includes("settings")) {
      window.location = document.referrer;
    }
    setState((prevState) => ({
      ...prevState,
      orderUpdated: false,
    }));
  }

  useEffect(() => {
    getUserPaymentMethod();

    return getOrderInfo();
    // eslint-disable-next-line
  }, []);

  function cancelOrder(id) {
    if (window.confirm("Are you sure you want to cancel this order")) {
      let data = {
        orderId: id,
      };
      setState({ ...state, isRequesting: true });
      axios
        .post(
          `${Constants.API_BASE_URL}/orders/cancel`,
          data,
          AuthService.getAxiosHeaders(),
        )
        .then((res) => {
          setState({ ...state, isRequesting: false });
          window.location.reload();
        })
        .catch((err) => {
          setState({ ...state, isRequesting: false });
        });
    }
  }

  return (
    <div className={`${style.order}`} style={{ minWidth: "600px" }}>
      <div className="modal-body scroll-y mx-5 mx-xl-18 pt-0 pb-15">
        <div className="text-center mb-13">
          <h1 className="mb-3">
            {`${state.orderInfo?.type?.slice(0, 1)?.toUpperCase()}${state.orderInfo?.type?.slice(1)}`}{" "}
            Order Details
          </h1>
        </div>

        <div
          id="kt_modal_users_search_handler"
          data-kt-search-keypress="true"
          data-kt-search-min-length="2"
          data-kt-search-enter="enter"
          data-kt-search-layout="inline"
          data-kt-search="true"
        >
          {state.isLoading || state.isRequesting ? (
            <div className="text-center py-20">
              <div className="spinner-border" role="status">
                <span className="sr-only">Loading...</span>
              </div>
            </div>
          ) : (
            <div className="py-5">
              {state.orderInfo?.type === "buy" ? (
                <div className="order">
                  <div className="text-center mb-9">
                    {state.orderInfo?.asset === "BTC" ? (
                      <img
                        src="/assets/media/svg/brand-logos/btc.svg"
                        className="h-80px align-self-center mb-8"
                        alt=""
                      />
                    ) : (
                      <img
                        src="/assets/media/svg/brand-logos/usdt.svg"
                        className="h-80px align-self-center mb-8"
                        alt=""
                      />
                    )}
                    <div>
                      <div className="fs-3x text-dark fw-bolder lh-1 mb-4">
                        ${state.orderInfo?.amount}
                      </div>
                      <div className="fs-6 text-gray-600 fw-bold">
                        Wallet Address:{" "}
                        <b>
                          {state.orderInfo?.transaction?.walletAddress ?? ""}
                        </b>
                      </div>
                    </div>
                  </div>
                  <div
                    className={classNames(
                      "alert d-flex align-items-center p-5 mb-10 w-100",
                      {
                        "bg-light-danger": ["cancelled", "refunded"].includes(
                          state.orderInfo?.status,
                        ),
                      },
                      {
                        "bg-light-warning": [
                          "pending_payment",
                          "pending_verification",
                          "pending",
                        ].includes(state.orderInfo?.status),
                      },
                      {
                        "bg-light-primary":
                          state.orderInfo?.status === "completed",
                      },
                    )}
                  >
                    <span
                      className={classNames(
                        "svg-icon svg-icon-2hx me-4",
                        {
                          "svg-icon-danger": ["cancelled", "refunded"].includes(
                            state.orderInfo?.status,
                          ),
                        },
                        {
                          "svg-icon-warning": [
                            "pending_payment",
                            "pending_verification",
                            "pending",
                          ].includes(state.orderInfo?.status),
                        },
                        {
                          "svg-icon-primary":
                            state.orderInfo?.status === "completed",
                        },
                      )}
                    >
                      {state.orderInfo?.status === "completed" ? (
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          fill="none"
                        >
                          <g
                            stroke="none"
                            strokeWidth="1"
                            fill="none"
                            fillRule="evenodd"
                          >
                            <polygon points="0 0 24 0 24 24 0 24" />
                            <path
                              d="M9.26193932,16.6476484 C8.90425297,17.0684559 8.27315905,17.1196257 7.85235158,16.7619393 C7.43154411,16.404253 7.38037434,15.773159 7.73806068,15.3523516 L16.2380607,5.35235158 C16.6013618,4.92493855 17.2451015,4.87991302 17.6643638,5.25259068 L22.1643638,9.25259068 C22.5771466,9.6195087 22.6143273,10.2515811 22.2474093,10.6643638 C21.8804913,11.0771466 21.2484189,11.1143273 20.8356362,10.7474093 L17.0997854,7.42665306 L9.26193932,16.6476484 Z"
                              fill="#000000"
                              fillRule="nonzero"
                              opacity="0.3"
                              transform="translate(14.999995, 11.000002) rotate(-180.000000) translate(-14.999995, -11.000002) "
                            />
                            <path
                              d="M4.26193932,17.6476484 C3.90425297,18.0684559 3.27315905,18.1196257 2.85235158,17.7619393 C2.43154411,17.404253 2.38037434,16.773159 2.73806068,16.3523516 L11.2380607,6.35235158 C11.6013618,5.92493855 12.2451015,5.87991302 12.6643638,6.25259068 L17.1643638,10.2525907 C17.5771466,10.6195087 17.6143273,11.2515811 17.2474093,11.6643638 C16.8804913,12.0771466 16.2484189,12.1143273 15.8356362,11.7474093 L12.0997854,8.42665306 L4.26193932,17.6476484 Z"
                              fill="#000000"
                              fillRule="nonzero"
                              transform="translate(9.999995, 12.000002) rotate(-180.000000) translate(-9.999995, -12.000002) "
                            />
                          </g>
                        </svg>
                      ) : (
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          fill="none"
                        >
                          <g
                            stroke="none"
                            strokeWidth="1"
                            fill="none"
                            fillRule="evenodd"
                          >
                            <rect x="0" y="0" width="24" height="24" />
                            <circle
                              fill="#000000"
                              opacity="0.3"
                              cx="12"
                              cy="12"
                              r="10"
                            />
                            <rect
                              fill="#000000"
                              x="11"
                              y="10"
                              width="2"
                              height="7"
                              rx="1"
                            />
                            <rect
                              fill="#000000"
                              x="11"
                              y="7"
                              width="2"
                              height="2"
                              rx="1"
                            />
                          </g>
                        </svg>
                      )}
                    </span>
                    <div className="d-flex flex-column">
                      <h4
                        className={classNames(
                          "mb-1 ",
                          {
                            "text-danger": ["cancelled", "refunded"].includes(
                              state.orderInfo?.status,
                            ),
                          },
                          {
                            "text-warning": [
                              "pending_payment",
                              "pending_verification",
                            ].includes(state.orderInfo?.status),
                          },
                          {
                            "text-primary":
                              state.orderInfo?.status === "completed",
                          },
                        )}
                      >
                        Order Status:{" "}
                      </h4>
                      <span>{`${props.passedOrder?.status}`}</span>
                      <div className="fs-7 text-muted">
                        Order ID: <b>{state.orderInfo.id}</b>
                      </div>
                    </div>
                  </div>
                  {state.errorMessage.length > 0 && (
                    <div className="alert alert-danger d-flex align-items-center p-5 mb-10 w-100">
                      <span className="svg-icon svg-icon-2hx svg-icon-danger me-4">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          fill="none"
                        >
                          <g
                            stroke="none"
                            strokeWidth="1"
                            fill="none"
                            fillRule="evenodd"
                          >
                            <rect x="0" y="0" width="24" height="24" />
                            <circle
                              fill="#000000"
                              opacity="0.3"
                              cx="12"
                              cy="12"
                              r="10"
                            />
                            <rect
                              fill="#000000"
                              x="11"
                              y="10"
                              width="2"
                              height="7"
                              rx="1"
                            />
                            <rect
                              fill="#000000"
                              x="11"
                              y="7"
                              width="2"
                              height="2"
                              rx="1"
                            />
                          </g>
                        </svg>
                      </span>
                      <div className="d-flex flex-column">
                        <h4 className="mb-1 text-danger">Error</h4>{" "}
                        <span>{state.errorMessage}</span>{" "}
                      </div>
                    </div>
                  )}
                  <div className="fv-row mt-8">
                    <div className="summary mt-10 p-7">
                      <div className="table-responsive">
                        <table className="table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4 my-0">
                          <tbody>
                            <tr>
                              <td className="ps-0">
                                {" "}
                                <span className="text-gray-800 fw-bolder text-hover-primary mb-1 fs-6 text-start pe-0">
                                  Buy Amount
                                </span>{" "}
                              </td>
                              <td>
                                {" "}
                                <span className="text-gray-800 fw-bolder d-block fs-6 ps-0 text-end">
                                  ${state.orderInfo.amount}
                                </span>{" "}
                              </td>
                            </tr>
                            <tr>
                              <td className="ps-0">
                                {" "}
                                <span className="text-gray-800 fw-bolder text-hover-primary mb-1 fs-6 text-start pe-0">
                                  Fees{" "}
                                  <small className="text-muted">
                                    ({state.orderInfo.feeType} priority)
                                  </small>
                                </span>{" "}
                              </td>
                              <td>
                                {" "}
                                <span className="text-gray-800 fw-bolder d-block fs-6 ps-0 text-end">
                                  ${state.orderInfo.fee}
                                </span>{" "}
                              </td>
                            </tr>
                            <tr>
                              <td className="ps-0">
                                {" "}
                                <span className="text-gray-800 fw-bolder text-hover-primary mb-1 fs-6 text-start pe-0">
                                  Rate
                                </span>{" "}
                              </td>
                              <td>
                                <span className="text-gray-800 fw-bolder d-block fs-6 ps-0 text-end">
                                  GHS {state.orderInfo.rate}
                                </span>
                              </td>
                            </tr>
                            <tr>
                              <td className="ps-0">
                                {" "}
                                <span className="text-gray-800 fw-bolder text-hover-primary mb-1 fs-6 text-start pe-0">
                                  Crypto Amount
                                </span>{" "}
                              </td>
                              <td>
                                <span className="text-gray-800 fw-bolder d-block fs-6 ps-0 text-end">
                                  {state.orderInfo.cryptoAmount}{" "}
                                  {state.orderInfo.asset}
                                </span>
                              </td>
                            </tr>
                            <tr>
                              <td className="ps-0">
                                {" "}
                                <span className="text-gray-800 fw-bolder text-hover-primary mb-1 fs-6 text-start pe-0">
                                  Order ID
                                </span>{" "}
                              </td>
                              <td>
                                <span className="text-gray-800 fw-bolder d-block fs-6 ps-0 text-end">
                                  {state.orderInfo.id}
                                </span>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                      <div className="mb-10 mt-5 px-9 text-center">
                        <span className="fs-6 fw-bold text-gray-400">
                          Total:
                        </span>
                        <div className="d-flex align-items-center mb-2 justify-content-center">
                          <span className="fs-4 fw-bold text-gray-400 align-self-start me-2 pr-5>">
                            GHS
                          </span>
                          <span className="fs-2hx fw-bolder text-gray-800 me-2 lh-1">
                            {(
                              parseFloat(
                                state.orderInfo.fee * state.orderInfo.rate,
                              ) +
                              parseInt(
                                state.orderInfo.amount * state.orderInfo.rate,
                              )
                            ).toFixed(2)}
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                  {(state.orderInfo?.status === "pending_payment") |
                    (state.orderInfo?.status === "pending") && (
                    <>
                      <div
                        className="pm_box mb-10 bg-light-primary p-10"
                        style={{ borderRadius: "15px" }}
                      >
                        <div className="form-label-group">
                          <label className="form-label">Send payment to:</label>
                        </div>
                        <label className="d-flex flex-stack mb-5 cursor-pointer">
                          <span className="d-flex align-items-center me-2">
                            <span className="symbol symbol-20px me-6">
                              <span className="symbol-label bg-light-primary">
                                <span className="svg-icon svg-icon-1 svg-icon-primary">
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="24"
                                    height="24"
                                    viewBox="0 0 24 24"
                                    fill="none"
                                  >
                                    <g
                                      stroke="none"
                                      strokeWidth="1"
                                      fill="none"
                                      fillRule="evenodd"
                                    >
                                      <rect
                                        x="0"
                                        y="0"
                                        width="24"
                                        height="24"
                                      />
                                      <path
                                        d="M7.5,4 L7.5,19 L16.5,19 L16.5,4 L7.5,4 Z M7.71428571,2 L16.2857143,2 C17.2324881,2 18,2.8954305 18,4 L18,20 C18,21.1045695 17.2324881,22 16.2857143,22 L7.71428571,22 C6.76751186,22 6,21.1045695 6,20 L6,4 C6,2.8954305 6.76751186,2 7.71428571,2 Z"
                                        fill="#000000"
                                        fillRule="nonzero"
                                      />
                                      <polygon
                                        fill="#000000"
                                        opacity="0.3"
                                        points="7.5 4 7.5 19 16.5 19 16.5 4"
                                      />
                                    </g>
                                  </svg>
                                </span>
                              </span>
                            </span>
                            <span className="d-flex flex-column">
                              <span className="fw-bolder fs-6">
                                {state.paymentMethod.network}
                              </span>
                              <span className="fs-7 text-muted">
                                Account Number:{" "}
                                <b className="text-dark">
                                  {state.HodlpayAccount.AccountNumber}
                                </b>
                              </span>
                              <span className="fs-7 text-muted">
                                Account Name:{" "}
                                <b className="text-dark">
                                  {state.HodlpayAccount.AccountName}
                                </b>
                              </span>
                              <br />
                              <small>
                                Send the sum of{" "}
                                <b
                                  className="text-primary"
                                  style={{ fontSize: "1.2rem" }}
                                >
                                  GHS{" "}
                                  {(
                                    parseFloat(
                                      state.orderInfo.fee *
                                        state.orderInfo.rate,
                                    ) +
                                    parseInt(
                                      state.orderInfo.amount *
                                        state.orderInfo.rate,
                                    )
                                  ).toFixed(2)}
                                </b>{" "}
                                to the account details above
                              </small>
                            </span>
                          </span>
                        </label>
                        <div className="form-group mt-8">
                          <label className="form-label">
                            Network Transaction Id:
                          </label>
                          <input
                            id="id"
                            type="text"
                            className="form-control"
                            value={state?.buyOrderConfirm.networkTransactionId}
                            onChange={(e) => {
                              updateForm(e, "networkTransactionId");
                            }}
                            placeholder="Enter your transaction id"
                          />
                          <br />
                        </div>
                      </div>
                      <div className="d-flex flex-center">
                        {state.isRequesting ? (
                          <>
                            <button
                              type="button"
                              className="btn btn-lg btn-primary me-2"
                              disabled
                            >
                              <span className="">
                                Please wait...
                                <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                              </span>
                            </button>
                            <button className="btn btn-lg btn-danger" disabled>
                              <span className="indicator-label">Cancel</span>
                            </button>
                            <button
                              className="btn btn-lg btn-secondary"
                              disabled
                            >
                              <span className="indicator-label">Close</span>
                            </button>
                          </>
                        ) : (
                          <>
                            <button
                              onClick={() => {
                                submitBuyOrderForm();
                              }}
                              className="btn btn-lg btn-primary me-2"
                            >
                              <span className="indicator-label">Submit</span>
                            </button>
                            <button
                              onClick={() => {
                                cancelOrder(state.orderInfo.id);
                              }}
                              className="btn btn-lg btn-danger me-2"
                            >
                              <span className="indicator-label">Cancel</span>
                            </button>
                            <button
                              className="btn btn-lg btn-secondary"
                              data-bs-dismiss="modal"
                              onClick={() => {
                                closeModal();
                              }}
                            >
                              <span className="indicator-label">Close</span>
                            </button>
                          </>
                        )}
                      </div>
                    </>
                  )}

                  {(state.orderInfo?.status === "approved" ||
                    state.orderInfo?.status === "cancelled" ||
                    state.orderInfo?.status === "refunded") && (
                    <>
                      <div
                        className="pm_box mb-10 bg-light-primary p-10"
                        style={{ borderRadius: "15px" }}
                      >
                        <div className="form-label-group">
                          <label className="form-label">Payment sent to:</label>
                        </div>
                        <label className="d-flex flex-stack mb-5 cursor-pointer">
                          <span className="d-flex align-items-center me-2">
                            <span className="symbol symbol-20px me-6">
                              <span className="symbol-label bg-light-primary">
                                <span className="svg-icon svg-icon-1 svg-icon-primary">
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="24"
                                    height="24"
                                    viewBox="0 0 24 24"
                                    fill="none"
                                  >
                                    <g
                                      stroke="none"
                                      strokeWidth="1"
                                      fill="none"
                                      fillRule="evenodd"
                                    >
                                      <rect
                                        x="0"
                                        y="0"
                                        width="24"
                                        height="24"
                                      />
                                      <path
                                        d="M7.5,4 L7.5,19 L16.5,19 L16.5,4 L7.5,4 Z M7.71428571,2 L16.2857143,2 C17.2324881,2 18,2.8954305 18,4 L18,20 C18,21.1045695 17.2324881,22 16.2857143,22 L7.71428571,22 C6.76751186,22 6,21.1045695 6,20 L6,4 C6,2.8954305 6.76751186,2 7.71428571,2 Z"
                                        fill="#000000"
                                        fillRule="nonzero"
                                      />
                                      <polygon
                                        fill="#000000"
                                        opacity="0.3"
                                        points="7.5 4 7.5 19 16.5 19 16.5 4"
                                      />
                                    </g>
                                  </svg>
                                </span>
                              </span>
                            </span>
                            <span className="d-flex flex-column">
                              <span className="fw-bolder fs-6">
                                {state.orderInfo.payment_type}
                              </span>
                              <span className="fs-7 text-muted">
                                Account Number:{" "}
                                <b>{state.HodlpayAccount.AccountNumber}</b>
                              </span>
                              <span className="fs-7 text-muted">
                                Account Name:{" "}
                                <b>{state.HodlpayAccount.AccountName}</b>
                              </span>
                            </span>
                          </span>
                        </label>
                        <div className="form-group mt-8">
                          <label className="form-label">
                            Payment Transaction Number:
                          </label>
                          <div className="fs-7 text-muted">
                            <b> {state.orderInfo.payment_transaction_number}</b>
                          </div>
                        </div>
                        <div className="separator separator-dashed my-4"></div>
                        <div className="form-group mt-8">
                          <label className="form-label">
                            Transaction Hash:
                          </label>
                          <div className="fs-7 text-muted">
                            <b> {state.orderInfo?.transactionId}</b>
                          </div>
                        </div>
                      </div>
                      <div className="d-flex flex-center">
                        <button
                          className="btn btn-lg btn-secondary"
                          data-bs-dismiss="modal"
                          onClick={() => {
                            closeModal();
                          }}
                        >
                          <span className="indicator-label">Close</span>
                        </button>
                      </div>
                    </>
                  )}
                </div>
              ) : (
                // Sell Starts Here ....
                <div className="sell_order_info">
                  <div className="text-center mb-9">
                    {state.orderInfo?.asset === "BTC" ? (
                      <img
                        src="/assets/media/svg/brand-logos/btc.svg"
                        className="h-80px align-self-center mb-8"
                        alt=""
                      />
                    ) : (
                      <img
                        src="/assets/media/svg/brand-logos/usdt.svg"
                        className="h-80px align-self-center mb-8"
                        alt=""
                      />
                    )}
                    <div>
                      <div className="fs-3x text-dark fw-bolder lh-1 mb-4">
                        ${state.orderInfo?.amount}
                      </div>
                      {/* <div className="fs-6 text-gray-600 fw-bold">Wallet Address: <b>{state.orderInfo?.receiving_address}</b></div> */}
                    </div>
                  </div>
                  <div
                    className={classNames(
                      "alert d-flex align-items-center p-5 mb-10 w-100",
                      {
                        "bg-light-danger": ["cancelled", "refunded"].includes(
                          state.orderInfo?.status,
                        ),
                      },
                      {
                        "bg-light-warning": [
                          "pending_payment",
                          "pending_transfer",
                          "pending_verification",
                          "pending_payout",
                          "pending",
                        ].includes(state.orderInfo?.status),
                      },
                      {
                        "bg-light-primary":
                          state.orderInfo?.status === "completed",
                      },
                    )}
                  >
                    <span
                      className={classNames(
                        "svg-icon svg-icon-2hx me-4",
                        {
                          "svg-icon-danger": ["cancelled", "refunded"].includes(
                            state.orderInfo?.status,
                          ),
                        },
                        {
                          "svg-icon-warning": [
                            "pending_payment",
                            "pending_transfer",
                            "pending_verification",
                            "pending_payout",
                            "pending",
                          ].includes(state.orderInfo?.status),
                        },
                        {
                          "svg-icon-primary":
                            state.orderInfo?.status === "completed",
                        },
                      )}
                    >
                      {state.orderInfo?.status === "completed" ? (
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          fill="none"
                        >
                          <g
                            stroke="none"
                            strokeWidth="1"
                            fill="none"
                            fillRule="evenodd"
                          >
                            <polygon points="0 0 24 0 24 24 0 24" />
                            <path
                              d="M9.26193932,16.6476484 C8.90425297,17.0684559 8.27315905,17.1196257 7.85235158,16.7619393 C7.43154411,16.404253 7.38037434,15.773159 7.73806068,15.3523516 L16.2380607,5.35235158 C16.6013618,4.92493855 17.2451015,4.87991302 17.6643638,5.25259068 L22.1643638,9.25259068 C22.5771466,9.6195087 22.6143273,10.2515811 22.2474093,10.6643638 C21.8804913,11.0771466 21.2484189,11.1143273 20.8356362,10.7474093 L17.0997854,7.42665306 L9.26193932,16.6476484 Z"
                              fill="#000000"
                              fillRule="nonzero"
                              opacity="0.3"
                              transform="translate(14.999995, 11.000002) rotate(-180.000000) translate(-14.999995, -11.000002) "
                            />
                            <path
                              d="M4.26193932,17.6476484 C3.90425297,18.0684559 3.27315905,18.1196257 2.85235158,17.7619393 C2.43154411,17.404253 2.38037434,16.773159 2.73806068,16.3523516 L11.2380607,6.35235158 C11.6013618,5.92493855 12.2451015,5.87991302 12.6643638,6.25259068 L17.1643638,10.2525907 C17.5771466,10.6195087 17.6143273,11.2515811 17.2474093,11.6643638 C16.8804913,12.0771466 16.2484189,12.1143273 15.8356362,11.7474093 L12.0997854,8.42665306 L4.26193932,17.6476484 Z"
                              fill="#000000"
                              fillRule="nonzero"
                              transform="translate(9.999995, 12.000002) rotate(-180.000000) translate(-9.999995, -12.000002) "
                            />
                          </g>
                        </svg>
                      ) : (
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          fill="none"
                        >
                          <g
                            stroke="none"
                            strokeWidth="1"
                            fill="none"
                            fillRule="evenodd"
                          >
                            <rect x="0" y="0" width="24" height="24" />
                            <circle
                              fill="#000000"
                              opacity="0.3"
                              cx="12"
                              cy="12"
                              r="10"
                            />
                            <rect
                              fill="#000000"
                              x="11"
                              y="10"
                              width="2"
                              height="7"
                              rx="1"
                            />
                            <rect
                              fill="#000000"
                              x="11"
                              y="7"
                              width="2"
                              height="2"
                              rx="1"
                            />
                          </g>
                        </svg>
                      )}
                    </span>
                    <div className="d-flex flex-column">
                      <h4
                        className={classNames(
                          "mb-1 ",
                          {
                            "text-danger": ["cancelled", "refunded"].includes(
                              state.orderInfo?.status,
                            ),
                          },
                          {
                            "text-warning": [
                              "pending_payment",
                              "pending_transfer",
                              "pending_verification",
                              "pending_payout",
                              "pending",
                            ].includes(state.orderInfo?.status),
                          },
                          {
                            "text-primary":
                              state.orderInfo?.status === "completed",
                          },
                        )}
                      >
                        Order Status:{" "}
                      </h4>
                      <span>{`${props.passedOrder?.status}`}</span>
                      <div className="fs-7 text-muted">
                        Order ID: <b>{state.orderInfo.id}</b>
                      </div>
                    </div>
                  </div>
                  {state.errorMessage.length > 0 && (
                    <div className="alert alert-danger d-flex align-items-center p-5 mb-10 w-100">
                      <span className="svg-icon svg-icon-2hx svg-icon-danger me-4">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          fill="none"
                        >
                          <g
                            stroke="none"
                            strokeWidth="1"
                            fill="none"
                            fillRule="evenodd"
                          >
                            <rect x="0" y="0" width="24" height="24" />
                            <circle
                              fill="#000000"
                              opacity="0.3"
                              cx="12"
                              cy="12"
                              r="10"
                            />
                            <rect
                              fill="#000000"
                              x="11"
                              y="10"
                              width="2"
                              height="7"
                              rx="1"
                            />
                            <rect
                              fill="#000000"
                              x="11"
                              y="7"
                              width="2"
                              height="2"
                              rx="1"
                            />
                          </g>
                        </svg>
                      </span>
                      <div className="d-flex flex-column">
                        <h4 className="mb-1 text-danger">Error</h4>{" "}
                        <span>{state.errorMessage}</span>{" "}
                      </div>
                    </div>
                  )}
                  <div className="fv-row mt-8">
                    <div className="summary mt-10 p-7">
                      <div className="table-responsive">
                        <table className="table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4 my-0">
                          <tbody>
                            <tr>
                              <td className="ps-0">
                                {" "}
                                <span className="text-gray-800 fw-bolder text-hover-primary mb-1 fs-6 text-start pe-0">
                                  Sell Amount
                                </span>{" "}
                              </td>
                              <td>
                                {" "}
                                <span className="text-gray-800 fw-bolder d-block fs-6 ps-0 text-end">
                                  ${state.orderInfo?.amount}
                                </span>{" "}
                              </td>
                            </tr>
                            <tr>
                              <td className="ps-0">
                                {" "}
                                <span className="text-gray-800 fw-bolder text-hover-primary mb-1 fs-6 text-start pe-0">
                                  Rate
                                </span>{" "}
                              </td>
                              <td>
                                {" "}
                                <span className="text-gray-800 fw-bolder d-block fs-6 ps-0 text-end">
                                  GHS {state.orderInfo?.rate}
                                </span>{" "}
                              </td>
                            </tr>
                            <tr>
                              <td className="ps-0">
                                {" "}
                                <span className="text-gray-800 fw-bolder text-hover-primary mb-1 fs-6 text-start pe-0">
                                  Crypto Amount
                                </span>{" "}
                              </td>
                              <td>
                                <span className="text-gray-800 fw-bolder d-block fs-6 ps-0 text-end">
                                  {state.orderInfo.cryptoAmount}{" "}
                                  {state.orderInfo.asset}
                                </span>
                              </td>
                            </tr>
                            <tr>
                              <td className="ps-0">
                                {" "}
                                <span className="text-gray-800 fw-bolder text-hover-primary mb-1 fs-6 text-start pe-0">
                                  Order ID
                                </span>{" "}
                              </td>
                              <td>
                                <span className="text-gray-800 fw-bolder d-block fs-6 ps-0 text-end">
                                  {state.orderInfo.id}
                                </span>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                      <div className="mb-10 mt-5 px-9 text-center">
                        <span className="fs-6 fw-bold text-gray-400">
                          Total Payout Amount:
                        </span>
                        <div className="d-flex align-items-center mb-2 justify-content-center">
                          <span className="fs-4 fw-bold text-gray-400 align-self-start me-2 pr-5>">
                            GHS
                          </span>
                          <span className="fs-2hx fw-bolder text-gray-800 me-2 lh-1">
                            {(
                              state.orderInfo.amount * state.orderInfo.rate
                            )?.toFixed(2)}
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                  {state.orderInfo?.status?.includes("waiting") && (
                    <>
                      <div
                        className="pm_box mb-10 bg-light-primary p-10"
                        style={{ borderRadius: "15px" }}
                      >
                        <div className="form-label-group">
                          <label className="form-label">
                            Send Transfer to:
                          </label>
                        </div>
                        <label className="d-flex flex-stack mb-5 cursor-pointer">
                          <span className="d-flex align-items-center me-2">
                            <span className="symbol symbol-20px me-6">
                              <span className="symbol-label bg-light-primary">
                                <span className="svg-icon svg-icon-1 svg-icon-primary">
                                  {state.orderInfo?.asset === "BTC" ? (
                                    <img
                                      src="/assets/media/svg/brand-logos/btc.svg"
                                      className="h-30px align-self-center"
                                      alt=""
                                    />
                                  ) : (
                                    <img
                                      src="/assets/media/svg/brand-logos/usdt.svg"
                                      className="h-30px align-self-center"
                                      alt=""
                                    />
                                  )}
                                </span>
                              </span>
                            </span>
                            <span className="d-flex flex-column">
                              <span className="fs-7 text-muted">
                                {state.orderInfo?.asset} Wallet
                              </span>
                              <div className="d-flex align-items-center flex-wrap">
                                <div
                                  id="wallet_address"
                                  className="fw-bolder fs-6 me-5"
                                >
                                  {state.orderInfo?.transaction.walletAddress}
                                </div>
                                <button
                                  className="btn btn-bg-white btn-sm btn-icon-gray-600 btn-text-gray-600"
                                  onClick={() => {
                                    window.copyText(
                                      state.orderInfo?.transaction
                                        .walletAddress,
                                    );
                                    copiedText();
                                  }}
                                >
                                  <span className="svg-icon svg-icon-1">
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      width="24"
                                      height="24"
                                      viewBox="0 0 24 24"
                                      fill="none"
                                    >
                                      <path
                                        opacity="0.5"
                                        d="M18 2H9C7.34315 2 6 3.34315 6 5H8C8 4.44772 8.44772 4 9 4H18C18.5523 4 19 4.44772 19 5V16C19 16.5523 18.5523 17 18 17V19C19.6569 19 21 17.6569 21 16V5C21 3.34315 19.6569 2 18 2Z"
                                        fill="black"
                                      ></path>
                                      <path
                                        fillRule="evenodd"
                                        clipRule="evenodd"
                                        d="M14.7857 7.125H6.21429C5.62255 7.125 5.14286 7.6007 5.14286 8.1875V18.8125C5.14286 19.3993 5.62255 19.875 6.21429 19.875H14.7857C15.3774 19.875 15.8571 19.3993 15.8571 18.8125V8.1875C15.8571 7.6007 15.3774 7.125 14.7857 7.125ZM6.21429 5C4.43908 5 3 6.42709 3 8.1875V18.8125C3 20.5729 4.43909 22 6.21429 22H14.7857C16.5609 22 18 20.5729 18 18.8125V8.1875C18 6.42709 16.5609 5 14.7857 5H6.21429Z"
                                        fill="black"
                                      ></path>
                                    </svg>
                                  </span>
                                  {state.copied ? `Copied` : `Copy`}
                                </button>
                              </div>
                            </span>
                          </span>
                        </label>
                        <div className="separator separator-dashed my-4"></div>
                        <div>
                          <div className="form-label-group">
                            <label className="form-label">
                              Payout Account:
                            </label>
                          </div>
                          <span className="d-flex flex-column">
                            <span className="fs-7 text-muted">
                              Account Type:{" "}
                              {state.orderInfo?.paymentMethod?.branch
                                ? state.paymentMethod?.type
                                : "Mobile Money"}{" "}
                              -{" "}
                              <b>
                                {state.orderInfo?.paymentMethod?.branch
                                  ? state.paymentMethod?.payout_type_name
                                  : state.paymentMethod?.network}
                              </b>
                            </span>
                            <span className="fs-7 text-muted">
                              Account Number:{" "}
                              <b>{state.paymentMethod.accountNumber}</b>
                            </span>
                            <span className="fs-7 text-muted">
                              Account Name:{" "}
                              <b>{state.paymentMethod.accountName}</b>
                            </span>
                            {state.paymentMethod?.branch && (
                              <span className="fs-7 text-muted">
                                Account Branch:{" "}
                                <b>{state.orderInfo?.paymentMethod?.branch}</b>
                              </span>
                            )}
                          </span>
                        </div>
                        {/* <div className="separator separator-dashed my-4"></div>
                      <div className="form-group mt-8">
                        <label className="form-label">Transaction Hash:</label>
                        <input type="text" className="form-control" value={state?.transactionId} onChange={(e) => {updateForm(e)}}/>
                      </div> */}
                      </div>
                      {/* <div className="d-flex flex-center">
                      {
                        state.isRequesting ?
                        <>
                          <button type="button" className="btn btn-lg btn-primary me-2" disabled>
                            <span className="">Please wait... 
                            <span className="spinner-border spinner-border-sm align-middle ms-2"></span></span>
                          </button>
                          <button className="btn btn-lg btn-secondary" disabled>
                            <span className="indicator-label">Close</span>
                          </button>
                        </>
                        :
                        <>
                          <button onClick={() => {submitSellOrderForm()}} className="btn btn-lg btn-primary me-2">
                            <span className="indicator-label">Submit</span>
                          </button>
                          <button className="btn btn-lg btn-secondary" data-bs-dismiss="modal" onClick={() => {closeModal()}}>
                            <span className="indicator-label">Close</span>
                          </button>
                        </>
                      }
                    </div> */}
                      <div className="d-flex flex-center">
                        <button
                          type="button"
                          className="btn btn-lg btn-secondary"
                          data-bs-dismiss="modal"
                          onClick={handlecloseModal}
                        >
                          Close
                        </button>
                        <div>
                          {state.orderInfo?.status === "approved" && (
                            <button
                              onClick={() =>
                                completeOrder(
                                  "sell",
                                  "completed",
                                  state.orderInfo?.id,
                                )
                              }
                              className="btn btn-danger me-2"
                              style={{ margin: "0 0.5rem 0 0.5rem" }}
                            >
                              Complete
                            </button>
                          )}
                        </div>
                      </div>
                    </>
                  )}

                  {(state.orderInfo?.status === "pending_verification" ||
                    state.orderInfo?.status === "pending_payout" ||
                    state.orderInfo?.status === "pending") && (
                    <div
                      className="pm_box mb-10 bg-light-primary p-10"
                      style={{ borderRadius: "15px" }}
                    >
                      <div className="form-label-group">
                        <label className="form-label">Transfer sent to:</label>
                      </div>
                      <label className="d-flex flex-stack mb-5 cursor-pointer">
                        <span className="d-flex align-items-center me-2">
                          <span className="symbol symbol-20px me-6">
                            <span className="symbol-label bg-light-primary">
                              <span className="svg-icon svg-icon-1 svg-icon-primary">
                                {state.orderInfo?.currency === "Bitcoin" ? (
                                  <img
                                    src="/assets/media/svg/brand-logos/btc.svg"
                                    className="h-30px align-self-center"
                                    alt=""
                                  />
                                ) : (
                                  <img
                                    src="/assets/media/svg/brand-logos/usdt.svg"
                                    className="h-30px align-self-center"
                                    alt=""
                                  />
                                )}
                              </span>
                            </span>
                          </span>
                          <span className="d-flex flex-column">
                            <span className="fs-7 text-muted">
                              {state.orderInfo?.currency} Wallet
                            </span>
                            <div className="d-flex align-items-center flex-wrap">
                              <div
                                id="wallet_address"
                                className="fw-bolder fs-6 me-5"
                              >
                                {state.orderInfo?.receiving_address}
                              </div>
                              <button
                                className="btn btn-bg-white btn-sm btn-icon-gray-600 btn-text-gray-600"
                                onClick={() => {
                                  window.copyText(
                                    state.orderInfo?.receiving_address,
                                  );
                                  copiedText();
                                }}
                              >
                                <span className="svg-icon svg-icon-1">
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="24"
                                    height="24"
                                    viewBox="0 0 24 24"
                                    fill="none"
                                  >
                                    <path
                                      opacity="0.5"
                                      d="M18 2H9C7.34315 2 6 3.34315 6 5H8C8 4.44772 8.44772 4 9 4H18C18.5523 4 19 4.44772 19 5V16C19 16.5523 18.5523 17 18 17V19C19.6569 19 21 17.6569 21 16V5C21 3.34315 19.6569 2 18 2Z"
                                      fill="black"
                                    ></path>
                                    <path
                                      fillRule="evenodd"
                                      clipRule="evenodd"
                                      d="M14.7857 7.125H6.21429C5.62255 7.125 5.14286 7.6007 5.14286 8.1875V18.8125C5.14286 19.3993 5.62255 19.875 6.21429 19.875H14.7857C15.3774 19.875 15.8571 19.3993 15.8571 18.8125V8.1875C15.8571 7.6007 15.3774 7.125 14.7857 7.125ZM6.21429 5C4.43908 5 3 6.42709 3 8.1875V18.8125C3 20.5729 4.43909 22 6.21429 22H14.7857C16.5609 22 18 20.5729 18 18.8125V8.1875C18 6.42709 16.5609 5 14.7857 5H6.21429Z"
                                      fill="black"
                                    ></path>
                                  </svg>
                                </span>
                                {state.copied ? `Copied` : `Copy`}
                              </button>
                            </div>
                          </span>
                        </span>
                      </label>
                      <div className="form-group mt-8">
                        <label className="form-label">Transaction Hash:</label>
                        <div className="fs-7 text-muted">
                          <b> {state.orderInfo?.transactionId}</b>
                        </div>
                      </div>
                      <div className="separator separator-dashed my-4"></div>
                      <div>
                        <div className="form-label-group">
                          <label className="form-label">Payout Account:</label>
                        </div>
                        <span className="d-flex flex-column">
                          <span className="fs-7 text-muted">
                            Account Type: {state.orderInfo?.payout_type} -{" "}
                            <b>{state.orderInfo?.payout_account_type}</b>
                          </span>
                          <span className="fs-7 text-muted">
                            Account Number:{" "}
                            <b>{state.orderInfo?.payout_account_number}</b>
                          </span>
                          <span className="fs-7 text-muted">
                            Account Name:{" "}
                            <b>{state.orderInfo?.payout_account_name}</b>
                          </span>
                          <span className="fs-7 text-muted">
                            Account Branch:{" "}
                            <b>{state.orderInfo?.payout_account_branch}</b>
                          </span>
                        </span>
                      </div>
                    </div>
                  )}

                  {(state.orderInfo?.status === "approved" ||
                    state.orderInfo?.status === "cancelled" ||
                    state.orderInfo?.status === "refunded") && (
                    <div
                      className="pm_box mb-10 bg-light-primary p-10"
                      style={{ borderRadius: "15px" }}
                    >
                      <div className="form-label-group">
                        <label className="form-label">Transfer sent to:</label>
                      </div>
                      <label className="d-flex flex-stack mb-5 cursor-pointer">
                        <span className="d-flex align-items-center me-2">
                          <span className="symbol symbol-20px me-6">
                            <span className="symbol-label bg-light-primary">
                              <span className="svg-icon svg-icon-1 svg-icon-primary">
                                {state.orderInfo?.asset === "BTC" ? (
                                  <img
                                    src="/assets/media/svg/brand-logos/btc.svg"
                                    className="h-30px align-self-center"
                                    alt=""
                                  />
                                ) : (
                                  <img
                                    src="/assets/media/svg/brand-logos/usdt.svg"
                                    className="h-30px align-self-center"
                                    alt=""
                                  />
                                )}
                              </span>
                            </span>
                          </span>
                          <span className="d-flex flex-column">
                            <span className="fs-7 text-muted">
                              {state.orderInfo?.asset} Wallet
                            </span>
                            <div className="d-flex align-items-center flex-wrap">
                              <div
                                id="wallet_address"
                                className="fw-bolder fs-6 me-5"
                              >
                                {state.orderInfo?.transaction.walletAddress}
                              </div>
                              <button
                                className="btn btn-bg-white btn-sm btn-icon-gray-600 btn-text-gray-600"
                                onClick={() => {
                                  window.copyText(
                                    state.orderInfo?.transaction.walletAddress,
                                  );
                                  copiedText();
                                }}
                              >
                                <span className="svg-icon svg-icon-1">
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="24"
                                    height="24"
                                    viewBox="0 0 24 24"
                                    fill="none"
                                  >
                                    <path
                                      opacity="0.5"
                                      d="M18 2H9C7.34315 2 6 3.34315 6 5H8C8 4.44772 8.44772 4 9 4H18C18.5523 4 19 4.44772 19 5V16C19 16.5523 18.5523 17 18 17V19C19.6569 19 21 17.6569 21 16V5C21 3.34315 19.6569 2 18 2Z"
                                      fill="black"
                                    ></path>
                                    <path
                                      fillRule="evenodd"
                                      clipRule="evenodd"
                                      d="M14.7857 7.125H6.21429C5.62255 7.125 5.14286 7.6007 5.14286 8.1875V18.8125C5.14286 19.3993 5.62255 19.875 6.21429 19.875H14.7857C15.3774 19.875 15.8571 19.3993 15.8571 18.8125V8.1875C15.8571 7.6007 15.3774 7.125 14.7857 7.125ZM6.21429 5C4.43908 5 3 6.42709 3 8.1875V18.8125C3 20.5729 4.43909 22 6.21429 22H14.7857C16.5609 22 18 20.5729 18 18.8125V8.1875C18 6.42709 16.5609 5 14.7857 5H6.21429Z"
                                      fill="black"
                                    ></path>
                                  </svg>
                                </span>
                                {state.copied ? `Copied` : `Copy`}
                              </button>
                            </div>
                          </span>
                        </span>
                      </label>
                      <div className="form-group mt-8">
                        <label className="form-label">Transaction Hash:</label>
                        <div className="fs-7 text-muted">
                          <b> {state.orderInfo?.transactionId}</b>
                        </div>
                      </div>
                      <div className="separator separator-dashed my-4"></div>
                      <div>
                        <div className="form-label-group">
                          <label className="form-label">Payout Account:</label>
                        </div>
                        <span className="d-flex flex-column">
                          <span className="fs-7 text-muted">
                            Account Type:{" "}
                            {state.orderInfo?.paymentMethod?.branch
                              ? state.paymentMethod?.type
                              : "Mobile Money"}{" "}
                            -{" "}
                            <b>
                              {state.orderInfo?.paymentMethod?.branch
                                ? state.paymentMethod?.payout_type_name
                                : state.paymentMethod?.network}
                            </b>
                          </span>
                          <span className="fs-7 text-muted">
                            Account Number:{" "}
                            <b>{state.paymentMethod.accountNumber}</b>
                          </span>
                          <span className="fs-7 text-muted">
                            Account Name:{" "}
                            <b>{state.paymentMethod.accountName}</b>
                          </span>
                          {state.paymentMethod?.branch && (
                            <span className="fs-7 text-muted">
                              Account Branch:{" "}
                              <b>{state.orderInfo?.paymentMethod?.branch}</b>
                            </span>
                          )}
                        </span>
                      </div>
                      <div className="form-group mt-8">
                        <label className="form-label">
                          Payout Transaction Number:
                        </label>
                        <div className="fs-7 text-muted">
                          <b> {state.paymentMethod.accountNumber}</b>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              )}
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default OrderInfo;
