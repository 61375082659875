import axios from "axios";
import { reactLocalStorage } from "reactjs-localstorage";
import Constants from "./Constants";

export default async function refreshToken(loc) {
  try {
    const data = {
      refreshToken: reactLocalStorage.get("refresh_token"),
    };

    const res = await axios.post(
      Constants.API_BASE_URL + "/refresh/token",
      data,
    );

    const newToken = res.data.token;
    const newRefreshToken = res.data.refreshToken;

    reactLocalStorage.set("access_token", newToken);
    reactLocalStorage.set("admin_access_token", newToken);
    reactLocalStorage.set("refresh_token", newRefreshToken);

    // return newToken;
  } catch (error) {
    console.log(error);
    if (loc === "admin") {
      window.location = "/admin/login";
    } else {
      // window.location = "/logout";
    }
    throw error;
  }
}
