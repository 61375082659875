import React, { useContext } from "react";
import Context from "../../store/Context";
import logo from "../../iamges/logo1.png";
import { ACTIONS } from "../../store/useGlobalState";
import style from "./style.module.css";

function Modal({ content, head }) {
  const { state, setState } = useContext(Context);
  const handleCloseModal = () => {
    if (state.modal.closeAlert) {
      if (window.confirm(`${state.modal.closeAlert}`)) {
        setState({ type: ACTIONS.modalOff });
      }
    } else {
      setState({ type: ACTIONS.modalOff });
    }
  };
  const ModalContent = () => content;
  return (
    <div
      className="flex j-c-c top-0 left-0 right-0 bottom-0 scaleanimate"
      style={{ zIndex: "110", position: "fixed" }}
    >
      <div
        className={`${style.modalBackDrop} modalBackDrop`}
        onClick={() => handleCloseModal()}
        style={{ width: "100%", height: "100%" }}
      ></div>

      <div
        className={`${style.modalContent} absolute flex v-flex`}
        style={{
          width: "450px",
          maxHeight: "80%",
          overflowY: "auto",
          borderRadius: "10px",
        }}
      >
        <div className="w-100 flex j-c-end p-3">
          <b
            className="text-white mb-0"
            onClick={() => setState({ type: ACTIONS.modalOff })}
            style={{ cursor: "pointer" }}
          >
            CLOSE
          </b>
        </div>
        <div
          className="shadow flex v-flex p-4 pt-5"
          style={{
            minWidth: "100%",
            height: "100%",
            background: "white",
            borderRadius: "10px",
            overflowY: "auto",
          }}
        >
          <img
            width={"100px"}
            style={{ borderRadius: "10px", margin: "0 auto 2rem auto" }}
            className="shadow"
            src={logo}
            alt=""
          />
          <div style={{ height: "fit-content", width: "100%" }} className="p-2">
            <div className="mb-2">{head}</div>
            <ModalContent />
          </div>
        </div>
      </div>
    </div>
  );
}

export default Modal;
