import React, { Fragment } from "react";
import classNames from "classnames";
import AuthService from "../../../misc/AuthService";
class SideNav extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showAcc: false,
      adminInfo: AuthService.getAdminAuth().admin,
    };
  }
  componentDidMount() {
    if (this.state.showAcc) {
      window.addEventListener("click", (e) => {
        setTimeout(() => {
          if (e.target.id !== "profilePop") {
            this.setState({ ...this.state, showAcc: false });
          }
        }, 500);
      });
    }
  }
  render() {
    window.addEventListener("resize", () =>
      document.getElementById("closeSec")?.click()
    );

    return (
      <Fragment>
        <div className={`Mainnavbar flex ${this.props.className}`}>
          <section
            style={{
              height: "100vh",
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-between",
            }}
            className="mainSide v-flex a-i-c"
          >
            <div
              className="sec1 aside-logo flex-column-auto pt-10 pt-lg-20"
              id="kt_aside_logo"
            >
              <a href="/admin/dashboard">
                <img
                  alt="Logo"
                  src="/assets/media/logos/hodlpay_icon.svg"
                  className="h-60px"
                />
              </a>
            </div>

            <div
              className="sec2 aside-menu flex-column-fluid pt-0 pb-7 py-lg-10"
              id="kt_aside_menu"
            >
              <div
                id="kt_aside_menu_wrapper"
                className="w-100 hover-scroll-overlay-y scroll-ps d-flex"
                data-kt-scroll="true"
                data-kt-scroll-height="auto"
                data-kt-scroll-dependencies="#kt_aside_logo, #kt_aside_footer"
                data-kt-scroll-wrappers="#kt_aside, #kt_aside_menu"
                data-kt-scroll-offset="0"
              >
                <div
                  id="kt_aside_menu"
                  className="menu menu-column menu-title-gray-600 menu-state-primary menu-state-icon-primary menu-state-bullet-primary menu-icon-gray-400 menu-arrow-gray-400 fw-bold fs-6 my-auto"
                  data-kt-menu="true"
                >
                  <div
                    className={classNames("menu-item py-3", {
                      "here show": this.props.active === "dashboard",
                    })}
                  >
                    <span
                      className="menu-link"
                      title="Dashboard"
                      data-bs-toggle="tooltip"
                      data-bs-trigger="hover"
                      data-bs-dismiss="click"
                      data-bs-placement="right"
                    >
                      <a href="/admin/dashboard" className="menu-icon">
                        <span className="svg-icon svg-icon-2x">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            fill="none"
                          >
                            <rect
                              x="2"
                              y="2"
                              width="9"
                              height="9"
                              rx="2"
                              fill="black"
                            />
                            <rect
                              opacity="0.3"
                              x="13"
                              y="2"
                              width="9"
                              height="9"
                              rx="2"
                              fill="black"
                            />
                            <rect
                              opacity="0.3"
                              x="13"
                              y="13"
                              width="9"
                              height="9"
                              rx="2"
                              fill="black"
                            />
                            <rect
                              opacity="0.3"
                              x="2"
                              y="13"
                              width="9"
                              height="9"
                              rx="2"
                              fill="black"
                            />
                          </svg>
                        </span>
                      </a>
                    </span>
                  </div>

                  <div
                    className={classNames("menu-item py-3", {
                      "here show": this.props.active === "orders",
                    })}
                  >
                    <span
                      className="menu-link"
                      title="Orders"
                      data-bs-toggle="tooltip"
                      data-bs-trigger="hover"
                      data-bs-dismiss="click"
                      data-bs-placement="right"
                    >
                      <a href="/admin/orders" className="menu-icon">
                        <span className="svg-icon svg-icon-2x">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            fill="none"
                          >
                            <g
                              stroke="none"
                              strokeWidth="1"
                              fill="none"
                              fillRule="evenodd"
                            >
                              <rect x="0" y="0" width="24" height="24" />
                              <rect
                                fill="#000000"
                                opacity="0.3"
                                x="2"
                                y="2"
                                width="10"
                                height="12"
                                rx="2"
                              />
                              <path
                                d="M4,6 L20,6 C21.1045695,6 22,6.8954305 22,8 L22,20 C22,21.1045695 21.1045695,22 20,22 L4,22 C2.8954305,22 2,21.1045695 2,20 L2,8 C2,6.8954305 2.8954305,6 4,6 Z M18,16 C19.1045695,16 20,15.1045695 20,14 C20,12.8954305 19.1045695,12 18,12 C16.8954305,12 16,12.8954305 16,14 C16,15.1045695 16.8954305,16 18,16 Z"
                                fill="#000000"
                              />
                            </g>
                          </svg>
                        </span>
                      </a>
                    </span>
                  </div>
                  <div
                    className={classNames("menu-item py-3", {
                      "here show": this.props.active === "users",
                    })}
                  >
                    <span
                      className="menu-link"
                      title="Users"
                      data-bs-toggle="tooltip"
                      data-bs-trigger="hover"
                      data-bs-dismiss="click"
                      data-bs-placement="right"
                    >
                      <a href="/admin/users" className="menu-icon">
                        <span className="svg-icon svg-icon-2x">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            fill="none"
                          >
                            <path
                              d="M16.0173 9H15.3945C14.2833 9 13.263 9.61425 12.7431 10.5963L12.154 11.7091C12.0645 11.8781 12.1072 12.0868 12.2559 12.2071L12.6402 12.5183C13.2631 13.0225 13.7556 13.6691 14.0764 14.4035L14.2321 14.7601C14.2957 14.9058 14.4396 15 14.5987 15H18.6747C19.7297 15 20.4057 13.8774 19.912 12.945L18.6686 10.5963C18.1487 9.61425 17.1285 9 16.0173 9Z"
                              fill="black"
                            />
                            <rect
                              opacity="0.3"
                              x="14"
                              y="4"
                              width="4"
                              height="4"
                              rx="2"
                              fill="black"
                            />
                            <path
                              d="M4.65486 14.8559C5.40389 13.1224 7.11161 12 9 12C10.8884 12 12.5961 13.1224 13.3451 14.8559L14.793 18.2067C15.3636 19.5271 14.3955 21 12.9571 21H5.04292C3.60453 21 2.63644 19.5271 3.20698 18.2067L4.65486 14.8559Z"
                              fill="black"
                            />
                            <rect
                              opacity="0.3"
                              x="6"
                              y="5"
                              width="6"
                              height="6"
                              rx="3"
                              fill="black"
                            />
                          </svg>
                        </span>
                      </a>
                    </span>
                  </div>

                  <div
                    className={classNames("menu-item py-3", {
                      "here show": this.props.active === "swap",
                    })}
                  >
                    <span
                      className="menu-link"
                      title="Swap Transactions"
                      data-bs-toggle="tooltip"
                      data-bs-trigger="hover"
                      data-bs-dismiss="click"
                      data-bs-placement="right"
                    >
                      <a href="/admin/swap" className="menu-icon">
                        <span
                          className={classNames("svg-icon svg-icon-2x", {
                            "text-blue-500": this.props.active === "swap",
                            "text-gray-500": this.props.active !== "swap",
                          })}
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            fill="none"
                          >
                            <path
                              d="M10 5L10 19M10 5L6 9M10 5L14 9"
                              stroke="currentColor"
                              strokeWidth="2"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            />
                            <path
                              d="M18 19L18 5M18 19L22 15M18 19L14 15"
                              stroke="currentColor"
                              strokeWidth="2"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            />
                          </svg>
                        </span>
                      </a>
                    </span>
                  </div>

                  
                  <div
                    className={classNames("menu-item py-3", {
                      "here show": this.props.active === "referral",
                    })}
                  >
                    <span
                      className="menu-link"
                      title="Referral"
                      data-bs-toggle="tooltip"
                      data-bs-trigger="hover"
                      data-bs-dismiss="click"
                      data-bs-placement="right"
                    >
                      <a href="/admin/referral" className="menu-icon">
                        <span
                          className={classNames("svg-icon svg-icon-2x", {
                            "text-blue-500": this.props.active === "referral",
                            "text-gray-500": this.props.active !== "referral",
                          })}
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            fill="none"
                            style={{ transform: "rotate(-45deg)" }}
                          >
                            <path
                              d="M2 21L23 12L2 3V10L17 12L2 14V21Z"
                              fill="currentColor"
                            />
                          </svg>
                        </span>
                      </a>
                    </span>
                  </div>
                  <div
                    className={classNames("menu-item py-3", {
                      "here show": this.props.active === "leaderboard",
                    })}
                  >
                    <span
                      className="menu-link"
                      title="LeaderBoard"
                      data-bs-toggle="tooltip"
                      data-bs-trigger="hover"
                      data-bs-dismiss="click"
                      data-bs-placement="right"
                    >
                      <a href="/admin/leaderboard" className="menu-icon">
                        <span
                          className={classNames("svg-icon svg-icon-2x", {
                            "text-blue-500":
                              this.props.active === "leaderboard",
                            "text-gray-500":
                              this.props.active !== "leaderboard",
                          })}
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            fill="none"
                          >
                            <path
                              d="M10 3H8V15H10V3ZM16 7H14V15H16V7ZM22 11H20V15H22V11ZM4 11H2V15H4V11Z"
                              fill="currentColor"
                            />
                          </svg>
                        </span>
                      </a>
                    </span>
                  </div>
                  <div
                    className={classNames("menu-item py-3", {
                      "here show": this.props.active === "settings",
                    })}
                  >
                    <span
                      className="menu-link"
                      title="System Settings"
                      data-bs-toggle="tooltip"
                      data-bs-trigger="hover"
                      data-bs-dismiss="click"
                      data-bs-placement="right"
                    >
                      <a href="/admin/settings" className="menu-icon">
                        <span className="svg-icon svg-icon-2x">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            fill="none"
                          >
                            <path
                              opacity="0.3"
                              d="M22.1 11.5V12.6C22.1 13.2 21.7 13.6 21.2 13.7L19.9 13.9C19.7 14.7 19.4 15.5 18.9 16.2L19.7 17.2999C20 17.6999 20 18.3999 19.6 18.7999L18.8 19.6C18.4 20 17.8 20 17.3 19.7L16.2 18.9C15.5 19.3 14.7 19.7 13.9 19.9L13.7 21.2C13.6 21.7 13.1 22.1 12.6 22.1H11.5C10.9 22.1 10.5 21.7 10.4 21.2L10.2 19.9C9.4 19.7 8.6 19.4 7.9 18.9L6.8 19.7C6.4 20 5.7 20 5.3 19.6L4.5 18.7999C4.1 18.3999 4.1 17.7999 4.4 17.2999L5.2 16.2C4.8 15.5 4.4 14.7 4.2 13.9L2.9 13.7C2.4 13.6 2 13.1 2 12.6V11.5C2 10.9 2.4 10.5 2.9 10.4L4.2 10.2C4.4 9.39995 4.7 8.60002 5.2 7.90002L4.4 6.79993C4.1 6.39993 4.1 5.69993 4.5 5.29993L5.3 4.5C5.7 4.1 6.3 4.10002 6.8 4.40002L7.9 5.19995C8.6 4.79995 9.4 4.39995 10.2 4.19995L10.4 2.90002C10.5 2.40002 11 2 11.5 2H12.6C13.2 2 13.6 2.40002 13.7 2.90002L13.9 4.19995C14.7 4.39995 15.5 4.69995 16.2 5.19995L17.3 4.40002C17.7 4.10002 18.4 4.1 18.8 4.5L19.6 5.29993C20 5.69993 20 6.29993 19.7 6.79993L18.9 7.90002C19.3 8.60002 19.7 9.39995 19.9 10.2L21.2 10.4C21.7 10.5 22.1 11 22.1 11.5ZM12.1 8.59998C10.2 8.59998 8.6 10.2 8.6 12.1C8.6 14 10.2 15.6 12.1 15.6C14 15.6 15.6 14 15.6 12.1C15.6 10.2 14 8.59998 12.1 8.59998Z"
                              fill="black"
                            />
                            <path
                              d="M17.1 12.1C17.1 14.9 14.9 17.1 12.1 17.1C9.30001 17.1 7.10001 14.9 7.10001 12.1C7.10001 9.29998 9.30001 7.09998 12.1 7.09998C14.9 7.09998 17.1 9.29998 17.1 12.1ZM12.1 10.1C11 10.1 10.1 11 10.1 12.1C10.1 13.2 11 14.1 12.1 14.1C13.2 14.1 14.1 13.2 14.1 12.1C14.1 11 13.2 10.1 12.1 10.1Z"
                              fill="black"
                            />
                          </svg>
                        </span>
                      </a>
                    </span>
                  </div>
                </div>
              </div>
            </div>

            <div
              className="sec3 aside-footer flex-column-auto pb-5 pb-lg-10"
              id="kt_aside_footer"
            >
              <div
                className="d-flex flex-center w-100 scroll-px"
                data-bs-toggle="tooltip"
                data-bs-placement="right"
                data-bs-dismiss="click"
                title="Account"
              >
                <button
                  onClick={() =>
                    this.setState({
                      ...this.state,
                      showAcc: !this.state.showAcc,
                    })
                  }
                  type="button"
                  className="btn btn-custom"
                  data-kt-menu-trigger="click"
                  data-kt-menu-overflow="true"
                  data-kt-menu-placement="top-start"
                >
                  <span className="svg-icon svg-icon-2x m-0">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                    >
                      <path
                        d="M6.28548 15.0861C7.34369 13.1814 9.35142 12 11.5304 12H12.4696C14.6486 12 16.6563 13.1814 17.7145 15.0861L19.3493 18.0287C20.0899 19.3618 19.1259 21 17.601 21H6.39903C4.87406 21 3.91012 19.3618 4.65071 18.0287L6.28548 15.0861Z"
                        fill="black"
                      />
                      <rect
                        opacity="0.3"
                        x="8"
                        y="3"
                        width="8"
                        height="8"
                        rx="4"
                        fill="black"
                      />
                    </svg>
                  </span>
                </button>
                {this.state.showAcc && (
                  <div
                    id="profilePop"
                    style={{
                      position: "fixed",
                      left: "85px",
                      bottom: "0",
                      background: "white",
                      color: "black",
                      borderRadius: "8px",
                      maxWidth: "70%",
                      boxShadow: "0 0 3px lightgray",
                      zIndex: "300",
                    }}
                    className="profilePop menu menu-column menu-rounded menu-gray-800 menu-state-bg menu-state-primary fw-bold py-4 fs-6 w-275px"
                  >
                    <div className="menu-item px-3">
                      <div className="menu-content d-flex align-items-center px-3">
                        <div className="symbol symbol-50px me-5">
                          <span className="svg-icon svg-icon-primary svg-icon-3x ms-n1">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="24"
                              height="24"
                              viewBox="0 0 24 24"
                              fill="none"
                            >
                              <g
                                stroke="none"
                                strokeWidth="1"
                                fill="none"
                                fillRule="evenodd"
                              >
                                <polygon points="0 0 24 0 24 24 0 24" />
                                <path
                                  d="M12,11 C9.790861,11 8,9.209139 8,7 C8,4.790861 9.790861,3 12,3 C14.209139,3 16,4.790861 16,7 C16,9.209139 14.209139,11 12,11 Z"
                                  fill="#000000"
                                  fillRule="nonzero"
                                  opacity="0.3"
                                />
                                <path
                                  d="M3.00065168,20.1992055 C3.38825852,15.4265159 7.26191235,13 11.9833413,13 C16.7712164,13 20.7048837,15.2931929 20.9979143,20.2 C21.0095879,20.3954741 20.9979143,21 20.2466999,21 C16.541124,21 11.0347247,21 3.72750223,21 C3.47671215,21 2.97953825,20.45918 3.00065168,20.1992055 Z"
                                  fill="#000000"
                                  fillRule="nonzero"
                                />
                              </g>
                            </svg>
                          </span>
                        </div>

                        <div className="d-flex flex-column">
                          <div className="fw-bolder d-flex align-items-center fs-5">
                            {this.state.adminInfo?.user?.firstName}{" "}
                            {this.state.adminInfo?.user?.lastName}
                            {/* <span className="badge badge-light-success fw-bolder fs-8 px-2 py-1 ms-2">Pro</span> */}
                          </div>
                          <small className="fw-bold text-muted text-hover-primary fs-7">
                            {this.state.adminInfo?.emailIdentity?.email}
                          </small>
                        </div>
                      </div>
                    </div>

                    <div className="separator my-2"></div>

                    {/* <div className="menu-item px-5">
                              <a href="account/overview.html" className="menu-link px-5">My Profile</a>
                            </div> */}

                    <div className="menu-item px-5 my-1">
                      <a href="/admin/settings" className="menu-link px-5">
                        Account Settings
                      </a>
                    </div>

                    <div className="menu-item px-5">
                      <a href="/logout" className="menu-link px-5">
                        Sign Out
                      </a>
                    </div>

                    {/* <div className="separator my-2"></div>
                            
                            
                            <div className="menu-item px-5">
                              <div className="menu-content px-5">
                                <label className="form-check form-switch form-check-custom form-check-solid pulse pulse-success" for="kt_user_menu_dark_mode_toggle">
                                  <input className="form-check-input w-30px h-20px" type="checkbox" value="1" name="mode" id="kt_user_menu_dark_mode_toggle" data-kt-url="dark/index.html" />
                                  <span className="pulse-ring ms-n1"></span>
                                  <span className="form-check-label text-gray-600 fs-7">Dark Mode</span>
                                </label>
                              </div>
                            </div> */}
                  </div>
                )}
              </div>
            </div>
          </section>
          <section
            id="closeSec"
            onClick={() => this.props.isSidebar && this.props?.closeBar()}
            className="sideRight"
          ></section>
        </div>
      </Fragment>
    );
  }
}

export default SideNav;
