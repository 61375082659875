import React from "react";
import Context from "./Context";
import useGlobalState from "./useGlobalState";

function GlobalProvider({ children }) {
  return (
    <Context.Provider value={useGlobalState()}>{children}</Context.Provider>
  );
}

export default GlobalProvider;
