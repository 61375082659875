import React, { useEffect, useState } from "react";
import pag from "./pag";

function Pagination({ total, setPage, isRequesting, page }) {
  const [current, setCurrent] = useState(page);

  const handlePageChange = (page) => {
    setPage(page);
  };

  useEffect(() => {
    if (page) {
      setPage(+page);
    }
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    setCurrent(+page);
    // eslint-disable-next-line
  }, [page]);

  return (
    <div className={`flex j-c-b w-full py-5`}>
      <div
        className="flex text-black cursor-pointer"
        style={{ width: "100px" }}
        onClick={() => handlePageChange(current - 1)}
      >
        {current - 1 !== 0 && (
          <>
            <i className="fa fa-arrow-left mr-1"></i>
            <p>Previous</p>
          </>
        )}
      </div>

      <div
        className="flex j-c-c cursor-pointer"
        style={{ width: "80%", overflow: "hidden", overflowX: "auto" }}
      >
        <div className="flex" style={{ width: "fit-content" }}>
          {current - 3 > 0 && (
            <div
              onClick={() => handlePageChange(current - 3)}
              className="flex mr-1"
            >
              <i className="fa fa-chevron-left"></i>
              <i className="fa fa-chevron-left"></i>
            </div>
          )}
          {pag(total).map((val) => {
            return (
              <div
                onClick={() => handlePageChange(val)}
                key={val}
                style={{
                  width: "2rem",
                  height: "2rem",
                  display: `${
                    Math.abs(+current - val) < 3 ||
                    val === current ||
                    +current === val
                      ? "flex"
                      : "none"
                  }`,
                }}
                className={`j-c-c mx-2 ${
                  current === val
                    ? "text-white bg-primary"
                    : "text-black bg-white"
                }`}
              >
                {val}
              </div>
            );
          })}
          {current + 3 <= pag(total)[pag(total).length - 1] && (
            <div
              onClick={() => handlePageChange(current + 3)}
              className="flex ml-1"
            >
              <button
                disabled={current + 3 > pag(total)[pag(total).length - 1]}
              >
                <i className="fa fa-chevron-right"></i>
              </button>
              <button
                disabled={current + 3 > pag(total)[pag(total).length - 1]}
              >
                <i className="fa fa-chevron-right"></i>
              </button>
            </div>
          )}
        </div>
      </div>

      <div
        className="flex text-black j-c-e cursor-pointer"
        style={{ width: "100px" }}
        onClick={() => handlePageChange(current + 1)}
      >
        {current + 1 <= pag(total)[pag(total).length - 1] && (
          <>
            <p>Next</p>
            <i className="fa fa-arrow-right ml-1"></i>
          </>
        )}
      </div>
    </div>
  );
}

export default Pagination;
