import React, { Fragment } from "react";
import MetaTags from "react-meta-tags";
import AuthService from "../../misc/AuthService";
import CONSTANTS from "../../misc/Constants";
import axios from "axios";
import ReactGA from "react-ga";

class ResetPassword extends React.Component {
  constructor(props) {
    AuthService.checkGreeter();
    super(props);
    this.state = {
      isLoading: false,
      showForgotPasswordForm: true,
      userName: "",
      password: "",
      isRequesting: false,
      token: "",
      errorMessage: "",
    };
  }

  resetPasswordForm(event, name) {
    let value = event.target.value;
    this.setState({
      ...this.state,
      password: value,
    });
  }

  componentDidMount() {
    ReactGA.pageview(window.location.pathname + window.location.search);
    const {
      match: { params },
    } = this.props;
    if (params.token) {
      axios
        .post(CONSTANTS.API_BASE_URL + "/auth/reset-password-token-check", {
          token: params.token,
        })
        .then((response) => {
          let result = response.data;

          this.setState({
            ...this.state,
            token: params.token,
            isLoading: false,
            userName: result.first_name,
          });
        })
        .catch((error) => {
          window.location = "/not-found";
        });
    } else {
      window.location = "/not-found";
    }
  }

  submitResetPasswordForm = (e) => {
    this.setState({
      ...this.state,
      isRequesting: true,
      errorMessage: "",
    });
    e.preventDefault();

    axios
      .post(CONSTANTS.API_BASE_URL + "/auth/reset-password", {
        token: this.state.token,
        password: this.state.password,
      })
      .then((response) => {
        this.setState({
          ...this.state,
          isRequesting: false,
          showForgotPasswordForm: false,
        });
      })
      .catch((error) => {
        try {
          let errorResponse = error.response.data;

          let errorMessage = "Error: Could not connect to server";
          if (errorResponse.hasOwnProperty("message")) {
            errorMessage = errorResponse.message;
          }

          this.setState({
            ...this.state,
            isRequesting: false,
            errorMessage: errorMessage,
          });
        } catch (e) {
          return; // window.location = "/server-offline";
        }
      });
  };

  render() {
    return (
      <Fragment>
        <MetaTags>
          <title>Reset Password - HodlPay</title>
        </MetaTags>
        <div class="d-flex flex-column flex-root">
          <div class="d-flex flex-column flex-column-fluid bgi-position-y-bottom position-x-center bgi-no-repeat bgi-size-contain bgi-attachment-fixed">
            <div class="d-flex flex-center flex-column flex-column-fluid p-10 pb-lg-20">
              <a href="/login" class="mb-10 pt-lg-10">
                <img
                  alt="Logo"
                  src="/assets/media/logos/hodlpay-black.svg"
                  class="h-70px"
                />
              </a>
              {this.state.showForgotPasswordForm ? (
                <div class="w-lg-550px bg-body rounded shadow-sm p-10 p-lg-15 mx-auto">
                  <form
                    class="form w-100"
                    onSubmit={this.submitResetPasswordForm}
                    method="POST"
                  >
                    <div class="text-center mb-10">
                      <h1 class="text-dark mb-3">Setup New Password</h1>
                      <div class="text-gray-400 fw-bold fs-4">
                        <p>
                          Hi <b>{this.state.userName}</b>, set a new password
                          for your account.
                        </p>
                      </div>
                    </div>
                    <div class="mb-10 fv-row" data-kt-password-meter="true">
                      <div class="mb-1">
                        <label class="form-label fw-bolder text-dark fs-6">
                          Password
                        </label>
                        <div class="position-relative mb-3">
                          <input
                            class="form-control form-control-lg form-control-solid"
                            type="password"
                            value={this.state.password}
                            onChange={(e) => {
                              this.resetPasswordForm(e, "password");
                            }}
                            placeholder="Enter new your password"
                            name="password"
                            autocomplete="off"
                          />
                          <span
                            class="btn btn-sm btn-icon position-absolute translate-middle top-50 end-0 me-n2"
                            data-kt-password-meter-control="visibility"
                          >
                            <i class="bi bi-eye-slash fs-2"></i>
                            <i class="bi bi-eye fs-2 d-none"></i>
                          </span>
                        </div>
                      </div>
                    </div>
                    <div class="text-center">
                      {this.state.isRequesting ? (
                        <button
                          type="button"
                          class="btn btn-lg btn-primary"
                          disabled
                        >
                          <span class="">
                            Please wait...
                            <span class="spinner-border spinner-border-sm align-middle ms-2"></span>
                          </span>
                        </button>
                      ) : (
                        <button type="submit" class="btn btn-lg btn-primary">
                          <span class="indicator-label">Submit</span>
                        </button>
                      )}
                    </div>
                  </form>
                </div>
              ) : (
                <div class="pt-lg-10 mb-10 text-center">
                  <h1 class="fw-bolder fs-2qx text-gray-800 mb-7">
                    Password Reset Successful!
                  </h1>
                  <div class="fs-3 fw-bold text-muted mb-10">
                    You have successfully changed your password. Click on the
                    login button.
                  </div>
                  <div class="text-center mb-10">
                    <a href="/login" class="btn btn-lg btn-primary fw-bolder">
                      Login
                    </a>
                  </div>
                </div>
              )}
            </div>
            <div class="d-flex flex-center flex-column-auto p-10">
              <div class="d-flex align-items-center fw-bold fs-6">
                <a
                  href={`${CONSTANTS.WEB_URL}/#about`}
                  target="_blank"
                  class="text-muted text-hover-primary px-2"
                  rel="noreferrer"
                >
                  About
                </a>
                <a
                  href={`${CONSTANTS.WEB_URL}/terms-and-conditions`}
                  target="_blank"
                  class="text-muted text-hover-primary px-2"
                  rel="noreferrer"
                >
                  Terms and Conditions
                </a>
                <a
                  href={`${CONSTANTS.WEB_URL}/privacy-policy`}
                  target="_blank"
                  class="text-muted text-hover-primary px-2"
                  rel="noreferrer"
                >
                  Privacy Policy
                </a>
              </div>
            </div>
          </div>
        </div>
      </Fragment>
    );
  }
}

export default ResetPassword;
