import React from "react";
import { Redirect } from "react-router-dom";
import { reactLocalStorage } from "reactjs-localstorage";

class Logout extends React.Component {
  render() {
    reactLocalStorage.clear();

    reactLocalStorage.set("isLogin", false);

    sessionStorage.clear();

    sessionStorage.setItem("currentCount", 1000);

    return (
      <Redirect
        to={{
          pathname: `${document.referrer.includes("admin") ? "/admin/login" : "/login"}`,
        }}
      />
    );
  }
}

export default Logout;
