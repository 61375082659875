import React, { useEffect, useState } from "react";

export function Status({ status, color }) {
  const [getStatus, setStatus] = useState(status);
  useEffect(() => {
    let stat = status?.split("");
    let firstChar = status?.[0]?.toUpperCase();
    stat?.splice(0, 1);
    stat = firstChar + stat?.join("");
    if (!status) stat = "Pending";
    setStatus(stat);
  }, [status]);
  let colors = {
    Pending: {
      bg: "rgb(246,150,10,.3)",
      color: "rgb(246,150,10)",
    },
    Complete: {
      bg: "rgb(0, 255, 0, .3)",
      color: "green",
    },
    Failed: {
      bg: "rgb(255, 0, 0, .3)",
      color: "red",
    },
  };

  return (
    <div
      className="flex j-c-c"
      style={{
        minWidth: "70px",
        height: "23px",
        color: `${colors[`${getStatus}`]?.color | colors.Failed}`,
        borderRadius: "3px",
        padding: "0 2px 0 2px",
      }}
    >
      <small>
        <b>{`${getStatus || ""}`}</b>
      </small>
    </div>
  );
}

export function Done() {
  return (
    <div
      className="flex j-c-c round bg-success"
      style={{ width: "20px", height: "20px", marginRight: "3px" }}
    >
      <i className="fa fa-check text-white text-sm"></i>
    </div>
  );
}

export function Undone({ color }) {
  return (
    <div
      className={`flex j-c-c round bg-${color}`}
      style={{ width: "20px", height: "20px", marginRight: "3px" }}
    >
      <i className="fa fa-clock text-white text-sm"></i>
    </div>
  );
}
