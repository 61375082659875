import React, { Fragment } from "react";
import MetaTags from "react-meta-tags";
import AuthService from "../../misc/AuthService";
import CONSTANTS from "../../misc/Constants";
import axios from "axios";
import Constants from "../../misc/Constants";
import ico from "./include/ico.png";
import ReactGA from "react-ga";
import { Formik, Form, Field, ErrorMessage } from "formik";
class ForgotPassword extends React.Component {
  constructor(props) {
    AuthService.checkGreeter();
    super(props);
    this.state = {
      isLoading: false,
      showForgotPasswordForm: true,
      isRequesting: false,
      email: "",
      errorMessage: "",
      isResetPassword: false,
      resetData: {
        token: "",
        password: "",
      },
      showToast: true,
    };
  }

  submitForgotPasswordForm = (values, actions) => {
    this.setState({
      ...this.state,
      isRequesting: true,
      errorMessage: "",
    });
    axios
      .post(CONSTANTS.API_BASE_URL + "/auth/user/forgot/password", {
        ...values,
      })
      .then(() => {
        this.setState({
          ...this.state,
          isRequesting: false,
          showForgotPasswordForm: false,
        });
      })
      .catch((error) => {
        try {
          let errorResponse = error.response.data;

          let errorMessage = "Error: Could not connect to server";
          if (errorResponse.hasOwnProperty("message")) {
            errorMessage = errorResponse.message;
          }

          this.setState({
            ...this.state,
            isRequesting: false,
            errorMessage: errorMessage,
          });
        } catch (e) {
          return; //window.location = "/server-offline";
        }
      });
  };

  resetPassword(e) {
    // e.preventDefault();
    this.setState({
      ...this.state,
      isRequesting: true,
    });

    axios
      .post(
        `${Constants.API_BASE_URL}/auth/user/reset/password`,
        this.state.resetData,
        AuthService.getAxiosHeaders(),
      )
      .then((res) => {
        this.setState({ ...this.state, isRequesting: false, newPassword: "" });
        alert("Password reset Successful");
        window.location = "/login";
      })
      .catch((err) => {
        this.setState({
          ...this.state,
          isRequesting: false,
          errorMessage: err.response.data.message ?? "",
        });
      });
  }

  componentDidMount() {
    ReactGA.pageview(window.location.pathname + window.location.search);
    const {
      match: { params },
    } = this.props;
    this.setState({
      ...this.state,
      token: params.token,
    });

    if (params.token) {
      let code = params.token;
      this.setState({
        ...this.state,
        isResetPassword: true,
        resetData: { ...this.state.resetData, token: code },
      });
    }
  }

  validateEmail = (value) => {
    let error;
    if (!value) {
      error = "Your email can't be empty";
    } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(value)) {
      error = "Invalid email address";
    }
    return error;
  };

  render() {
    return (
      <Fragment>
        <MetaTags>
          <title>Forgot Password - HodlPay</title>
        </MetaTags>
        <div className="d-flex flex-column flex-root">
          <div className="d-flex flex-column flex-column-fluid bgi-position-y-bottom position-x-center bgi-no-repeat bgi-size-contain bgi-attachment-fixed">
            <div className="d-flex flex-center flex-column flex-column-fluid p-10 pb-lg-20">
              <a href="/login" className="mb-10 pt-lg-10">
                <img
                  alt="Logo"
                  src="/assets/media/logos/hodlpay-black.svg"
                  className="h-70px"
                />
              </a>
              {this.state.isResetPassword ? (
                <div className="w-lg-500px bg-body rounded shadow-sm p-10 p-lg-15 mx-auto">
                  <form
                    className="form w-100"
                    onSubmit={(e) => {
                      e.preventDefault();
                      this.resetPassword();
                    }}
                    method="POST"
                  >
                    <div className="text-center mb-10">
                      <h1 className="text-dark mb-3">Forgot Password ?</h1>
                      <div className="text-gray-400 fw-bold fs-4">
                        Enter your new password to reset your password.
                      </div>
                    </div>
                    {this.state.errorMessage.length > 0 && (
                      <div className="alert alert-danger d-flex align-items-center p-5 mb-10 w-100">
                        <span className="svg-icon svg-icon-2hx svg-icon-danger me-4">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            fill="none"
                          >
                            <g
                              stroke="none"
                              stroke-width="1"
                              fill="none"
                              fill-rule="evenodd"
                            >
                              <rect x="0" y="0" width="24" height="24" />
                              <circle
                                fill="#000000"
                                opacity="0.3"
                                cx="12"
                                cy="12"
                                r="10"
                              />
                              <rect
                                fill="#000000"
                                x="11"
                                y="10"
                                width="2"
                                height="7"
                                rx="1"
                              />
                              <rect
                                fill="#000000"
                                x="11"
                                y="7"
                                width="2"
                                height="2"
                                rx="1"
                              />
                            </g>
                          </svg>
                        </span>
                        <div className="d-flex flex-column">
                          <h4 className="mb-1 text-danger">Error</h4>{" "}
                          <span>{this.state.errorMessage}</span>{" "}
                        </div>
                      </div>
                    )}
                    <div className="fv-row mb-10">
                      <label className="form-label fw-bolder text-gray-900 fs-6">
                        New Password
                      </label>
                      <input
                        className="form-control form-control-solid"
                        type="password"
                        placeholder=""
                        name="newPassword"
                        value={this.state.resetData.password}
                        onChange={(e) =>
                          this.setState({
                            ...this.state,
                            resetData: {
                              ...this.state.resetData,
                              password: e.target.value,
                            },
                          })
                        }
                      />
                    </div>

                    <div className="d-flex flex-wrap justify-content-center pb-lg-0">
                      {this.state.isRequesting ? (
                        <button
                          type="button"
                          className="btn btn-lg btn-primary mx-2"
                          disabled
                        >
                          <span className="">
                            Please wait...
                            <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                          </span>
                        </button>
                      ) : (
                        <>
                          <button
                            type="submit"
                            className="btn btn-lg btn-primary"
                          >
                            <span className="indicator-label">Submit</span>
                          </button>
                          <a
                            href="/login"
                            className="btn btn-lg btn-light-primary fw-bolder mx-2"
                          >
                            Back to Sign In
                          </a>
                        </>
                      )}
                    </div>
                  </form>
                </div>
              ) : (
                <>
                  {this.state.showForgotPasswordForm ? (
                    <div className="w-lg-500px bg-body rounded shadow-sm p-10 p-lg-15 mx-auto">
                      {this.state.errorMessage.length > 0 && (
                        <div className="alert alert-danger d-flex align-items-center p-5 mb-10 w-100">
                          <span className="svg-icon svg-icon-2hx svg-icon-danger me-4">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="24"
                              height="24"
                              viewBox="0 0 24 24"
                              fill="none"
                            >
                              <g
                                stroke="none"
                                stroke-width="1"
                                fill="none"
                                fill-rule="evenodd"
                              >
                                <rect x="0" y="0" width="24" height="24" />
                                <circle
                                  fill="#000000"
                                  opacity="0.3"
                                  cx="12"
                                  cy="12"
                                  r="10"
                                />
                                <rect
                                  fill="#000000"
                                  x="11"
                                  y="10"
                                  width="2"
                                  height="7"
                                  rx="1"
                                />
                                <rect
                                  fill="#000000"
                                  x="11"
                                  y="7"
                                  width="2"
                                  height="2"
                                  rx="1"
                                />
                              </g>
                            </svg>
                          </span>
                          <div className="d-flex flex-column">
                            <h4 className="mb-1 text-danger">Error</h4>{" "}
                            <span>{this.state.errorMessage}</span>{" "}
                          </div>
                        </div>
                      )}
                      <Formik
                        initialValues={{ email: "" }}
                        onSubmit={this.submitForgotPasswordForm}
                        validate={(values) => {
                          const errors = {};
                          errors.email = this.validateEmail(values.email);

                          return errors;
                        }}
                      >
                        {({ values, handleChange }) => (
                          <Form className="form w-100">
                            <div className="text-center mb-10">
                              <h1 className="text-dark mb-3">
                                Forgot Password ?
                              </h1>
                              <div className="text-gray-400 fw-bold fs-4">
                                Enter your email to reset your password.
                              </div>
                            </div>
                            <div>
                              <div className="fv-row mb-10">
                                <label
                                  htmlFor="name"
                                  className="form-label fs-6 fw-bolder text-dark"
                                >
                                  Email
                                </label>
                                <Field
                                  className="form-control form-control-lg form-control-solid"
                                  type="text"
                                  name="email"
                                  autoComplete="off"
                                  value={values.email}
                                  onChange={handleChange}
                                />
                                <div
                                  className="flex"
                                  style={{ overflow: "hidden" }}
                                >
                                  <ErrorMessage
                                    className="text-danger animatefromleft"
                                    name="email"
                                    component="div"
                                  />
                                </div>
                              </div>
                              <div className="d-flex flex-wrap justify-content-center pb-lg-0">
                                {this.state.isRequesting ? (
                                  <button
                                    type="button"
                                    className="btn btn-lg btn-primary mx-2"
                                    disabled
                                  >
                                    <span className="">
                                      Please wait...
                                      <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                                    </span>
                                  </button>
                                ) : (
                                  <>
                                    <button
                                      type="submit"
                                      className="btn btn-lg btn-primary"
                                    >
                                      <span className="indicator-label">
                                        Submit
                                      </span>
                                    </button>
                                    <a
                                      href="/login"
                                      className="btn btn-lg btn-light-primary fw-bolder mx-2"
                                    >
                                      Back to Sign In
                                    </a>
                                  </>
                                )}
                              </div>
                            </div>
                          </Form>
                        )}
                      </Formik>
                    </div>
                  ) : (
                    <>
                      {this.state.showToast && (
                        <div
                          class="p-3 animate-top m-2"
                          style={{
                            width: "260px",
                            border: "1px solid rgba(0,0,0,.0.7)",
                            borderRadius: "5px",
                            boxShadow: "0 0 5px -1px rgba(0,0,0,.3)",
                          }}
                        >
                          <div>
                            <div
                              class="flex mb-1 p-1 text-primary"
                              style={{
                                borderBottom: "1px dashed rgba(0,0,0,.2)",
                              }}
                            >
                              <aside
                                style={{
                                  width: "30px",
                                  height: "30px",
                                  overflow: "hidden",
                                  borderRadius: "50%",
                                }}
                                className="m-1"
                              >
                                <img
                                  src={ico}
                                  alt="Hodlpay"
                                  className="img-fit"
                                />
                              </aside>
                              <strong class="me-auto">Reset Password</strong>
                              <button
                                onClick={() =>
                                  this.setState({
                                    ...this.state,
                                    showToast: false,
                                  })
                                }
                                class="btn-close"
                              ></button>
                            </div>
                            <div class="toast-info bg-primary">
                              Please check your spam messages if you don't see
                              mail.
                            </div>
                          </div>
                        </div>
                      )}
                      <div className="pt-lg-10 mb-10 text-center">
                        <h1 className="fw-bolder fs-2qx text-gray-800 mb-7">
                          Password Reset
                        </h1>
                        <div className="fs-3 fw-bold text-muted mb-10">
                          We have sent an email to
                          <small className="link-primary fw-bolder">
                            {" "}
                            {this.state.email}
                          </small>
                          <br />
                          please follow a link to reset your password.
                        </div>
                        <div className="text-center mb-10">
                          <a
                            href="/login"
                            className="btn btn-lg btn-primary fw-bolder"
                          >
                            Skip for now
                          </a>
                        </div>
                        {/* <div className="fs-5">
                            <span className="fw-bold text-gray-700">Did’t receive an email?</span>
                            <a href="" className="link-primary fw-bolder">Resend</a>
                          </div> */}
                      </div>
                    </>
                  )}
                </>
              )}
            </div>
            <div className="d-flex flex-center flex-column-auto p-10">
              <div className="d-flex align-items-center fw-bold fs-6">
                <a
                  href={`${CONSTANTS.WEB_URL}/#about`}
                  target="_blank"
                  className="text-muted text-hover-primary px-2"
                  rel="noreferrer"
                >
                  About
                </a>
                <a
                  href={`${CONSTANTS.WEB_URL}/terms-and-conditions`}
                  target="_blank"
                  className="text-muted text-hover-primary px-2"
                  rel="noreferrer"
                >
                  Terms and Conditions
                </a>
                <a
                  href={`${CONSTANTS.WEB_URL}/privacy-policy`}
                  target="_blank"
                  className="text-muted text-hover-primary px-2"
                  rel="noreferrer"
                >
                  Privacy Policy
                </a>
              </div>
            </div>
          </div>
        </div>
      </Fragment>
    );
  }
}

export default ForgotPassword;
