import React, { useState } from "react";
import style from "../styles/sidebarMobile.module.css";

function AccountPopup({ close, link }) {
  //eslint-disable-next-line
  const [state, setState] = useState({
    showAcc: false,
    userInfo: /*AuthService.getAuth().user*/ "",
  });

  return (
    <div
      id="accPop"
      style={{
        position: "fixed",
        left: "5px",
        bottom: "5px",
        background: "white",
        color: "black",
        borderRadius: "8px",
        boxShadow: "0 0 3px gray",
      }}
      className={`${style.accPop} scaleanimate relative profilePop menu menu-column menu-rounded menu-gray-800 menu-state-bg menu-state-primary fw-bold py-4 fs-6 w-275px`}
    >
      <div className="menu-item px-3 relative">
        <aside
          onClick={close}
          className="border closeAccPop absolute flex j-c-c"
          style={{
            right: "1.5rem",
            top: "0.5rem",
            zIndex: "1222",
            width: "40px",
            height: "40px",
          }}
        >
          <i className="fa fa-times"></i>
        </aside>
        <div className="menu-content d-flex align-items-center px-3">
          <div className="symbol symbol-50px me-5">
            <span className="svg-icon svg-icon-primary svg-icon-3x ms-n1">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
              >
                <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                  <polygon points="0 0 24 0 24 24 0 24" />
                  <path
                    d="M12,11 C9.790861,11 8,9.209139 8,7 C8,4.790861 9.790861,3 12,3 C14.209139,3 16,4.790861 16,7 C16,9.209139 14.209139,11 12,11 Z"
                    fill="#000000"
                    fillRule="nonzero"
                    opacity="0.3"
                  />
                  <path
                    d="M3.00065168,20.1992055 C3.38825852,15.4265159 7.26191235,13 11.9833413,13 C16.7712164,13 20.7048837,15.2931929 20.9979143,20.2 C21.0095879,20.3954741 20.9979143,21 20.2466999,21 C16.541124,21 11.0347247,21 3.72750223,21 C3.47671215,21 2.97953825,20.45918 3.00065168,20.1992055 Z"
                    fill="#000000"
                    fillRule="nonzero"
                  />
                </g>
              </svg>
            </span>
          </div>

          <div className="d-flex flex-column">
            <div className="fw-bolder d-flex align-items-center fs-5">
              {state.userInfo?.user?.firstName} {state.userInfo?.user?.lastName}
              <span className="badge badge-light-success fw-bolder fs-8 px-2 py-1 ms-2">
                Pro
              </span>
            </div>
            <small className="fw-bold text-muted text-hover-primary fs-7">
              {state.userInfo?.emailIdentity?.email}
            </small>
          </div>
        </div>
      </div>

      <div className="separator my-2"></div>

      <div className="menu-item px-5 my-1">
        <a href={`${link ?? "/account-settings"}`} className="menu-link px-5">
          Account Settings
        </a>
      </div>

      <div className="menu-item px-5">
        <a href="/logout" className="menu-link px-5">
          Sign Out
        </a>
      </div>
    </div>
  );
}

export default AccountPopup;
