import React, { useReducer } from "react";

export const ACTIONS = {
  alertOn: "alert-on",
  alertOff: "alert-off",
  modalOn: "turn-on",
  modalOff: "turn-off",
  modal_2_On: "turn-on-2",
  modal_2_Off: "turn-off-2",
  showBack: "showBack",
  hideBack: "hideBack",
};
const reducer = (state, action) => {
  switch (action.type) {
    case ACTIONS.modalOn:
      return {
        ...state,
        modal: {
          on: true,
          content: action.content ?? state.modal?.content ?? "",
          closeAlert: action.closeAlert ?? state.modal?.closeAlert ?? "",
          head: action.head ?? state.modal?.head ?? "",
        },
      };
    case ACTIONS.alertOn:
      return {
        ...state,
        alert: {
          on: true,
          content: action.content ?? state.alert?.content ?? "",
          head: action.head ?? state.modal?.head ?? "",
        },
      };
    case ACTIONS.modal_2_On:
      return {
        ...state,
        modal2: {
          on: true,
          content: action.content ?? state.modal2?.content ?? "",
          head: action.head ?? state.modal2?.head ?? "",
        },
      };
    case ACTIONS.showBack:
      return { ...state, showBack: true };
    case ACTIONS.hideBack:
      return { ...state, showBack: false };
    case ACTIONS.modalOff:
      return { ...state, modal: { ...state.modal, on: false, content: "" } };
    case ACTIONS.alertOff:
      return { ...state, alert: { ...state.alert, on: false, content: "" } };
    case ACTIONS.modal_2_Off:
      return { ...state, modal2: { ...state.modal2, on: false, content: "" } };
    case "name":
      return { ...state, name: action.data };
    default:
      return state;
  }
};
const defaultState = {
  name: "Joshua",
  modal: {
    on: false,
    content: <small>Nothing here</small>,
    closeAlert: "",
  },
  alert: {
    on: false,
    content: "",
    closeAlert: "",
  },
  modal2: {
    on: false,
    content: <small>Nothing here</small>,
  },
  showBack: false,
  stuffs: [
    {
      name: "josh",
    },
    {
      name: "Gideon",
    },
    {
      name: "James",
    },
  ],
};

function useGlobalState() {
  const [state, setState] = useReducer(reducer, defaultState);
  return { state, setState };
}

export default useGlobalState;
